@charset "UTF-8";
:root {
  --polaris-version-number: "7.6.0";
  --polaris-animation-skeleton-shimmer: polaris-SkeletonShimmerAnimation;
}

html,
body {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  text-transform: initial;
  letter-spacing: initial;
  font-weight: 400;
  color: var(--p-text);
}

@media (min-width: 40em) {
  html,
  body {
    font-size: 1.4rem;
  }
}

html,
body,
button {
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif;
}

html {
  position: relative;
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f6f6f7;
}

@media print {
  body {
    background-color: transparent !important;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-size: 1em;
  font-weight: 400;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
}

@keyframes polaris-SkeletonShimmerAnimation {
  0% {
    opacity: 0.45;
  }
  100% {
    opacity: 0.9;
  }
}

.Polaris-Avatar {
  position: relative;
  display: block;
  overflow: hidden;
  min-width: 2.4rem;
  max-width: 100%;
  background: var(--p-surface-neutral);
  color: var(--p-icon-subdued);
  border-radius: 3rem;
  -webkit-user-select: none;
  user-select: none;
}

@media (forced-colors: active) {
  .Polaris-Avatar {
    border: 0.1rem solid transparent;
  }
}

.Polaris-Avatar::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.Polaris-Avatar--hidden {
  visibility: hidden;
}

.Polaris-Avatar--sizeExtraSmall {
  width: 2.4rem;
}

.Polaris-Avatar--sizeSmall {
  width: 3.2rem;
}

.Polaris-Avatar--sizeMedium {
  width: 4rem;
}

.Polaris-Avatar--sizeLarge {
  width: 6rem;
}

.Polaris-Avatar--styleOne {
  color: var(--p-decorative-one-text);
  background: var(--p-decorative-one-surface);
}

.Polaris-Avatar--styleTwo {
  color: var(--p-decorative-two-text);
  background: var(--p-decorative-two-surface);
}

.Polaris-Avatar--styleThree {
  color: var(--p-decorative-three-text);
  background: var(--p-decorative-three-surface);
}

.Polaris-Avatar--styleFour {
  color: var(--p-decorative-four-text);
  background: var(--p-decorative-four-surface);
}

.Polaris-Avatar--styleFive {
  color: var(--p-decorative-five-text);
  background: var(--p-decorative-five-surface);
}

.Polaris-Avatar--hasImage {
  background: transparent;
}

.Polaris-Avatar__Image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: var(--p-background);
  border-radius: 3rem;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

.Polaris-Avatar__Initials {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.Polaris-Avatar__Svg {
  width: 100%;
  height: 100%;
}

.Polaris-VisuallyHidden {
  position: absolute !important;
  width: 0.1rem !important;
  height: 0.1rem !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}

.Polaris-Icon {
  display: block;
  height: 2rem;
  width: 2rem;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
}

.Polaris-Icon--applyColor {
  color: var(--p-surface);
}

.Polaris-Icon--hasBackdrop {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0.4rem;
}

.Polaris-Icon--hasBackdrop::before {
  content: "";
  position: absolute;
  top: -0.4rem;
  bottom: -0.4rem;
  left: -0.4rem;
  right: -0.4rem;
  border-radius: 50%;
}

.Polaris-Icon--colorBase svg {
  fill: var(--p-icon);
}

.Polaris-Icon--colorBase::before {
  background-color: var(--p-surface-neutral);
}

.Polaris-Icon--colorSubdued svg {
  fill: var(--p-icon-subdued);
}

.Polaris-Icon--colorCritical svg {
  fill: var(--p-icon-critical);
}

.Polaris-Icon--colorCritical::before {
  background-color: var(--p-surface-critical);
}

.Polaris-Icon--colorInteractive svg {
  fill: var(--p-interactive);
}

.Polaris-Icon--colorWarning svg {
  fill: var(--p-icon-warning);
}

.Polaris-Icon--colorWarning::before {
  background-color: var(--p-surface-warning);
}

.Polaris-Icon--colorHighlight svg {
  fill: var(--p-icon-highlight);
}

.Polaris-Icon--colorHighlight::before {
  background-color: var(--p-surface-highlight);
}

.Polaris-Icon--colorSuccess svg {
  fill: var(--p-icon-success);
}

.Polaris-Icon--colorSuccess::before {
  background-color: var(--p-surface-success);
}

.Polaris-Icon--colorPrimary svg {
  fill: var(--p-action-primary);
}

.Polaris-Icon__Svg,
.Polaris-Icon__Img {
  position: relative;
  display: block;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

.Polaris-Icon__Placeholder {
  padding-bottom: 100%;
  background: currentColor;
}

@keyframes Polaris-Spinner--loading {
  to {
    transform: rotate(360deg);
  }
}

.Polaris-Spinner svg {
  animation: Polaris-Spinner--loading 500ms linear infinite;
  fill: var(--p-border-highlight);
}

.Polaris-Spinner--sizeSmall svg {
  height: 2rem;
  width: 2rem;
}

.Polaris-Spinner--sizeLarge svg {
  height: 4.4rem;
  width: 4.4rem;
}

.Polaris-Scrollable {
  -webkit-overflow-scrolling: touch;
  position: relative;
}

.Polaris-Scrollable:focus {
  outline: 0.2rem solid var(--p-focused);
  outline-offset: 0.2rem;
}

.Polaris-Scrollable--horizontal {
  overflow-x: auto;
}

.Polaris-Scrollable--vertical {
  overflow-y: auto;
}

.Polaris-Scrollable--verticalHasScrolling {
  overflow-y: scroll;
}

.Polaris-Scrollable--hasTopShadow {
  box-shadow: inset 0 2rem 2rem -2rem var(--p-hint-from-direct-light);
}

.Polaris-Scrollable--hasBottomShadow {
  box-shadow: inset 0 -2rem 2rem -2rem var(--p-hint-from-direct-light);
}

.Polaris-Scrollable--hasTopShadow.Polaris-Scrollable--hasBottomShadow {
  box-shadow: inset 0 2rem 2rem -2rem var(--p-hint-from-direct-light),
    inset 0 -2rem 2rem -2rem var(--p-hint-from-direct-light);
}

.Polaris-Popover {
  max-width: calc(100vw - 3.2rem);
  margin: 0.5rem 0.8rem 1.6rem;
  box-shadow: var(--p-popover-shadow);
  border-radius: var(--p-border-radius-wide);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: left, top;
}

.Polaris-Popover__PopoverOverlay {
  opacity: 0;
  transition: opacity 100ms cubic-bezier(0.25, 0.1, 0.25, 1), transform 100ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transform: translateY(-0.5rem);
}

.Polaris-Popover__PopoverOverlay--entering {
  opacity: 1;
  transform: translateY(0);
}

.Polaris-Popover__PopoverOverlay--open {
  opacity: 1;
  transform: none;
}

.Polaris-Popover__PopoverOverlay--exiting {
  opacity: 1;
  transform: translateY(0);
  transition-duration: 0ms;
}

.Polaris-Popover--measuring:not(.Polaris-Popover__PopoverOverlay--exiting) {
  opacity: 0;
  transform: translateY(-0.5rem);
}

.Polaris-Popover--fullWidth {
  margin: 0.5rem auto 0 auto;
}

.Polaris-Popover--fullWidth .Polaris-Popover__Content {
  max-width: none;
}

.Polaris-Popover--positionedAbove {
  margin: 1.6rem 0.8rem 0.5rem;
}

.Polaris-Popover--positionedAbove.Polaris-Popover--fullWidth {
  margin: 0 auto 0.5rem auto;
}

.Polaris-Popover__Wrapper {
  position: relative;
  overflow: hidden;
  background-color: var(--p-surface);
  border-radius: var(--p-border-radius-wide);
  outline: 0.1rem solid transparent;
}

.Polaris-Popover__Content {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: var(--p-border-radius-base);
  max-width: 40rem;
  max-height: 50rem;
}

.Polaris-Popover__Content:focus {
  outline: none;
}

.Polaris-Popover__Content--fullHeight {
  max-height: none;
}

.Polaris-Popover__Content--fluidContent {
  max-height: none;
  max-width: none;
}

.Polaris-Popover__Pane {
  flex: 1 1;
  max-width: 100%;
}

.Polaris-Popover__Pane + .Polaris-Popover__Pane {
  border-top: 0.1rem solid var(--p-divider);
}

.Polaris-Popover__Pane:focus {
  outline: none;
}

.Polaris-Popover__Pane--fixed {
  overflow: visible;
  flex: 0 0 auto;
}

.Polaris-Popover__Section {
  padding: 1.6rem;
}

.Polaris-Popover__Section + .Polaris-Popover__Section {
  border-top: 0.1rem solid var(--p-divider);
}

.Polaris-Popover__FocusTracker {
  position: absolute !important;
  width: 0.1rem !important;
  height: 0.1rem !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}

@media print {
  .Polaris-Popover__PopoverOverlay--hideOnPrint {
    display: none !important;
  }
}

.Polaris-PositionedOverlay {
  position: absolute;
  z-index: 400;
}

.Polaris-PositionedOverlay--fixed {
  position: fixed;
}

.Polaris-PositionedOverlay--calculating {
  visibility: hidden;
}

.Polaris-PositionedOverlay--preventInteraction {
  pointer-events: none;
}

.Polaris-Badge {
  --p-component-badge-pip-color: var(--p-icon);
  display: inline-flex;
  align-items: center;
  padding: 0.2rem 0.8rem;
  background-color: var(--p-surface-neutral);
  border-radius: 2rem;
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: var(--p-text);
  font-weight: var(--p-badge-font-weight);
}

@media print {
  .Polaris-Badge {
    border: solid 0.01rem var(--p-border);
  }
}

.Polaris-Badge--sizeSmall {
  font-size: 1.2rem;
}

.Polaris-Badge--statusSuccess {
  --p-component-badge-pip-color: var(--p-icon-success);
  background-color: var(--p-surface-success);
  color: var(--p-text);
}

.Polaris-Badge--statusInfo {
  --p-component-badge-pip-color: var(--p-icon-highlight);
  background-color: var(--p-surface-highlight);
  color: var(--p-text);
}

.Polaris-Badge--statusAttention {
  --p-component-badge-pip-color: rgb(138, 97, 22);
  background-color: #ffea8a;
  color: var(--p-text);
}

.Polaris-Badge--statusWarning {
  --p-component-badge-pip-color: var(--p-icon-warning);
  background-color: var(--p-surface-warning);
  color: var(--p-text);
}

.Polaris-Badge--statusCritical {
  --p-component-badge-pip-color: var(--p-icon-critical);
  background-color: var(--p-surface-critical);
  color: var(--p-text);
}

.Polaris-Badge--statusNew {
  background-color: var(--p-surface-neutral);
  color: var(--p-text);
  font-weight: 500;
  border: none;
}

.Polaris-Badge__Pip {
  color: var(--p-component-badge-pip-color);
  height: 0.8rem;
  width: 0.8rem;
  margin-right: 0.4rem;
  margin-left: -0.2rem;
  border: 0.2rem solid currentColor;
  border-radius: 50%;
  flex-shrink: 0;
}

.Polaris-Badge--progressIncomplete .Polaris-Badge__Pip {
  background: transparent;
}

.Polaris-Badge--progressPartiallyComplete .Polaris-Badge__Pip {
  background: linear-gradient(to top, currentColor, currentColor 50%, transparent 50%, transparent);
}

@media print {
  .Polaris-Badge--progressPartiallyComplete .Polaris-Badge__Pip {
    background: none;
    box-shadow: 0 -10.2rem 0 -10rem currentColor inset;
  }
}

.Polaris-Badge--progressComplete .Polaris-Badge__Pip {
  background: currentColor;
}

@media print {
  .Polaris-Badge--progressComplete .Polaris-Badge__Pip {
    background: none;
    box-shadow: 0 0 0 10rem currentColor inset;
  }
}

.Polaris-Badge--withinFilter {
  border-radius: var(--p-border-radius-base);
}

.Polaris-TextStyle--variationPositive {
  color: var(--p-text-success);
}

.Polaris-TextStyle--variationNegative {
  color: var(--p-text-critical);
}

.Polaris-TextStyle--variationWarning {
  color: var(--p-text-warning);
}

.Polaris-TextStyle--variationCode {
  position: relative;
  padding: 0 0.4rem;
  border-radius: 0.3rem;
  background-color: var(--p-surface-subdued);
  display: inline-block;
  font-size: 1.15em;
  box-shadow: inset 0 0 0 0.1rem var(--p-border-subdued);
}

.Polaris-TextStyle--variationCode::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0.1rem solid transparent;
  pointer-events: none;
}

.Polaris-TextStyle--variationStrong {
  font-weight: 600;
}

.Polaris-TextStyle--variationSubdued {
  color: var(--p-text-subdued);
}

@media print {
  .Polaris-TextStyle--variationSubdued {
    color: var(--p-text-subdued);
  }
}

.Polaris-ActionList {
  outline: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

.Polaris-ActionList__Section--withoutTitle:not(:first-child) {
  border-top: 0.1rem solid var(--p-divider);
}

.Polaris-ActionList__Actions {
  outline: none;
  list-style: none;
  margin: 0;
  border-top: 0.1rem solid var(--p-divider);
  padding: 0.8rem;
}

.Polaris-ActionList > .Polaris-ActionList__Section--withoutTitle .Polaris-ActionList__Actions,
.Polaris-ActionList__Section:first-child > .Polaris-ActionList__Section--withoutTitle .Polaris-ActionList__Actions {
  border-top: none;
}

.Polaris-ActionList__Title {
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.6rem;
  text-transform: uppercase;
  padding: 0.4rem 1.6rem 1.2rem 1.6rem;
}

@media (min-width: 40em) {
  .Polaris-ActionList__Title {
    font-size: 1.2rem;
  }
}

.Polaris-ActionList__Title.Polaris-ActionList--firstSectionWithTitle {
  padding-top: 1.2rem;
}

.Polaris-ActionList__Item {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  position: relative;
  display: block;
  width: 100%;
  min-height: 4rem;
  text-align: left;
  cursor: pointer;
  padding: 1rem 0.8rem;
  border-radius: var(--p-border-radius-base);
  border-top: 0.1rem solid transparent;
}

.Polaris-ActionList__Item:focus {
  outline: none;
}

.Polaris-ActionList__Item:visited {
  color: inherit;
}

.Polaris-ActionList__Item::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

@media (forced-colors: active) {
  .Polaris-ActionList__Item {
    border: 0.1rem solid transparent;
  }
}

.Polaris-ActionList__Item:hover {
  background-color: var(--p-surface-hovered);
  text-decoration: none;
  outline: 0.3rem solid transparent;
}

.Polaris-ActionList__Item.Polaris-ActionList--active {
  background-color: var(--p-surface-selected);
}

.Polaris-ActionList__Item.Polaris-ActionList--active svg {
  fill: var(--p-interactive);
}

.Polaris-ActionList__Item.Polaris-ActionList--active::before {
  content: "";
  background-color: var(--p-interactive);
  position: absolute;
  top: 0;
  left: -0.8rem;
  height: 100%;
  display: block;
  width: 0.3rem;
  border-top-right-radius: var(--p-border-radius-base);
  border-bottom-right-radius: var(--p-border-radius-base);
}

.Polaris-ActionList__Item:active {
  background-color: var(--p-surface-pressed);
}

.Polaris-ActionList__Item:active svg {
  fill: var(--p-interactive);
}

.Polaris-ActionList__Item:focus:not(:active) {
  outline: 0.3rem solid transparent;
}

.Polaris-ActionList__Item:focus:not(:active)::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-ActionList__Item.Polaris-ActionList--destructive {
  color: var(--p-interactive-critical);
}

.Polaris-ActionList__Item.Polaris-ActionList--destructive svg {
  fill: var(--p-icon-critical);
}

.Polaris-ActionList__Item.Polaris-ActionList--destructive:hover {
  background-color: var(--p-surface-critical-subdued-hovered);
}

.Polaris-ActionList__Item.Polaris-ActionList--destructive:active,
.Polaris-ActionList__Item.Polaris-ActionList--destructive.Polaris-ActionList--active {
  background-color: var(--p-surface-critical-subdued-pressed);
}

.Polaris-ActionList__Item.Polaris-ActionList--disabled {
  background-image: none;
  color: var(--p-text-disabled);
}

.Polaris-ActionList__Item.Polaris-ActionList--disabled .Polaris-ActionList__Prefix svg,
.Polaris-ActionList__Item.Polaris-ActionList--disabled .Polaris-ActionList__Suffix svg {
  fill: var(--p-icon-disabled);
}

.Polaris-ActionList__Content {
  display: flex;
  align-items: center;
}

.Polaris-ActionList__Prefix {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  border-radius: 0.3rem;
  margin: -1rem 1.6rem -1rem 0;
  background-size: cover;
  background-position: center center;
}

.Polaris-ActionList__Prefix svg {
  fill: var(--p-icon);
}

.Polaris-ActionList__Suffix {
  margin-left: 1.6rem;
}

.Polaris-ActionList__Suffix svg {
  fill: var(--p-icon);
}

.Polaris-ActionList__ContentBlock,
.Polaris-ActionList__ContentBlockInner {
  display: block;
}

.Polaris-ActionList__Text {
  min-width: 0;
  max-width: 100%;
  flex: 1 1 auto;
}

.Polaris-Button {
  position: relative;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 3.6rem;
  min-width: 3.6rem;
  margin: 0;
  padding: 0.7rem 1.6rem;
  background: var(--p-surface);
  box-shadow: var(--p-button-drop-shadow);
  border-radius: var(--p-border-radius-base);
  color: var(--p-text);
  border: 0.1rem solid var(--p-border-neutral-subdued);
  border-top-color: var(--p-border-subdued);
  border-bottom-color: var(--p-border-shadow-subdued);
  line-height: 1;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

.Polaris-Button svg {
  fill: var(--p-icon);
}

.Polaris-Button::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.2rem;
  right: -0.2rem;
  bottom: -0.2rem;
  left: -0.2rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.2rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Button:hover {
  background: var(--p-action-secondary-hovered);
  outline: 0.1rem solid transparent;
}

.Polaris-Button:focus {
  box-shadow: var(--p-button-drop-shadow);
  outline: 0;
}

.Polaris-Button:focus::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Button:active {
  background: var(--p-action-secondary-pressed);
  box-shadow: var(--p-button-drop-shadow);
}

.Polaris-Button:active::after {
  border: none;
  box-shadow: none;
}

.Polaris-Button.Polaris-Button--pressed {
  background: var(--p-action-secondary-depressed);
  box-shadow: var(--p-button-pressed-inner-shadow);
  color: var(--p-text-on-primary);
  border-color: var(--p-border-depressed);
}

.Polaris-Button.Polaris-Button--pressed svg {
  fill: currentColor;
}

@media (-ms-high-contrast: active) {
  .Polaris-Button {
    border: 0.1rem solid windowText;
  }
}

.Polaris-Button.Polaris-Button--disabled {
  transition: none;
  box-shadow: none;
  border-color: var(--p-border-disabled);
  background: var(--p-surface-disabled);
  color: var(--p-text-disabled);
}

.Polaris-Button.Polaris-Button--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Button.Polaris-Button--connectedDisclosure {
  z-index: 10;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Polaris-Button.Polaris-Button--connectedDisclosure::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Polaris-Button.Polaris-Button--connectedDisclosure:focus {
  z-index: 20;
}

.Polaris-Button__Content {
  font-size: 1.5rem;
  font-weight: var(--p-button-font-weight);
  line-height: 1.6rem;
  text-transform: initial;
  letter-spacing: initial;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0.1rem;
  min-height: 0.1rem;
}

@media (min-width: 40em) {
  .Polaris-Button__Content {
    font-size: 1.4rem;
  }
}

.Polaris-Button:not(.Polaris-Button--plain) .Polaris-Button__Content {
  width: 100%;
}

.Polaris-Button--textAlignLeft {
  justify-content: flex-start;
  text-align: left;
}

.Polaris-Button--textAlignLeft .Polaris-Button__Content {
  justify-content: flex-start;
}

.Polaris-Button--textAlignCenter {
  justify-content: center;
  text-align: center;
}

.Polaris-Button--textAlignRight {
  justify-content: flex-end;
  text-align: right;
}

.Polaris-Button--textAlignRight .Polaris-Button__Content {
  justify-content: flex-end;
}

.Polaris-Button__Icon {
  margin-left: -0.4rem;
}

.Polaris-Button__Icon:last-child {
  margin-right: -0.8rem;
  margin-left: 0.4rem;
}

.Polaris-Button--fullWidth.Polaris-Button--textAlignLeft .Polaris-Button__Icon:last-child:not(:only-child) {
  margin-left: auto;
}

.Polaris-Button__Icon + *:not(.Polaris-Button__Icon) {
  margin-left: 0.4rem;
}

.Polaris-Button--hidden {
  visibility: hidden;
}

.Polaris-Button__Spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1rem;
  margin-left: -1rem;
}

.Polaris-Button--primary,
.Polaris-Button--destructive {
  position: relative;
  background: var(--p-button-color);
  border-width: 0;
  border-color: transparent;
  box-shadow: var(--p-button-drop-shadow), var(--p-button-inner-shadow);
  color: var(--p-button-text);
}

.Polaris-Button--primary::after,
.Polaris-Button--destructive::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Button--primary:hover,
.Polaris-Button--destructive:hover {
  background: var(--p-button-color-hover);
  border-color: transparent;
  color: var(--p-button-text);
}

.Polaris-Button--primary:focus,
.Polaris-Button--destructive:focus {
  border-color: transparent;
  box-shadow: var(--p-button-drop-shadow), var(--p-button-inner-shadow);
}

.Polaris-Button--primary:active,
.Polaris-Button--destructive:active {
  background: var(--p-button-color-active);
  border-color: transparent;
  box-shadow: var(--p-button-drop-shadow), var(--p-button-inner-shadow);
}

.Polaris-Button--primary.Polaris-Button--pressed,
.Polaris-Button--destructive.Polaris-Button--pressed {
  color: var(--p-button-text);
  background: var(--p-button-color-depressed);
  border-color: transparent;
  box-shadow: var(--p-button-drop-shadow), var(--p-button-inner-shadow);
}

.Polaris-Button--primary.Polaris-Button--pressed:hover,
.Polaris-Button--primary.Polaris-Button--pressed:focus,
.Polaris-Button--destructive.Polaris-Button--pressed:hover,
.Polaris-Button--destructive.Polaris-Button--pressed:focus {
  background: var(--p-button-color-depressed);
  box-shadow: var(--p-button-drop-shadow), var(--p-button-inner-shadow);
}

.Polaris-Button--primary.Polaris-Button--disabled,
.Polaris-Button--destructive.Polaris-Button--disabled {
  color: var(--p-text-disabled);
  box-shadow: none;
  border-color: transparent;
}

.Polaris-Button--primary.Polaris-Button--disabled svg,
.Polaris-Button--destructive.Polaris-Button--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Button--primary {
  --p-button-color: var(--p-action-primary);
  --p-button-text: var(--p-text-on-primary);
  --p-button-color-hover: var(--p-action-primary-hovered);
  --p-button-color-active: var(--p-action-primary-pressed);
  --p-button-color-depressed: var(--p-action-primary-depressed);
}

.Polaris-Button--primary svg {
  fill: var(--p-icon-on-primary);
}

.Polaris-Button--primary.Polaris-Button--disabled {
  background: var(--p-action-primary-disabled);
}

.Polaris-Button--destructive {
  --p-button-color: var(--p-action-critical);
  --p-button-text: var(--p-text-on-critical);
  --p-button-color-hover: var(--p-action-critical-hovered);
  --p-button-color-active: var(--p-action-critical-pressed);
  --p-button-color-depressed: var(--p-action-critical-depressed);
}

.Polaris-Button--destructive svg {
  fill: var(--p-icon-on-critical);
}

.Polaris-Button--destructive.Polaris-Button--disabled {
  background: var(--p-action-critical-disabled);
}

.Polaris-Button--outline {
  background: transparent;
  border: 0.1rem solid var(--p-border);
  box-shadow: none;
  color: var(--p-text);
  position: relative;
}

.Polaris-Button--outline::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.2rem;
  right: -0.2rem;
  bottom: -0.2rem;
  left: -0.2rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.2rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Button--outline:hover {
  border: 0.1rem solid var(--p-border);
  box-shadow: none;
  background: var(--p-surface-hovered);
}

.Polaris-Button--outline:focus {
  border: 0.1rem solid var(--p-border);
  box-shadow: none;
}

.Polaris-Button--outline:focus::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Button--outline:active {
  border: 0.1rem solid var(--p-border);
  box-shadow: none;
  background: var(--p-surface-pressed);
}

.Polaris-Button--outline:active::after {
  box-shadow: none;
}

.Polaris-Button--outline.Polaris-Button--pressed {
  background: var(--p-action-secondary-pressed);
  border: 0.1rem solid var(--p-border);
  box-shadow: none;
  color: var(--p-button-text);
}

.Polaris-Button--outline.Polaris-Button--disabled {
  border: 0.1rem solid var(--p-border-disabled);
  box-shadow: none;
  background: transparent;
  color: var(--p-text-disabled);
}

.Polaris-Button--outline.Polaris-Button--destructive {
  background: transparent;
  border: 0.1rem solid var(--p-border-critical);
  box-shadow: none;
  color: var(--p-interactive-critical);
}

.Polaris-Button--outline.Polaris-Button--destructive svg {
  fill: var(--p-icon-critical);
}

.Polaris-Button--outline.Polaris-Button--destructive:hover {
  border: 0.1rem solid var(--p-border-critical);
  background: var(--p-surface-critical-subdued);
}

.Polaris-Button--outline.Polaris-Button--destructive:focus {
  border: 0.1rem solid var(--p-border-critical);
}

.Polaris-Button--outline.Polaris-Button--destructive:focus::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Button--outline.Polaris-Button--destructive:active {
  border: 0.1rem solid var(--p-border-critical);
  background: var(--p-surface-critical-subdued);
}

.Polaris-Button--outline.Polaris-Button--destructive.Polaris-Button--disabled {
  border: 0.1rem solid var(--p-border-critical-disabled);
  background: transparent;
  color: var(--p-interactive-critical-disabled);
}

.Polaris-Button--outline.Polaris-Button--destructive.Polaris-Button--pressed {
  background: var(--p-surface-critical-subdued);
  box-shadow: 0.1rem solid var(--p-border-critical);
  color: var(--p-interactive-critical);
}

.Polaris-Button--outline.Polaris-Button--disabled {
  background: transparent;
  box-shadow: none;
}

.Polaris-Button--destructive.Polaris-Button--outline {
  background: transparent;
  border: 0.1rem solid var(--p-border);
  box-shadow: none;
  color: var(--p-text);
  position: relative;
}

.Polaris-Button--destructive.Polaris-Button--outline::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.2rem;
  right: -0.2rem;
  bottom: -0.2rem;
  left: -0.2rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.2rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Button--destructive.Polaris-Button--outline:hover {
  border: 0.1rem solid var(--p-border);
  box-shadow: none;
  background: var(--p-surface-hovered);
}

.Polaris-Button--destructive.Polaris-Button--outline:focus {
  border: 0.1rem solid var(--p-border);
  box-shadow: none;
}

.Polaris-Button--destructive.Polaris-Button--outline:focus::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Button--destructive.Polaris-Button--outline:active {
  border: 0.1rem solid var(--p-border);
  box-shadow: none;
  background: var(--p-surface-pressed);
}

.Polaris-Button--destructive.Polaris-Button--outline:active::after {
  box-shadow: none;
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed {
  background: var(--p-action-secondary-pressed);
  border: 0.1rem solid var(--p-border);
  box-shadow: none;
  color: var(--p-button-text);
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--disabled {
  border: 0.1rem solid var(--p-border-disabled);
  box-shadow: none;
  background: transparent;
  color: var(--p-text-disabled);
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--destructive {
  background: transparent;
  border: 0.1rem solid var(--p-border-critical);
  box-shadow: none;
  color: var(--p-interactive-critical);
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--destructive svg {
  fill: var(--p-icon-critical);
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--destructive:hover {
  border: 0.1rem solid var(--p-border-critical);
  background: var(--p-surface-critical-subdued);
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--destructive:focus {
  border: 0.1rem solid var(--p-border-critical);
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--destructive:focus::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--destructive:active {
  border: 0.1rem solid var(--p-border-critical);
  background: var(--p-surface-critical-subdued);
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--destructive.Polaris-Button--disabled {
  border: 0.1rem solid var(--p-border-critical-disabled);
  background: transparent;
  color: var(--p-interactive-critical-disabled);
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--destructive.Polaris-Button--pressed {
  background: var(--p-surface-critical-subdued);
  box-shadow: 0.1rem solid var(--p-border-critical);
  color: var(--p-interactive-critical);
}

.Polaris-Button--disabled {
  cursor: default;
  pointer-events: none;
}

@keyframes Polaris-Button--loading {
  to {
    transform: rotate(360deg);
  }
}

.Polaris-Button--loading {
  position: relative;
}

.Polaris-Button--loading,
.Polaris-Button--loading:hover,
.Polaris-Button--loading.Polaris-Button--disabled {
  color: transparent;
}

.Polaris-Button--loading.Polaris-Button--disabled svg {
  fill: var(--p-icon-subdued);
}

.Polaris-Button--plain {
  margin: -0.7rem -0.8rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  background: transparent;
  border: 0;
  box-shadow: none;
  color: var(--p-interactive);
}

.Polaris-Button--plain svg {
  fill: var(--p-interactive);
}

.Polaris-Button--plain > .Polaris-Button__Content {
  font-weight: 400;
  position: relative;
}

.Polaris-Button--plain > .Polaris-Button__Content::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Button--plain:hover,
.Polaris-Button--plain:focus {
  color: var(--p-interactive-hovered);
  background: transparent;
  box-shadow: none;
}

.Polaris-Button--plain:hover svg,
.Polaris-Button--plain:focus svg {
  fill: var(--p-interactive-hovered);
}

.Polaris-Button--plain:hover:not(.Polaris-Button--removeUnderline),
.Polaris-Button--plain:focus:not(.Polaris-Button--removeUnderline) {
  text-decoration: underline;
}

.Polaris-Button--plain.Polaris-Button--pressed,
.Polaris-Button--plain:active {
  color: var(--p-interactive-pressed);
  background: transparent;
  box-shadow: none;
}

.Polaris-Button--plain.Polaris-Button--pressed svg,
.Polaris-Button--plain:active svg {
  fill: var(--p-interactive-pressed);
}

@media (-ms-high-contrast: active) {
  .Polaris-Button--plain.Polaris-Button--pressed,
  .Polaris-Button--plain:active {
    outline: none;
  }
}

.Polaris-Button--plain:focus::after {
  content: none;
}

@media (-ms-high-contrast: active) {
  .Polaris-Button--plain:focus {
    outline: none;
  }
}

@media (-ms-high-contrast: active) {
  .Polaris-Button--plain:focus > .Polaris-Button__Content {
    outline: 0.2rem dotted;
  }
}

.Polaris-Button--plain:focus:not(:active) > .Polaris-Button__Content::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Button--plain:active:not(.Polaris-Button--iconOnly) > .Polaris-Button__Content,
.Polaris-Button--plain.Polaris-Button--pressed:not(.Polaris-Button--iconOnly) > .Polaris-Button__Content {
  padding: 0.2rem 0.5rem;
  margin: -0.2rem -0.5rem;
  background: var(--p-action-secondary-hovered);
  border-radius: 0.3rem;
}

.Polaris-Button--plain.Polaris-Button--fullWidth {
  margin-left: 0;
  margin-right: 0;
}

.Polaris-Button--plain.Polaris-Button--disabled {
  color: var(--p-text-disabled);
  background: none;
}

.Polaris-Button--plain.Polaris-Button--disabled.Polaris-Button--loading {
  color: transparent;
}

.Polaris-Button--plain.Polaris-Button--destructive {
  color: var(--p-interactive-critical);
}

.Polaris-Button--plain.Polaris-Button--destructive svg {
  fill: var(--p-interactive-critical);
}

.Polaris-Button--plain.Polaris-Button--destructive:hover {
  color: var(--p-interactive-critical-hovered);
}

.Polaris-Button--plain.Polaris-Button--destructive:active,
.Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--pressed {
  color: var(--p-interactive-critical-pressed);
}

.Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--disabled {
  color: var(--p-interactive-critical-disabled);
}

.Polaris-Button--plain.Polaris-Button--sizeSlim {
  margin-top: -0.3rem;
  margin-bottom: -0.3rem;
}

.Polaris-Button--plain.Polaris-Button--sizeLarge {
  margin: -1.1rem -2rem;
}

.Polaris-Button--plain.Polaris-Button--iconOnly {
  margin: -0.8rem;
}

.Polaris-Button--plain.Polaris-Button--iconOnly svg {
  fill: var(--p-icon);
}

.Polaris-Button--plain.Polaris-Button--iconOnly:hover svg {
  fill: var(--p-icon-hovered);
}

.Polaris-Button--plain.Polaris-Button--iconOnly:active svg,
.Polaris-Button--plain.Polaris-Button--iconOnly.Polaris-Button--pressed svg {
  fill: var(--p-icon-pressed);
}

.Polaris-Button--plain.Polaris-Button--iconOnly.Polaris-Button--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--iconOnly svg {
  fill: var(--p-action-critical);
}

.Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--iconOnly:hover svg {
  fill: var(--p-action-critical-hovered);
}

.Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--iconOnly:active svg,
.Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--iconOnly.Polaris-Button--pressed svg {
  fill: var(--p-action-critical-pressed);
}

.Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--iconOnly.Polaris-Button--disabled svg {
  fill: var(--p-action-critical-disabled);
}

.Polaris-Button--plain .Polaris-Button__Icon {
  margin-left: 0;
  margin-right: 0;
}

.Polaris-Button--fullWidth {
  display: flex;
  width: 100%;
}

.Polaris-Button--iconOnly {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.Polaris-Button--iconOnly.Polaris-Button--sizeLarge {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

.Polaris-Button--iconOnly .Polaris-Button__Icon:first-child {
  margin-left: 0;
}

.Polaris-Button--iconOnly .Polaris-Button__Icon:last-child {
  margin-right: -0.4rem;
}

.Polaris-Button--iconOnly .Polaris-Button__Icon:only-child {
  margin-right: 0;
}

.Polaris-Button--sizeSlim {
  min-height: 2.8rem;
  padding: 0.3rem 1.2rem;
}

.Polaris-Button--sizeLarge {
  min-height: 4.4rem;
  min-width: 4.4rem;
  padding: 1.1rem 2.4rem;
}

.Polaris-Button--sizeLarge .Polaris-Button__Content {
  font-size: 1.7rem;
  font-weight: var(--p-button-font-weight);
  line-height: 2rem;
  text-transform: initial;
  letter-spacing: initial;
}

@media (min-width: 40em) {
  .Polaris-Button--sizeLarge .Polaris-Button__Content {
    font-size: 1.6rem;
  }
}

.Polaris-Button--monochrome.Polaris-Button--outline,
.Polaris-Button--monochrome.Polaris-Button--plain {
  color: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline svg,
.Polaris-Button--monochrome.Polaris-Button--plain svg {
  fill: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline:hover,
.Polaris-Button--monochrome.Polaris-Button--outline:focus,
.Polaris-Button--monochrome.Polaris-Button--outline:active,
.Polaris-Button--monochrome.Polaris-Button--plain:hover,
.Polaris-Button--monochrome.Polaris-Button--plain:focus,
.Polaris-Button--monochrome.Polaris-Button--plain:active {
  color: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline:hover svg,
.Polaris-Button--monochrome.Polaris-Button--outline:focus svg,
.Polaris-Button--monochrome.Polaris-Button--outline:active svg,
.Polaris-Button--monochrome.Polaris-Button--plain:hover svg,
.Polaris-Button--monochrome.Polaris-Button--plain:focus svg,
.Polaris-Button--monochrome.Polaris-Button--plain:active svg {
  fill: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--disabled,
.Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--disabled {
  color: currentColor;
  opacity: 0.4;
}

.Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--disabled svg,
.Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--disabled svg {
  fill: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--iconOnly svg,
.Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--iconOnly svg {
  fill: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--iconOnly:focus svg,
.Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--iconOnly:active svg,
.Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--iconOnly:focus svg,
.Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--iconOnly:active svg {
  fill: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--iconOnly.Polaris-Button--disabled svg,
.Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--iconOnly.Polaris-Button--disabled svg {
  fill: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--plain .Polaris-Button__Text:not(.Polaris-Button--removeUnderline) {
  text-decoration: underline;
}

.Polaris-Button--monochrome.Polaris-Button--outline {
  position: relative;
  border-color: currentColor;
  box-shadow: 0 0 0 0.1rem currentColor;
  position: relative;
}

.Polaris-Button--monochrome.Polaris-Button--outline::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.3rem;
  right: -0.3rem;
  bottom: -0.3rem;
  left: -0.3rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.3rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Button--monochrome.Polaris-Button--outline::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: currentColor;
  opacity: 0;
  z-index: 0;
}

.Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--pressed {
  background: transparent;
  box-shadow: none;
  color: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--pressed::before {
  opacity: 0.2;
}

.Polaris-Button--monochrome.Polaris-Button--outline:hover,
.Polaris-Button--monochrome.Polaris-Button--outline:focus,
.Polaris-Button--monochrome.Polaris-Button--outline:active {
  background-color: transparent;
  border-color: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline:hover::before,
.Polaris-Button--monochrome.Polaris-Button--outline:focus::before,
.Polaris-Button--monochrome.Polaris-Button--outline:active::before {
  opacity: 0.07;
}

.Polaris-Button--monochrome.Polaris-Button--outline:focus {
  box-shadow: 0 0 0 0.1rem currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline:focus::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Button--monochrome.Polaris-Button--outline:active::after {
  box-shadow: none;
}

.Polaris-Button--monochrome.Polaris-Button--outline:hover::before,
.Polaris-Button--monochrome.Polaris-Button--outline:active::before {
  opacity: 0.05;
}

.Polaris-Button__ConnectedDisclosureWrapper {
  display: flex;
}

.Polaris-Button__ConnectedDisclosure {
  z-index: 10;
  margin-left: -0.1rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 100%;
}

.Polaris-Button__ConnectedDisclosure.Polaris-Button--outline:not(:focus) {
  /* stylelint-disable color-no-hex */
  border-left-color: rgba(210, 213, 216, 0);
}

.Polaris-Button__ConnectedDisclosure:focus,
.Polaris-Button__ConnectedDisclosure:active {
  z-index: 20;
}

.Polaris-Button__ConnectedDisclosure.Polaris-Button--primary,
.Polaris-Button__ConnectedDisclosure.Polaris-Button--destructive {
  margin-left: 0.1rem;
}

.Polaris-Button__ConnectedDisclosure.Polaris-Button--primary.Polaris-Button--outline,
.Polaris-Button__ConnectedDisclosure.Polaris-Button--destructive.Polaris-Button--outline {
  margin-left: 0;
  border-left: 0;
}

.Polaris-Button__ConnectedDisclosure::after {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

[data-buttongroup-segmented="true"] .Polaris-Button,
[data-buttongroup-segmented="true"] .Polaris-Button::after {
  border-radius: 0;
}

[data-buttongroup-segmented="true"] > :first-child .Polaris-Button,
[data-buttongroup-segmented="true"] > :first-child .Polaris-Button::after {
  border-radius: 0;
  border-top-left-radius: var(--p-border-radius-base);
  border-bottom-left-radius: var(--p-border-radius-base);
}

[data-buttongroup-segmented="true"] > :last-child .Polaris-Button,
[data-buttongroup-segmented="true"] > :last-child .Polaris-Button::after {
  border-radius: 0;
  border-top-right-radius: var(--p-border-radius-base);
  border-bottom-right-radius: var(--p-border-radius-base);
}

[data-buttongroup-segmented="true"] > :last-child:first-child .Polaris-Button,
[data-buttongroup-segmented="true"] > :last-child:first-child .Polaris-Button::after {
  border-radius: var(--p-border-radius-base);
}

[data-buttongroup-connected-top="true"] > :first-child .Polaris-Button,
[data-buttongroup-connected-top="true"] > :first-child .Polaris-Button::after {
  border-top-left-radius: 0;
}

[data-buttongroup-connected-top="true"] > :last-child .Polaris-Button,
[data-buttongroup-connected-top="true"] > :last-child .Polaris-Button::after {
  border-top-right-radius: 0;
}

[data-buttongroup-full-width="true"] .Polaris-Button {
  display: flex;
  width: 100%;
}

.Polaris-ButtonGroup {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: -0.8rem;
  margin-left: -0.8rem;
}

.Polaris-ButtonGroup__Item {
  margin-top: 0.8rem;
  margin-left: 0.8rem;
}

.Polaris-ButtonGroup__Item--plain:not(:first-child) {
  margin-left: 1.6rem;
}

.Polaris-ButtonGroup__Item--plain:not(:last-child) {
  margin-right: 0.8rem;
}

.Polaris-ButtonGroup--segmented {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 0;
  margin-left: 0;
}

.Polaris-ButtonGroup--segmented .Polaris-ButtonGroup__Item {
  position: relative;
  z-index: 10;
  margin-top: 0;
  margin-left: 0;
}

.Polaris-ButtonGroup--segmented .Polaris-ButtonGroup__Item:not(:first-child) {
  margin-left: -0.1rem;
}

.Polaris-ButtonGroup--segmented .Polaris-ButtonGroup__Item--focused {
  z-index: 20;
}

.Polaris-ButtonGroup--fullWidth .Polaris-ButtonGroup__Item {
  flex: 1 1 auto;
}

.Polaris-ButtonGroup--extraTight {
  margin-top: -0.4rem;
  margin-left: -0.4rem;
}

.Polaris-ButtonGroup--extraTight .Polaris-ButtonGroup__Item {
  margin-top: 0.4rem;
  margin-left: 0.4rem;
}

.Polaris-ButtonGroup--tight {
  margin-top: -0.8rem;
  margin-left: -0.8rem;
}

.Polaris-ButtonGroup--tight .Polaris-ButtonGroup__Item {
  margin-top: 0.8rem;
  margin-left: 0.8rem;
}

.Polaris-ButtonGroup--loose {
  margin-top: -2rem;
  margin-left: -2rem;
}

.Polaris-ButtonGroup--loose .Polaris-ButtonGroup__Item {
  margin-top: 2rem;
  margin-left: 2rem;
}

.Polaris-Stack {
  margin-top: -1.6rem;
  margin-left: -1.6rem;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.Polaris-Stack > .Polaris-Stack__Item {
  margin-top: 1.6rem;
  margin-left: 1.6rem;
  max-width: 100%;
}

.Polaris-Stack--noWrap {
  flex-wrap: nowrap;
}

.Polaris-Stack--spacingNone {
  margin-top: 0;
  margin-left: 0;
}

.Polaris-Stack--spacingNone > .Polaris-Stack__Item {
  margin-top: 0;
  margin-left: 0;
  max-width: 100%;
}

.Polaris-Stack--spacingExtraTight {
  margin-top: -0.4rem;
  margin-left: -0.4rem;
}

.Polaris-Stack--spacingExtraTight > .Polaris-Stack__Item {
  margin-top: 0.4rem;
  margin-left: 0.4rem;
  max-width: 100%;
}

.Polaris-Stack--spacingTight {
  margin-top: -0.8rem;
  margin-left: -0.8rem;
}

.Polaris-Stack--spacingTight > .Polaris-Stack__Item {
  margin-top: 0.8rem;
  margin-left: 0.8rem;
  max-width: 100%;
}

.Polaris-Stack--spacingBaseTight {
  margin-top: -1.2rem;
  margin-left: -1.2rem;
}

.Polaris-Stack--spacingBaseTight > .Polaris-Stack__Item {
  margin-top: 1.2rem;
  margin-left: 1.2rem;
  max-width: 100%;
}

.Polaris-Stack--spacingLoose {
  margin-top: -2rem;
  margin-left: -2rem;
}

.Polaris-Stack--spacingLoose > .Polaris-Stack__Item {
  margin-top: 2rem;
  margin-left: 2rem;
  max-width: 100%;
}

.Polaris-Stack--spacingExtraLoose {
  margin-top: -3.2rem;
  margin-left: -3.2rem;
}

.Polaris-Stack--spacingExtraLoose > .Polaris-Stack__Item {
  margin-top: 3.2rem;
  margin-left: 3.2rem;
  max-width: 100%;
}

.Polaris-Stack--distributionLeading {
  justify-content: flex-start;
}

.Polaris-Stack--distributionTrailing {
  justify-content: flex-end;
}

.Polaris-Stack--distributionCenter {
  justify-content: center;
}

.Polaris-Stack--distributionEqualSpacing {
  justify-content: space-between;
}

.Polaris-Stack--distributionFill > .Polaris-Stack__Item {
  flex: 1 1 auto;
}

.Polaris-Stack--distributionFillEvenly > .Polaris-Stack__Item {
  flex: 1 1 auto;
}

@supports ((min-width: -webkit-fit-content) or (min-width: -moz-fit-content) or (min-width: fit-content)) {
  .Polaris-Stack--distributionFillEvenly > .Polaris-Stack__Item {
    flex: 1 0;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
  }
}

.Polaris-Stack--alignmentLeading {
  align-items: flex-start;
}

.Polaris-Stack--alignmentTrailing {
  align-items: flex-end;
}

.Polaris-Stack--alignmentCenter {
  align-items: center;
}

.Polaris-Stack--alignmentFill {
  align-items: stretch;
}

.Polaris-Stack--alignmentBaseline {
  align-items: baseline;
}

.Polaris-Stack--vertical {
  flex-direction: column;
  margin-left: 0;
}

.Polaris-Stack--vertical > .Polaris-Stack__Item {
  margin-left: 0;
}

.Polaris-Stack__Item {
  flex: 0 0 auto;
  min-width: 0;
}

.Polaris-Stack__Item--fill {
  flex: 1 1 auto;
}

.Polaris-Heading {
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 2.4rem;
  margin: 0;
}

@media (min-width: 40em) {
  .Polaris-Heading {
    font-size: 1.6rem;
  }
}

@media print {
  .Polaris-Heading {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
}

.Polaris-Card {
  background-color: var(--p-surface);
  box-shadow: var(--p-card-shadow);
  outline: 0.1rem solid transparent;
}

.Polaris-Card + .Polaris-Card {
  margin-top: 1.6rem;
}

@media print {
  .Polaris-Card + .Polaris-Card {
    margin-top: -0.8rem;
  }
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Card {
    border-radius: var(--p-border-radius-wide);
  }
}

@media (min-width: 30.625em) {
  .Polaris-Card {
    border-radius: var(--p-border-radius-wide);
  }
}

@media print {
  .Polaris-Card {
    box-shadow: none;
  }
}

.Polaris-Card--subdued {
  background-color: var(--p-surface-subdued);
}

@media print {
  .Polaris-Card__Section--hideOnPrint,
  .Polaris-Card--hideOnPrint {
    display: none !important;
  }
}

.Polaris-Card__Header {
  padding: 1.6rem 1.6rem 0;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Card__Header {
    padding: 2rem 2rem 0;
  }
}

@media (min-width: 30.625em) {
  .Polaris-Card__Header {
    padding: 2rem 2rem 0;
  }
}

@media print and (max-width: 48.0625em) and (min-width: 30.625em), print and (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Card__Header {
    padding: 0.8rem 1.6rem 0;
  }
}

@media print and (min-width: 30.625em) {
  .Polaris-Card__Header {
    padding: 0.8rem 1.6rem 0;
  }
}

.Polaris-Card__Section {
  padding: 1.6rem;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Card__Section {
    padding: 2rem;
  }
}

@media (min-width: 30.625em) {
  .Polaris-Card__Section {
    padding: 2rem;
  }
}

.Polaris-Card__Section + .Polaris-Card__Section {
  border-top: 0.1rem solid var(--p-divider);
}

@media print {
  .Polaris-Card__Section + .Polaris-Card__Section {
    border-top: 0;
  }
}

@media print {
  .Polaris-Card__Section {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
}

.Polaris-Card__Section:first-child {
  border-top-left-radius: var(--p-border-radius-wide);
  border-top-right-radius: var(--p-border-radius-wide);
}

.Polaris-Card__Section:last-child {
  border-bottom-left-radius: var(--p-border-radius-wide);
  border-bottom-right-radius: var(--p-border-radius-wide);
}

.Polaris-Card__Section--fullWidth {
  padding: 1.6rem 0;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Card__Section--fullWidth {
    padding: 2rem 0;
  }
}

@media (min-width: 30.625em) {
  .Polaris-Card__Section--fullWidth {
    padding: 2rem 0;
  }
}

.Polaris-Card__Section--flush {
  padding: 0;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Card__Section--flush {
    padding: 0;
  }
}

@media (min-width: 30.625em) {
  .Polaris-Card__Section--flush {
    padding: 0;
  }
}

.Polaris-Card__Section--subdued {
  background-color: var(--p-surface-subdued);
}

@media (-ms-high-contrast: active) {
  .Polaris-Card__Section--subdued {
    background-color: transparent;
  }
}

.Polaris-Card__Header + .Polaris-Card__Section--subdued {
  border-top: 0.1rem solid var(--p-divider);
  margin-top: 2rem;
}

.Polaris-Card__SectionHeader {
  padding-bottom: 0.8rem;
}

.Polaris-Card__Section--fullWidth .Polaris-Card__SectionHeader {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Card__Section--fullWidth .Polaris-Card__SectionHeader {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 30.625em) {
  .Polaris-Card__Section--fullWidth .Polaris-Card__SectionHeader {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.Polaris-Card__Subsection + .Polaris-Card__Subsection {
  margin-top: 1.6rem;
  padding-top: 1.6rem;
  border-top: 0.1rem solid var(--p-divider);
}

@media print {
  .Polaris-Card__Subsection + .Polaris-Card__Subsection {
    border-top: 0;
  }
}

@media print {
  .Polaris-Card__Subsection {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
}

.Polaris-Card__Footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 1.6rem 1.6rem;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Card__Footer {
    padding: 0 2rem 2rem;
  }
}

@media (min-width: 30.625em) {
  .Polaris-Card__Footer {
    padding: 0 2rem 2rem;
  }
}

.Polaris-Card__Footer.Polaris-Card__LeftJustified {
  justify-content: flex-start;
}

.Polaris-Card__Section--subdued + .Polaris-Card__Footer {
  border-top: 0.1rem solid var(--p-divider);
  padding: 2rem;
}

.Polaris-Subheading {
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.6rem;
  text-transform: uppercase;
  margin: 0;
}

@media (min-width: 40em) {
  .Polaris-Subheading {
    font-size: 1.2rem;
  }
}

@media print {
  .Polaris-Subheading {
    font-size: 1.1rem;
  }
}

.Polaris-SettingAction {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: -1.6rem;
  margin-left: -1.6rem;
}

.Polaris-SettingAction__Setting,
.Polaris-SettingAction__Action {
  flex: 0 0 auto;
  margin-top: 1.6rem;
  margin-left: 1.6rem;
  max-width: calc(100% - 1.6rem);
  min-width: 0;
}

.Polaris-SettingAction__Setting {
  flex: 1 0 35rem;
}

.Polaris-AccountConnection__TermsOfService {
  margin-top: 2rem;
}

.Polaris-AccountConnection__Content > * + * {
  margin-top: 0.8rem;
}

.Polaris-ActionMenu-SecondaryAction a,
.Polaris-ActionMenu-SecondaryAction button {
  position: relative;
  background: transparent !important;
  box-shadow: none !important;
  border-radius: var(--p-border-radius-base) !important;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

.Polaris-ActionMenu-SecondaryAction a::after,
.Polaris-ActionMenu-SecondaryAction button::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.2rem;
  right: -0.2rem;
  bottom: -0.2rem;
  left: -0.2rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.2rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-ActionMenu-SecondaryAction a:hover,
.Polaris-ActionMenu-SecondaryAction button:hover {
  background: var(--p-background-hovered) !important;
}

.Polaris-ActionMenu-SecondaryAction a:active,
.Polaris-ActionMenu-SecondaryAction button:active {
  background: var(--p-background-pressed) !important;
}

@media (min-width: 48em) {
  .Polaris-ActionMenu-SecondaryAction a,
  .Polaris-ActionMenu-SecondaryAction button {
    border: none !important;
    position: relative;
  }
  .Polaris-ActionMenu-SecondaryAction a::after,
  .Polaris-ActionMenu-SecondaryAction button::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -0.1rem;
    right: -0.1rem;
    bottom: -0.1rem;
    left: -0.1rem;
    display: block;
    pointer-events: none;
    box-shadow: 0 0 0 -0.1rem var(--p-focused);
    transition: box-shadow 100ms var(--p-ease);
    border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  }
}

.Polaris-ActionMenu-MenuGroup__Details {
  margin-top: -1.6rem;
  padding: 1.6rem;
}

.Polaris-ActionMenu-Actions__ActionsLayout {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 auto;
}

.Polaris-ActionMenu-Actions__ActionsLayout > * {
  flex: 0 0 auto;
}

.Polaris-ActionMenu-RollupActions__RollupActivator {
  text-align: right;
}

.Polaris-ActionMenu-RollupActions__RollupActivator button[type="button"] {
  margin: 0;
  border: 0.1rem solid var(--p-border-neutral-subdued);
}

.Polaris-ActionMenu-RollupActions__RollupActivator button[type="button"]:hover,
.Polaris-ActionMenu-RollupActions__RollupActivator button[type="button"]:active,
.Polaris-ActionMenu-RollupActions__RollupActivator button[type="button"]:focus {
  border: 0.1rem solid var(--p-border-neutral-subdued);
}

.Polaris-ActionMenu {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

@media print {
  .Polaris-ActionMenu {
    display: none !important;
  }
}

.Polaris-Combobox__Listbox {
  padding: 0.8rem 0;
  overflow: visible;
}

.Polaris-Label {
  -webkit-tap-highlight-color: transparent;
}

.Polaris-Label--hidden {
  position: absolute !important;
  width: 0.1rem !important;
  height: 0.1rem !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}

.Polaris-Label__Text {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  text-transform: initial;
  letter-spacing: initial;
  display: block;
  flex: 1 1 auto;
  color: currentColor;
  -webkit-tap-highlight-color: transparent;
}

@media (min-width: 40em) {
  .Polaris-Label__Text {
    font-size: 1.4rem;
  }
}

.Polaris-Label__RequiredIndicator::after {
  content: "*";
  color: var(--p-text-critical);
  margin-left: 0.4rem;
}

.Polaris-InlineError {
  display: flex;
  color: var(--p-text-critical);
  fill: var(--p-icon-critical);
}

.Polaris-InlineError__Icon {
  fill: currentColor;
  margin-left: -0.2rem;
  margin-right: 0.6rem;
}

.Polaris-Labelled--hidden > .Polaris-Labelled__LabelWrapper {
  position: absolute !important;
  width: 0.1rem !important;
  height: 0.1rem !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}

.Polaris-Labelled__LabelWrapper {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 0.4rem;
}

.Polaris-Labelled__HelpText {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  text-transform: initial;
  letter-spacing: initial;
  color: var(--p-text-subdued);
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-top: 0.4rem;
}

@media (min-width: 40em) {
  .Polaris-Labelled__HelpText {
    font-size: 1.4rem;
  }
}

.Polaris-Labelled__Error {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-top: 0.4rem;
}

.Polaris-Labelled__Action {
  flex: 0 0 auto;
}

.Polaris-Connected {
  position: relative;
  display: flex;
}

.Polaris-Connected__Item {
  position: relative;
  z-index: 10;
  flex: 0 0 auto;
}

.Polaris-Connected__Item:not(:first-child) {
  margin-left: 0.4rem;
}

.Polaris-Connected__Item--primary {
  z-index: 20;
  flex: 1 1 auto;
}

.Polaris-Connected__Item--focused {
  z-index: 30;
}

.Polaris-TextField {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  border: none;
  text-transform: initial;
  letter-spacing: initial;
  position: relative;
  display: flex;
  align-items: center;
  color: var(--p-text);
  cursor: text;
}

@media (min-width: 40em) {
  .Polaris-TextField {
    font-size: 1.4rem;
  }
}

.Polaris-TextField svg {
  fill: var(--p-icon);
}

.Polaris-TextField--multiline {
  padding: 0;
  flex-wrap: wrap;
}

.Polaris-TextField--multiline > .Polaris-TextField__Input {
  overflow: auto;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  resize: none;
}

.Polaris-TextField--hasValue {
  color: var(--p-text);
}

.Polaris-TextField--focus > .Polaris-TextField__Input,
.Polaris-TextField__Input:focus {
  outline: none;
}

.Polaris-TextField--focus > .Polaris-TextField__Input ~ .Polaris-TextField__Backdrop::after,
.Polaris-TextField__Input:focus ~ .Polaris-TextField__Backdrop::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-TextField--error .Polaris-TextField__Input::placeholder {
  color: #9c9798;
}

.Polaris-TextField--error > .Polaris-TextField__Input ~ .Polaris-TextField__Backdrop {
  background-color: var(--p-surface-critical-subdued);
  border-color: var(--p-border-critical);
}

.Polaris-TextField--error > .Polaris-TextField__Input ~ .Polaris-TextField__Backdrop::after {
  border-color: var(--p-focused);
}

.Polaris-TextField--readOnly > .Polaris-TextField__Backdrop {
  background-color: var(--p-action-secondary-disabled);
}

.Polaris-TextField--disabled {
  color: var(--p-text-disabled);
  cursor: initial;
}

.Polaris-TextField--disabled > .Polaris-TextField__Backdrop {
  background-color: var(--p-surface-disabled);
  border-top-color: var(--p-border-disabled);
}

.Polaris-TextField--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-TextField__Input {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  border: none;
  text-transform: initial;
  letter-spacing: initial;
  position: relative;
  z-index: 20;
  display: block;
  flex: 1 1;
  width: 100%;
  min-width: 0;
  min-height: 3.6rem;
  margin: 0;
  padding: 0.5rem 1.2rem;
  background: none;
  border: 0.1rem solid transparent;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  -webkit-appearance: none;
  appearance: none;
  caret-color: var(--p-text);
  color: var(--p-text);
}

@media (min-width: 40em) {
  .Polaris-TextField__Input {
    font-size: 1.4rem;
  }
}

.Polaris-TextField__Prefix + .Polaris-TextField__Input {
  padding-left: 0;
}

.Polaris-TextField__Input:disabled {
  opacity: 1;
  background: none;
  color: var(--p-text-disabled);
  -webkit-text-fill-color: var(--p-text-disabled);
}

.Polaris-TextField__Input:invalid {
  box-shadow: none;
}

.Polaris-TextField__Input::placeholder {
  color: var(--p-text-subdued);
}

.Polaris-TextField__Input[type="number"] {
  -webkit-appearance: textfield;
  appearance: textfield;
}

.Polaris-TextField__Input[type="number"]::-webkit-outer-spin-button,
.Polaris-TextField__Input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.Polaris-TextField__Input:-webkit-autofill {
  border-radius: var(--p-border-radius-base);
}

.Polaris-TextField__Input--hasClearButton[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}

.Polaris-TextField__Input--suffixed {
  padding-right: 0;
}

.Polaris-TextField__Input--alignRight {
  text-align: right;
}

.Polaris-TextField__Input--alignLeft {
  text-align: left;
}

.Polaris-TextField__Input--alignCenter {
  text-align: center;
}

.Polaris-TextField__Backdrop {
  position: relative;
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--p-surface);
  border: 0.1rem solid var(--p-border-subdued);
  border-top-color: var(--p-border-shadow);
  border-radius: var(--p-border-radius-base);
  pointer-events: none;
}

.Polaris-TextField__Backdrop::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.2rem;
  right: -0.2rem;
  bottom: -0.2rem;
  left: -0.2rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.2rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-TextField__Prefix,
.Polaris-TextField__Suffix {
  position: relative;
  z-index: 20;
  flex: 0 0 auto;
  color: var(--p-text-subdued);
  -webkit-user-select: none;
  user-select: none;
}

.Polaris-TextField__Prefix {
  margin-left: 1.2rem;
  margin-right: 0.8rem;
}

.Polaris-TextField__Suffix {
  margin-left: 0.25em;
  margin-right: 1.2rem;
}

.Polaris-TextField__CharacterCount {
  color: var(--p-text-subdued);
  z-index: 20;
  margin: 0 1.2rem 0 0.25em;
  pointer-events: none;
  text-align: right;
}

.Polaris-TextField__AlignFieldBottom {
  align-self: flex-end;
  width: 100%;
  padding-bottom: 0.8rem;
}

.Polaris-TextField__ClearButton {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  z-index: 20;
  margin: 0 1.2rem 0 0.25em;
}

.Polaris-TextField__ClearButton::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-TextField__ClearButton:focus {
  outline: none;
}

.Polaris-TextField__ClearButton:focus:enabled::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-TextField__ClearButton:disabled {
  cursor: default;
}

.Polaris-TextField__Hidden {
  visibility: hidden;
}

.Polaris-TextField__Spinner {
  --p-text-field-spinner-offset-large: calc(var(--p-text-field-spinner-offset) + 0.1rem);
  z-index: 20;
  margin: var(--p-text-field-spinner-offset-large);
  color: var(--p-icon);
  display: flex;
  align-self: stretch;
  flex-direction: column;
  width: 2.2rem;
  cursor: pointer;
}

.Polaris-TextField__SpinnerIcon {
  height: 1.2rem;
  width: 1.2rem;
}

.Polaris-TextField__Resizer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0;
  visibility: hidden;
  overflow: hidden;
}

.Polaris-TextField__DummyInput {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  border: none;
  text-transform: initial;
  letter-spacing: initial;
  padding: 0.5rem 1.2rem;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

@media (min-width: 40em) {
  .Polaris-TextField__DummyInput {
    font-size: 1.4rem;
  }
}

.Polaris-TextField__Segment {
  --p-text-field-spinner-border-radius: calc(var(--p-border-radius-base) - var(--p-text-field-spinner-offset));
  background: var(--p-surface-neutral);
  border-radius: var(--p-text-field-spinner-border-radius);
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  appearance: none;
  border: none;
}

.Polaris-TextField__Segment:focus {
  outline: none;
}

.Polaris-TextField__Segment:active {
  background: var(--p-surface-neutral-pressed);
}

.Polaris-TextField__Segment:first-child {
  border-top-right-radius: var(--p-text-field-spinner-border-radius);
  margin-bottom: var(--p-text-field-spinner-offset);
}

.Polaris-TextField__Segment:last-child {
  border-bottom-right-radius: var(--p-text-field-spinner-border-radius);
}

.Polaris-TextField__Segment:not(:first-child) {
  margin-top: 0;
}

.Polaris-TextField--monospaced {
  font-family: ui-monospace, SFMono-Regular, SF Mono, Consolas, Liberation Mono, Menlo, monospace;
}

.Polaris-Listbox-Section__SectionGroup {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-bottom: 0.1rem solid var(--p-divider);
}

.Polaris-Listbox-Section--noDivider {
  border-bottom: none;
}

.Polaris-Choice {
  display: inline-flex;
  justify-content: flex-start;
  padding: 0.4rem 0;
  cursor: pointer;
}

.Polaris-Choice--labelHidden {
  padding: 0;
}

.Polaris-Choice--labelHidden > .Polaris-Choice__Label {
  position: absolute !important;
  width: 0.1rem !important;
  height: 0.1rem !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}

.Polaris-Choice--labelHidden .Polaris-Choice__Control {
  margin-top: 0;
  margin-right: 0;
}

.Polaris-Choice--disabled {
  cursor: default;
}

.Polaris-Choice--disabled > .Polaris-Choice__Label {
  color: var(--p-text-disabled);
}

.Polaris-Choice--disabled > .Polaris-Choice__Label:hover {
  cursor: default;
}

@media (-ms-high-contrast: active) {
  .Polaris-Choice--disabled > .Polaris-Choice__Label {
    color: grayText;
  }
}

.Polaris-Choice__Control {
  display: flex;
  flex: 0 0 auto;
  align-items: stretch;
  width: var(--p-choice-size);
  height: var(--p-choice-size);
  margin-right: 0.8rem;
}

.Polaris-Choice__Control > * {
  width: 100%;
}

.Polaris-Choice__Label {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  text-transform: initial;
  letter-spacing: initial;
  -webkit-tap-highlight-color: transparent;
}

@media (min-width: 40em) {
  .Polaris-Choice__Label {
    font-size: 1.4rem;
  }
}

.Polaris-Choice__Label:hover {
  cursor: pointer;
}

.Polaris-Choice__Descriptions {
  padding-left: calc(0.8rem + var(--p-choice-size, 1.6rem));
}

.Polaris-Choice__HelpText {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  text-transform: initial;
  letter-spacing: initial;
  color: var(--p-text-subdued);
  margin-bottom: 0.4rem;
}

@media (min-width: 40em) {
  .Polaris-Choice__HelpText {
    font-size: 1.4rem;
  }
}

.Polaris-Checkbox {
  position: relative;
  margin: var(--p-choice-margin);
}

.Polaris-Checkbox__Input {
  position: absolute !important;
  width: 0.1rem !important;
  height: 0.1rem !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}

.Polaris-Checkbox__Input.Polaris-Checkbox--keyFocused + .Polaris-Checkbox__Backdrop::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Checkbox__Input:active:not(:disabled) + .Polaris-Checkbox__Backdrop,
.Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop,
.Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate + .Polaris-Checkbox__Backdrop {
  border-color: var(--p-interactive);
}

.Polaris-Checkbox__Input:active:not(:disabled) + .Polaris-Checkbox__Backdrop::before,
.Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop::before,
.Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate + .Polaris-Checkbox__Backdrop::before {
  opacity: 1;
  transform: scale(1);
}

@media (-ms-high-contrast: active) {
  .Polaris-Checkbox__Input:active:not(:disabled) + .Polaris-Checkbox__Backdrop::before,
  .Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop::before,
  .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate + .Polaris-Checkbox__Backdrop::before {
    border: 0.2rem solid windowText;
  }
}

.Polaris-Checkbox__Input:active:not(:disabled) ~ .Polaris-Checkbox__Icon,
.Polaris-Checkbox__Input:checked ~ .Polaris-Checkbox__Icon,
.Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate ~ .Polaris-Checkbox__Icon {
  transition: opacity var(--p-duration-1-5-0) var(--p-ease), transform var(--p-duration-1-5-0) var(--p-ease);
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}

.Polaris-Checkbox__Input:disabled + .Polaris-Checkbox__Backdrop {
  border-color: var(--p-border-disabled);
}

.Polaris-Checkbox__Input:disabled + .Polaris-Checkbox__Backdrop::before {
  background-color: var(--p-action-secondary-disabled);
}

.Polaris-Checkbox__Input:disabled + .Polaris-Checkbox__Backdrop:hover {
  cursor: default;
}

.Polaris-Checkbox__Input:disabled:checked + .Polaris-Checkbox__Backdrop {
  background: var(--p-border-disabled);
}

.Polaris-Checkbox__Input:disabled:checked + .Polaris-Checkbox__Backdrop::before {
  background: var(--p-border-disabled);
}

.Polaris-Checkbox__Backdrop {
  position: relative;
  border: var(--p-control-border-width) solid var(--p-border);
  background-color: var(--p-surface);
  border-radius: var(--p-border-radius-base);
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.Polaris-Checkbox__Backdrop::before {
  content: "";
  position: absolute;
  top: calc(var(--p-control-border-width) * -1);
  right: calc(var(--p-control-border-width) * -1);
  bottom: calc(var(--p-control-border-width) * -1);
  left: calc(var(--p-control-border-width) * -1);
  border-radius: var(--p-border-radius-base);
  background-color: var(--p-interactive);
  opacity: 0;
  transform: scale(0.25);
  transition: opacity 100ms var(--p-ease), transform 100ms var(--p-ease);
}

.Polaris-Checkbox__Backdrop.Polaris-Checkbox--hover,
.Polaris-Checkbox__Backdrop:hover {
  cursor: pointer;
  border-color: var(--p-border-hovered);
}

.Polaris-Checkbox__Backdrop::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: calc(var(--p-control-border-width) * -1 + -0.1rem);
  right: calc(var(--p-control-border-width) * -1 + -0.1rem);
  bottom: calc(var(--p-control-border-width) * -1 + -0.1rem);
  left: calc(var(--p-control-border-width) * -1 + -0.1rem);
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 calc(var(--p-control-border-width) * -1 + -0.1rem) var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Checkbox__Icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 50% 50%;
  pointer-events: none;
  transform: translate(-50%, -50%) scale(0.25);
  opacity: 0;
  transition: opacity 100ms var(--p-ease), transform 100ms var(--p-ease);
}

.Polaris-Checkbox__Icon svg {
  fill: var(--p-icon-on-interactive);
}

@media (-ms-high-contrast: active) {
  .Polaris-Checkbox__Icon {
    fill: windowText;
  }
}

.Polaris-Checkbox--error .Polaris-Checkbox__Icon svg {
  fill: var(--p-icon-on-critical);
}

.Polaris-Checkbox--error .Polaris-Checkbox__Backdrop {
  border-color: var(--p-border-critical);
  background-color: var(--p-surface-critical);
}

.Polaris-Checkbox--error .Polaris-Checkbox__Backdrop.Polaris-Checkbox--hover,
.Polaris-Checkbox--error .Polaris-Checkbox__Backdrop:hover {
  border-color: var(--p-border-critical);
}

.Polaris-Checkbox--error .Polaris-Checkbox__Backdrop::before {
  background-color: var(--p-border-critical);
}

.Polaris-Checkbox--error .Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop::before,
.Polaris-Checkbox--error .Polaris-Checkbox__Input:active + .Polaris-Checkbox__Backdrop::before,
.Polaris-Checkbox--error
  .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate
  + .Polaris-Checkbox__Backdrop::before {
  background-color: var(--p-border-critical);
}

.Polaris-Listbox-TextOption {
  margin: 0.4rem 0.8rem 0;
  flex: 1 1;
  border-radius: var(--p-border-radius-base);
  padding: 0.8rem 0.8rem;
  cursor: pointer;
  display: flex;
  position: relative;
}

.Polaris-Listbox-TextOption::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Listbox-TextOption.Polaris-Listbox-TextOption--allowMultiple {
  margin: 0.4rem 0.8rem 0;
  padding: 0.4rem 0.8rem;
}

.Polaris-Listbox-TextOption.Polaris-Listbox-TextOption--isAction {
  margin-top: 0;
  padding: 0.8rem 0.8rem;
}

.Polaris-Listbox-TextOption:hover {
  background-color: var(--p-surface-hovered);
}

.Polaris-Listbox-TextOption:focus {
  outline: none;
}

.Polaris-Listbox-TextOption:active {
  background-color: var(--p-surface-pressed);
}

.Polaris-Listbox-TextOption.Polaris-Listbox-TextOption--disabled {
  background-color: var(--p-surface-disabled);
  color: var(--p-text-disabled);
  cursor: default;
}

.Polaris-Listbox-TextOption.Polaris-Listbox-TextOption--selected {
  background-color: var(--p-surface-selected);
}

.Polaris-Listbox-TextOption.Polaris-Listbox-TextOption--selected::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -0.4rem;
  height: 100%;
  width: var(--p-border-radius-base);
  background-color: var(--p-interactive);
  border-top-right-radius: var(--p-border-radius-base);
  border-bottom-right-radius: var(--p-border-radius-base);
  transform: translateX(-100%);
}

li:first-of-type > .Polaris-Listbox-TextOption {
  margin-top: 0;
}

[data-focused] .Polaris-Listbox-TextOption {
  outline: none;
  background-color: var(--p-surface-selected);
}

[data-focused]:not(:focus) .Polaris-Listbox-TextOption::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Listbox-TextOption__Content {
  flex: 1 1 auto;
  display: flex;
}

.Polaris-Listbox-TextOption__Checkbox {
  pointer-events: none;
}

.Polaris-Listbox-Option {
  display: flex;
  margin: 0;
  padding: 0;
}

.Polaris-Listbox-Option:focus {
  outline: none;
}

.Polaris-Listbox-Option--divider {
  border-bottom: 0.1rem solid var(--p-divider);
}

.Polaris-Listbox-Loading__ListItem {
  padding: 0;
  margin: 0;
}

.Polaris-Listbox-Loading {
  padding: 0.8rem 1.6rem;
  display: grid;
  place-items: center;
}

.Polaris-Listbox-Header {
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.6rem;
  text-transform: uppercase;
  padding: 0.8rem 1.6rem;
  color: var(--p-text-subdued);
}

@media (min-width: 40em) {
  .Polaris-Listbox-Header {
    font-size: 1.2rem;
  }
}

.Polaris-Listbox-Action {
  display: flex;
  flex: 1 1;
}

.Polaris-Listbox-Action__ActionDivider {
  margin-bottom: 0.4rem;
}

.Polaris-Listbox-Action__Icon {
  padding-right: 0.8rem;
}

.Polaris-Listbox {
  padding: 0;
  margin: 0;
  list-style: none;
  max-width: 100%;
}

.Polaris-Listbox:focus {
  outline: none;
}

.Polaris-Autocomplete-MappedOption__Content {
  display: flex;
  flex: 1 1;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
}

.Polaris-Autocomplete-MappedOption__Media {
  padding: 0 0.8rem;
}

.Polaris-Autocomplete-MappedOption__Media svg {
  fill: var(--p-icon);
  color: var(--p-icon-on-interactive);
}

.Polaris-Autocomplete-MappedOption--singleSelectionMedia {
  padding: 0 0.8rem 0 0;
}

.Polaris-Autocomplete-MappedOption--disabledMedia svg {
  fill: var(--p-icon-disabled);
  color: var(--p-text-on-interactive);
}

.Polaris-Autocomplete-MappedAction__ActionContainer {
  margin-bottom: 1.2rem;
}

[data-focused] .Polaris-Autocomplete-MappedAction__Action svg {
  fill: var(--p-interactive);
}

[data-focused] .Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--destructive {
  background-color: var(--p-surface-critical-subdued-pressed);
}

.Polaris-Autocomplete-MappedAction__Action {
  position: relative;
  display: block;
  width: 100%;
  min-height: 4rem;
  text-align: left;
  cursor: pointer;
}

.Polaris-Autocomplete-MappedAction__Action::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Autocomplete-MappedAction__Action:hover {
  background-color: var(--p-surface-hovered);
  text-decoration: none;
}

@media (-ms-high-contrast: active) {
  .Polaris-Autocomplete-MappedAction__Action:hover {
    outline: 0.1rem solid windowText;
  }
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--selected {
  background-color: var(--p-surface-selected);
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--selected svg {
  fill: var(--p-interactive);
}

.Polaris-Autocomplete-MappedAction__Action:active {
  background-color: var(--p-surface-pressed);
}

.Polaris-Autocomplete-MappedAction__Action:active svg {
  fill: var(--p-interactive);
}

.Polaris-Autocomplete-MappedAction__Action:focus:not(:active)::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--destructive {
  color: var(--p-interactive-critical);
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--destructive svg {
  fill: var(--p-icon-critical);
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--destructive:hover {
  background-color: var(--p-surface-critical-subdued-hovered);
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--destructive:active,
.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--destructive.Polaris-Autocomplete-MappedAction--selected {
  background-color: var(--p-surface-critical-subdued-pressed);
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--disabled {
  background-image: none;
  color: var(--p-text-disabled);
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--disabled
  .Polaris-Autocomplete-MappedAction__Prefix
  svg,
.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--disabled
  .Polaris-Autocomplete-MappedAction__Suffix
  svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Autocomplete-MappedAction__Content {
  display: flex;
  align-items: center;
}

.Polaris-Autocomplete-MappedAction__Prefix {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  border-radius: 0.3rem;
  margin: -1rem 1.6rem -1rem 0;
  background-size: cover;
  background-position: center center;
}

.Polaris-Autocomplete-MappedAction__Prefix svg {
  fill: var(--p-icon);
}

.Polaris-Autocomplete-MappedAction__Suffix {
  margin-left: 1.6rem;
}

.Polaris-Autocomplete-MappedAction__Suffix svg {
  fill: var(--p-icon);
}

.Polaris-Autocomplete-MappedAction__Text {
  min-width: 0;
  max-width: 100%;
  flex: 1 1 auto;
}

.Polaris-Autocomplete-MappedAction__Text .Polaris-Autocomplete-MappedAction__ContentWrap {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
}

.Polaris-Autocomplete__Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.8rem 1.6rem;
}

.Polaris-Autocomplete__SectionWrapper > *:not(:first-child) {
  margin-top: 0.8rem;
}

[data-lock-scrolling] {
  overflow-y: scroll;
  margin: 0;
}

[data-lock-scrolling] [data-lock-scrolling-wrapper] {
  overflow: hidden;
  height: 100%;
}

.Polaris-Backdrop {
  position: fixed;
  z-index: 518;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background-color: var(--p-backdrop);
  animation: Polaris-Backdrop__fade--in 200ms 1 forwards;
  opacity: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: opacity;
}

.Polaris-Backdrop--transparent {
  background-color: transparent;
}

.Polaris-Backdrop--belowNavigation {
  z-index: 515;
}

@keyframes Polaris-Backdrop__fade--in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Polaris-Banner {
  --p-rgb-text: 33, 43, 54;
  position: relative;
  display: flex;
}

.Polaris-Banner.Polaris-Banner--statusCritical .Polaris-Banner__PrimaryAction .Polaris-Banner__Button {
  border-color: var(--p-border-critical-subdued);
  background: var(--p-surface-critical-subdued);
}

.Polaris-Banner.Polaris-Banner--statusCritical .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:hover {
  border-color: var(--p-border-critical-subdued);
  background: var(--p-surface-critical-subdued-hovered);
}

.Polaris-Banner.Polaris-Banner--statusCritical .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:active {
  border-color: var(--p-border-critical-subdued);
  background: var(--p-surface-critical-subdued-pressed);
}

.Polaris-Banner.Polaris-Banner--statusCritical
  .Polaris-Banner__PrimaryAction
  .Polaris-Banner__Button:focus:not(:active) {
  border-color: var(--p-border-critical-subdued);
  background: var(--p-surface-critical-subdued);
}

.Polaris-Banner.Polaris-Banner--statusWarning .Polaris-Banner__PrimaryAction .Polaris-Banner__Button {
  border-color: var(--p-border-warning-subdued);
  background: var(--p-surface-warning-subdued);
}

.Polaris-Banner.Polaris-Banner--statusWarning .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:hover {
  border-color: var(--p-border-warning-subdued);
  background: var(--p-surface-warning-subdued-hovered);
}

.Polaris-Banner.Polaris-Banner--statusWarning .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:active {
  border-color: var(--p-border-warning-subdued);
  background: var(--p-surface-warning-subdued-pressed);
}

.Polaris-Banner.Polaris-Banner--statusWarning
  .Polaris-Banner__PrimaryAction
  .Polaris-Banner__Button:focus:not(:active) {
  border-color: var(--p-border-warning-subdued);
  background: var(--p-surface-warning-subdued);
}

.Polaris-Banner.Polaris-Banner--statusInfo .Polaris-Banner__PrimaryAction .Polaris-Banner__Button {
  border-color: var(--p-border-highlight-subdued);
  background: var(--p-surface-highlight-subdued);
}

.Polaris-Banner.Polaris-Banner--statusInfo .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:hover {
  border-color: var(--p-border-highlight-subdued);
  background: var(--p-surface-highlight-subdued-hovered);
}

.Polaris-Banner.Polaris-Banner--statusInfo .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:active {
  border-color: var(--p-border-highlight-subdued);
  background: var(--p-surface-highlight-subdued-pressed);
}

.Polaris-Banner.Polaris-Banner--statusInfo .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:focus:not(:active) {
  border-color: var(--p-border-highlight-subdued);
  background: var(--p-surface-highlight-subdued);
}

.Polaris-Banner.Polaris-Banner--statusSuccess .Polaris-Banner__PrimaryAction .Polaris-Banner__Button {
  border-color: var(--p-border-success-subdued);
  background: var(--p-surface-success-subdued);
}

.Polaris-Banner.Polaris-Banner--statusSuccess .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:hover {
  border-color: var(--p-border-success-subdued);
  background: var(--p-surface-success-subdued-hovered);
}

.Polaris-Banner.Polaris-Banner--statusSuccess .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:active {
  border-color: var(--p-border-success-subdued);
  background: var(--p-surface-success-subdued-pressed);
}

.Polaris-Banner.Polaris-Banner--statusSuccess
  .Polaris-Banner__PrimaryAction
  .Polaris-Banner__Button:focus:not(:active) {
  border-color: var(--p-border-success-subdued);
  background: var(--p-surface-success-subdued);
}

.Polaris-Banner__ContentWrapper {
  flex: 1 1 auto;
}

.Polaris-Banner--withinContentContainer {
  padding: 1.6rem 1.6rem 1.4rem;
  --p-banner-background: var(--p-background);
  --p-banner-border: var(--p-banner-border-default);
  transition: box-shadow 200ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-delay: 100ms;
  box-shadow: var(--p-banner-border);
  position: relative;
  border-radius: var(--p-border-radius-base);
  background-color: var(--p-banner-background);
  outline: 0.1rem solid transparent;
}

.Polaris-Banner--withinContentContainer .Polaris-Banner__ContentWrapper {
  margin-top: -0.2rem;
}

.Polaris-Banner--withinContentContainer .Polaris-Banner__Dismiss {
  top: 1.6rem;
  right: 1.2rem;
  position: absolute;
}

.Polaris-Banner--withinContentContainer .Polaris-Banner__Ribbon {
  padding-right: 1.6rem;
}

.Polaris-Banner--withinContentContainer::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Banner--withinContentContainer:focus {
  outline: none;
}

.Polaris-Banner--withinContentContainer.Polaris-Banner--keyFocused {
  box-shadow: var(--p-banner-border);
}

.Polaris-Banner--withinContentContainer.Polaris-Banner--keyFocused::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Banner--withinContentContainer.Polaris-Banner--statusSuccess {
  --p-banner-background: var(--p-surface-success-subdued);
  --p-banner-border: var(--p-banner-border-success);
}

.Polaris-Banner--withinContentContainer.Polaris-Banner--statusInfo {
  --p-banner-background: var(--p-surface-highlight-subdued);
  --p-banner-border: var(--p-banner-border-highlight);
}

.Polaris-Banner--withinContentContainer.Polaris-Banner--statusWarning {
  --p-banner-background: var(--p-surface-warning-subdued);
  --p-banner-border: var(--p-banner-border-warning);
}

.Polaris-Banner--withinContentContainer.Polaris-Banner--statusCritical {
  --p-banner-background: var(--p-surface-critical-subdued);
  --p-banner-border: var(--p-banner-border-critical);
}

.Polaris-Banner--withinContentContainer + .Polaris-Banner {
  margin-top: 0.8rem;
}

.Polaris-Banner--withinContentContainer .Polaris-Banner__Actions {
  padding: 1.2rem 0 0.4rem 0;
}

.Polaris-Banner--withinPage {
  border-radius: 0 0 0.3rem 0.3rem;
  padding: 2rem 2rem 1.8rem;
  --p-banner-background: var(--p-background);
  --p-banner-border: var(--p-banner-border-default);
  transition: box-shadow 200ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-delay: 100ms;
  box-shadow: var(--p-banner-border);
  position: relative;
  border-radius: var(--p-border-radius-wide);
  background-color: var(--p-banner-background);
  outline: 0.1rem solid transparent;
}

.Polaris-Banner--withinPage .Polaris-Banner__ContentWrapper {
  margin-top: -0.2rem;
}

.Polaris-Banner--withinPage::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-wide) + 0.1rem);
}

.Polaris-Banner--withinPage:focus {
  outline: none;
}

.Polaris-Banner--withinPage.Polaris-Banner--keyFocused {
  box-shadow: var(--p-banner-border);
}

.Polaris-Banner--withinPage.Polaris-Banner--keyFocused::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Banner--withinPage.Polaris-Banner--statusSuccess {
  --p-banner-background: var(--p-surface-success-subdued);
  --p-banner-border: var(--p-banner-border-success);
}

.Polaris-Banner--withinPage.Polaris-Banner--statusInfo {
  --p-banner-background: var(--p-surface-highlight-subdued);
  --p-banner-border: var(--p-banner-border-highlight);
}

.Polaris-Banner--withinPage.Polaris-Banner--statusWarning {
  --p-banner-background: var(--p-surface-warning-subdued);
  --p-banner-border: var(--p-banner-border-warning);
}

.Polaris-Banner--withinPage.Polaris-Banner--statusCritical {
  --p-banner-background: var(--p-surface-critical-subdued);
  --p-banner-border: var(--p-banner-border-critical);
}

.Polaris-Banner--withinPage + .Polaris-Banner {
  margin-top: 2rem;
}

.Polaris-Banner--withinPage .Polaris-Banner__Ribbon {
  padding-right: 1.6rem;
}

.Polaris-Banner--withinPage .Polaris-Banner__Actions {
  padding-top: 1.6rem;
}

.Polaris-Banner--withinPage .Polaris-Banner__Dismiss {
  right: 1.6rem;
  top: 2rem;
  position: absolute;
}

.Polaris-Banner--hasDismiss {
  padding-right: calc(3.2rem + var(--p-icon-size));
}

.Polaris-Banner__Heading {
  word-break: break-word;
}

.Polaris-Banner__Content {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  padding: 0.2rem 0;
}

.Polaris-Banner__Ribbon {
  flex: 0 0 3.2rem;
}

.Polaris-Banner__PrimaryAction {
  margin-right: 0.6rem;
}

.Polaris-Banner__SecondaryAction {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  display: inline-block;
  text-align: left;
  margin: -0.8rem -0.6rem;
  padding: 0.8rem 1.2rem;
  color: var(--p-text);
  padding-left: 0.6rem;
}

.Polaris-Banner__SecondaryAction:focus {
  outline: none;
}

.Polaris-Banner__SecondaryAction:visited {
  color: inherit;
}

.Polaris-Banner__SecondaryAction:hover > .Polaris-Banner__Text {
  box-shadow: 0 -0.2rem 0 0 rgba(var(--p-rgb-text), 0.75) inset;
}

.Polaris-Banner__SecondaryAction:active > .Polaris-Banner__Text {
  box-shadow: 0 -0.2rem 0 0 rgba(var(--p-rgb-text), 0) inset;
}

.Polaris-Banner__SecondaryAction:focus > .Polaris-Banner__Text {
  padding: 0.2rem 0.5rem;
  margin: -0.2rem -0.5rem;
  background: var(--p-action-secondary-hovered);
  border-radius: 0.3rem;
  box-shadow: none;
}

@media (-ms-high-contrast: active) {
  .Polaris-Banner__SecondaryAction:focus > .Polaris-Banner__Text {
    outline: 0.2rem dotted;
  }
}

.Polaris-Banner__Text {
  box-shadow: 0 -0.2rem 0 0 rgba(var(--p-rgb-text), 0.25) inset;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: box-shadow;
  transition: box-shadow 200ms cubic-bezier(0.25, 0.1, 0.25, 1);
}

.Polaris-Banner__Button {
  position: relative;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 3.6rem;
  min-width: 3.6rem;
  margin: 0;
  padding: 0.7rem 1.6rem;
  background: var(--p-surface);
  box-shadow: var(--p-button-drop-shadow);
  border-radius: var(--p-border-radius-base);
  color: var(--p-text);
  border: 0.1rem solid var(--p-border-neutral-subdued);
  border-top-color: var(--p-border-subdued);
  border-bottom-color: var(--p-border-shadow-subdued);
  line-height: 1;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  font-size: 1.5rem;
  font-weight: var(--p-button-font-weight);
  line-height: 1.6rem;
  text-transform: initial;
  letter-spacing: initial;
  position: relative;
  color: var(--p-text);
}

.Polaris-Banner__Button svg {
  fill: var(--p-icon);
}

.Polaris-Banner__Button::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.2rem;
  right: -0.2rem;
  bottom: -0.2rem;
  left: -0.2rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.2rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Banner__Button:hover {
  background: var(--p-action-secondary-hovered);
  outline: 0.1rem solid transparent;
}

.Polaris-Banner__Button:focus {
  box-shadow: var(--p-button-drop-shadow);
  outline: 0;
}

.Polaris-Banner__Button:focus::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Banner__Button:active {
  background: var(--p-action-secondary-pressed);
  box-shadow: var(--p-button-drop-shadow);
}

.Polaris-Banner__Button:active::after {
  border: none;
  box-shadow: none;
}

.Polaris-Banner__Button.Polaris-Banner--pressed {
  background: var(--p-action-secondary-depressed);
  box-shadow: var(--p-button-pressed-inner-shadow);
  color: var(--p-text-on-primary);
  border-color: var(--p-border-depressed);
}

.Polaris-Banner__Button.Polaris-Banner--pressed svg {
  fill: currentColor;
}

@media (-ms-high-contrast: active) {
  .Polaris-Banner__Button {
    border: 0.1rem solid windowText;
  }
}

@media (min-width: 40em) {
  .Polaris-Banner__Button {
    font-size: 1.4rem;
  }
}

.Polaris-Banner__Button::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.3rem;
  right: -0.3rem;
  bottom: -0.3rem;
  left: -0.3rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.3rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Banner__Button:focus::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Banner--loading {
  position: relative;
  color: transparent;
  pointer-events: none;
}

.Polaris-Banner__Spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1rem;
  margin-left: -1rem;
}

.Polaris-Breadcrumbs__Breadcrumb {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  text-transform: initial;
  letter-spacing: initial;
  color: var(--p-text-subdued);
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3.6rem;
  min-width: 3.6rem;
  color: var(--p-text-subdued);
  text-decoration: none;
  margin: 0;
  padding: 0.2rem;
  border-radius: var(--p-border-radius-base);
  border: 0.1rem solid var(--p-border-neutral-subdued);
  position: relative;
}

@media (min-width: 40em) {
  .Polaris-Breadcrumbs__Breadcrumb {
    font-size: 1.4rem;
  }
}

.Polaris-Breadcrumbs__Breadcrumb:focus {
  outline: none;
}

.Polaris-Breadcrumbs__Breadcrumb::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.2rem;
  right: -0.2rem;
  bottom: -0.2rem;
  left: -0.2rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.2rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Breadcrumbs__Breadcrumb:hover {
  background-color: var(--p-surface-hovered);
}

.Polaris-Breadcrumbs__Breadcrumb:active {
  background-color: var(--p-surface-pressed);
}

.Polaris-Breadcrumbs__Breadcrumb:hover,
.Polaris-Breadcrumbs__Breadcrumb:active {
  color: var(--p-text);
  text-decoration: none;
}

.Polaris-Breadcrumbs__Breadcrumb:hover .Polaris-Breadcrumbs__Icon svg,
.Polaris-Breadcrumbs__Breadcrumb:active .Polaris-Breadcrumbs__Icon svg {
  fill: var(--p-icon-pressed);
}

.Polaris-Breadcrumbs__Breadcrumb:focus {
  outline: none;
}

.Polaris-Breadcrumbs__Breadcrumb:focus:not(:active)::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Breadcrumbs__Content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
}

.Polaris-Breadcrumbs__Icon {
  width: 2rem;
  height: 2rem;
  margin: -1rem 0 -1rem -0.8rem;
  margin: 0;
}

.Polaris-Breadcrumbs__Icon svg {
  fill: var(--p-icon);
}

.Polaris-CheckableButton {
  font-size: 1.5rem;
  font-weight: var(--p-button-font-weight);
  line-height: 1.6rem;
  text-transform: initial;
  letter-spacing: initial;
  display: flex;
  align-items: center;
  min-height: 3.6rem;
  min-width: 3.6rem;
  margin: 0;
  padding: 0.7rem 1.6rem;
  line-height: 1;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
  text-align: left;
  border-radius: var(--p-border-radius-base);
  width: 100%;
  background: var(--p-surface);
  box-shadow: var(--p-button-drop-shadow);
  border: 0.1rem solid var(--p-border-neutral-subdued);
  border-top-color: var(--p-border-subdued);
  border-bottom-color: var(--p-border-shadow-subdued);
}

@media (min-width: 40em) {
  .Polaris-CheckableButton {
    font-size: 1.4rem;
  }
}

.Polaris-CheckableButton svg {
  fill: var(--p-icon-on-interactive);
}

[data-buttongroup-segmented="true"] .Polaris-CheckableButton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Polaris-CheckableButton:hover {
  background: var(--p-action-secondary-hovered);
}

.Polaris-CheckableButton:active {
  background: var(--p-action-secondary-pressed);
}

.Polaris-CheckableButton.Polaris-CheckableButton__CheckableButton--measuring {
  font-size: 1.5rem;
  font-weight: 700;
}

.Polaris-CheckableButton.Polaris-CheckableButton__CheckableButton--measuring::before {
  content: "";
  display: inline-block;
  width: 1.5rem;
}

@media (min-width: 28.625em) {
  .Polaris-CheckableButton {
    flex: 0 1 auto;
  }
}

.Polaris-CheckableButton:focus {
  outline: none;
}

.Polaris-CheckableButton.Polaris-CheckableButton__CheckableButton--plain {
  border-radius: 0.3rem;
  box-shadow: none;
  background: transparent;
  border: none;
}

.Polaris-CheckableButton.Polaris-CheckableButton__CheckableButton--plain:hover {
  background: transparent;
}

.Polaris-CheckableButton.Polaris-CheckableButton__CheckableButton--selectMode {
  color: var(--p-text-subdued);
  font-weight: 500;
}

.Polaris-CheckableButton.Polaris-CheckableButton__CheckableButton--selected {
  color: var(--p-text);
}

.Polaris-CheckableButton__Checkbox {
  pointer-events: none;
  height: var(--p-choice-size);
  width: var(--p-choice-size);
  margin-left: calc(-0.9rem - var(--p-control-border-width));
}

.Polaris-CheckableButton__Label {
  flex: 1 1;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  padding: 0.1rem 0;
  margin-left: calc(2rem - var(--p-control-border-width));
}

.Polaris-Indicator::before,
.Polaris-Indicator::after {
  content: "";
  position: absolute;
  background-color: var(--p-border-highlight);
  right: -0.4rem;
  top: -0.4rem;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  border: 0.5rem solid transparent;
}

.Polaris-Indicator--pulseIndicator::before {
  z-index: 1;
  animation: Polaris-Indicator--bounce 5s ease infinite;
}

.Polaris-Indicator--pulseIndicator::after {
  right: -0.4rem;
  top: -0.4rem;
  animation: Polaris-Indicator--pulse 5s ease infinite;
}

@keyframes Polaris-Indicator--bounce {
  from,
  65%,
  85% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.85);
  }
  82.5% {
    transform: scale(1.05);
  }
}

@keyframes Polaris-Indicator--pulse {
  from,
  75% {
    transform: scale(0.85);
    opacity: 1;
  }
  to {
    transform: scale(2.5);
    opacity: 0;
  }
}

.Polaris-BulkActions__Group {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  border: none;
  text-transform: initial;
  letter-spacing: initial;
  width: 100%;
  display: none;
  align-items: center;
  flex-wrap: wrap;
  opacity: 0;
}

@media (min-width: 40em) {
  .Polaris-BulkActions__Group {
    font-size: 1.4rem;
  }
}

.Polaris-BulkActions__Group.Polaris-BulkActions__Group--measuring {
  transition: none;
  display: flex;
  opacity: 0;
}

.Polaris-BulkActions__Group--entering,
.Polaris-BulkActions__Group--exiting {
  opacity: 0;
  display: flex;
}

.Polaris-BulkActions__Group--entered {
  opacity: 1;
  display: flex;
}

.Polaris-BulkActions__Group--exited {
  opacity: 0;
  display: none;
}

@media (min-width: 28.625em) {
  .Polaris-BulkActions__Group--smallScreen {
    display: none;
  }
}

.Polaris-BulkActions__Group--largeScreen {
  display: none;
}

@media (min-width: 28.625em) {
  .Polaris-BulkActions__Group--largeScreen {
    display: flex;
  }
  .Polaris-BulkActions__Group--largeScreen.Polaris-BulkActions__Group--exiting {
    transition: none;
  }
  .Polaris-BulkActions__Group--largeScreen.Polaris-BulkActions__Group--exited {
    opacity: 0;
    display: none;
  }
}

.Polaris-BulkActions__ButtonGroupWrapper {
  width: 100%;
  max-width: 100%;
}

@media (max-width: 28.625em) {
  .Polaris-BulkActions__ButtonGroupWrapper > div > div:first-child {
    flex: 1 1 auto;
  }
}

@media (min-width: 28.625em) {
  .Polaris-BulkActions__ButtonGroupWrapper {
    width: auto;
    justify-content: flex-start;
    margin-right: 0.8rem;
  }
}

.Polaris-BulkActions__Group--measuring .Polaris-BulkActions__ButtonGroupWrapper {
  position: absolute;
  width: auto;
}

.Polaris-BulkActions__BulkActionButton {
  white-space: nowrap;
}

.Polaris-BulkActions__CheckableContainer {
  flex: 1 1;
}

.Polaris-BulkActions--disabled {
  transition: none;
  box-shadow: none;
  border-color: var(--p-border-disabled);
  background: var(--p-surface-disabled);
  color: var(--p-text-disabled);
  cursor: default;
  pointer-events: none;
}

.Polaris-BulkActions--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-BulkActions__PaginatedSelectAll {
  padding: 0.4rem 0;
}

.Polaris-BulkActions__Slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
  transform: translateY(0);
  transition: transform cubic-bezier(0.25, 0.1, 0.25, 1) 200ms;
}

.Polaris-BulkActions__Slide--appear,
.Polaris-BulkActions__Slide--enter,
.Polaris-BulkActions__Slide--exit {
  transform: translateX(-4rem);
}

.Polaris-BulkActions__Slide--appearing,
.Polaris-BulkActions__Slide--entering {
  transform: translateY(0);
}

.Polaris-TextContainer > *:not(:first-child) {
  margin-top: 1.6rem;
}

.Polaris-TextContainer--spacingTight > *:not(:first-child) {
  margin-top: 0.8rem;
}

.Polaris-TextContainer--spacingLoose > *:not(:first-child) {
  margin-top: 2rem;
}

.Polaris-CalloutCard {
  display: flex;
  align-items: center;
}

.Polaris-CalloutCard__Image {
  display: none;
  flex: 0 0 auto;
  width: 10rem;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-CalloutCard__Image {
    display: block;
    margin-left: 2rem;
  }
}

@media (min-width: 30.625em) {
  .Polaris-CalloutCard__Image {
    display: block;
    margin-left: 2rem;
  }
}

.Polaris-CalloutCard__DismissImage {
  margin-right: 2rem;
}

.Polaris-CalloutCard__Content {
  flex: 1 1 auto;
}

.Polaris-CalloutCard__Title {
  margin-bottom: 2rem;
}

.Polaris-CalloutCard__Buttons {
  margin-top: 2rem;
}

.Polaris-CalloutCard__Container {
  position: relative;
}

.Polaris-CalloutCard__Dismiss {
  right: 1.6rem;
  top: 1.6rem;
  position: absolute;
}

.Polaris-CalloutCard--hasDismiss {
  padding-right: calc(3.2rem + var(--p-icon-size));
}

.Polaris-Caption {
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2rem;
  margin: 0;
}

@media (min-width: 40em) {
  .Polaris-Caption {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

.Polaris-RadioButton {
  position: relative;
  margin: var(--p-choice-margin);
}

.Polaris-RadioButton__Input {
  position: absolute !important;
  width: 0.1rem !important;
  height: 0.1rem !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}

.Polaris-RadioButton__Input.Polaris-RadioButton--keyFocused + .Polaris-RadioButton__Backdrop::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-RadioButton__Input.Polaris-RadioButton--keyFocused + .Polaris-RadioButton__Backdrop::after {
  border-radius: 50%;
}

.Polaris-RadioButton__Input:checked + .Polaris-RadioButton__Backdrop {
  border-color: var(--p-interactive);
}

.Polaris-RadioButton__Input:checked + .Polaris-RadioButton__Backdrop::before {
  transition: opacity var(--p-duration-1-5-0) var(--p-ease), transform var(--p-duration-1-5-0) var(--p-ease);
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

.Polaris-RadioButton__Input:disabled + .Polaris-RadioButton__Backdrop {
  border-color: var(--p-border-disabled);
  cursor: default;
}

.Polaris-RadioButton__Input:disabled + .Polaris-RadioButton__Backdrop::before {
  background-color: var(--p-border-disabled);
}

.Polaris-RadioButton__Backdrop {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border: var(--p-control-border-width) solid var(--p-border);
  border-radius: 50%;
  background-color: var(--p-surface);
  transition: border-color 100ms var(--p-ease);
  position: relative;
}

.Polaris-RadioButton__Backdrop::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.1);
  transform-origin: 50% 50%;
  height: var(--p-icon-size);
  width: var(--p-icon-size);
  background-color: var(--p-interactive);
  border-radius: 50%;
  transition: opacity 100ms var(--p-ease), transform 100ms var(--p-ease);
}

@media (forced-colors: active) {
  .Polaris-RadioButton__Backdrop::before {
    border: 0.5rem solid transparent;
  }
}

.Polaris-RadioButton__Backdrop::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: calc(var(--p-control-border-width) * -1 + -0.1rem);
  right: calc(var(--p-control-border-width) * -1 + -0.1rem);
  bottom: calc(var(--p-control-border-width) * -1 + -0.1rem);
  left: calc(var(--p-control-border-width) * -1 + -0.1rem);
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 calc(var(--p-control-border-width) * -1 + -0.1rem) var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-RadioButton__Backdrop::after {
  border-radius: 50%;
}

.Polaris-RadioButton__Backdrop.Polaris-RadioButton--hover,
.Polaris-RadioButton__Backdrop:hover {
  cursor: pointer;
  border-color: var(--p-border-hovered);
}

.Polaris-ChoiceList {
  margin: 0;
  padding: 0;
  border: none;
}

.Polaris-ChoiceList--titleHidden > .Polaris-ChoiceList__Title {
  position: absolute !important;
  width: 0.1rem !important;
  height: 0.1rem !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}

.Polaris-ChoiceList__Choices {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Polaris-ChoiceList__ChoiceChildren {
  margin-bottom: 0.8rem;
  padding-left: calc(0.8rem + var(--p-choice-size, 1.6rem));
}

.Polaris-ChoiceList__ChoiceError {
  margin-top: 0.4rem;
  margin-bottom: 0.8rem;
}

.Polaris-ChoiceList__Title {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  text-transform: initial;
  letter-spacing: initial;
  display: block;
  margin: 0 0 0.4rem;
  padding: 0;
}

@media (min-width: 40em) {
  .Polaris-ChoiceList__Title {
    font-size: 1.4rem;
  }
}

.Polaris-Collapsible {
  padding-top: 0;
  padding-bottom: 0;
  max-height: 0;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: max-height;
  transition-property: max-height;
  transition-duration: 100ms;
  transition-timing-function: cubic-bezier(0, 0, 0.42, 1);
}

.Polaris-Collapsible--isFullyClosed {
  display: none;
}

@media print {
  .Polaris-Collapsible--expandOnPrint {
    max-height: none !important;
    overflow: visible;
    display: block;
  }
}

.Polaris-ColorPicker {
  -webkit-user-select: none;
  user-select: none;
  display: flex;
}

.Polaris-ColorPicker__MainColor {
  background: repeating-conic-gradient(var(--p-surface) 0% 25%, var(--p-surface-neutral-subdued) 0% 50%) 50% / 1.6rem
    1.6rem;
  position: relative;
  overflow: hidden;
  height: 16rem;
  width: 16rem;
  border-radius: var(--p-border-radius-base);
  cursor: pointer;
}

.Polaris-ColorPicker--fullWidth .Polaris-ColorPicker__MainColor {
  width: auto;
  flex-grow: 1;
}

.Polaris-ColorPicker__MainColor .Polaris-ColorPicker__Dragger {
  right: 0.9rem;
  margin: 0;
}

.Polaris-ColorPicker__MainColor .Polaris-ColorPicker__ColorLayer {
  border-radius: var(--p-border-radius-base);
}

.Polaris-ColorPicker__MainColor::after,
.Polaris-ColorPicker__MainColor::before {
  content: "";
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;
  pointer-events: none;
  border-radius: var(--p-border-radius-base);
}

.Polaris-ColorPicker__MainColor::before {
  background: linear-gradient(to right, white, transparent);
}

.Polaris-ColorPicker__MainColor::after {
  background-image: linear-gradient(to top, black, transparent);
}

.Polaris-ColorPicker__Dragger {
  position: relative;
  z-index: 30;
  bottom: 0.9rem;
  transform: none;
  height: 1.8rem;
  width: 1.8rem;
  margin: 0 auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
  background: transparent;
  border: var(--p-border-radius-base) solid var(--p-surface);
  border-radius: 50%;
  pointer-events: none;
}

.Polaris-ColorPicker__HuePicker,
.Polaris-ColorPicker__AlphaPicker {
  position: relative;
  overflow: hidden;
  height: 16rem;
  width: 2.4rem;
  margin-left: 0.8rem;
  border-width: var(--p-border-radius-base);
  border-radius: 8rem;
}

.Polaris-ColorPicker__HuePicker {
  background-image: linear-gradient(to bottom, red 1.8rem, yellow, lime, cyan, blue, magenta, red 14.2rem);
}

.Polaris-ColorPicker__AlphaPicker {
  background: repeating-conic-gradient(var(--p-surface) 0% 25%, var(--p-surface-neutral-subdued) 0% 50%) 50% / 1.6rem
    1.6rem;
}

.Polaris-ColorPicker__ColorLayer {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.Polaris-ColorPicker__Slidable {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.Polaris-DataTable {
  position: relative;
  max-width: 100vw;
}

.Polaris-DataTable--condensed .Polaris-DataTable__Navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1.6rem 0.8rem;
}

@media (min-width: 48em) {
  .Polaris-DataTable--condensed .Polaris-DataTable__Navigation {
    justify-content: flex-end;
  }
}

.Polaris-DataTable__Navigation {
  display: none;
}

.Polaris-DataTable__Pip {
  height: 0.6rem;
  width: 0.6rem;
  background: var(--p-text-subdued);
  border-radius: var(--p-border-radius-base);
}

.Polaris-DataTable__Pip:not(:last-of-type) {
  margin-right: 0.4rem;
}

.Polaris-DataTable__Pip--visible {
  background: var(--p-text);
}

.Polaris-DataTable__ScrollContainer {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.Polaris-DataTable__Table {
  width: 100%;
  border-spacing: 0;
}

@media (min-width: 48em) {
  .Polaris-DataTable--hoverable:hover .Polaris-DataTable__Cell {
    background: var(--p-surface-hovered);
  }
}

.Polaris-DataTable__TableRow + .Polaris-DataTable__TableRow .Polaris-DataTable__Cell {
  border-top: 0.1rem solid var(--p-divider);
}

.Polaris-DataTable__Cell {
  padding: 1.6rem;
  white-space: nowrap;
  text-align: left;
  transition: background-color 0.2s ease-in-out;
}

.Polaris-DataTable__Cell--firstColumn {
  font-weight: 400;
  color: var(--p-text);
  text-align: left;
  white-space: normal;
}

.Polaris-DataTable__Cell--numeric {
  text-align: right;
}

.Polaris-DataTable__Cell--truncated {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 14.5rem;
}

.Polaris-DataTable__Cell--header {
  font-weight: 400;
  color: var(--p-text);
  border-bottom: 0.1rem solid var(--p-border);
  border-top: 0;
}

.Polaris-DataTable__Cell--sortable {
  padding: 0;
}

.Polaris-DataTable__Cell--verticalAlignTop {
  vertical-align: top;
}

.Polaris-DataTable__Cell--verticalAlignBottom {
  vertical-align: bottom;
}

.Polaris-DataTable__Cell--verticalAlignMiddle {
  vertical-align: middle;
}

.Polaris-DataTable__Cell--verticalAlignBaseline {
  vertical-align: baseline;
}

.Polaris-DataTable__Icon {
  display: flex;
  align-self: flex-end;
  opacity: 0;
  transition: opacity 200ms cubic-bezier(0.25, 0.1, 0.25, 1), fill 200ms cubic-bezier(0.25, 0.1, 0.25, 1);
}

.Polaris-DataTable__Heading {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  position: relative;
  position: relative;
  display: inline-flex;
  justify-content: flex-end;
  align-items: baseline;
  color: var(--p-text);
  transition: color 200ms cubic-bezier(0.25, 0.1, 0.25, 1);
  cursor: pointer;
  padding: 0.8rem;
  margin: 0.8rem;
}

.Polaris-DataTable__Heading:focus {
  outline: none;
}

.Polaris-DataTable__Heading::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-DataTable__Heading svg {
  fill: var(--p-icon);
}

.Polaris-DataTable__Heading:hover {
  color: var(--p-interactive-hovered);
}

.Polaris-DataTable__Heading:hover svg {
  fill: var(--p-interactive-hovered);
}

.Polaris-DataTable__Heading:hover .Polaris-DataTable__Icon {
  opacity: 1;
}

.Polaris-DataTable__Heading:focus:not(:active)::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-DataTable__Heading--left {
  justify-content: flex-start;
  flex-direction: row-reverse;
}

.Polaris-DataTable__Cell--sorted .Polaris-DataTable__Icon {
  opacity: 1;
}

.Polaris-DataTable__Cell--total {
  font-weight: 600;
  background: var(--p-surface-subdued);
  border-bottom: 0.1rem solid var(--p-border-subdued);
}

.Polaris-DataTable--cellTotalFooter {
  border-top: 0.1rem solid var(--p-divider);
  border-bottom: none;
  border-bottom-left-radius: var(--p-border-radius-base);
  border-bottom-right-radius: var(--p-border-radius-base);
}

.Polaris-DataTable__Footer {
  padding: 1.6rem;
  background: var(--p-surface-subdued);
  color: var(--p-text-subdued);
  text-align: center;
  border-top: 0.1rem solid var(--p-divider);
  border-bottom-left-radius: var(--p-border-radius-base);
  border-bottom-right-radius: var(--p-border-radius-base);
}

.Polaris-DatePicker {
  position: relative;
}

.Polaris-DatePicker__MonthLayout {
  display: flex;
  flex-wrap: wrap;
  margin-top: -1.6rem;
  margin-left: -1.6rem;
}

.Polaris-DatePicker__MonthContainer {
  flex: 1 1 23rem;
  margin-top: 1.6rem;
  margin-left: 1.6rem;
  max-width: calc(100% - 1.6rem);
  min-width: 23rem;
}

.Polaris-DatePicker__Month {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border: none;
  border-spacing: 0;
}

.Polaris-DatePicker__Month--current {
  font-weight: 700;
}

.Polaris-DatePicker__DayCell {
  width: 14.28571%;
  background: transparent;
  margin: 0;
  padding: 0;
  border-radius: var(--p-border-radius-base);
}

.Polaris-DatePicker__DayCell--inRange {
  border-radius: 0;
}

.Polaris-DatePicker__Day {
  display: block;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0.8rem;
  background: transparent;
  border: none;
  border-radius: var(--p-border-radius-base);
  outline: none;
  font-size: 1.2rem;
  text-align: center;
  color: var(--p-text);
  cursor: pointer;
  position: relative;
}

.Polaris-DatePicker__Day:hover {
  background: var(--p-interactive-hovered);
  color: var(--p-text-on-interactive);
  outline: 0.1rem solid transparent;
}

.Polaris-DatePicker__Day::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-DatePicker__Day:focus:not(:active)::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-DatePicker__Day--today {
  font-weight: 700;
}

.Polaris-DatePicker__Day--inRange {
  background: var(--p-surface-selected);
  border-radius: 0;
}

@media (-ms-high-contrast: active) {
  .Polaris-DatePicker__Day--inRange {
    -ms-high-contrast-adjust: none;
    background-color: Highlight;
    color: HighlightText;
  }
  .Polaris-DatePicker__Day--inRange:hover {
    background-color: HighlightText;
    color: Highlight;
    outline: 0.2rem solid Highlight;
  }
}

.Polaris-DatePicker__Day--selected {
  background: var(--p-interactive);
  color: var(--p-text-on-interactive);
}

@media (-ms-high-contrast: active) {
  .Polaris-DatePicker__Day--selected {
    -ms-high-contrast-adjust: none;
    background-color: Highlight;
    color: HighlightText;
  }
  .Polaris-DatePicker__Day--selected:hover {
    background-color: HighlightText;
    color: Highlight;
    outline: 0.2rem solid Highlight;
  }
}

.Polaris-DatePicker__Day--disabled {
  background-color: transparent;
  color: var(--p-text-disabled);
}

.Polaris-DatePicker__Day--disabled:hover {
  background-color: transparent;
  color: var(--p-text-disabled);
}

@media (-ms-high-contrast) {
  .Polaris-DatePicker__Day--disabled {
    -ms-high-contrast-adjust: none;
    color: grayText;
  }
  .Polaris-DatePicker__Day--disabled:hover {
    color: grayText;
    outline: none;
  }
}

.Polaris-DatePicker__Day--disabled:focus::after {
  content: none;
}

.Polaris-DatePicker__EmptyDayCell {
  width: 14.28571%;
  margin: 0;
  padding: 0;
}

.Polaris-DatePicker__Weekday {
  padding: 0.8rem;
  background: transparent;
  font-size: 1.2rem;
  font-weight: 400;
  color: var(--p-text-subdued);
  text-align: center;
}

.Polaris-DatePicker__Weekday--current {
  font-weight: 700;
  color: var(--p-text);
}

.Polaris-DatePicker__Header {
  position: absolute;
  top: 1.6rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Polaris-DatePicker__Title {
  flex: 1 1 auto;
  margin-top: 0.2rem;
  padding-bottom: 0.4rem;
  text-align: center;
}

.Polaris-DatePicker__Day--firstInRange {
  border-radius: var(--p-border-radius-base);
}

.Polaris-DatePicker__Day--firstInRange.Polaris-DatePicker__Day--hasRange,
.Polaris-DatePicker__Day--firstInRange.Polaris-DatePicker__Day--hoverRight {
  border-radius: 3rem 0 0 3rem;
}

.Polaris-DatePicker__Day--firstInRange.Polaris-DatePicker__Day--hasRange::after,
.Polaris-DatePicker__Day--firstInRange.Polaris-DatePicker__Day--hoverRight::after {
  border-radius: 3rem 0 0 3rem;
}

.Polaris-DatePicker__Day--lastInRange {
  border-radius: 0 3rem 3rem 0;
}

.Polaris-DatePicker__Day--lastInRange::after {
  border-radius: 0 3rem 3rem 0;
}

.Polaris-DatePicker__Week {
  margin-bottom: 0.2rem;
}

.Polaris-DatePicker__Week
  > .Polaris-DatePicker__Day--inRange:first-child:not(.Polaris-DatePicker__Day--firstInRange):not(.Polaris-DatePicker__Day--lastInRange) {
  border-radius: var(--p-border-radius-base) 0 0 var(--p-border-radius-base);
}

.Polaris-DatePicker__Week
  > .Polaris-DatePicker__Day--inRange:last-child:not(.Polaris-DatePicker__Day--firstInRange):not(.Polaris-DatePicker__Day--lastInRange) {
  border-radius: 0 var(--p-border-radius-base) var(--p-border-radius-base) 0;
}

.Polaris-DatePicker__Day--inRange::after,
.Polaris-DatePicker__Day--inRange:not(:hover) + .Polaris-DatePicker__Day::after {
  border-radius: 0 3rem 3rem 0;
}

.Polaris-DescriptionList {
  margin: 0;
  padding: 0;
  word-break: break-word;
}

@media (max-width: 48.0625em) and (min-width: 36.875em), (min-width: 51.875em) {
  [data-has-navigation] .Polaris-DescriptionList {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

@media (min-width: 36.875em) {
  .Polaris-DescriptionList {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

.Polaris-DescriptionList__Term {
  font-weight: 600;
  padding: 1.6rem 0 0.8rem;
}

.Polaris-DescriptionList--spacingTight .Polaris-DescriptionList__Term {
  padding: 0.8rem 0 0.4rem;
}

@media (max-width: 48.0625em) and (min-width: 36.875em), (min-width: 51.875em) {
  [data-has-navigation] .Polaris-DescriptionList__Term {
    flex: 0 1 25%;
    padding: 1.6rem 1.6rem 1.6rem 0;
  }
  .Polaris-DescriptionList--spacingTight [data-has-navigation] .Polaris-DescriptionList__Term {
    padding: 0.8rem 0.8rem 0.8rem 0;
  }
  .Polaris-DescriptionList__Description
    + [data-has-navigation]
    .Polaris-DescriptionList__Term
    + .Polaris-DescriptionList__Description {
    border-top: 0.1rem solid var(--p-divider);
  }
}

@media (min-width: 36.875em) {
  .Polaris-DescriptionList__Term {
    flex: 0 1 25%;
    padding: 1.6rem 1.6rem 1.6rem 0;
  }
  .Polaris-DescriptionList--spacingTight .Polaris-DescriptionList__Term {
    padding: 0.8rem 0.8rem 0.8rem 0;
  }
  .Polaris-DescriptionList__Description + .Polaris-DescriptionList__Term + .Polaris-DescriptionList__Description {
    border-top: 0.1rem solid var(--p-divider);
  }
}

.Polaris-DescriptionList__Description {
  margin-left: 0;
  padding: 0 0 1.6rem;
}

.Polaris-DescriptionList--spacingTight .Polaris-DescriptionList__Description {
  padding: 0 0 0.8rem;
}

.Polaris-DescriptionList__Description + .Polaris-DescriptionList__Term {
  border-top: 0.1rem solid var(--p-divider);
}

@media (max-width: 48.0625em) and (min-width: 36.875em), (min-width: 51.875em) {
  [data-has-navigation] .Polaris-DescriptionList__Description {
    flex: 1 1 51%;
    padding: 1.6rem 0;
  }
  .Polaris-DescriptionList--spacingTight [data-has-navigation] .Polaris-DescriptionList__Description {
    padding: 0.8rem 0;
  }
  [data-has-navigation]
    .Polaris-DescriptionList__Description
    + .Polaris-DescriptionList__Term
    + .Polaris-DescriptionList__Description {
    border-top: 0.1rem solid var(--p-divider);
  }
}

@media (min-width: 36.875em) {
  .Polaris-DescriptionList__Description {
    flex: 1 1 51%;
    padding: 1.6rem 0;
  }
  .Polaris-DescriptionList--spacingTight .Polaris-DescriptionList__Description {
    padding: 0.8rem 0;
  }
  .Polaris-DescriptionList__Description + .Polaris-DescriptionList__Term + .Polaris-DescriptionList__Description {
    border-top: 0.1rem solid var(--p-divider);
  }
}

.Polaris-DisplayText {
  margin: 0;
}

.Polaris-DisplayText--sizeSmall {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
}

@media (min-width: 40em) {
  .Polaris-DisplayText--sizeSmall {
    font-size: 2rem;
    line-height: 2.8rem;
  }
}

.Polaris-DisplayText--sizeMedium {
  font-size: 2.1rem;
  font-weight: 400;
  line-height: 2.8rem;
}

@media (min-width: 40em) {
  .Polaris-DisplayText--sizeMedium {
    font-size: 2.6rem;
    line-height: 3.2rem;
  }
}

.Polaris-DisplayText--sizeLarge {
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 2.8rem;
}

@media (min-width: 40em) {
  .Polaris-DisplayText--sizeLarge {
    font-size: 2.8rem;
    line-height: 3.2rem;
  }
}

.Polaris-DisplayText--sizeExtraLarge {
  font-size: 2.7rem;
  font-weight: 600;
  line-height: 3.6rem;
}

@media (min-width: 40em) {
  .Polaris-DisplayText--sizeExtraLarge {
    font-size: 4.2rem;
    line-height: 4.4rem;
  }
}

.Polaris-DropZone-FileUpload {
  padding: 1.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.Polaris-DropZone-FileUpload--large {
  padding: 3.2rem;
}

.Polaris-DropZone-FileUpload--small {
  padding: 1.25rem;
}

.Polaris-DropZone-FileUpload img {
  vertical-align: bottom;
}

.Polaris-DropZone-FileUpload__Action {
  position: relative;
  display: inline-flex;
  flex: 0 0 auto;
  border: none;
  border-radius: var(--p-border-radius-base);
  padding: 0.4rem 0.6rem;
  margin: 0;
  text-decoration: none;
  color: var(--p-interactive-hovered);
  background: var(--p-surface-selected-pressed);
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  appearance: none;
}

.Polaris-DropZone-FileUpload__Action::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-DropZone-FileUpload__Action:hover {
  color: var(--p-interactive-pressed);
}

.Polaris-DropZone-FileUpload__Action.Polaris-DropZone-FileUpload--disabled {
  transition: none;
  box-shadow: none;
  border-color: var(--p-border-disabled);
  background: var(--p-surface-disabled);
  color: var(--p-text-disabled);
  cursor: not-allowed;
  box-shadow: none;
}

.Polaris-DropZone-FileUpload__Action.Polaris-DropZone-FileUpload--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-DropZone-FileUpload__ActionTitle {
  color: var(--p-interactive);
  text-decoration: none;
}

.Polaris-DropZone-FileUpload__ActionTitle:not(.Polaris-DropZone-FileUpload__ActionTitle--disabled) {
  cursor: pointer;
}

.Polaris-DropZone-FileUpload__ActionTitle:not(.Polaris-DropZone-FileUpload__ActionTitle--disabled):hover,
.Polaris-DropZone-FileUpload__ActionTitle:not(.Polaris-DropZone-FileUpload__ActionTitle--disabled):active {
  color: var(--p-interactive-pressed);
  text-decoration: underline;
}

.Polaris-DropZone-FileUpload__ActionTitle--focused {
  text-decoration: underline;
}

.Polaris-DropZone-FileUpload__ActionTitle--disabled {
  color: var(--p-interactive-disabled);
}

.Polaris-DropZone {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: var(--p-surface);
  border-radius: var(--p-border-radius-base);
}

.Polaris-DropZone::after {
  content: "";
  position: absolute;
  z-index: 29;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 0.1rem dashed transparent;
  border-radius: var(--p-border-radius-base);
  pointer-events: none;
}

.Polaris-DropZone:not(.Polaris-DropZone--focused)::after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transform: scale(1);
  border: 0.1rem dashed transparent;
}

.Polaris-DropZone:hover {
  outline: 0.1rem solid transparent;
}

.Polaris-DropZone--hasOutline {
  padding: 0.1rem;
}

.Polaris-DropZone--hasOutline::after {
  border-color: var(--p-border-neutral-subdued);
}

.Polaris-DropZone--hasOutline:not(.Polaris-DropZone--isDisabled):hover {
  cursor: pointer;
  background-color: var(--p-surface-subdued);
}

.Polaris-DropZone--hasOutline:not(.Polaris-DropZone--isDisabled):hover::after {
  border-color: var(--p-interactive-hovered);
}

.Polaris-DropZone--hasOutline:not(.Polaris-DropZone--focused)::after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transform: scale(1);
  border: 0.1rem dashed transparent;
  border-radius: calc(var(--p-border-radius-base) + 0.2rem);
  border-color: var(--p-border-neutral-subdued);
}

.Polaris-DropZone--isDragging:not(.Polaris-DropZone--isDisabled) {
  background-color: var(--p-surface-hovered);
}

.Polaris-DropZone--isDisabled {
  cursor: not-allowed;
}

.Polaris-DropZone--isDisabled::after {
  border-color: var(--p-border-disabled);
}

.Polaris-DropZone--sizeLarge {
  min-height: 12rem;
}

.Polaris-DropZone--sizeMedium {
  min-height: 10rem;
  align-items: center;
}

.Polaris-DropZone--sizeSmall {
  padding: 0;
  align-items: center;
  min-height: 5rem;
}

.Polaris-DropZone--measuring {
  visibility: hidden;
  min-height: 0;
}

.Polaris-DropZone__Container {
  position: relative;
  flex: 1 1;
}

.Polaris-DropZone__Container::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.2rem;
  right: -0.2rem;
  bottom: -0.2rem;
  left: -0.2rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.2rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-DropZone__Overlay {
  border-radius: calc(var(--p-border-radius-base) + 0.2rem);
  position: absolute;
  z-index: 30;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  border: 0.1rem dashed var(--p-interactive);
  text-align: center;
  color: var(--p-interactive);
  background-color: var(--p-surface-selected);
  pointer-events: none;
}

.Polaris-DropZone--hasError .Polaris-DropZone__Overlay {
  border-color: var(--p-border-critical);
  color: var(--p-text-critical);
  background-color: var(--p-surface-critical-subdued);
}

.Polaris-DropZone--sizeSmall .Polaris-DropZone__Overlay {
  padding: 0;
}

.Polaris-DropZone--focused:not(.Polaris-DropZone--isDisabled) .Polaris-DropZone__Container::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-EmptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 2rem 0;
  padding-top: 2rem;
  padding-bottom: 6rem;
  max-width: 99.8rem;
}

.Polaris-EmptyState__Section {
  display: flex;
  flex-direction: column-reverse;
  flex: 1 1 auto;
  width: 100%;
  align-items: center;
  justify-content: center;
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-EmptyState__Section {
    left: 2rem;
  }
}

@media (min-width: 46.5em) {
  .Polaris-EmptyState__Section {
    left: 2rem;
  }
}

.Polaris-EmptyState__Details {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-EmptyState__Details {
    max-width: 40rem;
  }
}

@media (min-width: 30.625em) {
  .Polaris-EmptyState__Details {
    max-width: 40rem;
  }
}

.Polaris-EmptyState__Image {
  margin: 0;
  width: initial;
}

.Polaris-EmptyState__ImageContainer,
.Polaris-EmptyState__DetailsContainer {
  flex: 1 1 auto;
  padding: 0;
  margin: 0;
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-EmptyState__ImageContainer,
  [data-has-navigation] .Polaris-EmptyState__DetailsContainer {
    flex-basis: 50%;
  }
}

@media (min-width: 46.5em) {
  .Polaris-EmptyState__ImageContainer,
  .Polaris-EmptyState__DetailsContainer {
    flex-basis: 50%;
  }
}

@media (max-width: 30.625em), (min-width: 48.0625em) and (max-width: 47.125em) {
  [data-has-navigation] .Polaris-EmptyState__ImageContainer,
  [data-has-navigation] .Polaris-EmptyState__DetailsContainer {
    overflow-x: hidden;
  }
}

@media (max-width: 30.625em) {
  .Polaris-EmptyState__ImageContainer,
  .Polaris-EmptyState__DetailsContainer {
    overflow-x: hidden;
  }
}

.Polaris-EmptyState--withinContentContainer {
  margin: 0 auto;
  padding-top: 2rem;
  padding-bottom: 6rem;
}

.Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Section {
  position: unset;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
}

.Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Details {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Details {
    max-width: 40rem;
  }
}

@media (min-width: 30.625em) {
  .Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Details {
    max-width: 40rem;
  }
}

.Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Image {
  margin: 0;
  width: initial;
}

.Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Content {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  text-transform: initial;
  letter-spacing: initial;
  padding-bottom: 0.8rem;
}

@media (min-width: 40em) {
  .Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Content {
    font-size: 1.4rem;
  }
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-EmptyState--imageContained .Polaris-EmptyState__Image {
    position: initial;
    width: 100%;
  }
}

@media (min-width: 46.5em) {
  .Polaris-EmptyState--imageContained .Polaris-EmptyState__Image {
    position: initial;
    width: 100%;
  }
}

.Polaris-EmptyState--fullWidth .Polaris-EmptyState__Details {
  max-width: 100%;
}

.Polaris-EmptyState__Content {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  text-transform: initial;
  letter-spacing: initial;
  color: var(--p-text-subdued);
  padding-bottom: 0.8rem;
}

@media (min-width: 40em) {
  .Polaris-EmptyState__Content {
    font-size: 1.4rem;
  }
}

.Polaris-EmptyState__Actions {
  margin-top: 1.6rem;
}

.Polaris-EmptyState__FooterContent {
  color: var(--p-text-subdued);
  margin-top: 1.6rem;
}

.Polaris-Truncate {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Polaris-ExceptionList {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Polaris-ExceptionList__Item {
  position: relative;
  padding-left: 2.4rem;
  color: var(--p-text-subdued);
}

.Polaris-ExceptionList__Item + .Polaris-ExceptionList__Item {
  margin-top: 0.4rem;
}

.Polaris-ExceptionList__Icon {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2rem;
  height: 2rem;
  margin-right: 0.4rem;
}

.Polaris-ExceptionList__Icon svg {
  fill: var(--p-icon-subdued);
}

.Polaris-ExceptionList--statusWarning .Polaris-ExceptionList__Icon svg {
  fill: var(--p-icon-warning);
}

.Polaris-ExceptionList--statusCritical .Polaris-ExceptionList__Icon svg {
  fill: var(--p-icon-critical);
}

.Polaris-ExceptionList__Bullet {
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 100%;
  background-color: var(--p-icon-subdued);
}

.Polaris-ExceptionList--statusWarning .Polaris-ExceptionList__Bullet {
  background-color: var(--p-icon-warning);
}

.Polaris-ExceptionList--statusCritical .Polaris-ExceptionList__Bullet {
  background-color: var(--p-icon-critical);
}

.Polaris-ExceptionList__Title + .Polaris-ExceptionList__Description::before {
  content: "–";
  margin: 0 0.4rem;
}

.Polaris-ExceptionList--statusWarning .Polaris-ExceptionList__Title,
.Polaris-ExceptionList--statusCritical .Polaris-ExceptionList__Title {
  font-weight: 500;
}

.Polaris-ExceptionList--statusWarning .Polaris-ExceptionList__Title {
  color: var(--p-text-warning);
}

.Polaris-ExceptionList--statusCritical .Polaris-ExceptionList__Title {
  color: var(--p-text-critical);
}

.Polaris-Tag {
  display: inline-flex;
  max-width: 100%;
  align-items: center;
  min-height: 2.8rem;
  padding: 0 0.8rem;
  background-color: var(--p-surface-neutral);
  border-radius: var(--p-border-radius-base);
  color: var(--p-text);
  outline: 0.1rem solid transparent;
}

.Polaris-Tag.Polaris-Tag--disabled {
  transition: none;
  background: var(--p-surface-neutral-disabled);
  color: var(--p-text-disabled);
}

.Polaris-Tag.Polaris-Tag--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Tag.Polaris-Tag--clickable {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  cursor: pointer;
  padding: 0 0.8rem;
  background-color: var(--p-surface-neutral);
  position: relative;
}

.Polaris-Tag.Polaris-Tag--clickable:focus {
  outline: none;
}

.Polaris-Tag.Polaris-Tag--clickable:hover {
  background: var(--p-surface-neutral-hovered);
}

.Polaris-Tag.Polaris-Tag--clickable::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Tag.Polaris-Tag--clickable:focus:not(:active)::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Tag.Polaris-Tag--clickable:active {
  background: var(--p-surface-neutral-pressed);
}

.Polaris-Tag.Polaris-Tag--clickable:disabled {
  background: var(--p-surface-neutral-disabled);
  cursor: default;
  pointer-events: none;
  color: var(--p-text-disabled);
}

.Polaris-Tag.Polaris-Tag--removable {
  padding-right: 0;
}

.Polaris-Tag.Polaris-Tag--linkable {
  padding: 0;
}

.Polaris-Tag__TagText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.Polaris-Tag__Button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  display: block;
  flex-shrink: 0;
  height: 2.8rem;
  width: 2.8rem;
  margin-left: 0.4rem;
  border-radius: 0 0.3rem 0.3rem 0;
  position: relative;
}

.Polaris-Tag__Button svg {
  fill: var(--p-icon);
}

.Polaris-Tag__Button:focus {
  outline: none;
}

.Polaris-Tag__Button:hover {
  background: var(--p-surface-neutral-hovered);
  outline: 0.1rem solid transparent;
}

.Polaris-Tag__Button::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Tag__Button:focus:not(:active)::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Tag__Button:active {
  background: var(--p-surface-neutral-pressed);
}

.Polaris-Tag__Button:disabled {
  cursor: default;
  pointer-events: none;
}

.Polaris-Tag__Button:disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Tag__Button.Polaris-Tag--segmented {
  margin-left: -0.4rem;
}

.Polaris-Tag__Button.Polaris-Tag--segmented::after {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Polaris-Tag__Link {
  display: inline-grid;
  color: var(--p-text);
  outline: none;
  border-radius: var(--p-border-radius-base);
  text-decoration: none;
  padding: 0.4rem 0.8rem;
  position: relative;
}

.Polaris-Tag__Link .Polaris-Tag__LinkText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Polaris-Tag__Link::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Tag__Link:focus:not(:active) {
  text-decoration: underline;
}

.Polaris-Tag__Link:focus:not(:active)::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Tag__Link:hover {
  background: var(--p-surface-neutral-hovered);
  text-decoration: underline;
}

.Polaris-Tag__Link.Polaris-Tag--segmented {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Polaris-Tag__Link.Polaris-Tag--segmented::after {
  margin-right: 0.4rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Polaris-Sheet {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: var(--p-surface);
  box-shadow: var(--p-modal-shadow);
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-Sheet {
    border-left: 0.1rem solid var(--p-border-subdued);
  }
}

@media (min-width: 48.0625em) {
  .Polaris-Sheet {
    right: 0;
    width: 38rem;
  }
}

.Polaris-Sheet:focus {
  outline: 0;
}

.Polaris-Sheet__Container {
  position: fixed;
  z-index: 519;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media (min-width: 48.0625em) {
  .Polaris-Sheet__Container {
    left: auto;
    width: 38rem;
  }
}

.Polaris-Sheet__Bottom {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
  transition: transform 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transform-origin: bottom;
}

.Polaris-Sheet--enterBottom {
  transform: translateY(100%);
}

.Polaris-Sheet--enterBottomActive {
  transform: translateY(0%);
}

.Polaris-Sheet--exitBottom {
  transform: translateY(0%);
}

.Polaris-Sheet--exitBottomActive {
  transform: translateY(100%);
}

.Polaris-Sheet__Right {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
  transition: transform 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transform-origin: right;
}

.Polaris-Sheet--enterRight {
  transform: translateX(100%);
}

.Polaris-Sheet--enterRightActive {
  transform: translateX(0%);
}

.Polaris-Sheet--exitRight {
  transform: translateX(0%);
}

.Polaris-Sheet--exitRightActive {
  transform: translateX(100%);
}

.Polaris-Filters-ConnectedFilterControl__Item {
  position: relative;
  z-index: 10;
}

.Polaris-Filters-ConnectedFilterControl__Item--focused {
  z-index: 20;
}

.Polaris-Filters-ConnectedFilterControl__ProxyButtonContainer {
  position: absolute;
  top: -100rem;
  left: -100rem;
  display: flex;
  width: 100%;
  height: 0;
  visibility: hidden;
  overflow: hidden;
}

.Polaris-Filters-ConnectedFilterControl__ProxyButtonContainer > * {
  flex-shrink: 0;
}

.Polaris-Filters-ConnectedFilterControl {
  display: flex;
  flex-grow: 1;
}

.Polaris-Filters-ConnectedFilterControl .Polaris-Filters-ConnectedFilterControl__CenterContainer {
  flex: 1 1 auto;
  min-width: 10rem;
}

.Polaris-Filters-ConnectedFilterControl.Polaris-Filters-ConnectedFilterControl--right
  .Polaris-Filters-ConnectedFilterControl__CenterContainer
  * {
  border-top-right-radius: var(--p-border-radius-base);
  border-bottom-right-radius: var(--p-border-radius-base);
}

.Polaris-Filters-ConnectedFilterControl__CenterContainer + .Polaris-Filters-ConnectedFilterControl__RightContainer,
.Polaris-Filters-ConnectedFilterControl__CenterContainer
  + .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  margin-left: 0.8rem;
}

.Polaris-Filters-ConnectedFilterControl__RightContainer {
  display: flex;
}

.Polaris-Filters-ConnectedFilterControl__RightContainer .Polaris-Filters-ConnectedFilterControl__Item > div > button {
  margin-right: var(--p-button-group-item-spacing);
  border-radius: 0;
}

.Polaris-Filters-ConnectedFilterControl__RightContainer .Polaris-Filters-ConnectedFilterControl__Item {
  flex-shrink: 0;
}

.Polaris-Filters-ConnectedFilterControl__RightContainer
  .Polaris-Filters-ConnectedFilterControl__Item:first-of-type
  > div
  > button {
  border-top-left-radius: var(--p-border-radius-base);
  border-bottom-left-radius: var(--p-border-radius-base);
}

.Polaris-Filters-ConnectedFilterControl__RightContainer.Polaris-Filters-ConnectedFilterControl--queryFieldHidden
  .Polaris-Filters-ConnectedFilterControl__Item:first-of-type
  > div
  > button {
  border-top-left-radius: var(--p-border-radius-base);
  border-bottom-left-radius: var(--p-border-radius-base);
}

.Polaris-Filters-ConnectedFilterControl__RightContainerWithoutMoreFilters
  .Polaris-Filters-ConnectedFilterControl__Item:last-child
  > div
  > button {
  border-top-right-radius: var(--p-border-radius-base);
  border-bottom-right-radius: var(--p-border-radius-base);
}

.Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer
  .Polaris-Filters-ConnectedFilterControl__Item
  > div
  > button {
  white-space: nowrap;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer.Polaris-Filters-ConnectedFilterControl--onlyButtonVisible
  .Polaris-Filters-ConnectedFilterControl__Item
  > div
  > button {
  border-radius: var(--p-border-radius-base);
}

.Polaris-Filters-ConnectedFilterControl__Wrapper {
  display: flex;
}

.Polaris-Filters-ConnectedFilterControl__AuxiliaryContainer {
  flex-grow: 0;
}

.Polaris-Filters {
  position: relative;
}

.Polaris-Filters__FiltersContainer {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Polaris-Filters__FiltersContainerHeader {
  top: 0;
  width: 100%;
  padding: 1.6rem 2rem;
  border-bottom: 0.1rem solid var(--p-divider);
  height: 5.6rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Polaris-Filters__FiltersDesktopContainerContent {
  width: 100%;
  height: calc(100% - 12.6rem);
  padding: 0.8rem;
}

.Polaris-Filters__FiltersMobileContainerContent {
  width: 100%;
  height: calc(100% - 5.6rem);
  padding: 0.8rem;
}

.Polaris-Filters__FiltersContainerFooter {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1.4rem 2rem;
  border-top: 0.1rem solid var(--p-divider);
  height: 7rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Polaris-Filters__FiltersMobileContainerFooter {
  width: 100%;
  padding: 1.4rem 1.6rem;
  height: 7rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Polaris-Filters__EmptyFooterState {
  border-top: 0.1rem solid var(--p-divider);
  padding-top: 1.4rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.Polaris-Filters__FilterTriggerContainer {
  position: relative;
}

.Polaris-Filters__FilterTrigger {
  width: 100%;
  margin: 0;
  padding: 1.4rem 2rem;
  color: var(--p-text);
  border-radius: var(--p-border-radius-base);
  background: none;
  border: none;
  outline: none;
  position: relative;
}

.Polaris-Filters__FilterTrigger::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Filters__FilterTrigger:focus {
  box-shadow: none;
}

.Polaris-Filters__FilterTrigger:hover {
  cursor: pointer;
  background-color: var(--p-surface-hovered);
  outline: 0.1rem solid transparent;
}

.Polaris-Filters__FilterTrigger:focus:not(:active)::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Filters__FilterTriggerTitle {
  font-size: 1.5rem;
  font-weight: 600;
}

.Polaris-Filters__AppliedFilterBadgeContainer {
  padding-top: 0.4rem;
  display: flex;
}

.Polaris-Filters--open .Polaris-Filters__AppliedFilterBadgeContainer {
  display: none;
}

.Polaris-Filters__FilterTriggerLabelContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Polaris-Filters--open::before,
.Polaris-Filters--open::after {
  content: "";
  position: relative;
  left: 1.6rem;
  width: calc(100% - 3.2rem);
  height: 0.1rem;
  background-color: var(--p-surface-subdued);
  display: block;
}

.Polaris-Filters--open::before {
  top: 0;
}

.Polaris-Filters--open::after {
  bottom: 0;
}

.Polaris-Filters--open.Polaris-Filters--first::after {
  content: "";
  bottom: 0;
  position: relative;
  left: 1.6rem;
  width: calc(100% - 3.2rem);
  height: 0.1rem;
  background-color: var(--p-surface-subdued);
  display: block;
}

.Polaris-Filters--open.Polaris-Filters--first::before {
  display: none;
}

.Polaris-Filters--open ~ .Polaris-Filters--open::before {
  display: none;
}

.Polaris-Filters--open.Polaris-Filters--last::before {
  content: "";
  top: 0;
  position: relative;
  left: 1.6rem;
  width: calc(100% - 3.2rem);
  height: 0.1rem;
  background-color: var(--p-surface-subdued);
  display: block;
}

.Polaris-Filters--open.Polaris-Filters--last::after {
  display: none;
}

.Polaris-Filters--open + .Polaris-Filters--last::before {
  display: none;
}

.Polaris-Filters__FilterNodeContainer {
  padding: 0.8rem 2rem 2rem 2rem;
}

.Polaris-Filters__SearchIcon {
  fill: currentColor;
}

.Polaris-Filters__Backdrop {
  position: fixed;
  z-index: 518;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  opacity: 0;
}

.Polaris-Filters__HelpText {
  margin-top: 1rem;
}

.Polaris-Filters__TagsContainer {
  display: flex;
  padding-top: 0.8rem;
  flex-wrap: wrap;
}

.Polaris-Filters__TagsContainer > * {
  margin-right: 0.8rem;
  margin-bottom: 0.8rem;
}

.Polaris-FooterHelp {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
  width: 100%;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-FooterHelp {
    margin: 2rem;
    width: auto;
  }
}

@media (min-width: 30.625em) {
  .Polaris-FooterHelp {
    margin: 2rem;
    width: auto;
  }
}

.Polaris-FooterHelp__Content {
  display: inline-flex;
  align-items: center;
  padding: 2rem 2rem 2rem 1.6rem;
  width: 100%;
  justify-content: center;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-FooterHelp__Content {
    width: auto;
  }
}

@media (min-width: 30.625em) {
  .Polaris-FooterHelp__Content {
    width: auto;
  }
}

.Polaris-FooterHelp__Icon {
  margin-right: 0.8rem;
}

.Polaris-FooterHelp__Text {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  border: none;
  text-transform: initial;
  letter-spacing: initial;
}

@media (min-width: 40em) {
  .Polaris-FooterHelp__Text {
    font-size: 1.4rem;
  }
}

.Polaris-FormLayout {
  margin-top: -1.6rem;
  margin-left: -2rem;
}

.Polaris-FormLayout__Title {
  margin-bottom: -0.8rem;
  padding: 1.6rem 2rem 0;
}

.Polaris-FormLayout__Items {
  display: flex;
  flex-wrap: wrap;
}

.Polaris-FormLayout__HelpText {
  color: var(--p-text-subdued);
  padding: 0.8rem 2rem 0;
}

.Polaris-FormLayout__Item {
  flex: 1 1 22rem;
  margin-top: 1.6rem;
  margin-left: 2rem;
  max-width: calc(100% - 2rem);
}

.Polaris-FormLayout--grouped .Polaris-FormLayout__Item {
  min-width: 22rem;
}

.Polaris-FormLayout--condensed .Polaris-FormLayout__Item {
  flex-basis: 11rem;
  min-width: 11rem;
}

.Polaris-Frame-Toast {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  display: inline-flex;
  max-width: 50rem;
  padding: 0.8rem 1.6rem;
  border-radius: var(--p-border-radius-wide);
  background: var(--p-surface);
  color: var(--p-text);
  margin-bottom: 2rem;
}

@media (min-width: 40em) {
  .Polaris-Frame-Toast {
    font-size: 2rem;
    line-height: 2.8rem;
  }
}

@media (min-width: 40em) {
  .Polaris-Frame-Toast {
    padding: 1.6rem;
  }
}

@media (forced-colors: active) {
  .Polaris-Frame-Toast {
    border: 0.2rem solid transparent;
  }
}

.Polaris-Frame-Toast__Action {
  margin-left: 3.2rem;
  margin-right: 1.6rem;
}

.Polaris-Frame-Toast--error {
  background: var(--p-action-critical);
  color: var(--p-text-on-critical);
}

.Polaris-Frame-Toast--error .Polaris-Frame-Toast__CloseButton {
  fill: var(--p-icon-on-critical);
}

.Polaris-Frame-Toast__CloseButton {
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: -1.6rem;
  padding: 0 1.6rem;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  color: currentColor;
  cursor: pointer;
}

.Polaris-Frame-Toast__CloseButton svg {
  fill: currentColor;
}

@media (min-width: 40em) {
  .Polaris-Frame-Toast__CloseButton {
    align-self: flex-start;
    margin: -0.8rem -1.6rem -0.8rem 0;
    padding: 1.2rem 1.6rem 0.8rem;
  }
}

.Polaris-Frame-Toast__CloseButton:focus {
  outline: none;
}

:root {
  --toast-translate-y-out: 15rem;
  --toast-translate-y-in: 0;
}

.Polaris-Frame-ToastManager {
  position: fixed;
  z-index: 520;
  right: 0;
  left: 0;
  text-align: center;
  bottom: var(--global-ribbon-height);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Polaris-Frame-ToastManager__ToastWrapper {
  position: absolute;
  display: inline-flex;
  opacity: 0;
  transition: transform 400ms ease, opacity 400ms ease;
  transform: translateY(var(--toast-translate-y-out));
}

.Polaris-Frame-ToastManager__ToastWrapper--enter,
.Polaris-Frame-ToastManager__ToastWrapper--exit {
  transform: translateY(var(--toast-translate-y-out));
  opacity: 0;
}

.Polaris-Frame-ToastManager--toastWrapperEnterDone {
  transform: translateY(var(--toast-translate-y-in));
  opacity: 1;
}

.Polaris-Frame-Loading {
  overflow: hidden;
  height: 0.3rem;
  background-color: var(--p-surface);
  opacity: 1;
}

.Polaris-Frame-Loading__Level {
  width: 100%;
  height: 100%;
  transform-origin: 0;
  background-color: var(--p-icon-success);
  transition: transform 500ms linear;
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-Frame-Loading__Level {
    background-color: highlight;
  }
}

.Polaris-Modal-Dialog__Container {
  position: fixed;
  z-index: 519;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media (min-width: 48.0625em) {
  .Polaris-Modal-Dialog__Container {
    justify-content: center;
  }
}

.Polaris-Modal-Dialog:focus {
  outline: 0;
}

.Polaris-Modal-Dialog__Modal {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - 6rem);
  background: var(--p-surface);
  box-shadow: var(--p-modal-shadow);
}

@media (forced-colors: active) {
  .Polaris-Modal-Dialog__Modal {
    border: 0.1rem solid transparent;
  }
}

@media (max-width: 48.0525em) {
  .Polaris-Modal-Dialog__Modal {
    bottom: 0;
    max-height: 100%;
  }
}

@media (min-width: 48.0625em) {
  .Polaris-Modal-Dialog__Modal {
    position: relative;
    max-width: 62rem;
    margin: 0 auto;
    border-radius: var(--p-border-radius-wide);
  }
}

@media (min-width: 48.0625em) and (min-height: 660px) {
  .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--limitHeight {
    max-height: 60rem;
  }
}

@media (min-width: 48.0625em) {
  .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeSmall {
    max-width: calc(100% - 6.4rem);
  }
}

@media (min-width: 27.75em) {
  .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeSmall {
    max-width: 38rem;
  }
}

@media (min-width: 48.0625em) {
  .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeLarge {
    max-width: calc(100% - 6.4rem);
  }
}

@media (min-width: 65.25em) {
  .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeLarge {
    max-width: 98rem;
  }
}

.Polaris-Modal-Dialog--animateFadeUp {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform, opacity;
  opacity: 1;
  transform: translateY(0);
  transition: transform cubic-bezier(0.25, 0.1, 0.25, 1) 200ms, opacity cubic-bezier(0.25, 0.1, 0.25, 1) 200ms;
}

.Polaris-Modal-Dialog--animateFadeUp.Polaris-Modal-Dialog--entering,
.Polaris-Modal-Dialog--animateFadeUp.Polaris-Modal-Dialog--exiting,
.Polaris-Modal-Dialog--animateFadeUp.Polaris-Modal-Dialog--exited {
  opacity: 0;
  transform: translateY(20rem);
}

.Polaris-Modal-Dialog--animateFadeUp.Polaris-Modal-Dialog--entered {
  opacity: 1;
  transform: translateY(0);
}

.Polaris-Modal-Footer {
  display: flex;
  align-self: flex-end;
  align-items: center;
  width: 100%;
  min-height: 6.4rem;
  padding: 1.6rem;
  border-top: 0.1rem solid var(--p-divider);
}

.Polaris-Modal-Footer__FooterContent {
  width: 100%;
}

.Polaris-Modal-CloseButton {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  position: relative;
  margin-left: 2rem;
  margin-right: -0.8rem;
  padding: 0.8rem;
  border-radius: 0.6rem;
}

.Polaris-Modal-CloseButton:focus {
  outline: none;
}

.Polaris-Modal-CloseButton::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Modal-CloseButton:hover {
  background: var(--p-surface-hovered);
}

.Polaris-Modal-CloseButton:hover svg {
  fill: var(--p-icon-hovered);
}

.Polaris-Modal-CloseButton:active {
  background: var(--p-surface-pressed);
}

.Polaris-Modal-CloseButton:focus:not(:active)::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Modal-Header {
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  padding: 1.6rem 2rem;
  border-bottom: 0.1rem solid var(--p-divider);
}

.Polaris-Modal-Header--titleHidden {
  position: absolute;
  right: 0.8rem;
  z-index: 1;
}

.Polaris-Modal-Header--titleHidden .Polaris-Modal-Header__Title {
  display: none;
}

.Polaris-Modal-Header__Title {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  flex: 1 1;
  margin-top: 0.4rem;
}

.Polaris-Modal-Section {
  flex: 0 0 auto;
  padding: 2rem;
}

.Polaris-Modal-Section:not(:last-of-type) {
  border-bottom: 0.1rem solid var(--p-divider);
}

.Polaris-Modal-Section.Polaris-Modal-Section--subdued {
  background: var(--p-surface-subdued);
}

.Polaris-Modal-Section.Polaris-Modal-Section--flush {
  padding: 0;
}

.Polaris-Modal__BodyWrapper {
  display: flex;
  flex-grow: 1;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.Polaris-Modal__Body {
  width: 100%;
}

.Polaris-Modal__IFrame {
  display: block;
  width: 62rem;
  max-width: 100vw;
  border: none;
}

@media (min-width: 48.0625em) {
  .Polaris-Modal__IFrame {
    max-width: 62rem;
  }
}

.Polaris-Modal__Spinner {
  margin: 1.6rem;
  text-align: center;
}

.Polaris-Frame-ContextualSaveBar {
  display: flex;
  height: 5.6rem;
  background: var(--p-surface);
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
}

.Polaris-Frame-ContextualSaveBar .Polaris-Frame-ContextualSaveBar__LogoContainer {
  border-right: none;
}

.Polaris-Frame-ContextualSaveBar .Polaris-Frame-ContextualSaveBar__ContextControl {
  opacity: 0.3;
  pointer-events: none;
}

.Polaris-Frame-ContextualSaveBar .Polaris-Frame-ContextualSaveBar__Message {
  color: var(--p-text);
}

@media (forced-colors: active) {
  .Polaris-Frame-ContextualSaveBar {
    border: 0.1rem solid transparent;
  }
}

.Polaris-Frame-ContextualSaveBar__LogoContainer {
  display: none;
}

@media (min-width: 48.0625em) {
  .Polaris-Frame-ContextualSaveBar__LogoContainer {
    display: flex;
    flex: 0 0 24rem;
    align-items: center;
    height: 100%;
    padding: 0 1.6rem;
    background-color: transparent;
  }
}

.Polaris-Frame-ContextualSaveBar__Contents {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: space-between;
  min-width: 0.1rem;
  max-width: 99.8rem;
  height: 100%;
  margin: 0 auto;
  padding: 0 1.6rem;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Frame-ContextualSaveBar__Contents {
    padding: 0 2rem;
  }
}

@media (min-width: 30.625em) {
  .Polaris-Frame-ContextualSaveBar__Contents {
    padding: 0 2rem;
  }
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-Frame-ContextualSaveBar__Contents {
    padding: 0 3.2rem;
  }
}

@media (min-width: 46.5em) {
  .Polaris-Frame-ContextualSaveBar__Contents {
    padding: 0 3.2rem;
  }
}

.Polaris-Frame-ContextualSaveBar--fullWidth {
  max-width: none;
  padding: 0 1.6rem;
}

.Polaris-Frame-ContextualSaveBar__Message {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 2.4rem;
  color: var(--p-text-subdued);
}

@media (min-width: 40em) {
  .Polaris-Frame-ContextualSaveBar__Message {
    font-size: 1.6rem;
  }
}

.Polaris-Frame-ContextualSaveBar__ActionContainer {
  flex-shrink: 0;
}

.Polaris-Frame-ContextualSaveBar__Action {
  margin-left: 0.8rem;
}

.Polaris-Frame-ContextualSaveBar__ContextControl {
  display: none;
}

@media (min-width: 48.0625em) {
  .Polaris-Frame-ContextualSaveBar__ContextControl {
    display: block;
    width: 24rem;
  }
}

.Polaris-Frame-CSSAnimation--startFade {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: opacity;
  transition: opacity 300ms cubic-bezier(0, 0, 0.42, 1);
  pointer-events: none;
}

.Polaris-Frame-CSSAnimation--endFade {
  opacity: 1;
  pointer-events: auto;
}

.Polaris-Frame {
  width: 100%;
  min-height: 100vh;
  display: flex;
  background-color: var(--p-background);
}

@media print {
  .Polaris-Frame {
    background-color: transparent;
  }
}

@media (min-width: 48.0625em) {
  .Polaris-Frame {
    width: calc(100% - var(--p-frame-offset));
    margin-left: var(--p-frame-offset);
  }
}

.Polaris-Frame__Navigation {
  position: fixed;
  z-index: 516;
  top: 0;
  left: 0;
  display: none;
  flex: 0 0 auto;
  align-items: stretch;
  height: 100%;
  outline: none;
  transform: translateX(0%);
}

@media print {
  .Polaris-Frame__Navigation {
    display: none !important;
  }
}

@media (min-width: 48.0625em) {
  .Polaris-Frame__Navigation {
    z-index: 1;
    left: var(--p-frame-offset);
    display: flex;
  }
  .Polaris-Frame--hasTopBar .Polaris-Frame__Navigation {
    top: 5.6rem;
    height: calc(100% - 5.6rem);
  }
}

.Polaris-Frame__Navigation:focus {
  outline: none;
}

.Polaris-Frame__Navigation--enter,
.Polaris-Frame__Navigation--enterActive,
.Polaris-Frame__Navigation--exit,
.Polaris-Frame__Navigation--exitActive {
  display: flex;
}

.Polaris-Frame__Navigation--enter {
  transform: translateX(-100%);
}

.Polaris-Frame__Navigation--enterActive {
  transform: translateX(0%);
  transition: transform 300ms cubic-bezier(0, 0, 0.42, 1);
}

.Polaris-Frame__Navigation--exit {
  transform: translateX(0%);
}

.Polaris-Frame__Navigation--exitActive {
  transform: translateX(-100%);
  transition: transform 300ms cubic-bezier(0, 0, 0.42, 1);
}

.Polaris-Frame__NavigationDismiss {
  position: relative;
  position: absolute;
  top: 0;
  left: 100%;
  width: 3.2rem;
  height: 3.2rem;
  margin: 1.6rem;
  padding: 0;
  border: none;
  border-radius: 50%;
  background: none;
  opacity: 0;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: opacity;
  cursor: pointer;
  transition: opacity 100ms cubic-bezier(0.25, 0.1, 0.25, 1);
}

@media print {
  .Polaris-Frame__NavigationDismiss {
    display: none !important;
  }
}

.Polaris-Frame__NavigationDismiss svg {
  fill: var(--p-surface);
}

.Polaris-Frame__NavigationDismiss::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Frame__Navigation--visible .Polaris-Frame__NavigationDismiss {
  pointer-events: all;
  opacity: 1;
}

.Polaris-Frame__NavigationDismiss:focus {
  position: absolute;
  border-radius: 0.3rem;
  outline: none;
}

.Polaris-Frame__NavigationDismiss:focus::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

@media (min-width: 48.0625em) {
  .Polaris-Frame__NavigationDismiss {
    display: none;
  }
}

.Polaris-Frame__NavigationDismiss:hover {
  background-color: var(--p-overlay);
}

.Polaris-Frame__NavigationDismiss:active {
  background-color: var(--p-overlay);
}

.Polaris-Frame__TopBar {
  position: fixed;
  z-index: 512;
  top: 0;
  left: 0;
  width: 100%;
  height: 5.6rem;
}

@media print {
  .Polaris-Frame__TopBar {
    display: none !important;
  }
}

@media (min-width: 48.0625em) {
  .Polaris-Frame__TopBar {
    left: var(--p-frame-offset);
    width: calc(100% - var(--p-frame-offset));
  }
}

.Polaris-Frame__ContextualSaveBar {
  position: fixed;
  z-index: 513;
  top: 0;
  left: 0;
  width: 100%;
}

@media (min-width: 48.0625em) {
  .Polaris-Frame__ContextualSaveBar {
    left: var(--p-frame-offset);
    width: calc(100% - var(--p-frame-offset));
  }
}

.Polaris-Frame__Main {
  flex: 1 1;
  display: flex;
  align-items: stretch;
  min-width: 0;
  max-width: 100%;
  padding-right: 0;
  padding-right: calc(constant(safe-area-inset-right));
  padding-right: calc(env(safe-area-inset-right));
  padding-left: 0;
  padding-left: calc(constant(safe-area-inset-left));
  padding-left: calc(env(safe-area-inset-left));
  padding-bottom: 0;
  padding-bottom: calc(constant(safe-area-inset-bottom));
  padding-bottom: calc(env(safe-area-inset-bottom));
}

@media (min-width: 48.0625em) {
  .Polaris-Frame--hasNav .Polaris-Frame__Main {
    padding-left: 24rem;
    padding-left: 24rem;
    padding-left: calc(24rem + constant(safe-area-inset-left));
    padding-left: calc(24rem + env(safe-area-inset-left));
  }
}

@media print and (min-width: 48.0625em) {
  .Polaris-Frame--hasNav .Polaris-Frame__Main {
    padding-left: 0;
  }
}

.Polaris-Frame--hasTopBar .Polaris-Frame__Main {
  padding-top: 5.6rem;
}

@media print {
  .Polaris-Frame--hasTopBar .Polaris-Frame__Main {
    padding-top: 0;
  }
}

.Polaris-Frame__Content {
  position: relative;
  padding-bottom: var(--global-ribbon-height, 0);
  flex: 1 1;
  min-width: 0;
  max-width: 100%;
}

.Polaris-Frame__GlobalRibbonContainer {
  position: fixed;
  z-index: 510;
  bottom: 0;
  width: 100%;
}

@media (min-width: 48.0625em) {
  .Polaris-Frame__GlobalRibbonContainer {
    left: var(--p-frame-offset);
  }
  .Polaris-Frame--hasNav .Polaris-Frame__GlobalRibbonContainer {
    left: calc(24rem + var(--p-frame-offset));
    left: calc(24rem + var(--p-frame-offset));
    left: calc(24rem + var(--p-frame-offset) + constant(safe-area-inset-left));
    left: calc(24rem + var(--p-frame-offset) + env(safe-area-inset-left));
    width: calc(100% - 24rem - var(--p-frame-offset));
  }
}

.Polaris-Frame__LoadingBar {
  position: fixed;
  z-index: 514;
  top: 0;
  right: 0;
  left: 0;
}

@media print {
  .Polaris-Frame__LoadingBar {
    display: none !important;
  }
}

@media (min-width: 48.0625em) {
  .Polaris-Frame--hasNav .Polaris-Frame__LoadingBar {
    left: var(--p-frame-offset);
  }
  .Polaris-Frame--hasTopBar .Polaris-Frame__LoadingBar {
    z-index: var(--p-override-loading-z-index, 511);
  }
}

.Polaris-Frame__Skip {
  position: fixed;
  z-index: 517;
  top: 1rem;
  left: calc(0.8rem + var(--p-frame-offset));
  opacity: 0;
  pointer-events: none;
}

.Polaris-Frame__Skip.Polaris-Frame--focused {
  pointer-events: all;
  opacity: 1;
}

.Polaris-Frame__Skip.Polaris-Frame--focused > a::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Frame__Skip > a {
  position: relative;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 3.6rem;
  min-width: 3.6rem;
  margin: 0;
  padding: 0.7rem 1.6rem;
  background: var(--p-surface);
  box-shadow: var(--p-button-drop-shadow);
  border-radius: var(--p-border-radius-base);
  color: var(--p-text);
  border: 0.1rem solid var(--p-border-neutral-subdued);
  border-top-color: var(--p-border-subdued);
  border-bottom-color: var(--p-border-shadow-subdued);
  line-height: 1;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  font-size: 1.5rem;
  font-weight: var(--p-button-font-weight);
  line-height: 1.6rem;
  text-transform: initial;
  letter-spacing: initial;
  position: relative;
  color: var(--p-text);
}

.Polaris-Frame__Skip > a svg {
  fill: var(--p-icon);
}

.Polaris-Frame__Skip > a::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.2rem;
  right: -0.2rem;
  bottom: -0.2rem;
  left: -0.2rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.2rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Frame__Skip > a:hover {
  background: var(--p-action-secondary-hovered);
  outline: 0.1rem solid transparent;
}

.Polaris-Frame__Skip > a:focus {
  box-shadow: var(--p-button-drop-shadow);
  outline: 0;
}

.Polaris-Frame__Skip > a:focus::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Frame__Skip > a:active {
  background: var(--p-action-secondary-pressed);
  box-shadow: var(--p-button-drop-shadow);
}

.Polaris-Frame__Skip > a:active::after {
  border: none;
  box-shadow: none;
}

.Polaris-Frame__Skip > a.Polaris-Frame--pressed {
  background: var(--p-action-secondary-depressed);
  box-shadow: var(--p-button-pressed-inner-shadow);
  color: var(--p-text-on-primary);
  border-color: var(--p-border-depressed);
}

.Polaris-Frame__Skip > a.Polaris-Frame--pressed svg {
  fill: currentColor;
}

@media (-ms-high-contrast: active) {
  .Polaris-Frame__Skip > a {
    border: 0.1rem solid windowText;
  }
}

@media (min-width: 40em) {
  .Polaris-Frame__Skip > a {
    font-size: 1.4rem;
  }
}

.Polaris-Frame__Skip > a::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.2rem;
  right: -0.2rem;
  bottom: -0.2rem;
  left: -0.2rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.2rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Frame__Skip > a:focus {
  border-color: none;
  box-shadow: none;
}

.Polaris-IndexTable {
  position: relative;
  overflow: hidden;
  border-radius: inherit;
}

.Polaris-IndexTable__LoadingContainer--enter {
  opacity: 0;
  transform: translateY(-100%);
}

.Polaris-IndexTable--loadingContainerEnterActive {
  opacity: 1;
  transition: opacity 100ms ease-out, transform 100ms ease-out;
  transform: translateY(0);
}

.Polaris-IndexTable__LoadingContainer--exit {
  opacity: 1;
  transform: translateY(0);
}

.Polaris-IndexTable--loadingContainerExitActive {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 100ms ease-in, transform 100ms ease-in;
}

.Polaris-IndexTable__LoadingPanel {
  position: absolute;
  z-index: 37;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: var(--p-surface);
  padding: 0.8rem 1.6rem;
  box-shadow: 0 0 0 0.1rem rgba(63, 63, 68, 0.05), 0 0.1rem 0.3rem 0 rgba(63, 63, 68, 0.15);
}

.Polaris-IndexTable__LoadingPanel .Polaris-IndexTable__LoadingPanelRow {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  background: var(--p-surface-highlight-subdued);
  padding: 0.8rem;
  padding-bottom: 0.4rem;
  border-radius: var(--p-border-radius-base);
}

.Polaris-IndexTable__LoadingPanelText {
  margin-left: 1.2rem;
  color: var(--p-text);
}

.Polaris-IndexTable__Table {
  width: 100%;
  min-width: 100%;
  border-collapse: collapse;
}

.Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableCell--first,
.Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell,
.Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableHeading--first,
.Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableHeading--second {
  visibility: visible;
  background-color: var(--p-surface);
}

.Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableCell--first,
.Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableHeading--first {
  filter: drop-shadow(0.1rem 0 0 var(--p-divider));
}

@media (min-width: 28.625em) {
  .Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell,
  .Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableHeading--second {
    filter: drop-shadow(0.1rem 0 0 var(--p-divider));
  }
}

.Polaris-IndexTable__Table--scrolling.Polaris-IndexTable__Table--unselectable .Polaris-IndexTable__TableHeading--second,
.Polaris-IndexTable__Table--scrolling.Polaris-IndexTable__Table--unselectable
  .Polaris-IndexTable__TableCell:first-child {
  visibility: visible;
  filter: drop-shadow(0.1rem 0 0 var(--p-divider));
}

.Polaris-IndexTable__TableRow {
  background-color: var(--p-surface);
  cursor: pointer;
  filter: drop-shadow(0 -0.1rem 0 var(--p-divider));
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--unclickable {
  cursor: auto;
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable--statusSuccess,
.Polaris-IndexTable__TableRow.Polaris-IndexTable--statusSuccess .Polaris-IndexTable__TableCell--first,
.Polaris-IndexTable__TableRow.Polaris-IndexTable--statusSuccess
  .Polaris-IndexTable__TableCell--first
  + .Polaris-IndexTable__TableCell {
  background-color: var(--p-surface-primary-selected);
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable--statusSubdued,
.Polaris-IndexTable__TableRow.Polaris-IndexTable--statusSubdued .Polaris-IndexTable__TableCell--first,
.Polaris-IndexTable__TableRow.Polaris-IndexTable--statusSubdued
  .Polaris-IndexTable__TableCell--first
  + .Polaris-IndexTable__TableCell {
  background-color: var(--p-surface-subdued);
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--hovered,
.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--hovered .Polaris-IndexTable__TableCell--first,
.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--hovered
  .Polaris-IndexTable__TableCell--first
  + .Polaris-IndexTable__TableCell {
  background-color: var(--p-surface-hovered);
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--selected,
.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--selected .Polaris-IndexTable__TableHeading--first,
.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--selected .Polaris-IndexTable__TableHeading--second,
.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--selected .Polaris-IndexTable__TableCell--first,
.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--selected
  .Polaris-IndexTable__TableCell--first
  + .Polaris-IndexTable__TableCell {
  background-color: var(--p-surface-selected);
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--hovered.Polaris-IndexTable__TableRow--selected,
.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--hovered.Polaris-IndexTable__TableRow--selected
  .Polaris-IndexTable__TableCell--first,
.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--hovered.Polaris-IndexTable__TableRow--selected
  .Polaris-IndexTable__TableCell--first
  + .Polaris-IndexTable__TableCell {
  background-color: var(--p-surface-selected-hovered);
}

.Polaris-IndexTable__TableRow--subdued {
  color: var(--p-text-subdued);
}

.Polaris-IndexTable__TableHeading {
  padding: 1.6rem;
  text-align: left;
  font-weight: 500;
  white-space: nowrap;
  border: 0;
}

.Polaris-IndexTable__TableHeading--first {
  position: sticky;
  left: 0;
  padding-left: 1.4rem;
  padding-right: 1.8rem;
}

.Polaris-IndexTable__ColumnHeaderCheckboxWrapper {
  display: flex;
}

.Polaris-IndexTable__FirstStickyHeaderElement {
  margin-left: -0.2rem;
  padding-right: 0.2rem;
}

.Polaris-IndexTable__TableHeading--second:not(.Polaris-IndexTable__TableHeading--unselectable) {
  padding-left: 0;
}

@media (min-width: 28.625em) {
  .Polaris-IndexTable__TableHeading--second:not(.Polaris-IndexTable__TableHeading--unselectable) {
    position: sticky;
    z-index: auto;
    left: 0;
  }
}

.Polaris-IndexTable__TableHeading--second.Polaris-IndexTable__TableHeading--unselectable {
  position: sticky;
  z-index: auto;
  left: 0;
}

.Polaris-IndexTable__TableCell {
  z-index: 1;
  text-align: left;
  padding: 0.8rem 1.6rem;
  white-space: nowrap;
}

.Polaris-IndexTable__TableCell--flush {
  padding: 0;
}

.Polaris-IndexTable__TableCell--first {
  position: sticky;
  left: 0;
  z-index: 31;
  padding: 0;
}

.Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell {
  left: var(--p-checkbox-offset);
  padding-left: 0;
}

@media (min-width: 28.625em) {
  .Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell {
    position: sticky;
    z-index: 31;
    padding: 0;
  }
}

.Polaris-IndexTable__Table--unselectable .Polaris-IndexTable__TableCell:first-child {
  left: 0;
  background-color: var(--p-surface);
  position: sticky;
  z-index: 31;
}

.Polaris-IndexTable__Table--unselectable .Polaris-IndexTable--statusSuccess .Polaris-IndexTable__TableCell:first-child {
  background-color: var(--p-surface-primary-selected);
}

.Polaris-IndexTable__Table--unselectable .Polaris-IndexTable--statusSubdued .Polaris-IndexTable__TableCell:first-child {
  background-color: var(--p-surface-subdued);
}

.Polaris-IndexTable__Table--unselectable
  .Polaris-IndexTable__TableRow--hovered
  .Polaris-IndexTable__TableCell:first-child {
  background-color: var(--p-surface-hovered);
}

@media (min-width: 28.625em) {
  .Polaris-IndexTable--tableStickyScrolling .Polaris-IndexTable__TableCell:last-child,
  .Polaris-IndexTable--tableStickyScrolling .Polaris-IndexTable__TableHeading--last {
    filter: drop-shadow(-0.1rem 0 0 var(--p-divider));
  }
}

@media (min-width: 28.625em) {
  .Polaris-IndexTable--tableStickyLast .Polaris-IndexTable__TableCell:last-child {
    position: sticky;
    right: 0;
    background-color: var(--p-surface);
    z-index: 31;
  }
}

@media (min-width: 28.625em) {
  .Polaris-IndexTable--tableStickyLast .Polaris-IndexTable__TableHeading--last {
    position: sticky;
    right: 0;
    background-color: var(--p-surface);
    z-index: auto;
  }
}

.Polaris-IndexTable--tableStickyLast .Polaris-IndexTable--statusSuccess .Polaris-IndexTable__TableCell:last-child {
  background-color: var(--p-surface-primary-selected);
}

.Polaris-IndexTable--tableStickyLast .Polaris-IndexTable--statusSubdued .Polaris-IndexTable__TableCell:last-child {
  background-color: var(--p-surface-subdued);
}

.Polaris-IndexTable--tableStickyLast .Polaris-IndexTable__TableRow--hovered .Polaris-IndexTable__TableCell:last-child {
  background-color: var(--p-surface-hovered);
}

.Polaris-IndexTable--tableStickyLast .Polaris-IndexTable__TableRow--selected .Polaris-IndexTable__TableCell:last-child {
  background-color: var(--p-surface-selected);
}

.Polaris-IndexTable__StickyTable {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  visibility: hidden;
  z-index: 37;
}

.Polaris-IndexTable__StickyTableHeader {
  position: absolute;
  display: flex;
  width: 100%;
}

.Polaris-IndexTable__StickyTableHeader:not(.Polaris-IndexTable__StickyTableHeader--isSticky) {
  top: -100rem;
  left: -100rem;
}

.Polaris-IndexTable__StickyTableColumnHeader {
  flex: 0 0 auto;
}

.Polaris-IndexTable__StickyTableColumnHeader--isScrolling {
  box-shadow: 0.1rem 0.1rem 0 0 var(--p-divider), 0.1rem 0 0.1rem 0.1rem rgba(63, 63, 68, 0.05),
    0.1rem 0 0.3rem 0 rgba(63, 63, 68, 0.15);
}

.Polaris-IndexTable__StickyTableHeadings {
  overflow: hidden;
  flex: 1 1 auto;
  display: flex;
}

.Polaris-IndexTable__StickyTableHeading--second {
  padding-left: 0;
}

@media (min-width: 28.625em) {
  .Polaris-IndexTable__StickyTableHeading--second {
    display: none;
  }
}

.Polaris-IndexTable__StickyTableHeading--second.Polaris-IndexTable--unselectable {
  display: none;
}

.Polaris-IndexTable--stickyTableHeadingSecondScrolling {
  padding: 0 0.06rem 0 1.6rem;
  display: none;
}

@media (min-width: 28.625em) {
  .Polaris-IndexTable--stickyTableHeadingSecondScrolling {
    display: block;
  }
}

.Polaris-IndexTable__StickyTableHeader--isSticky {
  visibility: visible;
  background-color: var(--p-surface);
  box-shadow: 0 0 0 0.1rem rgba(63, 63, 68, 0.05), 0 0.1rem 0.3rem 0 rgba(63, 63, 68, 0.15);
}

.Polaris-IndexTable:hover .Polaris-IndexTable__ScrollLeft {
  display: block;
}

.Polaris-IndexTable:hover .Polaris-IndexTable__ScrollRight {
  display: block;
}

.Polaris-IndexTable .Polaris-IndexTable__ScrollRight--onboarding {
  display: block;
}

.Polaris-IndexTable__BulkActionsWrapper {
  visibility: visible;
  position: relative;
  z-index: 36;
  top: 0;
  left: 0;
  right: 0;
  padding: 0.8rem;
  background: var(--p-surface);
}

.Polaris-IndexTable__ScrollBarContainer {
  position: sticky;
  z-index: 35;
  bottom: 0;
  padding: 0.2rem;
  border-top: 0.1rem solid var(--p-divider);
  background-color: var(--p-surface);
  border-bottom-right-radius: var(--p-border-radius-base);
  border-bottom-left-radius: var(--p-border-radius-base);
}

.Polaris-IndexTable--scrollBarContainerCondensed {
  visibility: hidden;
  pointer-events: none;
}

.Polaris-IndexTable__ScrollBar {
  overflow-x: scroll;
  width: 100%;
  margin: 0;
  padding: 0;
}

.Polaris-IndexTable__ScrollBar::-webkit-scrollbar-track {
  border-radius: 0.4rem;
  background-color: transparent;
}

.Polaris-IndexTable__ScrollBar::-webkit-scrollbar {
  -webkit-appearance: none;
  appearance: none;
  height: 0.8rem;
  width: 0.8rem;
  background-color: transparent;
}

.Polaris-IndexTable__ScrollBar::-webkit-scrollbar-thumb {
  border-radius: 0.4rem;
  background-color: var(--p-border-neutral-subdued);
  -webkit-transition: background-color 100ms ease-out;
  transition: background-color 100ms ease-out;
}

.Polaris-IndexTable__ScrollBar:hover::-webkit-scrollbar-thumb {
  background-color: var(--p-border-depressed);
}

.Polaris-IndexTable--disableTextSelection {
  -webkit-user-select: none;
  user-select: none;
}

.Polaris-IndexTable--selectMode {
  transform: translateY(-5.2rem);
  margin-bottom: -5.2rem;
}

.Polaris-IndexTable__EmptySearchResultWrapper {
  padding: 1.6rem;
}

.Polaris-IndexTable--condensedRow {
  width: calc(100% + 3.4rem);
  transform: translateX(-3.4rem);
  transition: transform cubic-bezier(0.25, 0.1, 0.25, 1) 200ms;
  display: flex;
  border-top: 0.1rem solid var(--p-divider);
  filter: none;
  align-items: center;
}

[data-selectmode="true"] .Polaris-IndexTable--condensedRow {
  transform: none;
}

.Polaris-IndexTable__CondensedList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.Polaris-IndexTable__HeaderWrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  min-height: 5.6rem;
  padding: 1rem 1.6rem;
  background-color: var(--p-surface);
}

.Polaris-IndexTable__HeaderWrapper.Polaris-IndexTable--unselectable {
  min-height: auto;
  padding: 0;
}

.Polaris-IndexTable__StickyTable--condensed {
  visibility: visible;
}

.Polaris-IndexTable__StickyTableHeader--condensed {
  padding: 1.6rem 1.6rem 0.8rem;
}

.Polaris-IndexTable__ScrollBarContent {
  height: 0.1rem;
  width: var(--p-scroll-bar-content-width);
}

.Polaris-IndexTable-Checkbox__TableCellContentContainer {
  display: flex;
  align-items: center;
}

.Polaris-IndexTable-Checkbox__Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Polaris-IndexTable-Checkbox--expanded {
  width: 3.2rem;
  height: 3.2rem;
  padding-left: 1.6rem;
}

.Polaris-IndexTable-Checkbox--condensed {
  min-width: 5rem;
  min-height: 4.4rem;
  margin-right: -1.6rem;
}

.Polaris-IndexTable-ScrollContainer {
  overflow-x: auto;
  overscroll-behavior-x: contain;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.Polaris-IndexTable-ScrollContainer::-webkit-scrollbar {
  display: none;
}

.Polaris-KeyboardKey {
  display: inline-block;
  height: 2.4rem;
  margin: 0 0.2rem 0.2rem;
  padding: 0 0.4rem;
  background-color: var(--p-surface);
  box-shadow: 0 0 0 0.1rem var(--p-border-subdued), 0 0.2rem 0 0 var(--p-surface),
    0 0.2rem 0 0.1rem var(--p-border-subdued);
  border-radius: var(--p-border-radius-base);
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: var(--p-text-subdued);
  text-align: center;
  min-width: 2.4rem;
  -webkit-user-select: none;
  user-select: none;
}

.Polaris-Layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: -1.6rem;
  margin-left: -2rem;
}

@media print {
  body .Polaris-Layout {
    font-size: 1.1rem;
    line-height: 1.6rem;
  }
  .Polaris-Layout a,
  .Polaris-Layout button {
    color: var(--p-text);
  }
}

.Polaris-Layout__Section {
  flex: 2 2 48rem;
  min-width: 51%;
}

@media print {
  .Polaris-Layout__Section {
    flex: 2 2 36rem;
  }
}

.Polaris-Layout__Section--secondary {
  flex: 1 1 24rem;
  min-width: 0;
}

.Polaris-Layout__Section--fullWidth {
  flex: 1 1 100%;
}

.Polaris-Layout__Section--oneHalf {
  flex: 1 1 45rem;
  min-width: 0;
}

.Polaris-Layout__Section--oneThird {
  flex: 1 1 24rem;
  min-width: 0;
}

.Polaris-Layout__AnnotatedSection {
  min-width: 0;
  flex: 1 1 100%;
}

.Polaris-Layout__Section,
.Polaris-Layout__AnnotatedSection {
  max-width: calc(100% - 2rem);
  margin-top: 1.6rem;
  margin-left: 2rem;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Layout__Section + .Polaris-Layout__AnnotatedSection,
  [data-has-navigation] .Polaris-Layout__AnnotatedSection + .Polaris-Layout__AnnotatedSection {
    padding-top: 1.6rem;
    border-top: 0.1rem solid var(--p-divider);
  }
}

@media (min-width: 30.625em) {
  .Polaris-Layout__Section + .Polaris-Layout__AnnotatedSection,
  .Polaris-Layout__AnnotatedSection + .Polaris-Layout__AnnotatedSection {
    padding-top: 1.6rem;
    border-top: 0.1rem solid var(--p-divider);
  }
}

.Polaris-Layout__AnnotationWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: -1.6rem;
  margin-left: -2rem;
}

.Polaris-Layout__AnnotationContent {
  flex: 2 2 48rem;
}

.Polaris-Layout__Annotation {
  flex: 1 1 24rem;
  padding: 1.6rem 2rem 0;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Layout__Annotation {
    padding: 1.6rem 0 0;
  }
}

@media (min-width: 30.625em) {
  .Polaris-Layout__Annotation {
    padding: 1.6rem 0 0;
  }
}

@media (max-width: 48.0625em) and (min-width: 50em), (min-width: 65em) {
  [data-has-navigation] .Polaris-Layout__Annotation {
    padding: 2rem 2rem 2rem 0;
  }
}

@media (min-width: 50em) {
  .Polaris-Layout__Annotation {
    padding: 2rem 2rem 2rem 0;
  }
}

.Polaris-Layout__Annotation,
.Polaris-Layout__AnnotationContent {
  min-width: 0;
  max-width: calc(100% - 2rem);
  margin-top: 1.6rem;
  margin-left: 2rem;
}

.Polaris-Layout__AnnotationDescription {
  color: var(--p-text-subdued);
}

.Polaris-Link {
  -webkit-appearance: none;
  appearance: none;
  display: inline;
  text-align: inherit;
  padding: 0;
  background: none;
  border: 0;
  font-size: inherit;
  font-weight: inherit;
  color: var(--p-interactive);
  text-decoration: underline;
  cursor: pointer;
}

.Polaris-Link:hover {
  color: var(--p-interactive-hovered);
  text-decoration: none;
}

.Polaris-Link:focus:not(:active) {
  outline: var(--p-focused) auto 0.1rem;
}

.Polaris-Link:active {
  position: relative;
  color: var(--p-interactive-pressed);
}

.Polaris-Link:active::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -0.2rem;
  right: -0.5rem;
  bottom: -0.2rem;
  left: -0.5rem;
  display: block;
  border-radius: 0.3rem;
}

@media print {
  .Polaris-Link {
    -webkit-text-decoration-color: var(--p-border-neutral-subdued);
    text-decoration-color: var(--p-border-neutral-subdued);
  }
}

.Polaris-Link__IconLockup {
  display: inline;
  white-space: nowrap;
}

.Polaris-Link__IconLockup svg {
  fill: currentColor;
}

.Polaris-Link__IconLockup::before {
  content: "\2060";
}

.Polaris-Link__IconLayout {
  display: inline-flex;
}

.Polaris-Link__IconLayout::before {
  content: "\2060";
}

.Polaris-Link--monochrome {
  color: inherit;
}

.Polaris-Link--monochrome:hover,
.Polaris-Link--monochrome:focus,
.Polaris-Link--monochrome:active {
  color: inherit;
}

.Polaris-Link--removeUnderline {
  text-decoration: none;
}

.Polaris-List {
  padding-left: 2rem;
  margin-top: 0;
  margin-bottom: 0;
  list-style: disc outside none;
}

.Polaris-List + .Polaris-List {
  margin-top: 1.6rem;
}

.Polaris-List--typeNumber {
  padding-left: 3.2rem;
  list-style: decimal outside none;
}

.Polaris-List__Item {
  margin-bottom: 0.8rem;
}

.Polaris-List__Item:last-child {
  margin-bottom: 0;
}

.Polaris-List__Item .Polaris-List:first-child {
  margin-top: 0.8rem;
}

.Polaris-MediaCard {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}

.Polaris-MediaCard.Polaris-MediaCard--portrait {
  flex-flow: column nowrap;
}

@media (max-width: 50.25em) {
  .Polaris-MediaCard {
    flex-flow: column nowrap;
  }
}

.Polaris-MediaCard__MediaContainer {
  overflow: hidden;
  border-top-left-radius: var(--p-border-radius-wide);
  border-top-right-radius: var(--p-border-radius-wide);
}

.Polaris-MediaCard__MediaContainer:not(.Polaris-MediaCard--portrait) {
  flex-basis: 40%;
}

.Polaris-MediaCard__MediaContainer:not(.Polaris-MediaCard--portrait).Polaris-MediaCard--sizeSmall {
  flex-basis: 33%;
}

@media (min-width: 50.25em) {
  .Polaris-MediaCard__MediaContainer:not(.Polaris-MediaCard--portrait) {
    border-top-right-radius: 0;
    border-top-left-radius: var(--p-border-radius-wide);
    border-bottom-left-radius: var(--p-border-radius-wide);
  }
}

.Polaris-MediaCard__InfoContainer {
  position: relative;
}

.Polaris-MediaCard__InfoContainer:not(.Polaris-MediaCard--portrait) {
  flex-basis: 60%;
}

.Polaris-MediaCard__InfoContainer:not(.Polaris-MediaCard--portrait).Polaris-MediaCard--sizeSmall {
  flex-basis: 67%;
}

.Polaris-MediaCard__Popover {
  position: absolute;
  z-index: 400;
  top: 1.6rem;
  right: 1.6rem;
}

.Polaris-MediaCard__Heading {
  margin-right: 3.2rem;
}

.Polaris-MediaCard__PrimaryAction {
  margin-right: 0.8rem;
}

.Polaris-MediaCard__SecondaryAction {
  margin-left: -spacing(tight);
}

.Polaris-MediaCard__ActionContainer {
  padding-top: 0.8rem;
}

.Polaris-MediaCard__ActionContainer.Polaris-MediaCard--portrait {
  padding-top: 3.2rem;
}

@media (max-width: 50.25em) {
  .Polaris-MediaCard__ActionContainer {
    padding-top: 3.2rem;
  }
}

@keyframes Polaris-Navigation__fade--in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Polaris-Navigation {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: calc(100vw - 6.4rem);
  min-width: 24rem;
  max-width: 36rem;
  height: 100%;
  min-height: 100%;
  background-color: var(--p-background);
  -webkit-overflow-scrolling: touch;
  border-right: 0.1rem solid var(--p-divider);
  padding-bottom: 0;
  padding-bottom: calc(constant(safe-area-inset-bottom));
  padding-bottom: calc(env(safe-area-inset-bottom));
}

.Polaris-Navigation:focus {
  outline: none;
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation {
    max-width: 24rem;
    max-width: 24rem;
    max-width: calc(24rem + constant(safe-area-inset-left));
    max-width: calc(24rem + env(safe-area-inset-left));
  }
}

.Polaris-Navigation__UserMenu {
  flex: 0 0 auto;
}

.Polaris-Navigation__ContextControl {
  min-height: 5.6rem;
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__ContextControl {
    display: none;
  }
}

.Polaris-Navigation__PrimaryNavigation {
  display: flex;
  overflow: auto;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: stretch;
  max-width: 100%;
}

.Polaris-Navigation__PrimaryNavigation:focus {
  outline: none;
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__PrimaryNavigation {
    padding-top: 1.2rem;
  }
}

.Polaris-Navigation__LogoContainer {
  display: none;
}

@media (max-width: 48.0625em) {
  .Polaris-Navigation__LogoContainer {
    display: flex;
    flex: 0 0 5.6rem;
    align-items: center;
    height: 5.6rem;
    padding: 0 0.8rem 0 1.6rem;
    flex-basis: 5.6rem;
    flex-basis: calc(5.6rem + constant(safe-area-inset-left));
    flex-basis: calc(5.6rem + env(safe-area-inset-left));
    padding-left: 1.6rem;
    padding-left: calc(1.6rem + constant(safe-area-inset-left));
    padding-left: calc(1.6rem + env(safe-area-inset-left));
  }
}

.Polaris-Navigation__Logo,
.Polaris-Navigation__LogoLink {
  display: block;
}

.Polaris-Navigation__Item {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 3.6rem;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  max-width: 100%;
  padding: 0 0.8rem;
  margin: 0 0.8rem;
  border-radius: var(--p-border-radius-base);
  color: var(--p-text);
  text-decoration: none;
  text-align: left;
  padding-left: 1.2rem;
  padding-right: 0.4rem;
  position: relative;
  position: relative;
}

.Polaris-Navigation__Item:focus {
  outline: none;
}

.Polaris-Navigation__Item:hover {
  background: var(--p-background-hovered);
  color: var(--p-text);
  text-decoration: none;
}

.Polaris-Navigation__Item::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Navigation__Item.Polaris-Navigation--keyFocused {
  background: var(--p-background-hovered);
  color: var(--p-text);
  text-decoration: none;
}

.Polaris-Navigation__Item.Polaris-Navigation--keyFocused::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Navigation__Item:active,
.Polaris-Navigation__Item:active:hover {
  color: var(--p-text);
  background-color: var(--p-background-pressed);
}

.Polaris-Navigation__Item:active::after,
.Polaris-Navigation__Item:active:hover::after {
  content: none;
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__Item {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 3.2rem;
  }
}

.Polaris-Navigation__Item::-moz-focus-inner {
  border: 0;
}

.Polaris-Navigation__Item--selected {
  font-weight: 600;
  color: var(--p-text-primary);
  background-color: var(--p-background-selected);
  outline: 0.1rem solid transparent;
  position: relative;
}

.Polaris-Navigation__Item--selected::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -0.8rem;
  height: 100%;
  width: 0.3rem;
  background-color: var(--p-action-primary);
  border-top-right-radius: var(--p-border-radius-base);
  border-bottom-right-radius: var(--p-border-radius-base);
}

.Polaris-Navigation__Item--selected::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Navigation__Item--selected:hover,
.Polaris-Navigation__Item--selected.Polaris-Navigation--keyFocused {
  background-color: var(--p-background-hovered);
  color: var(--p-text-primary-hovered);
}

.Polaris-Navigation__Item--selected .Polaris-Navigation--keyFocused::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Navigation__Item--selected:active,
.Polaris-Navigation__Item--selected:active:hover {
  color: var(--p-text-primary);
  background-color: var(--p-background-pressed);
}

.Polaris-Navigation__Item--selected:active::after,
.Polaris-Navigation__Item--selected:active:hover::after {
  content: none;
}

.Polaris-Navigation__Item--disabled {
  color: var(--p-text-disabled);
  pointer-events: none;
  opacity: 0.6;
}

.Polaris-Navigation__Item--disabled .Polaris-Navigation__Icon {
  opacity: 0.6;
}

.Polaris-Navigation__Badge {
  margin-left: 0.8rem;
  display: inline-flex;
  margin-top: 0.8rem;
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__Badge {
    margin-top: 0.4rem;
  }
}

.Polaris-Navigation__Icon {
  --p-filter-icon: brightness(0) saturate(100%) invert(36%) sepia(13%) saturate(137%) hue-rotate(169deg) brightness(95%)
    contrast(87%);
  --p-filter-icon-action-primary: brightness(0) saturate(100%) invert(20%) sepia(59%) saturate(5557%) hue-rotate(162deg)
    brightness(95%) contrast(101%);
  --p-filter-icon-on-interactive: brightness(0) saturate(100%) invert(100%);
  flex-shrink: 0;
  align-self: flex-start;
  width: 2rem;
  height: 2rem;
  margin-top: 1rem;
  margin-right: 1.6rem;
  margin-bottom: 1rem;
}

.Polaris-Navigation__Icon svg {
  fill: var(--p-icon);
}

.Polaris-Navigation__Icon img {
  filter: var(--p-filter-icon);
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__Icon {
    margin-top: 0.6rem;
    margin-right: 1.6rem;
    margin-bottom: 0.6rem;
  }
}

.Polaris-Navigation__Item:hover .Polaris-Navigation__Icon svg,
.Polaris-Navigation__Item.Polaris-Navigation--keyFocused .Polaris-Navigation__Icon svg {
  fill: var(--p-icon);
}

.Polaris-Navigation__Item:hover .Polaris-Navigation__Icon img,
.Polaris-Navigation__Item.Polaris-Navigation--keyFocused .Polaris-Navigation__Icon img {
  filter: var(--p-filter-icon);
}

.Polaris-Navigation__Item--selected .Polaris-Navigation__Icon svg,
.Polaris-Navigation__Item--selected:hover .Polaris-Navigation__Icon svg,
.Polaris-Navigation--subNavigationActive .Polaris-Navigation__Icon svg,
.Polaris-Navigation--subNavigationActive:hover .Polaris-Navigation__Icon svg,
.Polaris-Navigation__Item--selected.Polaris-Navigation--keyFocused .Polaris-Navigation__Icon svg {
  fill: var(--p-action-primary);
}

.Polaris-Navigation__Item--selected .Polaris-Navigation__Icon img,
.Polaris-Navigation__Item--selected:hover .Polaris-Navigation__Icon img,
.Polaris-Navigation--subNavigationActive .Polaris-Navigation__Icon img,
.Polaris-Navigation--subNavigationActive:hover .Polaris-Navigation__Icon img,
.Polaris-Navigation__Item--selected.Polaris-Navigation--keyFocused .Polaris-Navigation__Icon img {
  filter: var(--p-filter-icon-action-primary);
}

.Polaris-Navigation__Icon svg {
  display: block;
}

.Polaris-Navigation__ExternalIcon {
  display: flex;
  align-items: center;
  height: 4rem;
  padding: 0.4rem 0.8rem 0.4rem 1.6rem;
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__ExternalIcon {
    height: 3.2rem;
  }
}

.Polaris-Navigation__ListItem {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.Polaris-Navigation__RollupSection .Polaris-Navigation__ListItem,
.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__ListItem {
  opacity: 1;
}

.Polaris-Navigation__ListItem:nth-child(1) {
  animation-delay: 0ms;
}

.Polaris-Navigation__ListItem:nth-child(2) {
  animation-delay: 50ms;
}

.Polaris-Navigation__ListItem:nth-child(3) {
  animation-delay: 100ms;
}

.Polaris-Navigation__ListItem:nth-child(4) {
  animation-delay: 150ms;
}

.Polaris-Navigation__ListItem:nth-child(5) {
  animation-delay: 200ms;
}

.Polaris-Navigation__ListItem:nth-child(6) {
  animation-delay: 250ms;
}

.Polaris-Navigation__ListItem:nth-child(7) {
  animation-delay: 300ms;
}

.Polaris-Navigation__ListItem:nth-child(8) {
  animation-delay: 350ms;
}

.Polaris-Navigation__ListItem:nth-child(9) {
  animation-delay: 400ms;
}

.Polaris-Navigation__ListItem:nth-child(10) {
  animation-delay: 450ms;
}

.Polaris-Navigation__ListItem:nth-child(11) {
  animation-delay: 500ms;
}

.Polaris-Navigation__ListItem:nth-child(12) {
  animation-delay: 550ms;
}

.Polaris-Navigation__ListItem:not(:first-child) .Polaris-Navigation__Item {
  border-top: 0.1rem solid var(--p-background);
}

.Polaris-Navigation__ListItem--hasAction .Polaris-Navigation__Item {
  max-width: calc(100% - 5.6rem);
}

.Polaris-Navigation__ItemWrapper {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.Polaris-Navigation__Text {
  flex: 1 1 auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  line-height: 2rem;
  font-size: 1.4rem;
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__Text {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
  }
}

@media (max-width: 48.0625em) {
  .Polaris-Navigation__Text {
    font-size: 1.5rem;
    line-height: 1.9rem;
  }
}

.Polaris-Navigation__SecondaryAction {
  display: flex;
  align-items: center;
  height: 4rem;
  margin-right: 0.4rem;
  padding: 0.4rem 1.6rem;
  border-radius: var(--p-border-radius-base);
  position: relative;
}

.Polaris-Navigation__SecondaryAction svg {
  fill: var(--p-icon);
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__SecondaryAction {
    height: 3.2rem;
  }
}

.Polaris-Navigation__SecondaryAction:hover,
.Polaris-Navigation__SecondaryAction:focus,
.Polaris-Navigation__SecondaryAction:active {
  background: var(--p-background-hovered);
}

.Polaris-Navigation__SecondaryAction:hover svg,
.Polaris-Navigation__SecondaryAction:focus svg,
.Polaris-Navigation__SecondaryAction:active svg {
  fill: var(--p-icon-hovered);
}

.Polaris-Navigation__SecondaryAction:hover img,
.Polaris-Navigation__SecondaryAction:focus img,
.Polaris-Navigation__SecondaryAction:active img {
  filter: var(--p-filter-icon-action-primary);
}

@media (-ms-high-contrast: active) {
  .Polaris-Navigation__SecondaryAction:hover svg,
  .Polaris-Navigation__SecondaryAction:focus svg,
  .Polaris-Navigation__SecondaryAction:active svg {
    fill: var(--p-icon-on-interactive);
  }
  .Polaris-Navigation__SecondaryAction:hover img,
  .Polaris-Navigation__SecondaryAction:focus img,
  .Polaris-Navigation__SecondaryAction:active img {
    filter: var(--p-filter-icon-on-interactive);
  }
}

.Polaris-Navigation__SecondaryAction::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Navigation__SecondaryAction:focus::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Navigation__SecondaryAction:active {
  background: var(--p-background-pressed);
}

.Polaris-Navigation__SecondaryAction:active::after {
  content: none;
}

.Polaris-Navigation__SecondaryAction:active svg {
  fill: var(--p-icon-pressed);
}

.Polaris-Navigation__SecondaryAction:focus,
.Polaris-Navigation__SecondaryAction:active {
  outline: none;
}

.Polaris-Navigation__SecondaryNavigation {
  flex-basis: 100%;
  margin-left: 0;
  overflow-x: visible;
}

.Polaris-Navigation__SecondaryNavigation.Polaris-Navigation--isExpanded {
  margin-bottom: 0.8rem;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__List {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 4rem;
  color: var(--p-text-subdued);
  padding-left: 4.8rem;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:hover {
  color: var(--p-text-subdued);
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item.Polaris-Navigation--keyFocused {
  color: var(--p-text);
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item.Polaris-Navigation--keyFocused::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:active::after,
.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:active:hover::after {
  content: none;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:active {
  color: var(--p-text-primary);
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item {
    font-size: 1.4rem;
    line-height: 2.8rem;
  }
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Text {
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  line-height: 2rem;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected {
  color: var(--p-text-primary);
  position: relative;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected:hover {
  color: var(--p-text-primary-hovered);
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected.Polaris-Navigation--keyFocused {
  color: var(--p-text-primary);
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected:active {
  color: var(--p-text-primary);
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected:active::after {
  content: none;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--disabled {
  font-weight: 500;
  color: var(--p-text-disabled);
}

.Polaris-Navigation__SecondaryNavigation--noIcon {
  margin-left: 1.6rem;
}

.Polaris-Navigation__Section {
  margin: 0;
  padding: 0;
  list-style: none;
  flex: 0 0 auto;
  padding: 1.6rem 0;
  padding-top: 0;
  padding-left: 0;
  padding-left: calc(constant(safe-area-inset-left));
  padding-left: calc(env(safe-area-inset-left));
}

.Polaris-Navigation__Section + .Polaris-Navigation__Section {
  padding-top: 0.4rem;
  padding-bottom: 1.6rem;
}

.Polaris-Navigation__Section--fill {
  flex: 1 0 auto;
}

.Polaris-Navigation__Section--withSeparator {
  border-top: 0.1rem solid var(--p-divider);
}

.Polaris-Navigation__SectionHeading {
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.6rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding-left: 1.6rem;
}

@media (min-width: 40em) {
  .Polaris-Navigation__SectionHeading {
    font-size: 1.2rem;
  }
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Text {
  font-size: 1.3rem;
  color: var(--p-text-subdued);
}

@media (min-width: 40em) {
  .Polaris-Navigation__SectionHeading .Polaris-Navigation__Text {
    font-size: 1.2rem;
  }
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 0.4rem;
  padding: 0.4rem 1.6rem;
  border-radius: var(--p-border-radius-base);
  position: relative;
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus {
  outline: none;
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action svg {
  fill: var(--p-icon);
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:hover,
.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus {
  background: var(--p-background-hovered);
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:hover svg,
.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus svg {
  fill: var(--p-icon-hovered);
}

@media (-ms-high-contrast: active) {
  .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:hover svg,
  .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus svg {
    fill: var(--p-icon-on-interactive);
  }
  .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:hover img,
  .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus img {
    filter: var(--p-filter-icon-on-interactive);
  }
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:hover img {
  filter: var(--p-filter-icon);
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus:hover svg {
  fill: var(--p-icon-hovered);
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active,
.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active:hover {
  background: var(--p-background-pressed);
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active svg,
.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active:hover svg {
  fill: var(--p-icon-pressed);
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active::after,
.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active:hover::after {
  content: none;
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus,
.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active {
  outline: none;
}

.Polaris-Navigation__RollupToggle {
  font-weight: 400;
  color: var(--p-text);
  color: var(--p-text-subdued);
}

.Polaris-Navigation__RollupToggle:hover {
  color: var(--p-text-primary);
}

.Polaris-Navigation__RollupToggle:hover svg {
  fill: var(--p-action-primary);
}

.Polaris-Navigation__RollupToggle:hover img {
  filter: var(--p-filter-icon-action-primary);
}

.Polaris-Navigation__RollupToggle:focus {
  outline: none;
}

.Polaris-Navigation__RollupToggle:focus::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Navigation__List {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Polaris-Navigation__Indicator {
  position: relative;
  display: inline-block;
  height: 1rem;
  width: 1rem;
}

.Polaris-Navigation__SecondaryNavigation--noIcon .Polaris-Navigation__Item {
  padding-left: 1.2rem;
}

.Polaris-OptionList-Checkbox {
  position: relative;
  width: 100%;
  margin: var(--p-choice-margin);
}

.Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--active .Polaris-OptionList-Checkbox__Backdrop::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-OptionList-Checkbox__Input {
  position: absolute !important;
  width: 0.1rem !important;
  height: 0.1rem !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}

.Polaris-OptionList-Checkbox__Input.Polaris-OptionList-Checkbox--keyFocused
  + .Polaris-OptionList-Checkbox__Backdrop::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-OptionList-Checkbox__Input:active:not(:disabled) + .Polaris-OptionList-Checkbox__Backdrop,
.Polaris-OptionList-Checkbox__Input:checked + .Polaris-OptionList-Checkbox__Backdrop,
.Polaris-OptionList-Checkbox__Input.Polaris-OptionList-Checkbox__Input--indeterminate
  + .Polaris-OptionList-Checkbox__Backdrop {
  border-color: var(--p-interactive);
}

.Polaris-OptionList-Checkbox__Input:active:not(:disabled) + .Polaris-OptionList-Checkbox__Backdrop::before,
.Polaris-OptionList-Checkbox__Input:checked + .Polaris-OptionList-Checkbox__Backdrop::before,
.Polaris-OptionList-Checkbox__Input.Polaris-OptionList-Checkbox__Input--indeterminate
  + .Polaris-OptionList-Checkbox__Backdrop::before {
  opacity: 1;
  transform: scale(1);
}

@media (-ms-high-contrast: active) {
  .Polaris-OptionList-Checkbox__Input:active:not(:disabled) + .Polaris-OptionList-Checkbox__Backdrop::before,
  .Polaris-OptionList-Checkbox__Input:checked + .Polaris-OptionList-Checkbox__Backdrop::before,
  .Polaris-OptionList-Checkbox__Input.Polaris-OptionList-Checkbox__Input--indeterminate
    + .Polaris-OptionList-Checkbox__Backdrop::before {
    border: 0.2rem solid windowText;
  }
}

.Polaris-OptionList-Checkbox__Input:active:not(:disabled) ~ .Polaris-OptionList-Checkbox__Icon,
.Polaris-OptionList-Checkbox__Input:checked ~ .Polaris-OptionList-Checkbox__Icon,
.Polaris-OptionList-Checkbox__Input.Polaris-OptionList-Checkbox__Input--indeterminate
  ~ .Polaris-OptionList-Checkbox__Icon {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  transition: opacity var(--p-duration-1-5-0) var(--p-ease), transform var(--p-duration-1-5-0) var(--p-ease);
}

.Polaris-OptionList-Checkbox__Input:disabled + .Polaris-OptionList-Checkbox__Backdrop {
  border-color: var(--p-border-disabled);
}

.Polaris-OptionList-Checkbox__Input:disabled + .Polaris-OptionList-Checkbox__Backdrop::before {
  background-color: var(--p-action-secondary-disabled);
}

.Polaris-OptionList-Checkbox__Input:disabled + .Polaris-OptionList-Checkbox__Backdrop:hover {
  cursor: default;
}

.Polaris-OptionList-Checkbox__Input:disabled:checked + .Polaris-OptionList-Checkbox__Backdrop,
.Polaris-OptionList-Checkbox__Input:disabled:checked + .Polaris-OptionList-Checkbox__Backdrop::before {
  background: var(--p-border-disabled);
}

.Polaris-OptionList-Checkbox__Backdrop {
  position: relative;
  border: var(--p-control-border-width) solid var(--p-border);
  background-color: var(--p-surface);
  border-radius: var(--p-border-radius-base);
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.Polaris-OptionList-Checkbox__Backdrop::before {
  content: "";
  position: absolute;
  top: calc(var(--p-control-border-width) * -1);
  right: calc(var(--p-control-border-width) * -1);
  bottom: calc(var(--p-control-border-width) * -1);
  left: calc(var(--p-control-border-width) * -1);
  border-radius: var(--p-border-radius-base);
  background-color: var(--p-interactive);
  opacity: 0;
  transform: scale(0.25);
  transition: opacity 100ms var(--p-ease), transform 100ms var(--p-ease);
}

.Polaris-OptionList-Checkbox__Backdrop.Polaris-OptionList-Checkbox--hover,
.Polaris-OptionList-Checkbox__Backdrop:hover {
  cursor: pointer;
  border-color: var(--p-border-hovered);
}

.Polaris-OptionList-Checkbox__Backdrop::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: calc(var(--p-control-border-width) * -1 + -0.1rem);
  right: calc(var(--p-control-border-width) * -1 + -0.1rem);
  bottom: calc(var(--p-control-border-width) * -1 + -0.1rem);
  left: calc(var(--p-control-border-width) * -1 + -0.1rem);
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 calc(var(--p-control-border-width) * -1 + -0.1rem) var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-OptionList-Checkbox__Icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 50% 50%;
  pointer-events: none;
  transform: translate(-50%, -50%) scale(0.25);
  opacity: 0;
  transition: opacity 100ms var(--p-ease), transform 100ms var(--p-ease);
}

.Polaris-OptionList-Checkbox__Icon svg {
  fill: var(--p-icon-on-interactive);
}

@media (-ms-high-contrast: active) {
  .Polaris-OptionList-Checkbox__Icon {
    fill: windowText;
  }
}

.Polaris-OptionList-Option {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  width: 100%;
  min-height: 3.6rem;
  text-align: left;
  border-radius: var(--p-border-radius-base);
  margin-top: 0.4rem;
}

.Polaris-OptionList-Option:focus {
  outline: none;
}

.Polaris-OptionList-Option:visited {
  color: inherit;
}

.Polaris-OptionList-Option__SingleSelectOption {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  text-align: left;
  position: relative;
}

.Polaris-OptionList-Option__SingleSelectOption:focus {
  outline: none;
}

.Polaris-OptionList-Option__SingleSelectOption::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--focused:not(:active)::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--active {
  background: var(--p-surface-selected);
}

.Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--active::before,
.Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--select::before {
  content: "";
  background-color: var(--p-interactive);
  position: absolute;
  top: 0;
  left: -0.8rem;
  height: 100%;
  display: block;
  width: 0.3rem;
  border-top-right-radius: var(--p-border-radius-base);
  border-bottom-right-radius: var(--p-border-radius-base);
}

.Polaris-OptionList-Option__SingleSelectOption:not(.Polaris-OptionList-Option--disabled) {
  color: inherit;
}

.Polaris-OptionList-Option__SingleSelectOption .Polaris-OptionList-Option__Media {
  padding: 0 0.8rem 0 0;
}

.Polaris-OptionList-Option__Label,
.Polaris-OptionList-Option__SingleSelectOption {
  display: flex;
  align-items: flex-start;
  width: 100%;
  cursor: pointer;
  border-radius: var(--p-border-radius-base);
  padding: 0.8rem;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
}

.Polaris-OptionList-Option__Label:hover:not(.Polaris-OptionList-Option--disabled),
.Polaris-OptionList-Option__SingleSelectOption:hover:not(.Polaris-OptionList-Option--disabled) {
  background: var(--p-surface-hovered);
  outline: 0.1rem solid transparent;
}

.Polaris-OptionList-Option__Label.Polaris-OptionList-Option--select,
.Polaris-OptionList-Option__Label.Polaris-OptionList-Option--select:hover:not(.Polaris-OptionList-Option--disabled),
.Polaris-OptionList-Option__Label:active:not(.Polaris-OptionList-Option--disabled),
.Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--select,
.Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--select:hover:not(.Polaris-OptionList-Option--disabled),
.Polaris-OptionList-Option__SingleSelectOption:active:not(.Polaris-OptionList-Option--disabled) {
  background: var(--p-surface-selected);
  outline: 0.1rem solid transparent;
}

.Polaris-OptionList-Option__Label.Polaris-OptionList-Option--disabled,
.Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--disabled {
  background: var(--p-surface-disabled);
  cursor: default;
  color: var(--p-text-disabled);
}

.Polaris-OptionList-Option__Checkbox {
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  width: var(--p-choice-size);
  height: var(--p-choice-size);
  margin-right: 0.8rem;
  margin-left: calc(var(--p-choice-margin) * -1);
}

.Polaris-OptionList-Option--disabled .Polaris-OptionList-Option__Media svg {
  fill: var(--p-icon-disabled);
}

.Polaris-OptionList-Option__Media {
  padding: 0 0.8rem;
}

.Polaris-OptionList-Option__Media svg {
  fill: var(--p-icon);
}

.Polaris-OptionList-Option--verticalAlignTop {
  align-items: flex-start;
}

.Polaris-OptionList-Option--verticalAlignCenter {
  align-items: center;
}

.Polaris-OptionList-Option--verticalAlignBottom {
  align-items: flex-end;
}

.Polaris-OptionList {
  margin: 0;
  padding: 0;
  list-style: none;
  padding: 0.8rem;
}

.Polaris-OptionList__Options {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Polaris-OptionList__Title {
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.6rem;
  text-transform: uppercase;
  padding: 0.8rem;
  color: var(--p-text-subdued);
}

@media (min-width: 40em) {
  .Polaris-OptionList__Title {
    font-size: 1.2rem;
  }
}

.Polaris-Tooltip-TooltipOverlay {
  margin: 0.4rem 1.6rem 1.6rem;
  opacity: 1;
  box-shadow: var(--p-popover-shadow);
  border-radius: var(--p-border-radius-base);
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: opacity, left, top;
  transition: opacity 200ms cubic-bezier(0.36, 0, 1, 1) 100ms;
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-Tooltip-TooltipOverlay {
    border: 0.2rem solid windowText;
  }
}

.Polaris-Tooltip-TooltipOverlay--measuring {
  opacity: 0;
}

.Polaris-Tooltip-TooltipOverlay--positionedAbove {
  margin: 1.6rem 1.6rem 0.4rem;
}

.Polaris-Tooltip-TooltipOverlay__Content {
  position: relative;
  border-radius: 0.3rem;
  background-color: var(--p-surface);
  color: var(--p-text);
  max-width: 20rem;
  padding: 0.4rem 0.8rem;
  word-break: break-word;
}

[data-polaris-tooltip-activator] {
  outline: 0;
  position: relative;
}

[data-polaris-tooltip-activator]::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

[data-polaris-tooltip-activator]:focus-visible::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

[data-polaris-tooltip-activator][data-focus-visible-added]::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Header-Title {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 2.8rem;
}

@media (min-width: 40em) {
  .Polaris-Header-Title {
    font-size: 2rem;
  }
}

.Polaris-Header-Title__SubTitle {
  margin-top: 0.8rem;
  color: var(--p-text-subdued);
}

.Polaris-Header-Title__SubTitle.Polaris-Header-Title__SubtitleCompact {
  margin-top: 0;
}

.Polaris-Header-Title--hasThumbnail {
  display: grid;
  grid-gap: 1.6rem;
  grid-template-columns: auto 1fr;
}

.Polaris-Header-Title--hasThumbnail .Polaris-Header-Title__TitleAndSubtitleWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Polaris-Header-Title__TitleWithMetadataWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.Polaris-Header-Title__TitleWithMetadataWrapper .Polaris-Header-Title {
  display: inline;
  margin-right: 0.8rem;
}

.Polaris-Header-Title__TitleWithMetadataWrapper .Polaris-Header-Title > * {
  display: inline;
}

.Polaris-Header-Title__TitleWithMetadataWrapper .Polaris-Header-Title__TitleMetadata {
  margin-top: 0;
  vertical-align: bottom;
}

.Polaris-Page-Header {
  padding: 1.6rem 1.6rem 0;
  position: relative;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Page-Header {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 30.625em) {
  .Polaris-Page-Header {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-Page-Header {
    padding-top: 1.6rem;
  }
}

@media (min-width: 46.5em) {
  .Polaris-Page-Header {
    padding-top: 1.6rem;
  }
}

.Polaris-Page-Header--titleHidden {
  position: absolute !important;
  width: 0.1rem !important;
  height: 0.1rem !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}

.Polaris-Page-Header__TitleWrapper {
  grid-area: title;
  margin-top: 0.4rem;
  align-self: center;
  flex: 1 1 auto;
}

@media (min-width: 29.25em) {
  .Polaris-Page-Header__TitleWrapper {
    margin-top: 0;
  }
}

.Polaris-Page-Header__Navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.4rem;
}

.Polaris-Page-Header--hasActionMenu.Polaris-Page-Header--mobileView .Polaris-Page-Header__Navigation {
  padding-right: 4rem;
}

@media print {
  .Polaris-Page-Header__Navigation {
    display: none !important;
  }
}

.Polaris-Page-Header__BreadcrumbWrapper {
  flex: 0 0 auto;
  grid-area: breadcrumbs;
  max-width: 100%;
  margin-right: 1.6rem;
}

@media print {
  .Polaris-Page-Header__BreadcrumbWrapper {
    display: none !important;
  }
}

.Polaris-Page-Header__PaginationWrapper {
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
  margin-left: 0.4rem;
  line-height: 1;
}

.Polaris-Page-Header__PaginationWrapper button {
  border: 0.1rem solid var(--p-border-neutral-subdued) !important;
  box-shadow: none !important;
}

.Polaris-Page-Header__PaginationWrapper button:hover,
.Polaris-Page-Header__PaginationWrapper button:active,
.Polaris-Page-Header__PaginationWrapper button:focus {
  border: 0.1rem solid var(--p-border-neutral-subdued) !important;
}

@media print {
  .Polaris-Page-Header__PaginationWrapper {
    display: none !important;
  }
}

.Polaris-Page-Header__AdditionalNavigationWrapper {
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
}

@media print {
  .Polaris-Page-Header__AdditionalNavigationWrapper {
    display: none !important;
  }
}

.Polaris-Page-Header:not(.Polaris-Page-Header--mobileView) .Polaris-Page-Header__MainContent {
  display: flex;
  align-items: center;
}

.Polaris-Page-Header__TitleActionMenuWrapper {
  flex: 1 1 auto;
}

.Polaris-Page-Header:not(.Polaris-Page-Header--mobileView) .Polaris-Page-Header__TitleActionMenuWrapper {
  padding-right: 2rem;
}

.Polaris-Page-Header--hasActionMenu.Polaris-Page-Header--mobileView:not(.Polaris-Page-Header--hasNavigation)
  .Polaris-Page-Header__TitleActionMenuWrapper {
  padding-right: 4rem;
}

.Polaris-Page-Header__PrimaryActionWrapper {
  flex: 0 0 auto;
  margin-top: 0;
  margin-left: 0.4rem;
}

@media (min-width: 48em) {
  .Polaris-Page-Header__PrimaryActionWrapper {
    margin-left: 1.6rem;
  }
}

@media print {
  .Polaris-Page-Header__PrimaryActionWrapper {
    display: none !important;
  }
}

.Polaris-Page-Header__ActionMenuWrapper {
  margin-top: 0;
}

.Polaris-Page-Header--mobileView .Polaris-Page-Header__ActionMenuWrapper {
  position: absolute;
  top: 2.9rem;
  right: 2rem;
  margin-top: 0;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Page-Header--mobileView .Polaris-Page-Header__ActionMenuWrapper {
    right: -0.8rem;
  }
}

@media (min-width: 30.625em) {
  .Polaris-Page-Header--mobileView .Polaris-Page-Header__ActionMenuWrapper {
    right: -0.8rem;
  }
}

.Polaris-Page-Header--mobileView.Polaris-Page-Header--hasNavigation .Polaris-Page-Header__ActionMenuWrapper {
  top: 1.8rem;
}

@media print {
  .Polaris-Page-Header__ActionMenuWrapper {
    display: none !important;
  }
}

.Polaris-Page-Header__Row {
  display: flex;
  justify-content: space-between;
  min-height: 3.6rem;
}

.Polaris-Page-Header__Row + .Polaris-Page-Header__Row {
  margin-top: 0.4rem;
}

.Polaris-Page-Header--mobileView .Polaris-Page-Header__Row + .Polaris-Page-Header__Row {
  margin-top: 0.8rem;
}

.Polaris-Page-Header__Row + .Polaris-Page-Header__Row .Polaris-Page-Header__RightAlign {
  margin-left: 0;
}

.Polaris-Page-Header__LeftAlign {
  display: flex;
  align-content: flex-start;
  align-items: center;
}

.Polaris-Page-Header__RightAlign {
  grid-area: actions;
  display: flex;
  align-content: flex-end;
  flex: 1 1 auto;
  align-items: flex-start;
  justify-content: flex-end;
  margin-left: 1.6rem;
  white-space: nowrap;
}

@media (max-width: 29.25em) {
  .Polaris-Page-Header--noBreadcrumbs .Polaris-Page-Header__RightAlign {
    margin-left: 0;
  }
}

@media (min-width: 29.25em) {
  .Polaris-Page-Header__AdditionalMetaData {
    margin-left: 5.2rem;
  }
}

.Polaris-Page-Header--noBreadcrumbs .Polaris-Page-Header__AdditionalMetaData {
  margin-left: 0;
}

@media print {
  .Polaris-Page-Header__AdditionalMetaData {
    margin-left: 0 !important;
  }
}

.Polaris-Page-Header__Actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
}

@media (max-width: 67.5em) {
  .Polaris-Page-Header--longTitle .Polaris-Page-Header__AdditionalMetaData {
    margin-left: 0;
  }
  .Polaris-Page-Header--longTitle .Polaris-Page-Header__Row {
    display: grid;
    gap: 0.8rem 1.6rem;
    grid-template-columns: auto 1fr;
    grid-template-areas: "breadcrumbs actions" "title title";
  }
  .Polaris-Page-Header--longTitle .Polaris-Page-Header__Row + .Polaris-Page-Header__Row {
    gap: 0;
  }
}

@media (max-width: 53.75em) {
  .Polaris-Page-Header--mediumTitle:not(.Polaris-Page-Header--noBreadcrumbs) .Polaris-Page-Header__AdditionalMetaData {
    margin-left: 0;
  }
  .Polaris-Page-Header--mediumTitle:not(.Polaris-Page-Header--noBreadcrumbs) .Polaris-Page-Header__Row {
    display: grid;
    gap: 0.8rem 1.6rem;
    grid-template-columns: auto 1fr;
    grid-template-areas: "breadcrumbs actions" "title title";
  }
  .Polaris-Page-Header--mediumTitle:not(.Polaris-Page-Header--noBreadcrumbs)
    .Polaris-Page-Header__Row
    + .Polaris-Page-Header__Row {
    gap: 0;
  }
}

.Polaris-Page-Header--mediumTitle.Polaris-Page-Header--noBreadcrumbs .Polaris-Page-Header__TitleWrapper {
  margin-top: 0;
}

.Polaris-Page-Header--mediumTitle.Polaris-Page-Header--noBreadcrumbs .Polaris-Page-Header__RightAlign {
  margin-bottom: 0.4rem;
}

.Polaris-Page-Header--mediumTitle.Polaris-Page-Header--noBreadcrumbs .Polaris-Page-Header__Row {
  flex-wrap: wrap-reverse;
}

@media (min-width: 48.0625em) {
  .Polaris-Page-Header--mediumTitle.Polaris-Page-Header--noBreadcrumbs .Polaris-Page-Header__Row {
    flex-wrap: nowrap;
  }
}

.Polaris-Page-Header--isSingleRow .Polaris-Page-Header__Row {
  gap: 0;
}

html,
body {
  min-height: 100%;
  height: 100%;
}

.Polaris-Page {
  margin: 0 auto;
  padding: 0;
  max-width: 99.8rem;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Page {
    padding: 0 2rem;
  }
}

@media (min-width: 30.625em) {
  .Polaris-Page {
    padding: 0 2rem;
  }
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-Page {
    padding: 0 3.2rem;
  }
}

@media (min-width: 46.5em) {
  .Polaris-Page {
    padding: 0 3.2rem;
  }
}

.Polaris-Page::after {
  content: "";
  display: table;
}

.Polaris-Page--fullWidth {
  max-width: none;
}

.Polaris-Page--narrowWidth {
  max-width: 66.2rem;
}

.Polaris-Page__Content {
  margin: 0.8rem 0;
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-Page__Content {
    margin-top: 0.8rem;
  }
}

@media (min-width: 46.5em) {
  .Polaris-Page__Content {
    margin-top: 0.8rem;
  }
}

.Polaris-Page--divider {
  border-top: 0.1rem solid var(--p-border-subdued);
  padding-top: 1.6rem;
}

.Polaris-PageActions {
  margin: 0 auto;
  padding: 2rem;
  border-top: 0.1rem solid var(--p-divider);
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-PageActions {
    padding: 2rem 0;
  }
}

@media (min-width: 30.625em) {
  .Polaris-PageActions {
    padding: 2rem 0;
  }
}

@keyframes Polaris-ProgressBar--fillup {
  0% {
    width: 0;
  }
}

.Polaris-ProgressBar {
  overflow: hidden;
  width: 100%;
  background-color: var(--p-progressbar-background);
  border-radius: var(--p-border-radius-base);
}

@media (forced-colors: active) {
  .Polaris-ProgressBar {
    border: 0.1rem solid transparent;
  }
}

.Polaris-ProgressBar--sizeSmall {
  height: 0.8rem;
}

.Polaris-ProgressBar--sizeMedium {
  height: 1.6rem;
}

.Polaris-ProgressBar--sizeLarge {
  height: 3.2rem;
}

.Polaris-ProgressBar--colorHighlight {
  --p-progressbar-background: var(--p-surface-neutral);
  --p-progressbar-indicator: var(--p-border-highlight);
}

.Polaris-ProgressBar--colorPrimary {
  --p-progressbar-background: var(--p-surface-neutral);
  --p-progressbar-indicator: var(--p-action-primary);
}

.Polaris-ProgressBar--colorSuccess {
  --p-progressbar-background: var(--p-surface-neutral);
  --p-progressbar-indicator: var(--p-border-success);
}

.Polaris-ProgressBar--colorCritical {
  --p-progressbar-background: var(--p-surface-neutral);
  --p-progressbar-indicator: var(--p-interactive-critical);
}

.Polaris-ProgressBar__Indicator {
  height: inherit;
  width: 0;
  background-color: var(--p-progressbar-indicator);
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-ProgressBar__Indicator {
    border: 1.6rem solid highlight;
  }
}

.Polaris-ProgressBar__Animated {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: width;
  animation: Polaris-ProgressBar--fillup 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: width 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
}

.Polaris-ProgressBar__Progress,
.Polaris-ProgressBar__Label {
  position: absolute !important;
  width: 0.1rem !important;
  height: 0.1rem !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}

.Polaris-RangeSlider-DualThumb__Wrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.Polaris-RangeSlider-DualThumb__TrackWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 2.8rem;
  cursor: pointer;
}

.Polaris-RangeSlider-DualThumb__TrackWrapper.Polaris-RangeSlider-DualThumb--disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.Polaris-RangeSlider-DualThumb__Track {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 0.4rem;
  border-radius: var(--p-range-slider-thumb-size-base);
  --unselected-range: transparent;
  --selected-range: var(--p-interactive);
  --gradient-colors: var(--unselected-range) 0%, var(--unselected-range) var(--Polaris-RangeSlider-progress-lower),
    var(--selected-range) var(--Polaris-RangeSlider-progress-lower),
    var(--selected-range) var(--Polaris-RangeSlider-progress-upper),
    var(--unselected-range) var(--Polaris-RangeSlider-progress-upper), var(--unselected-range) 100%;
  background-image: linear-gradient(to right, var(--gradient-colors));
}

.Polaris-RangeSlider-DualThumb--error .Polaris-RangeSlider-DualThumb__Track {
  --selected-range: var(--p-action-critical);
  --gradient-colors: var(--unselected-range) 0%, var(--unselected-range) var(--Polaris-RangeSlider-progress-lower),
    var(--selected-range) var(--Polaris-RangeSlider-progress-lower),
    var(--selected-range) var(--Polaris-RangeSlider-progress-upper),
    var(--unselected-range) var(--Polaris-RangeSlider-progress-upper), var(--unselected-range) 100%;
  background-image: linear-gradient(to right, var(--gradient-colors));
}

.Polaris-RangeSlider-DualThumb--disabled .Polaris-RangeSlider-DualThumb__Track {
  background: var(--p-border-disabled) none;
}

.Polaris-RangeSlider-DualThumb--trackDashed {
  content: "";
  position: absolute;
  height: 0.4rem;
  width: 100%;
  background-image: linear-gradient(to right, var(--p-border), var(--p-border) 50%, transparent 50%, transparent 100%);
  background-size: 0.4rem 0.4rem;
  border-radius: var(--p-border-radius-base);
  border-right: var(--p-border-radius-base) var(--p-border) solid;
}

.Polaris-RangeSlider-DualThumb__Thumbs {
  position: relative;
  position: absolute;
  z-index: 10;
  padding: 0;
  width: var(--p-range-slider-thumb-size-base);
  height: var(--p-range-slider-thumb-size-base);
  border-radius: 50%;
  border: 0.1rem solid var(--p-interactive);
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
  -webkit-tap-highlight-color: transparent;
  cursor: -webkit-grab;
  transition: transform var(--p-duration-1-5-0) var(--p-ease);
}

.Polaris-RangeSlider-DualThumb__Thumbs::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.2rem;
  right: -0.2rem;
  bottom: -0.2rem;
  left: -0.2rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.2rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-wide) + 0.1rem);
}

.Polaris-RangeSlider-DualThumb__Thumbs.Polaris-RangeSlider-DualThumb--disabled {
  cursor: not-allowed;
  border-color: var(--p-border-disabled);
  background: var(--p-border-disabled);
}

.Polaris-RangeSlider-DualThumb__Thumbs:active {
  transform: scale(1.5);
}

.Polaris-RangeSlider-DualThumb__Thumbs:focus {
  outline: 0;
}

.Polaris-RangeSlider-DualThumb__Thumbs:focus::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-RangeSlider-DualThumb--error .Polaris-RangeSlider-DualThumb__Thumbs {
  border-color: var(--p-action-critical);
  background: linear-gradient(var(--p-action-critical), var(--p-action-critical));
}

.Polaris-RangeSlider-DualThumb__Prefix {
  flex: 0 0 auto;
  margin-right: 0.8rem;
}

.Polaris-RangeSlider-DualThumb__Suffix {
  flex: 0 0 auto;
  margin-left: 0.8rem;
}

.Polaris-RangeSlider-DualThumb__Output {
  position: absolute;
  z-index: 20;
  bottom: var(--p-range-slider-thumb-size-active);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition-property: opacity, visibility, bottom;
  transition-duration: var(--p-duration-1-5-0);
  transition-timing-function: var(--p-ease);
  transform: translateX(calc(-50% + var(--p-range-slider-thumb-size-base) / 2));
}

.Polaris-RangeSlider-DualThumb__Thumbs:active + .Polaris-RangeSlider-DualThumb__Output {
  opacity: 1;
  visibility: visible;
  bottom: calc(
    var(--p-range-slider-thumb-size-active) + var(--p-range-slider-thumb-size-active) -
      var(--p-range-slider-thumb-size-base)
  );
}

.Polaris-RangeSlider-DualThumb__OutputBubble {
  position: relative;
  display: flex;
  padding: 0 0.8rem;
  min-width: 3.2rem;
  height: 3.2rem;
  background-color: var(--p-surface);
  box-shadow: var(--p-popover-shadow);
  border-radius: 0.3rem;
  transition-property: transform;
  transition-duration: var(--p-duration-1-5-0);
  transition-timing-function: var(--p-ease);
}

.Polaris-RangeSlider-DualThumb__Thumbs:hover
  + .Polaris-RangeSlider-DualThumb__Output
  .Polaris-RangeSlider-DualThumb__OutputBubble,
.Polaris-RangeSlider-DualThumb__Thumbs:active
  + .Polaris-RangeSlider-DualThumb__Output
  .Polaris-RangeSlider-DualThumb__OutputBubble,
.Polaris-RangeSlider-DualThumb__Thumbs:focus
  + .Polaris-RangeSlider-DualThumb__Output
  .Polaris-RangeSlider-DualThumb__OutputBubble {
  transform: translateY(-1.6rem);
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation]
    .Polaris-RangeSlider-DualThumb__Thumbs:hover
    + .Polaris-RangeSlider-DualThumb__Output
    .Polaris-RangeSlider-DualThumb__OutputBubble,
  [data-has-navigation]
    .Polaris-RangeSlider-DualThumb__Thumbs:active
    + .Polaris-RangeSlider-DualThumb__Output
    .Polaris-RangeSlider-DualThumb__OutputBubble,
  [data-has-navigation]
    .Polaris-RangeSlider-DualThumb__Thumbs:focus
    + .Polaris-RangeSlider-DualThumb__Output
    .Polaris-RangeSlider-DualThumb__OutputBubble {
    transform: translateY(-0.8rem);
  }
}

@media (min-width: 46.5em) {
  .Polaris-RangeSlider-DualThumb__Thumbs:hover
    + .Polaris-RangeSlider-DualThumb__Output
    .Polaris-RangeSlider-DualThumb__OutputBubble,
  .Polaris-RangeSlider-DualThumb__Thumbs:active
    + .Polaris-RangeSlider-DualThumb__Output
    .Polaris-RangeSlider-DualThumb__OutputBubble,
  .Polaris-RangeSlider-DualThumb__Thumbs:focus
    + .Polaris-RangeSlider-DualThumb__Output
    .Polaris-RangeSlider-DualThumb__OutputBubble {
    transform: translateY(-0.8rem);
  }
}

.Polaris-RangeSlider-DualThumb__OutputText {
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.6rem;
  text-transform: uppercase;
  display: block;
  flex: 1 1 auto;
  margin: auto;
  text-align: center;
  color: var(--p-text);
}

@media (min-width: 40em) {
  .Polaris-RangeSlider-DualThumb__OutputText {
    font-size: 1.2rem;
  }
}

.Polaris-RangeSlider-SingleThumb {
  display: flex;
  align-items: center;
}

.Polaris-RangeSlider-SingleThumb.Polaris-RangeSlider-SingleThumb--disabled {
  opacity: 0.8;
}

.Polaris-RangeSlider-SingleThumb__InputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  height: var(--p-range-slider-thumb-size-base);
}

.Polaris-RangeSlider-SingleThumb__InputWrapper input {
  padding: 1.2rem 0;
  background-color: transparent;
  cursor: pointer;
}

.Polaris-RangeSlider-SingleThumb__InputWrapper::after {
  content: "";
  position: absolute;
  height: 0.4rem;
  width: 100%;
  background-image: linear-gradient(to right, var(--p-border), var(--p-border) 50%, transparent 50%, transparent 100%);
  background-size: 0.4rem 0.4rem;
  border-radius: var(--p-border-radius-base);
  border-right: var(--p-border-radius-base) var(--p-border) solid;
}

.Polaris-RangeSlider-SingleThumb--disabled input {
  cursor: not-allowed;
}

.Polaris-RangeSlider-SingleThumb__Prefix {
  flex: 0 0 auto;
  margin-right: 0.8rem;
}

.Polaris-RangeSlider-SingleThumb__Suffix {
  flex: 0 0 auto;
  margin-left: 0.8rem;
}

.Polaris-RangeSlider-SingleThumb__Input {
  --progress-lower: var(--p-interactive);
  --progress-upper: transparent;
  --gradient-colors: var(--progress-lower, transparent) 0%,
    var(--progress-lower, transparent) var(--Polaris-RangeSlider-progress, 0%),
    var(--progress-upper, transparent) var(--Polaris-RangeSlider-progress, 100%),
    var(--progress-upper, transparent) 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  z-index: 10;
  flex: 1 1 auto;
}

.Polaris-RangeSlider-SingleThumb__Input::-ms-tooltip {
  display: none;
}

.Polaris-RangeSlider-SingleThumb__Input:focus {
  outline: 0;
}

.Polaris-RangeSlider-SingleThumb__Input::-moz-focus-outer {
  border: 0;
}

.Polaris-RangeSlider-SingleThumb__Input::-ms-track {
  outline: 0.1rem solid transparent;
  cursor: pointer;
  width: 100%;
  height: 0.4rem;
  background-image: linear-gradient(to right, var(--gradient-colors));
  border: none;
  border-radius: 0.4rem;
}

.Polaris-RangeSlider-SingleThumb__Input::-moz-range-track {
  cursor: pointer;
  width: 100%;
  height: 0.4rem;
  background-image: linear-gradient(to right, var(--gradient-colors));
  border: none;
  border-radius: 0.4rem;
}

.Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-runnable-track {
  cursor: pointer;
  width: 100%;
  height: 0.4rem;
  background-image: linear-gradient(to right, var(--gradient-colors));
  border: none;
  border-radius: 0.4rem;
}

.Polaris-RangeSlider-SingleThumb__Input::-ms-thumb {
  cursor: -webkit-grab;
  width: var(--p-range-slider-thumb-size-base);
  height: var(--p-range-slider-thumb-size-base);
  border: 0.1rem solid transparent;
  border-radius: 50%;
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
  box-shadow: 0 0 0 0 var(--p-focused);
  appearance: none;
  -ms-transition: box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease);
  -ms-transition-property: border-color, box-shadow, transform;
  transition-property: border-color, box-shadow, transform;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  margin-top: calc(var(--p-range-slider-thumb-size-base) * -1 / 2 - -0.2rem);
}

.Polaris-RangeSlider-SingleThumb__Input::-ms-thumb:hover {
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
}

.Polaris-RangeSlider-SingleThumb__Input::-moz-range-thumb {
  cursor: -webkit-grab;
  width: var(--p-range-slider-thumb-size-base);
  height: var(--p-range-slider-thumb-size-base);
  border: 0.1rem solid transparent;
  border-radius: 50%;
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
  box-shadow: 0 0 0 0 var(--p-focused);
  appearance: none;
  -moz-transition: box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease);
  -moz-transition-property: border-color, box-shadow, transform;
  transition-property: border-color, box-shadow, transform;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  margin-top: calc(var(--p-range-slider-thumb-size-base) * -1 / 2 - -0.2rem);
}

.Polaris-RangeSlider-SingleThumb__Input::-moz-range-thumb:hover {
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
}

.Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb {
  cursor: -webkit-grab;
  width: var(--p-range-slider-thumb-size-base);
  height: var(--p-range-slider-thumb-size-base);
  border: 0.1rem solid transparent;
  border-radius: 50%;
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
  box-shadow: 0 0 0 0 var(--p-focused);
  -webkit-appearance: none;
  appearance: none;
  -webkit-transition: box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease);
  -webkit-transition-property: border-color, box-shadow, transform;
  transition-property: border-color, box-shadow, transform;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  margin-top: calc(var(--p-range-slider-thumb-size-base) * -1 / 2 - -0.2rem);
}

.Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb:hover {
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
}

.Polaris-RangeSlider-SingleThumb__Input::-ms-thumb {
  margin-top: 0;
  transform: translateY(var(--p-range-slider-thumb-size-base) / 5) scale(0.4);
}

.Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb {
  margin-top: -var(--p-range-slider-thumb-size-base)-0.4rem/2;
}

.Polaris-RangeSlider-SingleThumb__Input:active::-ms-thumb {
  transform: scale(1.5);
}

.Polaris-RangeSlider-SingleThumb__Input:active::-moz-range-thumb {
  transform: scale(1.5);
}

.Polaris-RangeSlider-SingleThumb__Input:active::-webkit-slider-thumb {
  transform: scale(1.5);
}

.Polaris-RangeSlider-SingleThumb__Input:focus {
  outline: 0.1rem solid transparent;
}

.Polaris-RangeSlider-SingleThumb__Input:focus::-ms-thumb {
  border-color: var(--p-surface);
  box-shadow: 0 0 0 0.2rem var(--p-focused);
}

.Polaris-RangeSlider-SingleThumb__Input:focus::-moz-range-thumb {
  border-color: var(--p-surface);
  box-shadow: 0 0 0 0.2rem var(--p-focused);
}

.Polaris-RangeSlider-SingleThumb__Input:focus::-webkit-slider-thumb {
  border-color: var(--p-surface);
  box-shadow: 0 0 0 0.2rem var(--p-focused);
}

.Polaris-RangeSlider-SingleThumb--error .Polaris-RangeSlider-SingleThumb__Input {
  --progress-lower: var(--p-action-critical);
}

.Polaris-RangeSlider-SingleThumb--error .Polaris-RangeSlider-SingleThumb__Input::-ms-thumb {
  border-color: var(--p-action-critical);
  background: var(--p-action-critical);
}

.Polaris-RangeSlider-SingleThumb--error .Polaris-RangeSlider-SingleThumb__Input::-moz-range-thumb {
  border-color: var(--p-action-critical);
  background: var(--p-action-critical);
}

.Polaris-RangeSlider-SingleThumb--error .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb {
  border-color: var(--p-action-critical);
  background: var(--p-action-critical);
}

.Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-ms-track {
  outline: 0.1rem solid transparent;
  cursor: auto;
  background-image: none;
  background-color: var(--p-border-disabled);
}

.Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-moz-range-track {
  cursor: auto;
  background-image: none;
  background-color: var(--p-border-disabled);
}

.Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-runnable-track {
  cursor: auto;
  background-image: none;
  background-color: var(--p-border-disabled);
}

.Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-ms-thumb {
  cursor: not-allowed;
  border-color: var(--p-border-disabled);
  background: var(--p-border-disabled);
}

.Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-moz-range-thumb {
  cursor: not-allowed;
  border-color: var(--p-border-disabled);
  background: var(--p-border-disabled);
}

.Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb {
  cursor: not-allowed;
  border-color: var(--p-border-disabled);
  background: var(--p-border-disabled);
}

.Polaris-RangeSlider-SingleThumb__Output {
  position: absolute;
  z-index: 20;
  bottom: var(--p-range-slider-thumb-size-base);
  left: var(--Polaris-RangeSlider-progress);
  transform: translateX(calc(-50% + var(--Polaris-RangeSlider-output-factor) * var(--p-range-slider-thumb-size-base)));
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition-property: opacity, visibility, bottom;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

.Polaris-RangeSlider-SingleThumb__Input:active + .Polaris-RangeSlider-SingleThumb__Output {
  opacity: 1;
  visibility: visible;
  bottom: calc(
    var(--p-range-slider-thumb-size-base) + var(--p-range-slider-thumb-size-active) -
      var(--p-range-slider-thumb-size-base)
  );
}

.Polaris-RangeSlider-SingleThumb__OutputBubble {
  position: relative;
  display: flex;
  box-shadow: var(--p-popover-shadow);
  padding: 0 0.8rem;
  min-width: 3.2rem;
  height: 3.2rem;
  background-color: var(--p-surface);
  border-radius: 0.3rem;
  transition-property: transform;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  outline: 0.1rem solid transparent;
}

.Polaris-RangeSlider-SingleThumb__Input:hover
  + .Polaris-RangeSlider-SingleThumb__Output
  .Polaris-RangeSlider-SingleThumb__OutputBubble,
.Polaris-RangeSlider-SingleThumb__Input:active
  + .Polaris-RangeSlider-SingleThumb__Output
  .Polaris-RangeSlider-SingleThumb__OutputBubble,
.Polaris-RangeSlider-SingleThumb__Input:focus
  + .Polaris-RangeSlider-SingleThumb__Output
  .Polaris-RangeSlider-SingleThumb__OutputBubble {
  transform: translateY(-1.6rem);
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation]
    .Polaris-RangeSlider-SingleThumb__Input:hover
    + .Polaris-RangeSlider-SingleThumb__Output
    .Polaris-RangeSlider-SingleThumb__OutputBubble,
  [data-has-navigation]
    .Polaris-RangeSlider-SingleThumb__Input:active
    + .Polaris-RangeSlider-SingleThumb__Output
    .Polaris-RangeSlider-SingleThumb__OutputBubble,
  [data-has-navigation]
    .Polaris-RangeSlider-SingleThumb__Input:focus
    + .Polaris-RangeSlider-SingleThumb__Output
    .Polaris-RangeSlider-SingleThumb__OutputBubble {
    transform: translateY(-0.8rem);
  }
}

@media (min-width: 46.5em) {
  .Polaris-RangeSlider-SingleThumb__Input:hover
    + .Polaris-RangeSlider-SingleThumb__Output
    .Polaris-RangeSlider-SingleThumb__OutputBubble,
  .Polaris-RangeSlider-SingleThumb__Input:active
    + .Polaris-RangeSlider-SingleThumb__Output
    .Polaris-RangeSlider-SingleThumb__OutputBubble,
  .Polaris-RangeSlider-SingleThumb__Input:focus
    + .Polaris-RangeSlider-SingleThumb__Output
    .Polaris-RangeSlider-SingleThumb__OutputBubble {
    transform: translateY(-0.8rem);
  }
}

.Polaris-RangeSlider-SingleThumb__OutputText {
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.6rem;
  text-transform: uppercase;
  display: block;
  flex: 1 1 auto;
  margin: auto;
  text-align: center;
  color: var(--p-text);
}

@media (min-width: 40em) {
  .Polaris-RangeSlider-SingleThumb__OutputText {
    font-size: 1.2rem;
  }
}

.Polaris-ResourceItem__CheckboxWrapper {
  display: flex;
}

.Polaris-ResourceItem {
  position: relative;
  outline: none;
  cursor: pointer;
}

.Polaris-ResourceItem:not(.Polaris-ResourceItem--persistActions) .Polaris-ResourceItem__Actions {
  right: 1.6rem;
}

.Polaris-ResourceItem:hover {
  background-color: var(--p-surface-hovered);
}

.Polaris-ResourceItem:hover:not(.Polaris-ResourceItem--persistActions) .Polaris-ResourceItem__Actions {
  clip: auto;
  overflow: visible;
  height: 100%;
}

@media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em) {
  [data-has-navigation]
    .Polaris-ResourceItem:hover:not(.Polaris-ResourceItem--persistActions)
    .Polaris-ResourceItem__Actions {
    display: none;
  }
}

@media (max-width: 46.5em) {
  .Polaris-ResourceItem:hover:not(.Polaris-ResourceItem--persistActions) .Polaris-ResourceItem__Actions {
    display: none;
  }
}

.Polaris-ResourceItem:active {
  background-color: var(--p-surface-pressed);
}

.Polaris-ResourceItem__ItemWrapper {
  overflow: hidden;
  max-width: 100%;
}

.Polaris-ResourceItem--focusedInner,
.Polaris-ResourceItem--focusedInner.Polaris-ResourceItem--focused,
.Polaris-ResourceItem--focusedInner.Polaris-ResourceItem--focused.Polaris-ResourceItem--selected {
  box-shadow: none;
}

.Polaris-ResourceItem__Link,
.Polaris-ResourceItem__Button {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.Polaris-ResourceItem__Button {
  padding: 0;
  border: none;
}

.Polaris-ResourceItem__Container {
  position: relative;
  z-index: 2;
  padding: 1.2rem 1.6rem;
  min-height: 4.4rem;
  display: flex;
  align-items: flex-start;
}

@media (min-width: 28.625em) {
  .Polaris-ResourceItem__Container {
    padding: 1.2rem 2rem;
  }
}

.Polaris-ResourceItem--alignmentLeading {
  align-items: flex-start;
}

.Polaris-ResourceItem--alignmentTrailing {
  align-items: flex-end;
}

.Polaris-ResourceItem--alignmentCenter {
  align-items: center;
}

.Polaris-ResourceItem--alignmentFill {
  align-items: stretch;
}

.Polaris-ResourceItem--alignmentBaseline {
  align-items: baseline;
}

.Polaris-ResourceItem__Owned {
  display: flex;
}

.Polaris-ResourceItem__OwnedNoMedia {
  padding-top: 0.4rem;
}

.Polaris-ResourceItem__Handle {
  width: 4.8rem;
  min-height: 4.4rem;
  justify-content: center;
  align-items: center;
  margin: -1.2rem 0.4rem -1.2rem -1.2rem;
  display: flex;
}

@media (max-width: 28.615em) {
  .Polaris-ResourceItem__Handle {
    visibility: hidden;
  }
  .Polaris-ResourceItem--selectMode .Polaris-ResourceItem__Handle {
    visibility: visible;
  }
}

.Polaris-ResourceItem--selectable {
  width: calc(100% + 4rem);
  transform: translateX(-4rem);
  transition: transform cubic-bezier(0.25, 0.1, 0.25, 1) 200ms;
  margin-right: -4rem;
}

.Polaris-ResourceItem--selectable.Polaris-ResourceItem--selectMode {
  transform: translateX(0);
}

@media (min-width: 28.625em) {
  .Polaris-ResourceItem--selectable {
    width: 100%;
    transform: translateX(0);
    margin-right: 0;
  }
}

.Polaris-ResourceItem__Media {
  flex: 0 0 auto;
  margin-right: 2rem;
  color: inherit;
  text-decoration: none;
}

.Polaris-ResourceItem__Content {
  min-width: 0;
  max-width: 100%;
  flex: 1 1 auto;
}

.Polaris-ResourceItem__Actions {
  position: absolute;
  top: 0;
  display: flex;
  pointer-events: initial;
  height: 100%;
  max-height: 5.6rem;
  clip: rect(0.1rem, 0.1rem, 0.1rem, 0.1rem);
  overflow: hidden;
  height: 0.1rem;
}

.Polaris-ResourceItem--focused .Polaris-ResourceItem__Actions {
  clip: auto;
  overflow: visible;
  height: 100%;
}

@media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em) {
  [data-has-navigation] .Polaris-ResourceItem__Actions {
    display: none;
  }
}

@media (max-width: 46.5em) {
  .Polaris-ResourceItem__Actions {
    display: none;
  }
}

.Polaris-ResourceItem--persistActions .Polaris-ResourceItem__Actions {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  flex-basis: auto;
  align-items: center;
  margin-top: 0;
  margin-left: 1.6rem;
  pointer-events: initial;
  height: 100%;
}

@media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em) {
  [data-has-navigation] .Polaris-ResourceItem--persistActions .Polaris-ResourceItem__Actions {
    display: none;
  }
}

@media (max-width: 46.5em) {
  .Polaris-ResourceItem--persistActions .Polaris-ResourceItem__Actions {
    display: none;
  }
}

.Polaris-ResourceItem__Disclosure {
  position: relative;
  top: -1.2rem;
  right: -1.6rem;
  display: none;
  width: 4.8rem;
  min-height: 4.4rem;
  pointer-events: initial;
}

.Polaris-ResourceItem--selectMode .Polaris-ResourceItem__Disclosure {
  display: none;
}

@media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em) {
  [data-has-navigation] .Polaris-ResourceItem__Disclosure {
    display: flex;
    flex: 0 0 4.8rem;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 46.5em) {
  .Polaris-ResourceItem__Disclosure {
    display: flex;
    flex: 0 0 4.8rem;
    justify-content: center;
    align-items: center;
  }
}

.Polaris-ResourceItem--selected {
  background-color: var(--p-surface-selected);
}

.Polaris-ResourceItem--selected:hover {
  background-color: var(--p-surface-selected-hovered);
}

.Polaris-ResourceItem--selected:active {
  background-color: var(--p-surface-selected-pressed);
}

.Polaris-ResourceItem__ListItem {
  position: relative;
  position: relative;
}

.Polaris-ResourceItem__ListItem::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0rem;
  right: 0rem;
  bottom: 0rem;
  left: 0rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 0rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-ResourceItem__ListItem + .Polaris-ResourceItem__ListItem {
  border-top: 0.1rem solid var(--p-divider);
}

.Polaris-ResourceItem__ListItem::after {
  border-radius: 0.2rem;
}

.Polaris-ResourceItem__ListItem:last-of-type {
  border-bottom-left-radius: var(--p-border-radius-wide);
  border-bottom-right-radius: var(--p-border-radius-wide);
}

.Polaris-ResourceItem__ListItem:last-of-type .Polaris-ResourceItem__ItemWrapper {
  border-bottom-left-radius: var(--p-border-radius-wide);
  border-bottom-right-radius: var(--p-border-radius-wide);
}

.Polaris-ResourceItem__ListItem:last-of-type.Polaris-ResourceItem--focused::after {
  border-bottom-left-radius: var(--p-border-radius-wide);
  border-bottom-right-radius: var(--p-border-radius-wide);
}

.Polaris-ResourceItem__ListItem.Polaris-ResourceItem--focused {
  z-index: 1;
}

.Polaris-ResourceItem__ListItem.Polaris-ResourceItem--focused::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

* + ul > .Polaris-ResourceItem__ListItem:first-of-type.Polaris-ResourceItem--focused::after {
  top: 0.1rem;
}

.Polaris-Select {
  position: relative;
}

.Polaris-Select select::-ms-expand {
  display: none;
}

.Polaris-Select--disabled .Polaris-Select__Content {
  color: var(--p-text-disabled);
}

.Polaris-Select--disabled .Polaris-Select__InlineLabel {
  color: inherit;
}

.Polaris-Select--disabled .Polaris-Select__Icon svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Select--disabled .Polaris-Select__Backdrop {
  border-color: var(--p-border-disabled);
}

.Polaris-Select--disabled .Polaris-Select__Backdrop::before {
  background-color: var(--p-action-secondary-disabled);
}

.Polaris-Select--disabled .Polaris-Select__Backdrop:hover {
  cursor: default;
}

.Polaris-Select__Content {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  border: none;
  text-transform: initial;
  letter-spacing: initial;
  position: relative;
  z-index: 20;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 3.6rem;
  padding: 0.5rem 0.8rem 0.5rem 1.2rem;
}

@media (min-width: 40em) {
  .Polaris-Select__Content {
    font-size: 1.4rem;
  }
}

.Polaris-Select__InlineLabel {
  color: var(--p-text-subdued);
  margin-right: 0.4rem;
  white-space: nowrap;
  overflow: hidden;
}

.Polaris-Select__SelectedOption {
  flex: 1 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Polaris-Select__Prefix {
  padding-right: 0.8rem;
}

.Polaris-Select__Icon svg {
  fill: var(--p-icon);
}

.Polaris-Select__Input {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  border: none;
  text-transform: initial;
  letter-spacing: initial;
  position: absolute;
  text-rendering: auto;
  top: 0;
  left: 0;
  z-index: 30;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0.001;
  -webkit-appearance: none;
  appearance: none;
}

@media (min-width: 40em) {
  .Polaris-Select__Input {
    font-size: 1.4rem;
  }
}

.Polaris-Select__Backdrop {
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 0.1rem solid var(--p-border-subdued);
  border-bottom-color: var(--p-border-shadow-subdued);
  border-radius: var(--p-border-radius-base);
  background-color: var(--p-surface);
  box-shadow: var(--p-button-drop-shadow);
  position: relative;
  position: absolute;
}

.Polaris-Select__Backdrop::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.2rem;
  right: -0.2rem;
  bottom: -0.2rem;
  left: -0.2rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.2rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Select--error .Polaris-Select__Backdrop {
  border-color: var(--p-border-critical);
  background-color: var(--p-surface-critical-subdued);
}

.Polaris-Select--error .Polaris-Select__Backdrop.Polaris-Select--hover,
.Polaris-Select--error .Polaris-Select__Backdrop:hover {
  border-color: var(--p-border-critical);
}

.Polaris-Select--error .Polaris-Select__Input:focus ~ .Polaris-Select__Backdrop::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Select__Input:focus ~ .Polaris-Select__Backdrop::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

@media (-ms-high-contrast: active) {
  .Polaris-Select__Content {
    color: windowText;
    -ms-high-contrast-adjust: none;
  }
  .Polaris-Select__InlineLabel {
    color: inherit;
  }
  .Polaris-Select__InlineLabel::after {
    content: ":";
  }
  .Polaris-Select__SelectedOption {
    color: inherit;
  }
  .Polaris-Select__Icon svg {
    fill: buttonText;
  }
  .Polaris-Select__Backdrop::after {
    display: none;
  }
  .Polaris-Select__Input:focus ~ .Polaris-Select__Content {
    color: highlightText;
  }
  .Polaris-Select__Input:focus ~ .Polaris-Select__Backdrop {
    background-color: highlight;
  }
  .Polaris-Select--disabled .Polaris-Select__Content {
    color: grayText;
  }
  .Polaris-Select--disabled .Polaris-Select__Icon {
    opacity: 1;
  }
  .Polaris-Select--disabled .Polaris-Select__Icon svg {
    fill: grayText;
  }
}

.Polaris-ResourceList__FiltersWrapper {
  padding: 1.2rem 1.2rem 1.6rem;
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList__FiltersWrapper {
    padding: 1.6rem;
  }
}

.Polaris-ResourceList__FiltersWrapper + .Polaris-ResourceList {
  border-top: 0.1rem solid var(--p-divider);
}

.Polaris-ResourceList__HeaderOuterWrapper {
  position: relative;
  background-color: var(--p-surface);
  z-index: 2;
  overflow: hidden;
  border-top-left-radius: var(--p-border-radius-wide);
  border-top-right-radius: var(--p-border-radius-wide);
}

.Polaris-ResourceList__HeaderOuterWrapper + .Polaris-ResourceList {
  border-top: 0.1rem solid var(--p-divider);
}

.Polaris-ResourceList__HeaderWrapper--disabled {
  pointer-events: none;
}

.Polaris-ResourceList__HeaderWrapper--overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  background-color: var(--p-overlay);
}

.Polaris-ResourceList__FiltersWrapper + .Polaris-ResourceList__HeaderOuterWrapper {
  margin-top: -1.6rem;
}

.Polaris-ResourceList__HeaderWrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  min-height: 5.6rem;
  padding: 1rem 1.2rem;
  background-color: var(--p-surface);
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList__HeaderWrapper {
    flex-direction: row;
    padding: 1rem 1.6rem;
  }
}

.Polaris-ResourceList__HeaderWrapper--isSticky {
  box-shadow: 0 0 0 0.1rem rgba(63, 63, 68, 0.05), 0 0.1rem 0.3rem 0 rgba(63, 63, 68, 0.15);
}

.Polaris-ResourceList__HeaderContentWrapper {
  position: absolute;
  z-index: 1;
  right: 1.2rem;
  left: 1.2rem;
  display: flex;
  min-height: 3.6rem;
  opacity: 1;
  transition: opacity cubic-bezier(0.25, 0.1, 0.25, 1) 200ms;
}

.Polaris-ResourceList__HeaderWrapper--inSelectMode .Polaris-ResourceList__HeaderContentWrapper {
  opacity: 0;
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList__HeaderContentWrapper {
    right: 1.6rem;
    left: 1.6rem;
    align-self: flex-start;
  }
}

.Polaris-ResourceList__SortWrapper,
.Polaris-ResourceList__AlternateToolWrapper {
  position: relative;
  display: flex;
  flex: 1 1;
  align-items: center;
}

.Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__SortWrapper,
.Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__AlternateToolWrapper {
  padding-right: 0.8rem;
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList__SortWrapper,
  .Polaris-ResourceList__AlternateToolWrapper {
    position: relative;
    left: auto;
    flex: 0 1 auto;
    margin-left: 1.6rem;
  }
  .Polaris-ResourceList__HeaderWrapper--hasAlternateTool.Polaris-ResourceList__HeaderWrapper--hasSelect
    .Polaris-ResourceList__SortWrapper,
  .Polaris-ResourceList__HeaderWrapper--hasSort.Polaris-ResourceList__HeaderWrapper--hasSelect
    .Polaris-ResourceList__SortWrapper,
  .Polaris-ResourceList__HeaderWrapper--hasAlternateTool.Polaris-ResourceList__HeaderWrapper--hasSelect
    .Polaris-ResourceList__AlternateToolWrapper,
  .Polaris-ResourceList__HeaderWrapper--hasSort.Polaris-ResourceList__HeaderWrapper--hasSelect
    .Polaris-ResourceList__AlternateToolWrapper {
    padding-right: 0;
  }
}

.Polaris-ResourceList__SortWrapper {
  min-width: 0;
  max-width: 100%;
}

.Polaris-ResourceList__SortWrapper > * {
  max-width: 100%;
}

.Polaris-ResourceList__HeaderTitleWrapper {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1 1;
  padding-left: 0.4rem;
  align-self: center;
}

.Polaris-ResourceList__HeaderWrapper--hasAlternateTool .Polaris-ResourceList__HeaderTitleWrapper,
.Polaris-ResourceList__HeaderWrapper--hasSort .Polaris-ResourceList__HeaderTitleWrapper {
  display: none;
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__HeaderTitleWrapper,
  .Polaris-ResourceList__HeaderWrapper--hasAlternateTool.Polaris-ResourceList__HeaderWrapper--hasSelect
    .Polaris-ResourceList__HeaderTitleWrapper,
  .Polaris-ResourceList__HeaderWrapper--hasSort.Polaris-ResourceList__HeaderWrapper--hasSelect
    .Polaris-ResourceList__HeaderTitleWrapper {
    display: none;
  }
  .Polaris-ResourceList__HeaderWrapper--hasAlternateTool .Polaris-ResourceList__HeaderTitleWrapper,
  .Polaris-ResourceList__HeaderWrapper--hasSort .Polaris-ResourceList__HeaderTitleWrapper {
    display: block;
  }
}

.Polaris-ResourceList__BulkActionsWrapper {
  position: relative;
  z-index: 2;
  width: 100%;
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList__BulkActionsWrapper {
    flex: 0 1 auto;
    align-self: flex-start;
  }
}

.Polaris-ResourceList__CheckableButtonWrapper {
  display: none;
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList__CheckableButtonWrapper {
    flex: 1 1;
    display: block;
  }
}

.Polaris-ResourceList__SelectButtonWrapper {
  position: relative;
  flex: none;
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList__SelectButtonWrapper {
    display: none;
  }
}

.Polaris-ResourceList__EmptySearchResultWrapper {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
}

@media (min-height: 37.5em) {
  .Polaris-ResourceList__EmptySearchResultWrapper {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
  }
}

.Polaris-ResourceList__ResourceListWrapper {
  position: relative;
}

.Polaris-ResourceList {
  position: relative;
  z-index: 1;
  margin: 0;
  padding: 0;
  list-style: none;
}

.Polaris-ResourceList__ItemWrapper {
  position: relative;
  z-index: 1;
  overflow: hidden;
  max-width: 100%;
}

.Polaris-ResourceList__ItemWrapper + .Polaris-ResourceList__ItemWrapper {
  border-top: 0.1rem solid var(--p-divider);
}

.Polaris-ResourceList__ItemWrapper--isLoading {
  min-height: 6.4rem;
}

.Polaris-ResourceList__SpinnerContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  display: flex;
  justify-content: center;
}

.Polaris-ResourceList__LoadingOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  background-color: var(--p-overlay);
}

.Polaris-ResourceList__DisabledPointerEvents {
  pointer-events: none;
}

.Polaris-ResourceList--disableTextSelection {
  -webkit-user-select: none;
  user-select: none;
}

.Polaris-FilterControl-DateSelector__DateTextField {
  margin-top: 0.8rem;
}

.Polaris-FilterControl-DateSelector__DatePicker {
  margin-top: 1.6rem;
}

.Polaris-ResourceList-FilterControl__AppliedFilters {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: nowrap;
  margin-top: -1.2rem;
  margin-left: -1.2rem;
  margin-right: -1.2rem;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.Polaris-ResourceList-FilterControl__AppliedFilters::after {
  content: "";
  flex: 0 0 1.2rem;
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList-FilterControl__AppliedFilters {
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: -0.8rem;
    margin-top: -1.6rem;
  }
  .Polaris-ResourceList-FilterControl__AppliedFilters::after {
    content: none;
    flex: none;
  }
}

@media (max-width: 28.625em) {
  .Polaris-ResourceList-FilterControl__AppliedFilters {
    padding-bottom: 0.8rem;
  }
}

.Polaris-ResourceList-FilterControl__AppliedFilter {
  flex: 1 0 auto;
  margin-top: 0.8rem;
  margin-left: 1.2rem;
  max-width: calc(100% - 0.8rem);
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList-FilterControl__AppliedFilter {
    flex: 0 1 auto;
    margin-left: 0.8rem;
  }
}

.Polaris-SkeletonBodyText {
  height: 0.8rem;
  position: relative;
}

.Polaris-SkeletonBodyText::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background-color: var(--p-surface-neutral);
  border-radius: 0.3rem;
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-SkeletonBodyText::after {
    background-color: grayText;
  }
}

.Polaris-SkeletonBodyText:last-child:not(:first-child) {
  width: 80%;
}

.Polaris-SkeletonBodyText + .Polaris-SkeletonBodyText {
  margin-top: 1.2rem;
}

.Polaris-SkeletonDisplayText__DisplayText {
  max-width: 12rem;
  position: relative;
}

.Polaris-SkeletonDisplayText__DisplayText::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background-color: var(--p-surface-neutral);
  border-radius: 0.3rem;
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-SkeletonDisplayText__DisplayText::after {
    background-color: grayText;
  }
}

.Polaris-SkeletonDisplayText--sizeSmall {
  height: 2.4rem;
}

@media (min-width: 40em) {
  .Polaris-SkeletonDisplayText--sizeSmall {
    height: 2.8rem;
  }
}

.Polaris-SkeletonDisplayText--sizeMedium {
  height: 2.8rem;
}

@media (min-width: 40em) {
  .Polaris-SkeletonDisplayText--sizeMedium {
    height: 3.2rem;
  }
}

.Polaris-SkeletonDisplayText--sizeLarge {
  height: 2.8rem;
}

@media (min-width: 40em) {
  .Polaris-SkeletonDisplayText--sizeLarge {
    height: 3.2rem;
  }
}

.Polaris-SkeletonDisplayText--sizeExtraLarge {
  height: 3.6rem;
}

@media (min-width: 40em) {
  .Polaris-SkeletonDisplayText--sizeExtraLarge {
    height: 4.4rem;
  }
}

.Polaris-SkeletonPage__Page {
  margin: 0 auto;
  padding: 0;
  max-width: 99.8rem;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-SkeletonPage__Page {
    padding: 0 2rem;
  }
}

@media (min-width: 30.625em) {
  .Polaris-SkeletonPage__Page {
    padding: 0 2rem;
  }
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-SkeletonPage__Page {
    padding: 0 3.2rem;
  }
}

@media (min-width: 46.5em) {
  .Polaris-SkeletonPage__Page {
    padding: 0 3.2rem;
  }
}

.Polaris-SkeletonPage--fullWidth {
  max-width: none;
}

.Polaris-SkeletonPage--narrowWidth {
  max-width: 66.2rem;
}

.Polaris-SkeletonPage__Content {
  margin: 0.8rem 0;
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-SkeletonPage__Content {
    margin-top: 0.8rem;
  }
}

@media (min-width: 46.5em) {
  .Polaris-SkeletonPage__Content {
    margin-top: 0.8rem;
  }
}

.Polaris-SkeletonPage__Header {
  padding: 1.6rem 1.6rem 0;
  padding-bottom: 0.8rem;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-SkeletonPage__Header {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 30.625em) {
  .Polaris-SkeletonPage__Header {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-SkeletonPage__Header {
    padding-top: 1.6rem;
  }
}

@media (min-width: 46.5em) {
  .Polaris-SkeletonPage__Header {
    padding-top: 1.6rem;
  }
}

.Polaris-SkeletonPage__BreadcrumbAction {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  margin-top: -0.4rem;
  margin-bottom: -0.4rem;
}

.Polaris-SkeletonPage__TitleAndPrimaryAction {
  display: flex;
}

@media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em) {
  [data-has-navigation] .Polaris-SkeletonPage__TitleAndPrimaryAction {
    display: block;
  }
}

@media (max-width: 46.5em) {
  .Polaris-SkeletonPage__TitleAndPrimaryAction {
    display: block;
  }
}

.Polaris-SkeletonPage__TitleWrapper {
  flex: 1 1;
}

.Polaris-SkeletonPage__Title {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 2.8rem;
}

@media (min-width: 40em) {
  .Polaris-SkeletonPage__Title {
    font-size: 2rem;
  }
}

.Polaris-SkeletonPage__SkeletonTitle {
  position: relative;
  max-width: 12rem;
  height: 2.8rem;
}

.Polaris-SkeletonPage__SkeletonTitle::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background-color: var(--p-surface-neutral);
  border-radius: 0.3rem;
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-SkeletonPage__SkeletonTitle::after {
    background-color: grayText;
  }
}

.Polaris-SkeletonPage__PrimaryAction {
  align-self: stretch;
}

.Polaris-SkeletonPage__PrimaryAction > * {
  height: 3.6rem;
  min-width: 10rem;
}

@media (max-width: 48.0625em), (min-width: 48.0625em) and (max-width: 65em) {
  [data-has-navigation] .Polaris-SkeletonPage__PrimaryAction {
    margin-top: 1.6rem;
    margin-bottom: -0.8rem;
  }
}

@media (max-width: 50em) {
  .Polaris-SkeletonPage__PrimaryAction {
    margin-top: 1.6rem;
    margin-bottom: -0.8rem;
  }
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-SkeletonPage__PrimaryAction {
    margin-top: 0.8rem;
    margin-bottom: -0.8rem;
  }
}

@media (min-width: 30.625em) {
  .Polaris-SkeletonPage__PrimaryAction {
    margin-top: 0.8rem;
    margin-bottom: -0.8rem;
  }
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-SkeletonPage__PrimaryAction {
    margin-top: 0;
  }
}

@media (min-width: 46.5em) {
  .Polaris-SkeletonPage__PrimaryAction {
    margin-top: 0;
  }
}

@media (max-width: 48.0625em) and (min-width: 50em), (min-width: 65em) {
  [data-has-navigation] .Polaris-SkeletonPage__PrimaryAction {
    margin-top: 0;
  }
}

@media (min-width: 50em) {
  .Polaris-SkeletonPage__PrimaryAction {
    margin-top: 0;
  }
}

.Polaris-SkeletonPage__Actions {
  margin-top: 0.8rem;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}

.Polaris-SkeletonPage__Action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 2.8rem;
  padding-right: 2.4rem;
  margin-top: -0.4rem;
  margin-bottom: -0.4rem;
  padding-top: 1.6rem;
}

.Polaris-SkeletonPage__Action:first-child {
  padding-right: 0;
}

@media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em) {
  [data-has-navigation] .Polaris-SkeletonPage__Action:not(:last-child) {
    display: none;
  }
}

@media (max-width: 46.5em) {
  .Polaris-SkeletonPage__Action:not(:last-child) {
    display: none;
  }
}

.Polaris-SkeletonThumbnail {
  position: relative;
}

.Polaris-SkeletonThumbnail::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background-color: var(--p-surface-neutral);
  border-radius: 0.3rem;
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-SkeletonThumbnail::after {
    background-color: grayText;
  }
}

.Polaris-SkeletonThumbnail--sizeSmall {
  height: 4rem;
  width: 4rem;
}

.Polaris-SkeletonThumbnail--sizeMedium {
  height: 6rem;
  width: 6rem;
}

.Polaris-SkeletonThumbnail--sizeLarge {
  height: 8rem;
  width: 8rem;
}

.Polaris-Tabs {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.Polaris-Tabs__Wrapper {
  border-bottom: 0.1rem solid var(--p-divider);
  padding: 0 0.8rem;
}

.Polaris-Tabs--fitted {
  flex-wrap: nowrap;
}

.Polaris-Tabs--fitted .Polaris-Tabs__TabContainer {
  flex: 1 1 100%;
}

.Polaris-Tabs--fitted .Polaris-Tabs__Title {
  width: 100%;
  padding: 0.8rem 1.6rem;
}

.Polaris-Tabs--fillSpace .Polaris-Tabs__TabContainer {
  flex: 1 1 auto;
}

.Polaris-Tabs__TabContainer {
  display: flex;
  margin: 0;
  padding: 0;
}

.Polaris-Tabs__Tab {
  color: inherit;
  text-decoration: none;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  text-transform: initial;
  letter-spacing: initial;
  color: var(--p-text-subdued);
  color: var(--p-text);
  position: relative;
  justify-content: center;
  width: 100%;
  min-width: 100%;
  margin-top: 0.1rem;
  margin-bottom: -0.1rem;
  padding: 0.8rem 0.4rem;
  outline: none;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
}

.Polaris-Tabs__Tab:visited {
  color: inherit;
}

.Polaris-Tabs__Tab:focus {
  outline: none;
}

@media (min-width: 40em) {
  .Polaris-Tabs__Tab {
    font-size: 1.4rem;
  }
}

.Polaris-Tabs__Tab:hover {
  text-decoration: none;
}

.Polaris-Tabs__Tab:hover .Polaris-Tabs__Title {
  font-weight: 400;
  color: var(--p-text);
  background-color: transparent;
}

.Polaris-Tabs__Tab:hover .Polaris-Tabs__Title::before {
  background-color: var(--p-border-hovered);
}

.Polaris-Tabs__Tab:active .Polaris-Tabs__Title {
  background-color: transparent;
}

.Polaris-Tabs__Tab:active .Polaris-Tabs__Title::before {
  background: var(--p-surface-primary-selected-pressed);
}

.Polaris-Tabs__Tab:focus .Polaris-Tabs__Title {
  font-weight: 400;
  color: var(--p-text);
}

.Polaris-Tabs__Tab:focus:not(:active) .Polaris-Tabs__Title::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Tabs__Tab--selected {
  font-weight: 400;
  color: var(--p-text);
}

.Polaris-Tabs__Tab--selected:focus .Polaris-Tabs__Title {
  outline: 0.3rem solid transparent;
}

.Polaris-Tabs__Tab--selected:focus .Polaris-Tabs__Title::before {
  background: var(--p-action-primary);
}

.Polaris-Tabs__Tab--selected .Polaris-Tabs__Title {
  outline: 0.3rem solid transparent;
  color: var(--p-text);
}

.Polaris-Tabs__Tab--selected .Polaris-Tabs__Title::before {
  background: var(--p-action-primary);
}

.Polaris-Tabs__Title {
  position: relative;
  border-radius: var(--p-border-radius-base);
  display: block;
  padding: 0.8rem 1.6rem;
  min-width: 5rem;
  color: var(--p-text-subdued);
}

.Polaris-Tabs__Title::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Tabs__Title::before {
  content: "";
  position: absolute;
  bottom: -0.8rem;
  left: 0;
  right: 0;
  height: 0.3rem;
  border-top-left-radius: var(--p-border-radius-base);
  border-top-right-radius: var(--p-border-radius-base);
}

.Polaris-Tabs--titleWithIcon {
  display: flex;
}

.Polaris-Tabs__Panel {
  display: block;
}

.Polaris-Tabs__Panel:focus {
  outline: none;
}

.Polaris-Tabs__Panel--hidden {
  display: none;
}

.Polaris-Tabs__List {
  list-style: none;
  margin: 0;
  padding: 0.8rem;
}

.Polaris-Tabs__Item {
  color: inherit;
  text-decoration: none;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  position: relative;
  display: block;
  width: 100%;
  min-height: 1.6rem;
  padding: 0.8rem 1.6rem;
  text-align: left;
  cursor: pointer;
  border-radius: var(--p-border-radius-base);
}

.Polaris-Tabs__Item:visited {
  color: inherit;
}

.Polaris-Tabs__Item:focus {
  outline: none;
}

.Polaris-Tabs__Item::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Tabs__Item::-moz-focus-inner {
  border: none;
}

.Polaris-Tabs__Item:hover {
  background-color: var(--p-surface-hovered);
}

.Polaris-Tabs__Item:active {
  background-color: var(--p-surface-primary-selected-pressed);
}

.Polaris-Tabs__Item:focus:not(:active)::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Tabs__DisclosureTab {
  display: none;
}

.Polaris-Tabs__DisclosureTab--visible {
  display: flex;
}

.Polaris-Tabs__DisclosureActivator {
  position: relative;
  height: 100%;
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  margin: 0.1rem 0.1rem -0.1rem 0;
}

.Polaris-Tabs__DisclosureActivator::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-Tabs__DisclosureActivator:hover svg,
.Polaris-Tabs__DisclosureActivator:focus svg {
  fill: var(--p-icon);
}

.Polaris-Tabs__DisclosureActivator:focus .Polaris-Tabs__Title::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-Tabs__DisclosureActivator:hover .Polaris-Tabs__Title::before {
  background-color: var(--p-border-hovered);
}

.Polaris-Tabs__TabMeasurer {
  display: flex;
  visibility: hidden;
  height: 0;
}

.Polaris-Thumbnail {
  position: relative;
  display: block;
  overflow: hidden;
  background: var(--p-surface);
  min-width: 4rem;
  max-width: 100%;
  border-radius: var(--p-border-radius-base);
  border: 0.1rem solid var(--p-divider);
}

.Polaris-Thumbnail::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.Polaris-Thumbnail--sizeSmall {
  width: 4rem;
}

.Polaris-Thumbnail--sizeMedium {
  width: 6rem;
}

.Polaris-Thumbnail--sizeLarge {
  width: 8rem;
}

.Polaris-Thumbnail > * {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  color: var(--p-icon-subdued);
}

.Polaris-Thumbnail > * svg {
  fill: currentColor;
}

.Polaris-TopBar-SearchDismissOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 515;
  height: 100%;
}

.Polaris-TopBar-SearchDismissOverlay--visible {
  background-color: transparent;
  animation: none;
}

@keyframes Polaris-TopBar-SearchDismissOverlay__fade--in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Polaris-TopBar-Search {
  position: fixed;
  visibility: hidden;
  z-index: 516;
  pointer-events: none;
  top: 5.6rem;
  left: 0;
  right: 0;
  box-shadow: var(--p-modal-shadow);
  overflow: hidden;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-TopBar-Search {
    position: absolute;
    top: 100%;
    max-width: 58rem;
    margin: 0.4rem 2rem 0;
    border-radius: var(--p-border-radius-wide);
  }
}

@media (min-width: 30.625em) {
  .Polaris-TopBar-Search {
    position: absolute;
    top: 100%;
    max-width: 58rem;
    margin: 0.4rem 2rem 0;
    border-radius: var(--p-border-radius-wide);
  }
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-TopBar-Search {
    margin: 0.4rem 3.2rem 0;
  }
}

@media (min-width: 46.5em) {
  .Polaris-TopBar-Search {
    margin: 0.4rem 3.2rem 0;
  }
}

.Polaris-TopBar-Search__SearchContent {
  background-color: var(--p-surface);
}

.Polaris-TopBar-Search--visible {
  visibility: initial;
  pointer-events: all;
}

.Polaris-TopBar-Search__Results {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 5.6rem);
  margin: 0;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-TopBar-Search__Results {
    max-height: 60vh;
  }
}

@media (min-width: 30.625em) {
  .Polaris-TopBar-Search__Results {
    max-height: 60vh;
  }
}

.Polaris-TopBar-SearchField {
  z-index: 519;
  position: relative;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  border: 0.1rem solid transparent;
  width: 100%;
  max-width: 58rem;
}

.Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Input,
.Polaris-TopBar-SearchField__Input:focus {
  border: none;
  color: var(--p-text);
}

.Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Input::placeholder,
.Polaris-TopBar-SearchField__Input:focus::placeholder {
  color: var(--p-text-subdued);
}

.Polaris-TopBar-SearchField__Input:focus ~ .Polaris-TopBar-SearchField__Backdrop {
  background-color: var(--p-surface-search-field);
}

.Polaris-TopBar-SearchField__Input:focus ~ .Polaris-TopBar-SearchField__Backdrop::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-TopBar-SearchField__Input:focus ~ .Polaris-TopBar-SearchField__BackdropShowFocusBorder {
  border: 0.1rem solid var(--top-bar-border);
}

.Polaris-TopBar-SearchField__Input:focus ~ .Polaris-TopBar-SearchField__Icon svg {
  fill: var(--p-icon);
}

.Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Backdrop {
  background-color: var(--p-surface-search-field);
}

.Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__BackdropShowFocusBorder {
  border: 0.1rem solid var(--top-bar-border);
}

.Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Icon svg {
  fill: var(--p-icon);
}

.Polaris-TopBar-SearchField__Input {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  border: none;
  text-transform: initial;
  letter-spacing: initial;
  z-index: 2;
  height: 3.6rem;
  width: 100%;
  padding: 0 0 0 3.6rem;
  border: none;
  background-color: transparent;
  outline: none;
  color: var(--p-text);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: fill, color;
  transition: fill 200ms cubic-bezier(0.25, 0.1, 0.25, 1), color 200ms cubic-bezier(0.25, 0.1, 0.25, 1);
  -webkit-appearance: textfield;
  appearance: textfield;
}

@media (min-width: 40em) {
  .Polaris-TopBar-SearchField__Input {
    font-size: 1.4rem;
  }
}

.Polaris-TopBar-SearchField__Input::placeholder {
  color: var(--p-text);
  transition: 167ms color cubic-bezier(0.25, 0.1, 0.25, 1) 33ms;
}

.Polaris-TopBar-SearchField__Input::-webkit-search-decoration,
.Polaris-TopBar-SearchField__Input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}

.Polaris-TopBar-SearchField__Icon {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 0.8rem;
  display: flex;
  height: 2rem;
  pointer-events: none;
  transform: translateY(-50%);
}

.Polaris-TopBar-SearchField__Icon svg {
  fill: var(--p-icon);
}

.Polaris-TopBar-SearchField__Icon svg {
  transition: 167ms fill cubic-bezier(0.25, 0.1, 0.25, 1) 33ms;
}

.Polaris-TopBar-SearchField__Clear {
  position: relative;
  position: relative;
  z-index: 3;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  padding: 0.8rem;
}

.Polaris-TopBar-SearchField__Clear svg {
  fill: var(--p-icon);
}

.Polaris-TopBar-SearchField__Clear::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-wide) + 0.1rem);
}

.Polaris-TopBar-SearchField__Clear:focus,
.Polaris-TopBar-SearchField__Clear:hover {
  outline: none;
}

.Polaris-TopBar-SearchField__Clear:focus svg,
.Polaris-TopBar-SearchField__Clear:hover svg {
  fill: var(--p-icon-hovered);
}

.Polaris-TopBar-SearchField__Clear:focus::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-TopBar-SearchField__Clear:active svg {
  fill: var(--p-icon-pressed);
}

.Polaris-TopBar-SearchField__Clear:active::after {
  border: none;
}

.Polaris-TopBar-SearchField__Backdrop {
  position: relative;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--p-surface-search-field, var(--top-bar-background-lighter));
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: background-color;
  transition: background-color 200ms cubic-bezier(0.25, 0.1, 0.25, 1);
  border-radius: var(--p-border-radius-base);
  animation: Polaris-TopBar-SearchField--toLightBackground 0.01ms;
}

.Polaris-TopBar-SearchField__Backdrop::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

@keyframes Polaris-TopBar-SearchField--toLightBackground {
  to {
    background-color: var(--p-surface-neutral, var(--top-bar-background-lighter));
  }
}

.Polaris-MessageIndicator__MessageIndicatorWrapper {
  position: relative;
}

.Polaris-MessageIndicator {
  position: absolute;
  z-index: 1;
  top: -0.3rem;
  right: -0.3rem;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 100%;
  background-color: var(--p-icon-highlight);
  border: solid 0.2rem var(--p-background);
}

.Polaris-Menu-Message__Section {
  max-width: 32.5rem;
  margin-top: 0.8rem;
  padding-top: 0.8rem;
  border-top: 0.1rem solid var(--p-divider);
}

.Polaris-TopBar-Menu__ActivatorWrapper {
  height: 5.6rem;
  display: flex;
  align-items: center;
}

.Polaris-TopBar-Menu__Activator {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  position: relative;
  color: var(--p-text);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 3.6rem;
  padding: 0.6rem 0.8rem;
  border: 0;
  cursor: pointer;
  transition: background-color 0.1s;
  margin-right: 0.8rem;
  border-radius: 0.3rem;
}

.Polaris-TopBar-Menu__Activator:focus {
  outline: none;
}

.Polaris-TopBar-Menu__Activator::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
}

.Polaris-TopBar-Menu__Activator:focus {
  background-color: var(--top-bar-background-lighter);
  outline: none;
}

.Polaris-TopBar-Menu__Activator:focus::after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: 0.1rem solid transparent;
}

.Polaris-TopBar-Menu__Activator:hover {
  background-color: var(--top-bar-background-lighter, var(--p-surface-hovered));
}

.Polaris-TopBar-Menu__Activator:active,
.Polaris-TopBar-Menu__Activator[aria-expanded="true"] {
  background-color: var(--top-bar-background-darker, var(--p-surface-pressed));
  outline: none;
  transition: none;
}

.Polaris-TopBar-Menu__Activator:active::after,
.Polaris-TopBar-Menu__Activator[aria-expanded="true"]::after {
  border: none;
}

@media (max-width: 48.0525em) {
  .Polaris-TopBar-Menu__Activator {
    margin: 0;
  }
  .Polaris-TopBar-Menu__Activator:focus,
  .Polaris-TopBar-Menu__Activator:hover,
  .Polaris-TopBar-Menu__Activator:active,
  .Polaris-TopBar-Menu__Activator[aria-expanded="true"] {
    background-color: transparent;
    opacity: 0.85;
  }
}

.Polaris-TopBar-Menu__Section {
  margin-top: 0.8rem;
  padding-top: 0.8rem;
  border-top: 0.1rem solid var(--p-divider);
}

.Polaris-TopBar-UserMenu__Details {
  max-width: 16rem;
  margin-left: 0.8rem;
}

@media (max-width: 48.0525em) {
  .Polaris-TopBar-UserMenu__Details {
    display: none;
  }
}

.Polaris-TopBar-UserMenu__Name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  text-transform: initial;
  letter-spacing: initial;
  font-weight: 500;
  line-height: 1.6rem;
  color: var(--p-text);
  text-align: left;
}

@media (min-width: 40em) {
  .Polaris-TopBar-UserMenu__Name {
    font-size: 1.4rem;
  }
}

.Polaris-TopBar-UserMenu__Detail {
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 0.7;
  color: var(--p-text);
  text-align: left;
}

@media (min-width: 40em) {
  .Polaris-TopBar-UserMenu__Detail {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

.Polaris-TopBar-UserMenu__Name,
.Polaris-TopBar-UserMenu__Detail {
  transition: 167ms color cubic-bezier(0.25, 0.1, 0.25, 1) 33ms;
}

.Polaris-TopBar {
  position: relative;
  display: flex;
  height: 5.6rem;
  box-shadow: var(--p-top-bar-shadow);
  background-color: var(--p-surface);
  transition: 200ms background-color ease-in-out;
}

.Polaris-TopBar::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  border-bottom: 0.1rem solid transparent;
}

.Polaris-TopBar__LogoDisplayControl {
  display: none;
}

@media (min-width: 48.0625em) {
  .Polaris-TopBar__LogoDisplayControl {
    display: flex;
  }
}

.Polaris-TopBar__LogoDisplayContainer {
  display: flex;
}

.Polaris-TopBar__LogoContainer {
  flex: 0 0 24rem;
  align-items: center;
  height: 100%;
  padding: 0 0.8rem 0 1.6rem;
  flex-basis: 24rem;
  flex-basis: calc(24rem + constant(safe-area-inset-left));
  flex-basis: calc(24rem + env(safe-area-inset-left));
  padding-left: 1.6rem;
  padding-left: calc(1.6rem + constant(safe-area-inset-left));
  padding-left: calc(1.6rem + env(safe-area-inset-left));
}

.Polaris-TopBar__Logo,
.Polaris-TopBar__LogoLink {
  display: block;
}

.Polaris-TopBar__ContextControl {
  display: none;
}

@media (min-width: 48.0625em) {
  .Polaris-TopBar__ContextControl {
    width: 24rem;
    display: block;
  }
}

@media (min-width: 87.5em) {
  .Polaris-TopBar__ContextControl {
    width: 24rem;
  }
}

.Polaris-TopBar__NavigationIcon {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  position: relative;
  align-self: center;
  margin-left: 1rem;
  margin-right: 0.8rem;
  padding: 0.8rem;
  border-radius: 0.3rem;
  fill: var(--p-icon);
  transition: 167ms fill cubic-bezier(0.25, 0.1, 0.25, 1) 33ms;
}

.Polaris-TopBar__NavigationIcon:focus {
  outline: none;
}

.Polaris-TopBar__NavigationIcon.Polaris-TopBar--focused:active {
  background-color: var(--p-surface-pressed);
}

.Polaris-TopBar__NavigationIcon:hover {
  background-color: var(--p-surface-hovered);
}

.Polaris-TopBar__NavigationIcon::after {
  content: "";
  position: absolute;
  top: -0.8rem;
  left: -0.8rem;
  width: calc(100% + 2rem);
  height: calc(100% + 2rem);
}

@media (min-width: 48.0625em) {
  .Polaris-TopBar__NavigationIcon {
    display: none;
  }
}

.Polaris-TopBar__Contents {
  z-index: 10;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

@media (min-width: 42.5em) {
  .Polaris-TopBar__Contents {
    position: relative;
  }
}

.Polaris-TopBar__SearchField {
  margin: 0 auto;
  padding: 0;
  max-width: 99.8rem;
  position: relative;
  width: 100%;
  margin: 0;
  max-width: none;
  margin-left: calc(50% - 49.9rem);
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-TopBar__SearchField {
    padding: 0 2rem;
  }
}

@media (min-width: 30.625em) {
  .Polaris-TopBar__SearchField {
    padding: 0 2rem;
  }
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-TopBar__SearchField {
    padding: 0 3.2rem;
  }
}

@media (min-width: 46.5em) {
  .Polaris-TopBar__SearchField {
    padding: 0 3.2rem;
  }
}

@media (max-width: 1238px) {
  .Polaris-TopBar__SearchField {
    margin-left: 0;
    margin-right: 0.4rem;
  }
}

.Polaris-TopBar__SecondaryMenu svg {
  fill: var(--p-icon);
}

.Polaris-VideoThumbnail__Thumbnail {
  position: relative;
  padding-bottom: 56.25%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.Polaris-VideoThumbnail__Thumbnail.Polaris-VideoThumbnail__WithPlayer {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-bottom: auto;
}

.Polaris-VideoThumbnail__PlayButton {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  background: transparent;
  opacity: 0.8;
  transition: opacity 0.2s ease-in;
  cursor: pointer;
}

.Polaris-VideoThumbnail__PlayButton:hover,
.Polaris-VideoThumbnail__PlayButton:focus {
  opacity: 1;
}

.Polaris-VideoThumbnail__PlayButton:focus {
  outline: none;
  box-shadow: inset 0.2rem 0 0 var(--p-focused);
  background-image: linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3));
}

.Polaris-VideoThumbnail__PlayIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6rem;
  height: 6rem;
  margin-top: -3rem;
  margin-left: -3rem;
}

.Polaris-VideoThumbnail__Timestamp {
  position: absolute;
  bottom: 0;
  padding: 0 0.4rem;
  margin-bottom: 0.8rem;
  margin-left: 0.8rem;
  border-radius: var(--p-border-radius-base);
  color: var(--p-text);
  background-color: var(--p-surface);
  opacity: 0.8;
  text-align: center;
}

.Polaris-VideoThumbnail--withProgress {
  margin-bottom: 1.2rem;
}

.Polaris-VideoThumbnail__Progress {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: var(--p-surface);
  height: 0.6rem;
  overflow: hidden;
}

.Polaris-VideoThumbnail__Indicator {
  height: inherit;
  width: 100%;
  transform-origin: left;
  transform: scaleX(0);
  background-color: var(--p-border-highlight);
  transition: transform 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
}

.Polaris-VideoThumbnail__ProgressBar,
.Polaris-VideoThumbnail__Label {
  position: absolute !important;
  width: 0.1rem !important;
  height: 0.1rem !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}
