:root {
  --polaris-version-number: "7.6.0";
  --polaris-animation-skeleton-shimmer: polaris-SkeletonShimmerAnimation;
}

html, body {
  text-transform: initial;
  letter-spacing: initial;
  color: var(--p-text);
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
}

@media (min-width: 40em) {
  html, body {
    font-size: 1.4rem;
  }
}

html, body, button {
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  text-rendering: optimizelegibility;
  font-size: 62.5%;
  position: relative;
}

body {
  min-height: 100%;
  background-color: #f6f6f7;
  margin: 0;
  padding: 0;
}

@media print {
  body {
    background-color: #0000 !important;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  font-size: 1em;
  font-weight: 400;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
}

@keyframes polaris-SkeletonShimmerAnimation {
  0% {
    opacity: .45;
  }

  100% {
    opacity: .9;
  }
}

.Polaris-Avatar {
  min-width: 2.4rem;
  max-width: 100%;
  background: var(--p-surface-neutral);
  color: var(--p-icon-subdued);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 3rem;
  display: block;
  position: relative;
  overflow: hidden;
}

@media (forced-colors: active) {
  .Polaris-Avatar {
    border: .1rem solid #0000;
  }
}

.Polaris-Avatar:after {
  content: "";
  padding-bottom: 100%;
  display: block;
}

.Polaris-Avatar--hidden {
  visibility: hidden;
}

.Polaris-Avatar--sizeExtraSmall {
  width: 2.4rem;
}

.Polaris-Avatar--sizeSmall {
  width: 3.2rem;
}

.Polaris-Avatar--sizeMedium {
  width: 4rem;
}

.Polaris-Avatar--sizeLarge {
  width: 6rem;
}

.Polaris-Avatar--styleOne {
  color: var(--p-decorative-one-text);
  background: var(--p-decorative-one-surface);
}

.Polaris-Avatar--styleTwo {
  color: var(--p-decorative-two-text);
  background: var(--p-decorative-two-surface);
}

.Polaris-Avatar--styleThree {
  color: var(--p-decorative-three-text);
  background: var(--p-decorative-three-surface);
}

.Polaris-Avatar--styleFour {
  color: var(--p-decorative-four-text);
  background: var(--p-decorative-four-surface);
}

.Polaris-Avatar--styleFive {
  color: var(--p-decorative-five-text);
  background: var(--p-decorative-five-surface);
}

.Polaris-Avatar--hasImage {
  background: none;
}

.Polaris-Avatar__Image {
  width: 100%;
  height: 100%;
  background-color: var(--p-background);
  object-fit: cover;
  border-radius: 3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Polaris-Avatar__Initials {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.Polaris-Avatar__Svg {
  width: 100%;
  height: 100%;
}

.Polaris-VisuallyHidden {
  width: .1rem !important;
  height: .1rem !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.Polaris-Icon {
  height: 2rem;
  width: 2rem;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
  display: block;
}

.Polaris-Icon--applyColor {
  color: var(--p-surface);
}

.Polaris-Icon--hasBackdrop {
  align-items: center;
  margin: .4rem;
  display: flex;
  position: relative;
}

.Polaris-Icon--hasBackdrop:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  inset: -.4rem;
}

.Polaris-Icon--colorBase svg {
  fill: var(--p-icon);
}

.Polaris-Icon--colorBase:before {
  background-color: var(--p-surface-neutral);
}

.Polaris-Icon--colorSubdued svg {
  fill: var(--p-icon-subdued);
}

.Polaris-Icon--colorCritical svg {
  fill: var(--p-icon-critical);
}

.Polaris-Icon--colorCritical:before {
  background-color: var(--p-surface-critical);
}

.Polaris-Icon--colorInteractive svg {
  fill: var(--p-interactive);
}

.Polaris-Icon--colorWarning svg {
  fill: var(--p-icon-warning);
}

.Polaris-Icon--colorWarning:before {
  background-color: var(--p-surface-warning);
}

.Polaris-Icon--colorHighlight svg {
  fill: var(--p-icon-highlight);
}

.Polaris-Icon--colorHighlight:before {
  background-color: var(--p-surface-highlight);
}

.Polaris-Icon--colorSuccess svg {
  fill: var(--p-icon-success);
}

.Polaris-Icon--colorSuccess:before {
  background-color: var(--p-surface-success);
}

.Polaris-Icon--colorPrimary svg {
  fill: var(--p-action-primary);
}

.Polaris-Icon__Svg, .Polaris-Icon__Img {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  display: block;
  position: relative;
}

.Polaris-Icon__Placeholder {
  background: currentColor;
  padding-bottom: 100%;
}

@keyframes Polaris-Spinner--loading {
  to {
    transform: rotate(360deg);
  }
}

.Polaris-Spinner svg {
  fill: var(--p-border-highlight);
  animation: Polaris-Spinner--loading .5s linear infinite;
}

.Polaris-Spinner--sizeSmall svg {
  height: 2rem;
  width: 2rem;
}

.Polaris-Spinner--sizeLarge svg {
  height: 4.4rem;
  width: 4.4rem;
}

.Polaris-Scrollable {
  -webkit-overflow-scrolling: touch;
  position: relative;
}

.Polaris-Scrollable:focus {
  outline: 0.2rem solid var(--p-focused);
  outline-offset: 0.2rem;
}

.Polaris-Scrollable--horizontal {
  overflow-x: auto;
}

.Polaris-Scrollable--vertical {
  overflow-y: auto;
}

.Polaris-Scrollable--verticalHasScrolling {
  overflow-y: scroll;
}

.Polaris-Scrollable--hasTopShadow {
  box-shadow: inset 0 2rem 2rem -2rem var(--p-hint-from-direct-light);
}

.Polaris-Scrollable--hasBottomShadow {
  box-shadow: inset 0 -2rem 2rem -2rem var(--p-hint-from-direct-light);
}

.Polaris-Scrollable--hasTopShadow.Polaris-Scrollable--hasBottomShadow {
  box-shadow: inset 0 2rem 2rem -2rem var(--p-hint-from-direct-light),
    inset 0 -2rem 2rem -2rem var(--p-hint-from-direct-light);
}

.Polaris-Popover {
  max-width: calc(100vw - 3.2rem);
  box-shadow: var(--p-popover-shadow);
  border-radius: var(--p-border-radius-wide);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: left, top;
  margin: .5rem .8rem 1.6rem;
}

.Polaris-Popover__PopoverOverlay {
  opacity: 0;
  transition: opacity .1s, transform .1s;
  transform: translateY(-.5rem);
}

.Polaris-Popover__PopoverOverlay--entering {
  opacity: 1;
  transform: translateY(0);
}

.Polaris-Popover__PopoverOverlay--open {
  opacity: 1;
  transform: none;
}

.Polaris-Popover__PopoverOverlay--exiting {
  opacity: 1;
  transition-duration: 0s;
  transform: translateY(0);
}

.Polaris-Popover--measuring:not(.Polaris-Popover__PopoverOverlay--exiting) {
  opacity: 0;
  transform: translateY(-.5rem);
}

.Polaris-Popover--fullWidth {
  margin: .5rem auto 0;
}

.Polaris-Popover--fullWidth .Polaris-Popover__Content {
  max-width: none;
}

.Polaris-Popover--positionedAbove {
  margin: 1.6rem .8rem .5rem;
}

.Polaris-Popover--positionedAbove.Polaris-Popover--fullWidth {
  margin: 0 auto .5rem;
}

.Polaris-Popover__Wrapper {
  background-color: var(--p-surface);
  border-radius: var(--p-border-radius-wide);
  outline: .1rem solid #0000;
  position: relative;
  overflow: hidden;
}

.Polaris-Popover__Content {
  border-radius: var(--p-border-radius-base);
  max-width: 40rem;
  max-height: 50rem;
  flex-direction: column;
  display: flex;
  position: relative;
}

.Polaris-Popover__Content:focus {
  outline: none;
}

.Polaris-Popover__Content--fullHeight {
  max-height: none;
}

.Polaris-Popover__Content--fluidContent {
  max-height: none;
  max-width: none;
}

.Polaris-Popover__Pane {
  max-width: 100%;
  flex: 1;
}

.Polaris-Popover__Pane + .Polaris-Popover__Pane {
  border-top: 0.1rem solid var(--p-divider);
}

.Polaris-Popover__Pane:focus {
  outline: none;
}

.Polaris-Popover__Pane--fixed {
  flex: none;
  overflow: visible;
}

.Polaris-Popover__Section {
  padding: 1.6rem;
}

.Polaris-Popover__Section + .Polaris-Popover__Section {
  border-top: 0.1rem solid var(--p-divider);
}

.Polaris-Popover__FocusTracker {
  width: .1rem !important;
  height: .1rem !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

@media print {
  .Polaris-Popover__PopoverOverlay--hideOnPrint {
    display: none !important;
  }
}

.Polaris-PositionedOverlay {
  z-index: 400;
  position: absolute;
}

.Polaris-PositionedOverlay--fixed {
  position: fixed;
}

.Polaris-PositionedOverlay--calculating {
  visibility: hidden;
}

.Polaris-PositionedOverlay--preventInteraction {
  pointer-events: none;
}

.Polaris-Badge {
  --p-component-badge-pip-color: var(--p-icon);
  background-color: var(--p-surface-neutral);
  color: var(--p-text);
  font-size: 1.3rem;
  line-height: 1.6rem;
  font-weight: var(--p-badge-font-weight);
  border-radius: 2rem;
  align-items: center;
  padding: .2rem .8rem;
  display: inline-flex;
}

@media print {
  .Polaris-Badge {
    border: solid 0.01rem var(--p-border);
  }
}

.Polaris-Badge--sizeSmall {
  font-size: 1.2rem;
}

.Polaris-Badge--statusSuccess {
  --p-component-badge-pip-color: var(--p-icon-success);
  background-color: var(--p-surface-success);
  color: var(--p-text);
}

.Polaris-Badge--statusInfo {
  --p-component-badge-pip-color: var(--p-icon-highlight);
  background-color: var(--p-surface-highlight);
  color: var(--p-text);
}

.Polaris-Badge--statusAttention {
  --p-component-badge-pip-color: rgb(138, 97, 22);
  color: var(--p-text);
  background-color: #ffea8a;
}

.Polaris-Badge--statusWarning {
  --p-component-badge-pip-color: var(--p-icon-warning);
  background-color: var(--p-surface-warning);
  color: var(--p-text);
}

.Polaris-Badge--statusCritical {
  --p-component-badge-pip-color: var(--p-icon-critical);
  background-color: var(--p-surface-critical);
  color: var(--p-text);
}

.Polaris-Badge--statusNew {
  background-color: var(--p-surface-neutral);
  color: var(--p-text);
  border: none;
  font-weight: 500;
}

.Polaris-Badge__Pip {
  color: var(--p-component-badge-pip-color);
  height: .8rem;
  width: .8rem;
  border-radius: 50%;
  border: .2rem solid ;
  flex-shrink: 0;
  margin-left: -.2rem;
  margin-right: .4rem;
}

.Polaris-Badge--progressIncomplete .Polaris-Badge__Pip {
  background: none;
}

.Polaris-Badge--progressPartiallyComplete .Polaris-Badge__Pip {
  background: linear-gradient(to top, currentColor, currentColor 50%, #0000 50%, #0000);
}

@media print {
  .Polaris-Badge--progressPartiallyComplete .Polaris-Badge__Pip {
    box-shadow: inset 0 -10.2rem 0 -10rem;
    background: none;
  }
}

.Polaris-Badge--progressComplete .Polaris-Badge__Pip {
  background: currentColor;
}

@media print {
  .Polaris-Badge--progressComplete .Polaris-Badge__Pip {
    box-shadow: inset 0 0 0 10rem;
    background: none;
  }
}

.Polaris-Badge--withinFilter {
  border-radius: var(--p-border-radius-base);
}

.Polaris-TextStyle--variationPositive {
  color: var(--p-text-success);
}

.Polaris-TextStyle--variationNegative {
  color: var(--p-text-critical);
}

.Polaris-TextStyle--variationWarning {
  color: var(--p-text-warning);
}

.Polaris-TextStyle--variationCode {
  background-color: var(--p-surface-subdued);
  box-shadow: inset 0 0 0 0.1rem var(--p-border-subdued);
  border-radius: .3rem;
  padding: 0 .4rem;
  font-size: 1.15em;
  display: inline-block;
  position: relative;
}

.Polaris-TextStyle--variationCode:after {
  content: "";
  width: 100%;
  height: 100%;
  pointer-events: none;
  border: .1rem solid #0000;
  position: absolute;
  top: 0;
  left: 0;
}

.Polaris-TextStyle--variationStrong {
  font-weight: 600;
}

.Polaris-TextStyle--variationSubdued {
  color: var(--p-text-subdued);
}

@media print {
  .Polaris-TextStyle--variationSubdued {
    color: var(--p-text-subdued);
  }
}

.Polaris-ActionList {
  outline: none;
  margin: 0;
  padding: 0;
  list-style: none;
}

.Polaris-ActionList__Section--withoutTitle:not(:first-child) {
  border-top: 0.1rem solid var(--p-divider);
}

.Polaris-ActionList__Actions {
  border-top: 0.1rem solid var(--p-divider);
  outline: none;
  margin: 0;
  padding: .8rem;
  list-style: none;
}

.Polaris-ActionList > .Polaris-ActionList__Section--withoutTitle .Polaris-ActionList__Actions, .Polaris-ActionList__Section:first-child > .Polaris-ActionList__Section--withoutTitle .Polaris-ActionList__Actions {
  border-top: none;
}

.Polaris-ActionList__Title {
  text-transform: uppercase;
  padding: .4rem 1.6rem 1.2rem;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.6rem;
}

@media (min-width: 40em) {
  .Polaris-ActionList__Title {
    font-size: 1.2rem;
  }
}

.Polaris-ActionList__Title.Polaris-ActionList--firstSectionWithTitle {
  padding-top: 1.2rem;
}

.Polaris-ActionList__Item {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  color: inherit;
  width: 100%;
  min-height: 4rem;
  text-align: left;
  cursor: pointer;
  border-radius: var(--p-border-radius-base);
  background: none;
  border: none;
  border-top: .1rem solid #0000;
  margin: 0;
  padding: 1rem .8rem;
  text-decoration: none;
  display: block;
  position: relative;
}

.Polaris-ActionList__Item:focus {
  outline: none;
}

.Polaris-ActionList__Item:visited {
  color: inherit;
}

.Polaris-ActionList__Item:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

@media (forced-colors: active) {
  .Polaris-ActionList__Item {
    border: .1rem solid #0000;
  }
}

.Polaris-ActionList__Item:hover {
  background-color: var(--p-surface-hovered);
  outline: .3rem solid #0000;
  text-decoration: none;
}

.Polaris-ActionList__Item.Polaris-ActionList--active {
  background-color: var(--p-surface-selected);
}

.Polaris-ActionList__Item.Polaris-ActionList--active svg {
  fill: var(--p-interactive);
}

.Polaris-ActionList__Item.Polaris-ActionList--active:before {
  content: "";
  background-color: var(--p-interactive);
  height: 100%;
  width: .3rem;
  border-top-right-radius: var(--p-border-radius-base);
  border-bottom-right-radius: var(--p-border-radius-base);
  display: block;
  position: absolute;
  top: 0;
  left: -.8rem;
}

.Polaris-ActionList__Item:active {
  background-color: var(--p-surface-pressed);
}

.Polaris-ActionList__Item:active svg {
  fill: var(--p-interactive);
}

.Polaris-ActionList__Item:focus:not(:active) {
  outline: .3rem solid #0000;
}

.Polaris-ActionList__Item:focus:not(:active):after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-ActionList__Item.Polaris-ActionList--destructive {
  color: var(--p-interactive-critical);
}

.Polaris-ActionList__Item.Polaris-ActionList--destructive svg {
  fill: var(--p-icon-critical);
}

.Polaris-ActionList__Item.Polaris-ActionList--destructive:hover {
  background-color: var(--p-surface-critical-subdued-hovered);
}

.Polaris-ActionList__Item.Polaris-ActionList--destructive:active, .Polaris-ActionList__Item.Polaris-ActionList--destructive.Polaris-ActionList--active {
  background-color: var(--p-surface-critical-subdued-pressed);
}

.Polaris-ActionList__Item.Polaris-ActionList--disabled {
  color: var(--p-text-disabled);
  background-image: none;
}

.Polaris-ActionList__Item.Polaris-ActionList--disabled .Polaris-ActionList__Prefix svg, .Polaris-ActionList__Item.Polaris-ActionList--disabled .Polaris-ActionList__Suffix svg {
  fill: var(--p-icon-disabled);
}

.Polaris-ActionList__Content {
  align-items: center;
  display: flex;
}

.Polaris-ActionList__Prefix {
  height: 2rem;
  width: 2rem;
  background-position: center;
  background-size: cover;
  border-radius: .3rem;
  flex: none;
  justify-content: center;
  align-items: center;
  margin: -1rem 1.6rem -1rem 0;
  display: flex;
}

.Polaris-ActionList__Prefix svg {
  fill: var(--p-icon);
}

.Polaris-ActionList__Suffix {
  margin-left: 1.6rem;
}

.Polaris-ActionList__Suffix svg {
  fill: var(--p-icon);
}

.Polaris-ActionList__ContentBlock, .Polaris-ActionList__ContentBlockInner {
  display: block;
}

.Polaris-ActionList__Text {
  min-width: 0;
  max-width: 100%;
  flex: auto;
}

.Polaris-Button {
  min-height: 3.6rem;
  min-width: 3.6rem;
  background: var(--p-surface);
  box-shadow: var(--p-button-drop-shadow);
  border-radius: var(--p-border-radius-base);
  color: var(--p-text);
  border: 0.1rem solid var(--p-border-neutral-subdued);
  border-top-color: var(--p-border-subdued);
  border-bottom-color: var(--p-border-shadow-subdued);
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: .7rem 1.6rem;
  line-height: 1;
  text-decoration: none;
  display: inline-flex;
  position: relative;
}

.Polaris-Button svg {
  fill: var(--p-icon);
}

.Polaris-Button:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.2rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.2rem;
}

.Polaris-Button:hover {
  background: var(--p-action-secondary-hovered);
  outline: .1rem solid #0000;
}

.Polaris-Button:focus {
  box-shadow: var(--p-button-drop-shadow);
  outline: none;
}

.Polaris-Button:focus:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Button:active {
  background: var(--p-action-secondary-pressed);
  box-shadow: var(--p-button-drop-shadow);
}

.Polaris-Button:active:after {
  box-shadow: none;
  border: none;
}

.Polaris-Button.Polaris-Button--pressed {
  background: var(--p-action-secondary-depressed);
  box-shadow: var(--p-button-pressed-inner-shadow);
  color: var(--p-text-on-primary);
  border-color: var(--p-border-depressed);
}

.Polaris-Button.Polaris-Button--pressed svg {
  fill: currentColor;
}

@media (-ms-high-contrast: active) {
  .Polaris-Button {
    border: 0.1rem solid windowText;
  }
}

.Polaris-Button.Polaris-Button--disabled {
  box-shadow: none;
  border-color: var(--p-border-disabled);
  background: var(--p-surface-disabled);
  color: var(--p-text-disabled);
  transition: none;
}

.Polaris-Button.Polaris-Button--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Button.Polaris-Button--connectedDisclosure {
  z-index: 10;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Polaris-Button.Polaris-Button--connectedDisclosure:after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Polaris-Button.Polaris-Button--connectedDisclosure:focus {
  z-index: 20;
}

.Polaris-Button__Content {
  font-size: 1.5rem;
  font-weight: var(--p-button-font-weight);
  text-transform: initial;
  letter-spacing: initial;
  min-width: .1rem;
  min-height: .1rem;
  justify-content: center;
  align-items: center;
  line-height: 1.6rem;
  display: flex;
  position: relative;
}

@media (min-width: 40em) {
  .Polaris-Button__Content {
    font-size: 1.4rem;
  }
}

.Polaris-Button:not(.Polaris-Button--plain) .Polaris-Button__Content {
  width: 100%;
}

.Polaris-Button--textAlignLeft {
  text-align: left;
  justify-content: flex-start;
}

.Polaris-Button--textAlignLeft .Polaris-Button__Content {
  justify-content: flex-start;
}

.Polaris-Button--textAlignCenter {
  text-align: center;
  justify-content: center;
}

.Polaris-Button--textAlignRight {
  text-align: right;
  justify-content: flex-end;
}

.Polaris-Button--textAlignRight .Polaris-Button__Content {
  justify-content: flex-end;
}

.Polaris-Button__Icon {
  margin-left: -.4rem;
}

.Polaris-Button__Icon:last-child {
  margin-left: .4rem;
  margin-right: -.8rem;
}

.Polaris-Button--fullWidth.Polaris-Button--textAlignLeft .Polaris-Button__Icon:last-child:not(:only-child) {
  margin-left: auto;
}

.Polaris-Button__Icon + :not(.Polaris-Button__Icon) {
  margin-left: .4rem;
}

.Polaris-Button--hidden {
  visibility: hidden;
}

.Polaris-Button__Spinner {
  margin-top: -1rem;
  margin-left: -1rem;
  position: absolute;
  top: 50%;
  left: 50%;
}

.Polaris-Button--primary, .Polaris-Button--destructive {
  background: var(--p-button-color);
  box-shadow: var(--p-button-drop-shadow), var(--p-button-inner-shadow);
  color: var(--p-button-text);
  border-width: 0;
  border-color: #0000;
  position: relative;
}

.Polaris-Button--primary:after, .Polaris-Button--destructive:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

.Polaris-Button--primary:hover, .Polaris-Button--destructive:hover {
  background: var(--p-button-color-hover);
  color: var(--p-button-text);
  border-color: #0000;
}

.Polaris-Button--primary:focus, .Polaris-Button--destructive:focus {
  box-shadow: var(--p-button-drop-shadow), var(--p-button-inner-shadow);
  border-color: #0000;
}

.Polaris-Button--primary:active, .Polaris-Button--destructive:active {
  background: var(--p-button-color-active);
  box-shadow: var(--p-button-drop-shadow), var(--p-button-inner-shadow);
  border-color: #0000;
}

.Polaris-Button--primary.Polaris-Button--pressed, .Polaris-Button--destructive.Polaris-Button--pressed {
  color: var(--p-button-text);
  background: var(--p-button-color-depressed);
  box-shadow: var(--p-button-drop-shadow), var(--p-button-inner-shadow);
  border-color: #0000;
}

.Polaris-Button--primary.Polaris-Button--pressed:hover, .Polaris-Button--primary.Polaris-Button--pressed:focus, .Polaris-Button--destructive.Polaris-Button--pressed:hover, .Polaris-Button--destructive.Polaris-Button--pressed:focus {
  background: var(--p-button-color-depressed);
  box-shadow: var(--p-button-drop-shadow), var(--p-button-inner-shadow);
}

.Polaris-Button--primary.Polaris-Button--disabled, .Polaris-Button--destructive.Polaris-Button--disabled {
  color: var(--p-text-disabled);
  box-shadow: none;
  border-color: #0000;
}

.Polaris-Button--primary.Polaris-Button--disabled svg, .Polaris-Button--destructive.Polaris-Button--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Button--primary {
  --p-button-color: var(--p-action-primary);
  --p-button-text: var(--p-text-on-primary);
  --p-button-color-hover: var(--p-action-primary-hovered);
  --p-button-color-active: var(--p-action-primary-pressed);
  --p-button-color-depressed: var(--p-action-primary-depressed);
}

.Polaris-Button--primary svg {
  fill: var(--p-icon-on-primary);
}

.Polaris-Button--primary.Polaris-Button--disabled {
  background: var(--p-action-primary-disabled);
}

.Polaris-Button--destructive {
  --p-button-color: var(--p-action-critical);
  --p-button-text: var(--p-text-on-critical);
  --p-button-color-hover: var(--p-action-critical-hovered);
  --p-button-color-active: var(--p-action-critical-pressed);
  --p-button-color-depressed: var(--p-action-critical-depressed);
}

.Polaris-Button--destructive svg {
  fill: var(--p-icon-on-critical);
}

.Polaris-Button--destructive.Polaris-Button--disabled {
  background: var(--p-action-critical-disabled);
}

.Polaris-Button--outline {
  border: 0.1rem solid var(--p-border);
  box-shadow: none;
  color: var(--p-text);
  background: none;
  position: relative;
}

.Polaris-Button--outline:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.2rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.2rem;
}

.Polaris-Button--outline:hover {
  border: 0.1rem solid var(--p-border);
  box-shadow: none;
  background: var(--p-surface-hovered);
}

.Polaris-Button--outline:focus {
  border: 0.1rem solid var(--p-border);
  box-shadow: none;
}

.Polaris-Button--outline:focus:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Button--outline:active {
  border: 0.1rem solid var(--p-border);
  box-shadow: none;
  background: var(--p-surface-pressed);
}

.Polaris-Button--outline:active:after {
  box-shadow: none;
}

.Polaris-Button--outline.Polaris-Button--pressed {
  background: var(--p-action-secondary-pressed);
  border: 0.1rem solid var(--p-border);
  box-shadow: none;
  color: var(--p-button-text);
}

.Polaris-Button--outline.Polaris-Button--disabled {
  border: 0.1rem solid var(--p-border-disabled);
  box-shadow: none;
  color: var(--p-text-disabled);
  background: none;
}

.Polaris-Button--outline.Polaris-Button--destructive {
  border: 0.1rem solid var(--p-border-critical);
  box-shadow: none;
  color: var(--p-interactive-critical);
  background: none;
}

.Polaris-Button--outline.Polaris-Button--destructive svg {
  fill: var(--p-icon-critical);
}

.Polaris-Button--outline.Polaris-Button--destructive:hover {
  border: 0.1rem solid var(--p-border-critical);
  background: var(--p-surface-critical-subdued);
}

.Polaris-Button--outline.Polaris-Button--destructive:focus {
  border: 0.1rem solid var(--p-border-critical);
}

.Polaris-Button--outline.Polaris-Button--destructive:focus:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Button--outline.Polaris-Button--destructive:active {
  border: 0.1rem solid var(--p-border-critical);
  background: var(--p-surface-critical-subdued);
}

.Polaris-Button--outline.Polaris-Button--destructive.Polaris-Button--disabled {
  border: 0.1rem solid var(--p-border-critical-disabled);
  color: var(--p-interactive-critical-disabled);
  background: none;
}

.Polaris-Button--outline.Polaris-Button--destructive.Polaris-Button--pressed {
  background: var(--p-surface-critical-subdued);
  box-shadow: 0.1rem solid var(--p-border-critical);
  color: var(--p-interactive-critical);
}

.Polaris-Button--outline.Polaris-Button--disabled {
  box-shadow: none;
  background: none;
}

.Polaris-Button--destructive.Polaris-Button--outline {
  border: 0.1rem solid var(--p-border);
  box-shadow: none;
  color: var(--p-text);
  background: none;
  position: relative;
}

.Polaris-Button--destructive.Polaris-Button--outline:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.2rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.2rem;
}

.Polaris-Button--destructive.Polaris-Button--outline:hover {
  border: 0.1rem solid var(--p-border);
  box-shadow: none;
  background: var(--p-surface-hovered);
}

.Polaris-Button--destructive.Polaris-Button--outline:focus {
  border: 0.1rem solid var(--p-border);
  box-shadow: none;
}

.Polaris-Button--destructive.Polaris-Button--outline:focus:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Button--destructive.Polaris-Button--outline:active {
  border: 0.1rem solid var(--p-border);
  box-shadow: none;
  background: var(--p-surface-pressed);
}

.Polaris-Button--destructive.Polaris-Button--outline:active:after {
  box-shadow: none;
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed {
  background: var(--p-action-secondary-pressed);
  border: 0.1rem solid var(--p-border);
  box-shadow: none;
  color: var(--p-button-text);
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--disabled {
  border: 0.1rem solid var(--p-border-disabled);
  box-shadow: none;
  color: var(--p-text-disabled);
  background: none;
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--destructive {
  border: 0.1rem solid var(--p-border-critical);
  box-shadow: none;
  color: var(--p-interactive-critical);
  background: none;
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--destructive svg {
  fill: var(--p-icon-critical);
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--destructive:hover {
  border: 0.1rem solid var(--p-border-critical);
  background: var(--p-surface-critical-subdued);
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--destructive:focus {
  border: 0.1rem solid var(--p-border-critical);
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--destructive:focus:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--destructive:active {
  border: 0.1rem solid var(--p-border-critical);
  background: var(--p-surface-critical-subdued);
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--destructive.Polaris-Button--disabled {
  border: 0.1rem solid var(--p-border-critical-disabled);
  color: var(--p-interactive-critical-disabled);
  background: none;
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--destructive.Polaris-Button--pressed {
  background: var(--p-surface-critical-subdued);
  box-shadow: 0.1rem solid var(--p-border-critical);
  color: var(--p-interactive-critical);
}

.Polaris-Button--disabled {
  cursor: default;
  pointer-events: none;
}

@keyframes Polaris-Button--loading {
  to {
    transform: rotate(360deg);
  }
}

.Polaris-Button--loading {
  position: relative;
}

.Polaris-Button--loading, .Polaris-Button--loading:hover, .Polaris-Button--loading.Polaris-Button--disabled {
  color: #0000;
}

.Polaris-Button--loading.Polaris-Button--disabled svg {
  fill: var(--p-icon-subdued);
}

.Polaris-Button--plain {
  box-shadow: none;
  color: var(--p-interactive);
  background: none;
  border: none;
  margin: -.7rem -.8rem;
  padding-left: .8rem;
  padding-right: .8rem;
}

.Polaris-Button--plain svg {
  fill: var(--p-interactive);
}

.Polaris-Button--plain > .Polaris-Button__Content {
  font-weight: 400;
  position: relative;
}

.Polaris-Button--plain > .Polaris-Button__Content:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

.Polaris-Button--plain:hover, .Polaris-Button--plain:focus {
  color: var(--p-interactive-hovered);
  box-shadow: none;
  background: none;
}

.Polaris-Button--plain:hover svg, .Polaris-Button--plain:focus svg {
  fill: var(--p-interactive-hovered);
}

.Polaris-Button--plain:hover:not(.Polaris-Button--removeUnderline), .Polaris-Button--plain:focus:not(.Polaris-Button--removeUnderline) {
  text-decoration: underline;
}

.Polaris-Button--plain.Polaris-Button--pressed, .Polaris-Button--plain:active {
  color: var(--p-interactive-pressed);
  box-shadow: none;
  background: none;
}

.Polaris-Button--plain.Polaris-Button--pressed svg, .Polaris-Button--plain:active svg {
  fill: var(--p-interactive-pressed);
}

@media (-ms-high-contrast: active) {
  .Polaris-Button--plain.Polaris-Button--pressed, .Polaris-Button--plain:active {
    outline: none;
  }
}

.Polaris-Button--plain:focus:after {
  content: none;
}

@media (-ms-high-contrast: active) {
  .Polaris-Button--plain:focus {
    outline: none;
  }
}

@media (-ms-high-contrast: active) {
  .Polaris-Button--plain:focus > .Polaris-Button__Content {
    outline: .2rem dotted ;
  }
}

.Polaris-Button--plain:focus:not(:active) > .Polaris-Button__Content:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Button--plain:active:not(.Polaris-Button--iconOnly) > .Polaris-Button__Content, .Polaris-Button--plain.Polaris-Button--pressed:not(.Polaris-Button--iconOnly) > .Polaris-Button__Content {
  background: var(--p-action-secondary-hovered);
  border-radius: .3rem;
  margin: -.2rem -.5rem;
  padding: .2rem .5rem;
}

.Polaris-Button--plain.Polaris-Button--fullWidth {
  margin-left: 0;
  margin-right: 0;
}

.Polaris-Button--plain.Polaris-Button--disabled {
  color: var(--p-text-disabled);
  background: none;
}

.Polaris-Button--plain.Polaris-Button--disabled.Polaris-Button--loading {
  color: #0000;
}

.Polaris-Button--plain.Polaris-Button--destructive {
  color: var(--p-interactive-critical);
}

.Polaris-Button--plain.Polaris-Button--destructive svg {
  fill: var(--p-interactive-critical);
}

.Polaris-Button--plain.Polaris-Button--destructive:hover {
  color: var(--p-interactive-critical-hovered);
}

.Polaris-Button--plain.Polaris-Button--destructive:active, .Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--pressed {
  color: var(--p-interactive-critical-pressed);
}

.Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--disabled {
  color: var(--p-interactive-critical-disabled);
}

.Polaris-Button--plain.Polaris-Button--sizeSlim {
  margin-top: -.3rem;
  margin-bottom: -.3rem;
}

.Polaris-Button--plain.Polaris-Button--sizeLarge {
  margin: -1.1rem -2rem;
}

.Polaris-Button--plain.Polaris-Button--iconOnly {
  margin: -.8rem;
}

.Polaris-Button--plain.Polaris-Button--iconOnly svg {
  fill: var(--p-icon);
}

.Polaris-Button--plain.Polaris-Button--iconOnly:hover svg {
  fill: var(--p-icon-hovered);
}

.Polaris-Button--plain.Polaris-Button--iconOnly:active svg, .Polaris-Button--plain.Polaris-Button--iconOnly.Polaris-Button--pressed svg {
  fill: var(--p-icon-pressed);
}

.Polaris-Button--plain.Polaris-Button--iconOnly.Polaris-Button--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--iconOnly svg {
  fill: var(--p-action-critical);
}

.Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--iconOnly:hover svg {
  fill: var(--p-action-critical-hovered);
}

.Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--iconOnly:active svg, .Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--iconOnly.Polaris-Button--pressed svg {
  fill: var(--p-action-critical-pressed);
}

.Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--iconOnly.Polaris-Button--disabled svg {
  fill: var(--p-action-critical-disabled);
}

.Polaris-Button--plain .Polaris-Button__Icon {
  margin-left: 0;
  margin-right: 0;
}

.Polaris-Button--fullWidth {
  width: 100%;
  display: flex;
}

.Polaris-Button--iconOnly {
  padding-left: .8rem;
  padding-right: .8rem;
}

.Polaris-Button--iconOnly.Polaris-Button--sizeLarge {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

.Polaris-Button--iconOnly .Polaris-Button__Icon:first-child {
  margin-left: 0;
}

.Polaris-Button--iconOnly .Polaris-Button__Icon:last-child {
  margin-right: -.4rem;
}

.Polaris-Button--iconOnly .Polaris-Button__Icon:only-child {
  margin-right: 0;
}

.Polaris-Button--sizeSlim {
  min-height: 2.8rem;
  padding: .3rem 1.2rem;
}

.Polaris-Button--sizeLarge {
  min-height: 4.4rem;
  min-width: 4.4rem;
  padding: 1.1rem 2.4rem;
}

.Polaris-Button--sizeLarge .Polaris-Button__Content {
  font-size: 1.7rem;
  font-weight: var(--p-button-font-weight);
  text-transform: initial;
  letter-spacing: initial;
  line-height: 2rem;
}

@media (min-width: 40em) {
  .Polaris-Button--sizeLarge .Polaris-Button__Content {
    font-size: 1.6rem;
  }
}

.Polaris-Button--monochrome.Polaris-Button--outline, .Polaris-Button--monochrome.Polaris-Button--plain {
  color: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline svg, .Polaris-Button--monochrome.Polaris-Button--plain svg {
  fill: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline:hover, .Polaris-Button--monochrome.Polaris-Button--outline:focus, .Polaris-Button--monochrome.Polaris-Button--outline:active, .Polaris-Button--monochrome.Polaris-Button--plain:hover, .Polaris-Button--monochrome.Polaris-Button--plain:focus, .Polaris-Button--monochrome.Polaris-Button--plain:active {
  color: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline:hover svg, .Polaris-Button--monochrome.Polaris-Button--outline:focus svg, .Polaris-Button--monochrome.Polaris-Button--outline:active svg, .Polaris-Button--monochrome.Polaris-Button--plain:hover svg, .Polaris-Button--monochrome.Polaris-Button--plain:focus svg, .Polaris-Button--monochrome.Polaris-Button--plain:active svg {
  fill: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--disabled, .Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--disabled {
  color: currentColor;
  opacity: .4;
}

.Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--disabled svg, .Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--disabled svg, .Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--iconOnly svg, .Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--iconOnly svg, .Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--iconOnly:focus svg, .Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--iconOnly:active svg, .Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--iconOnly:focus svg, .Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--iconOnly:active svg, .Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--iconOnly.Polaris-Button--disabled svg, .Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--iconOnly.Polaris-Button--disabled svg {
  fill: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--plain .Polaris-Button__Text:not(.Polaris-Button--removeUnderline) {
  text-decoration: underline;
}

.Polaris-Button--monochrome.Polaris-Button--outline {
  box-shadow: 0 0 0 .1rem;
  border-color: currentColor;
  position: relative;
}

.Polaris-Button--monochrome.Polaris-Button--outline:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.3rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.3rem;
}

.Polaris-Button--monochrome.Polaris-Button--outline:before {
  content: "";
  opacity: 0;
  z-index: 0;
  background-color: currentColor;
  position: absolute;
  inset: 0;
}

.Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--pressed {
  box-shadow: none;
  color: currentColor;
  background: none;
}

.Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--pressed:before {
  opacity: .2;
}

.Polaris-Button--monochrome.Polaris-Button--outline:hover, .Polaris-Button--monochrome.Polaris-Button--outline:focus, .Polaris-Button--monochrome.Polaris-Button--outline:active {
  background-color: #0000;
  border-color: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline:hover:before, .Polaris-Button--monochrome.Polaris-Button--outline:focus:before, .Polaris-Button--monochrome.Polaris-Button--outline:active:before {
  opacity: .07;
}

.Polaris-Button--monochrome.Polaris-Button--outline:focus {
  box-shadow: 0 0 0 .1rem;
}

.Polaris-Button--monochrome.Polaris-Button--outline:focus:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Button--monochrome.Polaris-Button--outline:active:after {
  box-shadow: none;
}

.Polaris-Button--monochrome.Polaris-Button--outline:hover:before, .Polaris-Button--monochrome.Polaris-Button--outline:active:before {
  opacity: .05;
}

.Polaris-Button__ConnectedDisclosureWrapper {
  display: flex;
}

.Polaris-Button__ConnectedDisclosure {
  z-index: 10;
  height: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -.1rem;
}

.Polaris-Button__ConnectedDisclosure.Polaris-Button--outline:not(:focus) {
  border-left-color: #d2d5d800;
}

.Polaris-Button__ConnectedDisclosure:focus, .Polaris-Button__ConnectedDisclosure:active {
  z-index: 20;
}

.Polaris-Button__ConnectedDisclosure.Polaris-Button--primary, .Polaris-Button__ConnectedDisclosure.Polaris-Button--destructive {
  margin-left: .1rem;
}

.Polaris-Button__ConnectedDisclosure.Polaris-Button--primary.Polaris-Button--outline, .Polaris-Button__ConnectedDisclosure.Polaris-Button--destructive.Polaris-Button--outline {
  border-left: none;
  margin-left: 0;
}

.Polaris-Button__ConnectedDisclosure:after {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

[data-buttongroup-segmented="true"] .Polaris-Button, [data-buttongroup-segmented="true"] .Polaris-Button:after {
  border-radius: 0;
}

[data-buttongroup-segmented="true"] > :first-child .Polaris-Button, [data-buttongroup-segmented="true"] > :first-child .Polaris-Button:after {
  border-radius: 0;
  border-top-left-radius: var(--p-border-radius-base);
  border-bottom-left-radius: var(--p-border-radius-base);
}

[data-buttongroup-segmented="true"] > :last-child .Polaris-Button, [data-buttongroup-segmented="true"] > :last-child .Polaris-Button:after {
  border-radius: 0;
  border-top-right-radius: var(--p-border-radius-base);
  border-bottom-right-radius: var(--p-border-radius-base);
}

[data-buttongroup-segmented="true"] > :last-child:first-child .Polaris-Button, [data-buttongroup-segmented="true"] > :last-child:first-child .Polaris-Button:after {
  border-radius: var(--p-border-radius-base);
}

[data-buttongroup-connected-top="true"] > :first-child .Polaris-Button, [data-buttongroup-connected-top="true"] > :first-child .Polaris-Button:after {
  border-top-left-radius: 0;
}

[data-buttongroup-connected-top="true"] > :last-child .Polaris-Button, [data-buttongroup-connected-top="true"] > :last-child .Polaris-Button:after {
  border-top-right-radius: 0;
}

[data-buttongroup-full-width="true"] .Polaris-Button {
  width: 100%;
  display: flex;
}

.Polaris-ButtonGroup {
  flex-wrap: wrap;
  align-items: center;
  margin-top: -.8rem;
  margin-left: -.8rem;
  display: flex;
}

.Polaris-ButtonGroup__Item {
  margin-top: .8rem;
  margin-left: .8rem;
}

.Polaris-ButtonGroup__Item--plain:not(:first-child) {
  margin-left: 1.6rem;
}

.Polaris-ButtonGroup__Item--plain:not(:last-child) {
  margin-right: .8rem;
}

.Polaris-ButtonGroup--segmented {
  flex-wrap: nowrap;
  margin-top: 0;
  margin-left: 0;
  display: flex;
}

.Polaris-ButtonGroup--segmented .Polaris-ButtonGroup__Item {
  z-index: 10;
  margin-top: 0;
  margin-left: 0;
  position: relative;
}

.Polaris-ButtonGroup--segmented .Polaris-ButtonGroup__Item:not(:first-child) {
  margin-left: -.1rem;
}

.Polaris-ButtonGroup--segmented .Polaris-ButtonGroup__Item--focused {
  z-index: 20;
}

.Polaris-ButtonGroup--fullWidth .Polaris-ButtonGroup__Item {
  flex: auto;
}

.Polaris-ButtonGroup--extraTight {
  margin-top: -.4rem;
  margin-left: -.4rem;
}

.Polaris-ButtonGroup--extraTight .Polaris-ButtonGroup__Item {
  margin-top: .4rem;
  margin-left: .4rem;
}

.Polaris-ButtonGroup--tight {
  margin-top: -.8rem;
  margin-left: -.8rem;
}

.Polaris-ButtonGroup--tight .Polaris-ButtonGroup__Item {
  margin-top: .8rem;
  margin-left: .8rem;
}

.Polaris-ButtonGroup--loose {
  margin-top: -2rem;
  margin-left: -2rem;
}

.Polaris-ButtonGroup--loose .Polaris-ButtonGroup__Item {
  margin-top: 2rem;
  margin-left: 2rem;
}

.Polaris-Stack {
  flex-wrap: wrap;
  align-items: stretch;
  margin-top: -1.6rem;
  margin-left: -1.6rem;
  display: flex;
}

.Polaris-Stack > .Polaris-Stack__Item {
  max-width: 100%;
  margin-top: 1.6rem;
  margin-left: 1.6rem;
}

.Polaris-Stack--noWrap {
  flex-wrap: nowrap;
}

.Polaris-Stack--spacingNone {
  margin-top: 0;
  margin-left: 0;
}

.Polaris-Stack--spacingNone > .Polaris-Stack__Item {
  max-width: 100%;
  margin-top: 0;
  margin-left: 0;
}

.Polaris-Stack--spacingExtraTight {
  margin-top: -.4rem;
  margin-left: -.4rem;
}

.Polaris-Stack--spacingExtraTight > .Polaris-Stack__Item {
  max-width: 100%;
  margin-top: .4rem;
  margin-left: .4rem;
}

.Polaris-Stack--spacingTight {
  margin-top: -.8rem;
  margin-left: -.8rem;
}

.Polaris-Stack--spacingTight > .Polaris-Stack__Item {
  max-width: 100%;
  margin-top: .8rem;
  margin-left: .8rem;
}

.Polaris-Stack--spacingBaseTight {
  margin-top: -1.2rem;
  margin-left: -1.2rem;
}

.Polaris-Stack--spacingBaseTight > .Polaris-Stack__Item {
  max-width: 100%;
  margin-top: 1.2rem;
  margin-left: 1.2rem;
}

.Polaris-Stack--spacingLoose {
  margin-top: -2rem;
  margin-left: -2rem;
}

.Polaris-Stack--spacingLoose > .Polaris-Stack__Item {
  max-width: 100%;
  margin-top: 2rem;
  margin-left: 2rem;
}

.Polaris-Stack--spacingExtraLoose {
  margin-top: -3.2rem;
  margin-left: -3.2rem;
}

.Polaris-Stack--spacingExtraLoose > .Polaris-Stack__Item {
  max-width: 100%;
  margin-top: 3.2rem;
  margin-left: 3.2rem;
}

.Polaris-Stack--distributionLeading {
  justify-content: flex-start;
}

.Polaris-Stack--distributionTrailing {
  justify-content: flex-end;
}

.Polaris-Stack--distributionCenter {
  justify-content: center;
}

.Polaris-Stack--distributionEqualSpacing {
  justify-content: space-between;
}

.Polaris-Stack--distributionFill > .Polaris-Stack__Item, .Polaris-Stack--distributionFillEvenly > .Polaris-Stack__Item {
  flex: auto;
}

@supports ((min-width: -webkit-fit-content) or (min-width: -moz-fit-content) or (min-width: fit-content)) {
  .Polaris-Stack--distributionFillEvenly > .Polaris-Stack__Item {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    flex: 1 0;
  }
}

.Polaris-Stack--alignmentLeading {
  align-items: flex-start;
}

.Polaris-Stack--alignmentTrailing {
  align-items: flex-end;
}

.Polaris-Stack--alignmentCenter {
  align-items: center;
}

.Polaris-Stack--alignmentFill {
  align-items: stretch;
}

.Polaris-Stack--alignmentBaseline {
  align-items: baseline;
}

.Polaris-Stack--vertical {
  flex-direction: column;
  margin-left: 0;
}

.Polaris-Stack--vertical > .Polaris-Stack__Item {
  margin-left: 0;
}

.Polaris-Stack__Item {
  min-width: 0;
  flex: none;
}

.Polaris-Stack__Item--fill {
  flex: auto;
}

.Polaris-Heading {
  margin: 0;
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 2.4rem;
}

@media (min-width: 40em) {
  .Polaris-Heading {
    font-size: 1.6rem;
  }
}

@media print {
  .Polaris-Heading {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
}

.Polaris-Card {
  background-color: var(--p-surface);
  box-shadow: var(--p-card-shadow);
  outline: .1rem solid #0000;
}

.Polaris-Card + .Polaris-Card {
  margin-top: 1.6rem;
}

@media print {
  .Polaris-Card + .Polaris-Card {
    margin-top: -.8rem;
  }
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Card {
    border-radius: var(--p-border-radius-wide);
  }
}

@media (min-width: 30.625em) {
  .Polaris-Card {
    border-radius: var(--p-border-radius-wide);
  }
}

@media print {
  .Polaris-Card {
    box-shadow: none;
  }
}

.Polaris-Card--subdued {
  background-color: var(--p-surface-subdued);
}

@media print {
  .Polaris-Card__Section--hideOnPrint, .Polaris-Card--hideOnPrint {
    display: none !important;
  }
}

.Polaris-Card__Header {
  padding: 1.6rem 1.6rem 0;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Card__Header {
    padding: 2rem 2rem 0;
  }
}

@media (min-width: 30.625em) {
  .Polaris-Card__Header {
    padding: 2rem 2rem 0;
  }
}

@media print and (max-width: 48.0625em) and (min-width: 30.625em), print and (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Card__Header {
    padding: .8rem 1.6rem 0;
  }
}

@media print and (min-width: 30.625em) {
  .Polaris-Card__Header {
    padding: .8rem 1.6rem 0;
  }
}

.Polaris-Card__Section {
  padding: 1.6rem;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Card__Section {
    padding: 2rem;
  }
}

@media (min-width: 30.625em) {
  .Polaris-Card__Section {
    padding: 2rem;
  }
}

.Polaris-Card__Section + .Polaris-Card__Section {
  border-top: 0.1rem solid var(--p-divider);
}

@media print {
  .Polaris-Card__Section + .Polaris-Card__Section {
    border-top: none;
  }
}

@media print {
  .Polaris-Card__Section {
    padding-top: .4rem;
    padding-bottom: .4rem;
  }
}

.Polaris-Card__Section:first-child {
  border-top-left-radius: var(--p-border-radius-wide);
  border-top-right-radius: var(--p-border-radius-wide);
}

.Polaris-Card__Section:last-child {
  border-bottom-left-radius: var(--p-border-radius-wide);
  border-bottom-right-radius: var(--p-border-radius-wide);
}

.Polaris-Card__Section--fullWidth {
  padding: 1.6rem 0;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Card__Section--fullWidth {
    padding: 2rem 0;
  }
}

@media (min-width: 30.625em) {
  .Polaris-Card__Section--fullWidth {
    padding: 2rem 0;
  }
}

.Polaris-Card__Section--flush {
  padding: 0;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Card__Section--flush {
    padding: 0;
  }
}

@media (min-width: 30.625em) {
  .Polaris-Card__Section--flush {
    padding: 0;
  }
}

.Polaris-Card__Section--subdued {
  background-color: var(--p-surface-subdued);
}

@media (-ms-high-contrast: active) {
  .Polaris-Card__Section--subdued {
    background-color: #0000;
  }
}

.Polaris-Card__Header + .Polaris-Card__Section--subdued {
  border-top: 0.1rem solid var(--p-divider);
  margin-top: 2rem;
}

.Polaris-Card__SectionHeader {
  padding-bottom: .8rem;
}

.Polaris-Card__Section--fullWidth .Polaris-Card__SectionHeader {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Card__Section--fullWidth .Polaris-Card__SectionHeader {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 30.625em) {
  .Polaris-Card__Section--fullWidth .Polaris-Card__SectionHeader {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.Polaris-Card__Subsection + .Polaris-Card__Subsection {
  border-top: 0.1rem solid var(--p-divider);
  margin-top: 1.6rem;
  padding-top: 1.6rem;
}

@media print {
  .Polaris-Card__Subsection + .Polaris-Card__Subsection {
    border-top: none;
  }
}

@media print {
  .Polaris-Card__Subsection {
    padding-top: .4rem;
    padding-bottom: .4rem;
  }
}

.Polaris-Card__Footer {
  justify-content: flex-end;
  padding: 0 1.6rem 1.6rem;
  display: flex;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Card__Footer {
    padding: 0 2rem 2rem;
  }
}

@media (min-width: 30.625em) {
  .Polaris-Card__Footer {
    padding: 0 2rem 2rem;
  }
}

.Polaris-Card__Footer.Polaris-Card__LeftJustified {
  justify-content: flex-start;
}

.Polaris-Card__Section--subdued + .Polaris-Card__Footer {
  border-top: 0.1rem solid var(--p-divider);
  padding: 2rem;
}

.Polaris-Subheading {
  text-transform: uppercase;
  margin: 0;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.6rem;
}

@media (min-width: 40em) {
  .Polaris-Subheading {
    font-size: 1.2rem;
  }
}

@media print {
  .Polaris-Subheading {
    font-size: 1.1rem;
  }
}

.Polaris-SettingAction {
  flex-wrap: wrap;
  align-items: center;
  margin-top: -1.6rem;
  margin-left: -1.6rem;
  display: flex;
}

.Polaris-SettingAction__Setting, .Polaris-SettingAction__Action {
  max-width: calc(100% - 1.6rem);
  min-width: 0;
  flex: none;
  margin-top: 1.6rem;
  margin-left: 1.6rem;
}

.Polaris-SettingAction__Setting {
  flex: 1 0 35rem;
}

.Polaris-AccountConnection__TermsOfService {
  margin-top: 2rem;
}

.Polaris-AccountConnection__Content > * + * {
  margin-top: .8rem;
}

.Polaris-ActionMenu-SecondaryAction a, .Polaris-ActionMenu-SecondaryAction button {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  position: relative;
  box-shadow: none !important;
  border-radius: var(--p-border-radius-base) !important;
  background: none !important;
}

.Polaris-ActionMenu-SecondaryAction a:after, .Polaris-ActionMenu-SecondaryAction button:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.2rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.2rem;
}

.Polaris-ActionMenu-SecondaryAction a:hover, .Polaris-ActionMenu-SecondaryAction button:hover {
  background: var(--p-background-hovered) !important;
}

.Polaris-ActionMenu-SecondaryAction a:active, .Polaris-ActionMenu-SecondaryAction button:active {
  background: var(--p-background-pressed) !important;
}

@media (min-width: 48em) {
  .Polaris-ActionMenu-SecondaryAction a, .Polaris-ActionMenu-SecondaryAction button {
    position: relative;
    border: none !important;
  }

  .Polaris-ActionMenu-SecondaryAction a:after, .Polaris-ActionMenu-SecondaryAction button:after {
    content: "";
    z-index: 1;
    pointer-events: none;
    box-shadow: 0 0 0 -0.1rem var(--p-focused);
    transition: box-shadow 100ms var(--p-ease);
    border-radius: calc(var(--p-border-radius-base) + 0.1rem);
    display: block;
    position: absolute;
    inset: -.1rem;
  }
}

.Polaris-ActionMenu-MenuGroup__Details {
  margin-top: -1.6rem;
  padding: 1.6rem;
}

.Polaris-ActionMenu-Actions__ActionsLayout {
  flex-wrap: wrap;
  flex: auto;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.Polaris-ActionMenu-Actions__ActionsLayout > * {
  flex: none;
}

.Polaris-ActionMenu-RollupActions__RollupActivator {
  text-align: right;
}

.Polaris-ActionMenu-RollupActions__RollupActivator button[type="button"] {
  border: 0.1rem solid var(--p-border-neutral-subdued);
  margin: 0;
}

.Polaris-ActionMenu-RollupActions__RollupActivator button[type="button"]:hover, .Polaris-ActionMenu-RollupActions__RollupActivator button[type="button"]:active, .Polaris-ActionMenu-RollupActions__RollupActivator button[type="button"]:focus {
  border: 0.1rem solid var(--p-border-neutral-subdued);
}

.Polaris-ActionMenu {
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

@media print {
  .Polaris-ActionMenu {
    display: none !important;
  }
}

.Polaris-Combobox__Listbox {
  padding: .8rem 0;
  overflow: visible;
}

.Polaris-Label {
  -webkit-tap-highlight-color: transparent;
}

.Polaris-Label--hidden {
  width: .1rem !important;
  height: .1rem !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.Polaris-Label__Text {
  text-transform: initial;
  letter-spacing: initial;
  color: currentColor;
  -webkit-tap-highlight-color: transparent;
  flex: auto;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  display: block;
}

@media (min-width: 40em) {
  .Polaris-Label__Text {
    font-size: 1.4rem;
  }
}

.Polaris-Label__RequiredIndicator:after {
  content: "*";
  color: var(--p-text-critical);
  margin-left: .4rem;
}

.Polaris-InlineError {
  color: var(--p-text-critical);
  fill: var(--p-icon-critical);
  display: flex;
}

.Polaris-InlineError__Icon {
  fill: currentColor;
  margin-left: -.2rem;
  margin-right: .6rem;
}

.Polaris-Labelled--hidden > .Polaris-Labelled__LabelWrapper {
  width: .1rem !important;
  height: .1rem !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.Polaris-Labelled__LabelWrapper {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: .4rem;
  display: flex;
}

.Polaris-Labelled__HelpText {
  text-transform: initial;
  letter-spacing: initial;
  color: var(--p-text-subdued);
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-top: .4rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
}

@media (min-width: 40em) {
  .Polaris-Labelled__HelpText {
    font-size: 1.4rem;
  }
}

.Polaris-Labelled__Error {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-top: .4rem;
}

.Polaris-Labelled__Action {
  flex: none;
}

.Polaris-Connected {
  display: flex;
  position: relative;
}

.Polaris-Connected__Item {
  z-index: 10;
  flex: none;
  position: relative;
}

.Polaris-Connected__Item:not(:first-child) {
  margin-left: .4rem;
}

.Polaris-Connected__Item--primary {
  z-index: 20;
  flex: auto;
}

.Polaris-Connected__Item--focused {
  z-index: 30;
}

.Polaris-TextField {
  text-transform: initial;
  letter-spacing: initial;
  color: var(--p-text);
  cursor: text;
  border: none;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  display: flex;
  position: relative;
}

@media (min-width: 40em) {
  .Polaris-TextField {
    font-size: 1.4rem;
  }
}

.Polaris-TextField svg {
  fill: var(--p-icon);
}

.Polaris-TextField--multiline {
  flex-wrap: wrap;
  padding: 0;
}

.Polaris-TextField--multiline > .Polaris-TextField__Input {
  resize: none;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  overflow: auto;
}

.Polaris-TextField--hasValue {
  color: var(--p-text);
}

.Polaris-TextField--focus > .Polaris-TextField__Input, .Polaris-TextField__Input:focus {
  outline: none;
}

.Polaris-TextField--focus > .Polaris-TextField__Input ~ .Polaris-TextField__Backdrop:after, .Polaris-TextField__Input:focus ~ .Polaris-TextField__Backdrop:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-TextField--error .Polaris-TextField__Input::-ms-input-placeholder {
  color: #9c9798;
}

.Polaris-TextField--error .Polaris-TextField__Input::placeholder {
  color: #9c9798;
}

.Polaris-TextField--error > .Polaris-TextField__Input ~ .Polaris-TextField__Backdrop {
  background-color: var(--p-surface-critical-subdued);
  border-color: var(--p-border-critical);
}

.Polaris-TextField--error > .Polaris-TextField__Input ~ .Polaris-TextField__Backdrop:after {
  border-color: var(--p-focused);
}

.Polaris-TextField--readOnly > .Polaris-TextField__Backdrop {
  background-color: var(--p-action-secondary-disabled);
}

.Polaris-TextField--disabled {
  color: var(--p-text-disabled);
  cursor: initial;
}

.Polaris-TextField--disabled > .Polaris-TextField__Backdrop {
  background-color: var(--p-surface-disabled);
  border-top-color: var(--p-border-disabled);
}

.Polaris-TextField--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-TextField__Input {
  text-transform: initial;
  letter-spacing: initial;
  z-index: 20;
  width: 100%;
  min-width: 0;
  min-height: 3.6rem;
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  font-size: inherit;
  font-weight: inherit;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  caret-color: var(--p-text);
  color: var(--p-text);
  background: none;
  border: .1rem solid #0000;
  flex: 1;
  margin: 0;
  padding: .5rem 1.2rem;
  display: block;
  position: relative;
}

@media (min-width: 40em) {
  .Polaris-TextField__Input {
    font-size: 1.4rem;
  }
}

.Polaris-TextField__Prefix + .Polaris-TextField__Input {
  padding-left: 0;
}

.Polaris-TextField__Input:disabled {
  opacity: 1;
  color: var(--p-text-disabled);
  -webkit-text-fill-color: var(--p-text-disabled);
  background: none;
}

.Polaris-TextField__Input:invalid {
  box-shadow: none;
}

.Polaris-TextField__Input::-ms-input-placeholder {
  color: var(--p-text-subdued);
}

.Polaris-TextField__Input::placeholder {
  color: var(--p-text-subdued);
}

.Polaris-TextField__Input[type="number"] {
  -webkit-appearance: textfield;
  -ms-appearance: textfield;
  appearance: textfield;
}

.Polaris-TextField__Input[type="number"]::-webkit-outer-spin-button, .Polaris-TextField__Input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  margin: 0;
}

.Polaris-TextField__Input:-webkit-autofill {
  border-radius: var(--p-border-radius-base);
}

.Polaris-TextField__Input--hasClearButton[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

.Polaris-TextField__Input--suffixed {
  padding-right: 0;
}

.Polaris-TextField__Input--alignRight {
  text-align: right;
}

.Polaris-TextField__Input--alignLeft {
  text-align: left;
}

.Polaris-TextField__Input--alignCenter {
  text-align: center;
}

.Polaris-TextField__Backdrop {
  z-index: 10;
  background-color: var(--p-surface);
  border: 0.1rem solid var(--p-border-subdued);
  border-top-color: var(--p-border-shadow);
  border-radius: var(--p-border-radius-base);
  pointer-events: none;
  position: absolute;
  inset: 0;
}

.Polaris-TextField__Backdrop:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.2rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.2rem;
}

.Polaris-TextField__Prefix, .Polaris-TextField__Suffix {
  z-index: 20;
  color: var(--p-text-subdued);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex: none;
  position: relative;
}

.Polaris-TextField__Prefix {
  margin-left: 1.2rem;
  margin-right: .8rem;
}

.Polaris-TextField__Suffix {
  margin-left: .25em;
  margin-right: 1.2rem;
}

.Polaris-TextField__CharacterCount {
  color: var(--p-text-subdued);
  z-index: 20;
  pointer-events: none;
  text-align: right;
  margin: 0 1.2rem 0 .25em;
}

.Polaris-TextField__AlignFieldBottom {
  width: 100%;
  align-self: flex-end;
  padding-bottom: .8rem;
}

.Polaris-TextField__ClearButton {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  z-index: 20;
  background: none;
  border: none;
  margin: 0 1.2rem 0 .25em;
  padding: 0;
  position: relative;
}

.Polaris-TextField__ClearButton:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

.Polaris-TextField__ClearButton:focus {
  outline: none;
}

.Polaris-TextField__ClearButton:focus:enabled:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-TextField__ClearButton:disabled {
  cursor: default;
}

.Polaris-TextField__Hidden {
  visibility: hidden;
}

.Polaris-TextField__Spinner {
  --p-text-field-spinner-offset-large: calc(var(--p-text-field-spinner-offset) + 0.1rem);
  z-index: 20;
  margin: var(--p-text-field-spinner-offset-large);
  color: var(--p-icon);
  width: 2.2rem;
  cursor: pointer;
  flex-direction: column;
  align-self: stretch;
  display: flex;
}

.Polaris-TextField__SpinnerIcon {
  height: 1.2rem;
  width: 1.2rem;
}

.Polaris-TextField__Resizer {
  height: 0;
  visibility: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.Polaris-TextField__DummyInput {
  text-transform: initial;
  letter-spacing: initial;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  border: none;
  padding: .5rem 1.2rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
}

@media (min-width: 40em) {
  .Polaris-TextField__DummyInput {
    font-size: 1.4rem;
  }
}

.Polaris-TextField__Segment {
  --p-text-field-spinner-border-radius: calc(var(--p-border-radius-base) - var(--p-text-field-spinner-offset));
  background: var(--p-surface-neutral);
  border-radius: var(--p-text-field-spinner-border-radius);
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  border: none;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Polaris-TextField__Segment:focus {
  outline: none;
}

.Polaris-TextField__Segment:active {
  background: var(--p-surface-neutral-pressed);
}

.Polaris-TextField__Segment:first-child {
  border-top-right-radius: var(--p-text-field-spinner-border-radius);
  margin-bottom: var(--p-text-field-spinner-offset);
}

.Polaris-TextField__Segment:last-child {
  border-bottom-right-radius: var(--p-text-field-spinner-border-radius);
}

.Polaris-TextField__Segment:not(:first-child) {
  margin-top: 0;
}

.Polaris-TextField--monospaced {
  font-family: ui-monospace, SFMono-Regular, SF Mono, Consolas, Liberation Mono, Menlo, monospace;
}

.Polaris-Listbox-Section__SectionGroup {
  border-bottom: 0.1rem solid var(--p-divider);
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.Polaris-Listbox-Section--noDivider {
  border-bottom: none;
}

.Polaris-Choice {
  cursor: pointer;
  justify-content: flex-start;
  padding: .4rem 0;
  display: inline-flex;
}

.Polaris-Choice--labelHidden {
  padding: 0;
}

.Polaris-Choice--labelHidden > .Polaris-Choice__Label {
  width: .1rem !important;
  height: .1rem !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.Polaris-Choice--labelHidden .Polaris-Choice__Control {
  margin-top: 0;
  margin-right: 0;
}

.Polaris-Choice--disabled {
  cursor: default;
}

.Polaris-Choice--disabled > .Polaris-Choice__Label {
  color: var(--p-text-disabled);
}

.Polaris-Choice--disabled > .Polaris-Choice__Label:hover {
  cursor: default;
}

@media (-ms-high-contrast: active) {
  .Polaris-Choice--disabled > .Polaris-Choice__Label {
    color: grayText;
  }
}

.Polaris-Choice__Control {
  width: var(--p-choice-size);
  height: var(--p-choice-size);
  flex: none;
  align-items: stretch;
  margin-right: .8rem;
  display: flex;
}

.Polaris-Choice__Control > * {
  width: 100%;
}

.Polaris-Choice__Label {
  text-transform: initial;
  letter-spacing: initial;
  -webkit-tap-highlight-color: transparent;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
}

@media (min-width: 40em) {
  .Polaris-Choice__Label {
    font-size: 1.4rem;
  }
}

.Polaris-Choice__Label:hover {
  cursor: pointer;
}

.Polaris-Choice__Descriptions {
  padding-left: calc(0.8rem + var(--p-choice-size, 1.6rem));
}

.Polaris-Choice__HelpText {
  text-transform: initial;
  letter-spacing: initial;
  color: var(--p-text-subdued);
  margin-bottom: .4rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
}

@media (min-width: 40em) {
  .Polaris-Choice__HelpText {
    font-size: 1.4rem;
  }
}

.Polaris-Checkbox {
  margin: var(--p-choice-margin);
  position: relative;
}

.Polaris-Checkbox__Input {
  width: .1rem !important;
  height: .1rem !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.Polaris-Checkbox__Input.Polaris-Checkbox--keyFocused + .Polaris-Checkbox__Backdrop:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Checkbox__Input:active:not(:disabled) + .Polaris-Checkbox__Backdrop, .Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop, .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate + .Polaris-Checkbox__Backdrop {
  border-color: var(--p-interactive);
}

.Polaris-Checkbox__Input:active:not(:disabled) + .Polaris-Checkbox__Backdrop:before, .Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop:before, .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate + .Polaris-Checkbox__Backdrop:before {
  opacity: 1;
  transform: scale(1);
}

@media (-ms-high-contrast: active) {
  .Polaris-Checkbox__Input:active:not(:disabled) + .Polaris-Checkbox__Backdrop:before, .Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop:before, .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate + .Polaris-Checkbox__Backdrop:before {
    border: 0.2rem solid windowText;
  }
}

.Polaris-Checkbox__Input:active:not(:disabled) ~ .Polaris-Checkbox__Icon, .Polaris-Checkbox__Input:checked ~ .Polaris-Checkbox__Icon, .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate ~ .Polaris-Checkbox__Icon {
  transition: opacity var(--p-duration-1-5-0) var(--p-ease), transform var(--p-duration-1-5-0) var(--p-ease);
  opacity: 1;
  transform: translate(-50%, -50%)scale(1);
}

.Polaris-Checkbox__Input:disabled + .Polaris-Checkbox__Backdrop {
  border-color: var(--p-border-disabled);
}

.Polaris-Checkbox__Input:disabled + .Polaris-Checkbox__Backdrop:before {
  background-color: var(--p-action-secondary-disabled);
}

.Polaris-Checkbox__Input:disabled + .Polaris-Checkbox__Backdrop:hover {
  cursor: default;
}

.Polaris-Checkbox__Input:disabled:checked + .Polaris-Checkbox__Backdrop, .Polaris-Checkbox__Input:disabled:checked + .Polaris-Checkbox__Backdrop:before {
  background: var(--p-border-disabled);
}

.Polaris-Checkbox__Backdrop {
  border: var(--p-control-border-width) solid var(--p-border);
  background-color: var(--p-surface);
  border-radius: var(--p-border-radius-base);
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.Polaris-Checkbox__Backdrop:before {
  content: "";
  top: calc(var(--p-control-border-width) * -1);
  right: calc(var(--p-control-border-width) * -1);
  bottom: calc(var(--p-control-border-width) * -1);
  left: calc(var(--p-control-border-width) * -1);
  border-radius: var(--p-border-radius-base);
  background-color: var(--p-interactive);
  opacity: 0;
  transition: opacity 100ms var(--p-ease), transform 100ms var(--p-ease);
  position: absolute;
  transform: scale(.25);
}

.Polaris-Checkbox__Backdrop.Polaris-Checkbox--hover, .Polaris-Checkbox__Backdrop:hover {
  cursor: pointer;
  border-color: var(--p-border-hovered);
}

.Polaris-Checkbox__Backdrop:after {
  content: "";
  z-index: 1;
  top: calc(var(--p-control-border-width) * -1 + -0.1rem);
  right: calc(var(--p-control-border-width) * -1 + -0.1rem);
  bottom: calc(var(--p-control-border-width) * -1 + -0.1rem);
  left: calc(var(--p-control-border-width) * -1 + -0.1rem);
  pointer-events: none;
  box-shadow: 0 0 0 calc(var(--p-control-border-width) * -1 + -0.1rem) var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
}

.Polaris-Checkbox__Icon {
  transform-origin: 50%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 100ms var(--p-ease), transform 100ms var(--p-ease);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(.25);
}

.Polaris-Checkbox__Icon svg {
  fill: var(--p-icon-on-interactive);
}

@media (-ms-high-contrast: active) {
  .Polaris-Checkbox__Icon {
    fill: windowText;
  }
}

.Polaris-Checkbox--error .Polaris-Checkbox__Icon svg {
  fill: var(--p-icon-on-critical);
}

.Polaris-Checkbox--error .Polaris-Checkbox__Backdrop {
  border-color: var(--p-border-critical);
  background-color: var(--p-surface-critical);
}

.Polaris-Checkbox--error .Polaris-Checkbox__Backdrop.Polaris-Checkbox--hover, .Polaris-Checkbox--error .Polaris-Checkbox__Backdrop:hover {
  border-color: var(--p-border-critical);
}

.Polaris-Checkbox--error .Polaris-Checkbox__Backdrop:before, .Polaris-Checkbox--error .Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop:before, .Polaris-Checkbox--error .Polaris-Checkbox__Input:active + .Polaris-Checkbox__Backdrop:before, .Polaris-Checkbox--error .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate + .Polaris-Checkbox__Backdrop:before {
  background-color: var(--p-border-critical);
}

.Polaris-Listbox-TextOption {
  border-radius: var(--p-border-radius-base);
  cursor: pointer;
  flex: 1;
  margin: .4rem .8rem 0;
  padding: .8rem;
  display: flex;
  position: relative;
}

.Polaris-Listbox-TextOption:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

.Polaris-Listbox-TextOption.Polaris-Listbox-TextOption--allowMultiple {
  margin: .4rem .8rem 0;
  padding: .4rem .8rem;
}

.Polaris-Listbox-TextOption.Polaris-Listbox-TextOption--isAction {
  margin-top: 0;
  padding: .8rem;
}

.Polaris-Listbox-TextOption:hover {
  background-color: var(--p-surface-hovered);
}

.Polaris-Listbox-TextOption:focus {
  outline: none;
}

.Polaris-Listbox-TextOption:active {
  background-color: var(--p-surface-pressed);
}

.Polaris-Listbox-TextOption.Polaris-Listbox-TextOption--disabled {
  background-color: var(--p-surface-disabled);
  color: var(--p-text-disabled);
  cursor: default;
}

.Polaris-Listbox-TextOption.Polaris-Listbox-TextOption--selected {
  background-color: var(--p-surface-selected);
}

.Polaris-Listbox-TextOption.Polaris-Listbox-TextOption--selected:before {
  content: "";
  height: 100%;
  width: var(--p-border-radius-base);
  background-color: var(--p-interactive);
  border-top-right-radius: var(--p-border-radius-base);
  border-bottom-right-radius: var(--p-border-radius-base);
  position: absolute;
  top: 0;
  bottom: 0;
  left: -.4rem;
  transform: translateX(-100%);
}

li:first-of-type > .Polaris-Listbox-TextOption {
  margin-top: 0;
}

[data-focused] .Polaris-Listbox-TextOption {
  background-color: var(--p-surface-selected);
  outline: none;
}

[data-focused]:not(:focus) .Polaris-Listbox-TextOption:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Listbox-TextOption__Content {
  flex: auto;
  display: flex;
}

.Polaris-Listbox-TextOption__Checkbox {
  pointer-events: none;
}

.Polaris-Listbox-Option {
  margin: 0;
  padding: 0;
  display: flex;
}

.Polaris-Listbox-Option:focus {
  outline: none;
}

.Polaris-Listbox-Option--divider {
  border-bottom: 0.1rem solid var(--p-divider);
}

.Polaris-Listbox-Loading__ListItem {
  margin: 0;
  padding: 0;
}

.Polaris-Listbox-Loading {
  place-items: center;
  padding: .8rem 1.6rem;
  display: grid;
}

.Polaris-Listbox-Header {
  text-transform: uppercase;
  color: var(--p-text-subdued);
  padding: .8rem 1.6rem;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.6rem;
}

@media (min-width: 40em) {
  .Polaris-Listbox-Header {
    font-size: 1.2rem;
  }
}

.Polaris-Listbox-Action {
  flex: 1;
  display: flex;
}

.Polaris-Listbox-Action__ActionDivider {
  margin-bottom: .4rem;
}

.Polaris-Listbox-Action__Icon {
  padding-right: .8rem;
}

.Polaris-Listbox {
  max-width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.Polaris-Listbox:focus {
  outline: none;
}

.Polaris-Autocomplete-MappedOption__Content {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  flex: 1;
  display: flex;
}

.Polaris-Autocomplete-MappedOption__Media {
  padding: 0 .8rem;
}

.Polaris-Autocomplete-MappedOption__Media svg {
  fill: var(--p-icon);
  color: var(--p-icon-on-interactive);
}

.Polaris-Autocomplete-MappedOption--singleSelectionMedia {
  padding: 0 .8rem 0 0;
}

.Polaris-Autocomplete-MappedOption--disabledMedia svg {
  fill: var(--p-icon-disabled);
  color: var(--p-text-on-interactive);
}

.Polaris-Autocomplete-MappedAction__ActionContainer {
  margin-bottom: 1.2rem;
}

[data-focused] .Polaris-Autocomplete-MappedAction__Action svg {
  fill: var(--p-interactive);
}

[data-focused] .Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--destructive {
  background-color: var(--p-surface-critical-subdued-pressed);
}

.Polaris-Autocomplete-MappedAction__Action {
  width: 100%;
  min-height: 4rem;
  text-align: left;
  cursor: pointer;
  display: block;
  position: relative;
}

.Polaris-Autocomplete-MappedAction__Action:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

.Polaris-Autocomplete-MappedAction__Action:hover {
  background-color: var(--p-surface-hovered);
  text-decoration: none;
}

@media (-ms-high-contrast: active) {
  .Polaris-Autocomplete-MappedAction__Action:hover {
    outline: 0.1rem solid windowText;
  }
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--selected {
  background-color: var(--p-surface-selected);
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--selected svg {
  fill: var(--p-interactive);
}

.Polaris-Autocomplete-MappedAction__Action:active {
  background-color: var(--p-surface-pressed);
}

.Polaris-Autocomplete-MappedAction__Action:active svg {
  fill: var(--p-interactive);
}

.Polaris-Autocomplete-MappedAction__Action:focus:not(:active):after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--destructive {
  color: var(--p-interactive-critical);
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--destructive svg {
  fill: var(--p-icon-critical);
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--destructive:hover {
  background-color: var(--p-surface-critical-subdued-hovered);
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--destructive:active, .Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--destructive.Polaris-Autocomplete-MappedAction--selected {
  background-color: var(--p-surface-critical-subdued-pressed);
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--disabled {
  color: var(--p-text-disabled);
  background-image: none;
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--disabled .Polaris-Autocomplete-MappedAction__Prefix svg, .Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--disabled .Polaris-Autocomplete-MappedAction__Suffix svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Autocomplete-MappedAction__Content {
  align-items: center;
  display: flex;
}

.Polaris-Autocomplete-MappedAction__Prefix {
  height: 2rem;
  width: 2rem;
  background-position: center;
  background-size: cover;
  border-radius: .3rem;
  flex: none;
  justify-content: center;
  align-items: center;
  margin: -1rem 1.6rem -1rem 0;
  display: flex;
}

.Polaris-Autocomplete-MappedAction__Prefix svg {
  fill: var(--p-icon);
}

.Polaris-Autocomplete-MappedAction__Suffix {
  margin-left: 1.6rem;
}

.Polaris-Autocomplete-MappedAction__Suffix svg {
  fill: var(--p-icon);
}

.Polaris-Autocomplete-MappedAction__Text {
  min-width: 0;
  max-width: 100%;
  flex: auto;
}

.Polaris-Autocomplete-MappedAction__Text .Polaris-Autocomplete-MappedAction__ContentWrap {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
}

.Polaris-Autocomplete__Loading {
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: .8rem 1.6rem;
  display: flex;
}

.Polaris-Autocomplete__SectionWrapper > :not(:first-child) {
  margin-top: .8rem;
}

[data-lock-scrolling] {
  margin: 0;
  overflow-y: scroll;
}

[data-lock-scrolling] [data-lock-scrolling-wrapper] {
  height: 100%;
  overflow: hidden;
}

.Polaris-Backdrop {
  z-index: 518;
  background-color: var(--p-backdrop);
  opacity: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: opacity;
  animation: Polaris-Backdrop__fade--in .2s forwards;
  display: block;
  position: fixed;
  inset: 0;
}

.Polaris-Backdrop--transparent {
  background-color: #0000;
}

.Polaris-Backdrop--belowNavigation {
  z-index: 515;
}

@keyframes Polaris-Backdrop__fade--in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.Polaris-Banner {
  --p-rgb-text: 33, 43, 54;
  display: flex;
  position: relative;
}

.Polaris-Banner.Polaris-Banner--statusCritical .Polaris-Banner__PrimaryAction .Polaris-Banner__Button {
  border-color: var(--p-border-critical-subdued);
  background: var(--p-surface-critical-subdued);
}

.Polaris-Banner.Polaris-Banner--statusCritical .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:hover {
  border-color: var(--p-border-critical-subdued);
  background: var(--p-surface-critical-subdued-hovered);
}

.Polaris-Banner.Polaris-Banner--statusCritical .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:active {
  border-color: var(--p-border-critical-subdued);
  background: var(--p-surface-critical-subdued-pressed);
}

.Polaris-Banner.Polaris-Banner--statusCritical .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:focus:not(:active) {
  border-color: var(--p-border-critical-subdued);
  background: var(--p-surface-critical-subdued);
}

.Polaris-Banner.Polaris-Banner--statusWarning .Polaris-Banner__PrimaryAction .Polaris-Banner__Button {
  border-color: var(--p-border-warning-subdued);
  background: var(--p-surface-warning-subdued);
}

.Polaris-Banner.Polaris-Banner--statusWarning .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:hover {
  border-color: var(--p-border-warning-subdued);
  background: var(--p-surface-warning-subdued-hovered);
}

.Polaris-Banner.Polaris-Banner--statusWarning .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:active {
  border-color: var(--p-border-warning-subdued);
  background: var(--p-surface-warning-subdued-pressed);
}

.Polaris-Banner.Polaris-Banner--statusWarning .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:focus:not(:active) {
  border-color: var(--p-border-warning-subdued);
  background: var(--p-surface-warning-subdued);
}

.Polaris-Banner.Polaris-Banner--statusInfo .Polaris-Banner__PrimaryAction .Polaris-Banner__Button {
  border-color: var(--p-border-highlight-subdued);
  background: var(--p-surface-highlight-subdued);
}

.Polaris-Banner.Polaris-Banner--statusInfo .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:hover {
  border-color: var(--p-border-highlight-subdued);
  background: var(--p-surface-highlight-subdued-hovered);
}

.Polaris-Banner.Polaris-Banner--statusInfo .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:active {
  border-color: var(--p-border-highlight-subdued);
  background: var(--p-surface-highlight-subdued-pressed);
}

.Polaris-Banner.Polaris-Banner--statusInfo .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:focus:not(:active) {
  border-color: var(--p-border-highlight-subdued);
  background: var(--p-surface-highlight-subdued);
}

.Polaris-Banner.Polaris-Banner--statusSuccess .Polaris-Banner__PrimaryAction .Polaris-Banner__Button {
  border-color: var(--p-border-success-subdued);
  background: var(--p-surface-success-subdued);
}

.Polaris-Banner.Polaris-Banner--statusSuccess .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:hover {
  border-color: var(--p-border-success-subdued);
  background: var(--p-surface-success-subdued-hovered);
}

.Polaris-Banner.Polaris-Banner--statusSuccess .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:active {
  border-color: var(--p-border-success-subdued);
  background: var(--p-surface-success-subdued-pressed);
}

.Polaris-Banner.Polaris-Banner--statusSuccess .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:focus:not(:active) {
  border-color: var(--p-border-success-subdued);
  background: var(--p-surface-success-subdued);
}

.Polaris-Banner__ContentWrapper {
  flex: auto;
}

.Polaris-Banner--withinContentContainer {
  --p-banner-background: var(--p-background);
  --p-banner-border: var(--p-banner-border-default);
  box-shadow: var(--p-banner-border);
  border-radius: var(--p-border-radius-base);
  background-color: var(--p-banner-background);
  outline: .1rem solid #0000;
  padding: 1.6rem 1.6rem 1.4rem;
  transition: box-shadow .2s .1s;
  position: relative;
}

.Polaris-Banner--withinContentContainer .Polaris-Banner__ContentWrapper {
  margin-top: -.2rem;
}

.Polaris-Banner--withinContentContainer .Polaris-Banner__Dismiss {
  position: absolute;
  top: 1.6rem;
  right: 1.2rem;
}

.Polaris-Banner--withinContentContainer .Polaris-Banner__Ribbon {
  padding-right: 1.6rem;
}

.Polaris-Banner--withinContentContainer:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

.Polaris-Banner--withinContentContainer:focus {
  outline: none;
}

.Polaris-Banner--withinContentContainer.Polaris-Banner--keyFocused {
  box-shadow: var(--p-banner-border);
}

.Polaris-Banner--withinContentContainer.Polaris-Banner--keyFocused:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Banner--withinContentContainer.Polaris-Banner--statusSuccess {
  --p-banner-background: var(--p-surface-success-subdued);
  --p-banner-border: var(--p-banner-border-success);
}

.Polaris-Banner--withinContentContainer.Polaris-Banner--statusInfo {
  --p-banner-background: var(--p-surface-highlight-subdued);
  --p-banner-border: var(--p-banner-border-highlight);
}

.Polaris-Banner--withinContentContainer.Polaris-Banner--statusWarning {
  --p-banner-background: var(--p-surface-warning-subdued);
  --p-banner-border: var(--p-banner-border-warning);
}

.Polaris-Banner--withinContentContainer.Polaris-Banner--statusCritical {
  --p-banner-background: var(--p-surface-critical-subdued);
  --p-banner-border: var(--p-banner-border-critical);
}

.Polaris-Banner--withinContentContainer + .Polaris-Banner {
  margin-top: .8rem;
}

.Polaris-Banner--withinContentContainer .Polaris-Banner__Actions {
  padding: 1.2rem 0 .4rem;
}

.Polaris-Banner--withinPage {
  --p-banner-background: var(--p-background);
  --p-banner-border: var(--p-banner-border-default);
  box-shadow: var(--p-banner-border);
  border-radius: 0 0 .3rem .3rem;
  border-radius: var(--p-border-radius-wide);
  background-color: var(--p-banner-background);
  outline: .1rem solid #0000;
  padding: 2rem 2rem 1.8rem;
  transition: box-shadow .2s .1s;
  position: relative;
}

.Polaris-Banner--withinPage .Polaris-Banner__ContentWrapper {
  margin-top: -.2rem;
}

.Polaris-Banner--withinPage:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-wide) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

.Polaris-Banner--withinPage:focus {
  outline: none;
}

.Polaris-Banner--withinPage.Polaris-Banner--keyFocused {
  box-shadow: var(--p-banner-border);
}

.Polaris-Banner--withinPage.Polaris-Banner--keyFocused:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Banner--withinPage.Polaris-Banner--statusSuccess {
  --p-banner-background: var(--p-surface-success-subdued);
  --p-banner-border: var(--p-banner-border-success);
}

.Polaris-Banner--withinPage.Polaris-Banner--statusInfo {
  --p-banner-background: var(--p-surface-highlight-subdued);
  --p-banner-border: var(--p-banner-border-highlight);
}

.Polaris-Banner--withinPage.Polaris-Banner--statusWarning {
  --p-banner-background: var(--p-surface-warning-subdued);
  --p-banner-border: var(--p-banner-border-warning);
}

.Polaris-Banner--withinPage.Polaris-Banner--statusCritical {
  --p-banner-background: var(--p-surface-critical-subdued);
  --p-banner-border: var(--p-banner-border-critical);
}

.Polaris-Banner--withinPage + .Polaris-Banner {
  margin-top: 2rem;
}

.Polaris-Banner--withinPage .Polaris-Banner__Ribbon {
  padding-right: 1.6rem;
}

.Polaris-Banner--withinPage .Polaris-Banner__Actions {
  padding-top: 1.6rem;
}

.Polaris-Banner--withinPage .Polaris-Banner__Dismiss {
  position: absolute;
  top: 2rem;
  right: 1.6rem;
}

.Polaris-Banner--hasDismiss {
  padding-right: calc(3.2rem + var(--p-icon-size));
}

.Polaris-Banner__Heading {
  word-break: break-word;
}

.Polaris-Banner__Content {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  padding: .2rem 0;
}

.Polaris-Banner__Ribbon {
  flex: 0 0 3.2rem;
}

.Polaris-Banner__PrimaryAction {
  margin-right: .6rem;
}

.Polaris-Banner__SecondaryAction {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  color: inherit;
  text-align: left;
  color: var(--p-text);
  background: none;
  border: none;
  margin: -.8rem -.6rem;
  padding: .8rem 1.2rem .8rem .6rem;
  text-decoration: none;
  display: inline-block;
}

.Polaris-Banner__SecondaryAction:focus {
  outline: none;
}

.Polaris-Banner__SecondaryAction:visited {
  color: inherit;
}

.Polaris-Banner__SecondaryAction:hover > .Polaris-Banner__Text {
  box-shadow: 0 -0.2rem 0 0 rgba(var(--p-rgb-text), 0.75) inset;
}

.Polaris-Banner__SecondaryAction:active > .Polaris-Banner__Text {
  box-shadow: 0 -0.2rem 0 0 rgba(var(--p-rgb-text), 0) inset;
}

.Polaris-Banner__SecondaryAction:focus > .Polaris-Banner__Text {
  background: var(--p-action-secondary-hovered);
  box-shadow: none;
  border-radius: .3rem;
  margin: -.2rem -.5rem;
  padding: .2rem .5rem;
}

@media (-ms-high-contrast: active) {
  .Polaris-Banner__SecondaryAction:focus > .Polaris-Banner__Text {
    outline: .2rem dotted ;
  }
}

.Polaris-Banner__Text {
  box-shadow: 0 -0.2rem 0 0 rgba(var(--p-rgb-text), 0.25) inset;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: box-shadow;
  transition: box-shadow .2s;
}

.Polaris-Banner__Button {
  min-height: 3.6rem;
  min-width: 3.6rem;
  background: var(--p-surface);
  box-shadow: var(--p-button-drop-shadow);
  border-radius: var(--p-border-radius-base);
  color: var(--p-text);
  border: 0.1rem solid var(--p-border-neutral-subdued);
  border-top-color: var(--p-border-subdued);
  border-bottom-color: var(--p-border-shadow-subdued);
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  font-size: 1.5rem;
  line-height: 1;
  font-weight: var(--p-button-font-weight);
  text-transform: initial;
  letter-spacing: initial;
  color: var(--p-text);
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: .7rem 1.6rem;
  line-height: 1.6rem;
  text-decoration: none;
  display: inline-flex;
  position: relative;
}

.Polaris-Banner__Button svg {
  fill: var(--p-icon);
}

.Polaris-Banner__Button:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.2rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.2rem;
}

.Polaris-Banner__Button:hover {
  background: var(--p-action-secondary-hovered);
  outline: .1rem solid #0000;
}

.Polaris-Banner__Button:focus {
  box-shadow: var(--p-button-drop-shadow);
  outline: none;
}

.Polaris-Banner__Button:focus:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Banner__Button:active {
  background: var(--p-action-secondary-pressed);
  box-shadow: var(--p-button-drop-shadow);
}

.Polaris-Banner__Button:active:after {
  box-shadow: none;
  border: none;
}

.Polaris-Banner__Button.Polaris-Banner--pressed {
  background: var(--p-action-secondary-depressed);
  box-shadow: var(--p-button-pressed-inner-shadow);
  color: var(--p-text-on-primary);
  border-color: var(--p-border-depressed);
}

.Polaris-Banner__Button.Polaris-Banner--pressed svg {
  fill: currentColor;
}

@media (-ms-high-contrast: active) {
  .Polaris-Banner__Button {
    border: 0.1rem solid windowText;
  }
}

@media (min-width: 40em) {
  .Polaris-Banner__Button {
    font-size: 1.4rem;
  }
}

.Polaris-Banner__Button:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.3rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.3rem;
}

.Polaris-Banner__Button:focus:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Banner--loading {
  color: #0000;
  pointer-events: none;
  position: relative;
}

.Polaris-Banner__Spinner {
  margin-top: -1rem;
  margin-left: -1rem;
  position: absolute;
  top: 50%;
  left: 50%;
}

.Polaris-Breadcrumbs__Breadcrumb {
  text-transform: initial;
  letter-spacing: initial;
  color: var(--p-text-subdued);
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  min-height: 3.6rem;
  min-width: 3.6rem;
  color: var(--p-text-subdued);
  border-radius: var(--p-border-radius-base);
  border: none;
  border: 0.1rem solid var(--p-border-neutral-subdued);
  background: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: .2rem;
  text-decoration: none;
  display: flex;
  position: relative;
}

@media (min-width: 40em) {
  .Polaris-Breadcrumbs__Breadcrumb {
    font-size: 1.4rem;
  }
}

.Polaris-Breadcrumbs__Breadcrumb:focus {
  outline: none;
}

.Polaris-Breadcrumbs__Breadcrumb:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.2rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.2rem;
}

.Polaris-Breadcrumbs__Breadcrumb:hover {
  background-color: var(--p-surface-hovered);
}

.Polaris-Breadcrumbs__Breadcrumb:active {
  background-color: var(--p-surface-pressed);
}

.Polaris-Breadcrumbs__Breadcrumb:hover, .Polaris-Breadcrumbs__Breadcrumb:active {
  color: var(--p-text);
  text-decoration: none;
}

.Polaris-Breadcrumbs__Breadcrumb:hover .Polaris-Breadcrumbs__Icon svg, .Polaris-Breadcrumbs__Breadcrumb:active .Polaris-Breadcrumbs__Icon svg {
  fill: var(--p-icon-pressed);
}

.Polaris-Breadcrumbs__Breadcrumb:focus {
  outline: none;
}

.Polaris-Breadcrumbs__Breadcrumb:focus:not(:active):after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Breadcrumbs__Content {
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  overflow: hidden;
}

.Polaris-Breadcrumbs__Icon {
  width: 2rem;
  height: 2rem;
  margin: 0;
}

.Polaris-Breadcrumbs__Icon svg {
  fill: var(--p-icon);
}

.Polaris-CheckableButton {
  font-size: 1.5rem;
  font-weight: var(--p-button-font-weight);
  text-transform: initial;
  letter-spacing: initial;
  min-height: 3.6rem;
  min-width: 3.6rem;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;
  border-radius: var(--p-border-radius-base);
  width: 100%;
  background: var(--p-surface);
  box-shadow: var(--p-button-drop-shadow);
  border: 0.1rem solid var(--p-border-neutral-subdued);
  border-top-color: var(--p-border-subdued);
  border-bottom-color: var(--p-border-shadow-subdued);
  align-items: center;
  margin: 0;
  padding: .7rem 1.6rem;
  line-height: 1;
  text-decoration: none;
  display: flex;
}

@media (min-width: 40em) {
  .Polaris-CheckableButton {
    font-size: 1.4rem;
  }
}

.Polaris-CheckableButton svg {
  fill: var(--p-icon-on-interactive);
}

[data-buttongroup-segmented="true"] .Polaris-CheckableButton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Polaris-CheckableButton:hover {
  background: var(--p-action-secondary-hovered);
}

.Polaris-CheckableButton:active {
  background: var(--p-action-secondary-pressed);
}

.Polaris-CheckableButton.Polaris-CheckableButton__CheckableButton--measuring {
  font-size: 1.5rem;
  font-weight: 700;
}

.Polaris-CheckableButton.Polaris-CheckableButton__CheckableButton--measuring:before {
  content: "";
  width: 1.5rem;
  display: inline-block;
}

@media (min-width: 28.625em) {
  .Polaris-CheckableButton {
    flex: 0 auto;
  }
}

.Polaris-CheckableButton:focus {
  outline: none;
}

.Polaris-CheckableButton.Polaris-CheckableButton__CheckableButton--plain {
  box-shadow: none;
  background: none;
  border-radius: .3rem;
  border: none;
}

.Polaris-CheckableButton.Polaris-CheckableButton__CheckableButton--plain:hover {
  background: none;
}

.Polaris-CheckableButton.Polaris-CheckableButton__CheckableButton--selectMode {
  color: var(--p-text-subdued);
  font-weight: 500;
}

.Polaris-CheckableButton.Polaris-CheckableButton__CheckableButton--selected {
  color: var(--p-text);
}

.Polaris-CheckableButton__Checkbox {
  pointer-events: none;
  height: var(--p-choice-size);
  width: var(--p-choice-size);
  margin-left: calc(-0.9rem - var(--p-control-border-width));
}

.Polaris-CheckableButton__Label {
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  margin-left: calc(2rem - var(--p-control-border-width));
  flex: 1;
  padding: .1rem 0;
  overflow: hidden;
}

.Polaris-Indicator:before, .Polaris-Indicator:after {
  content: "";
  background-color: var(--p-border-highlight);
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  border: .5rem solid #0000;
  position: absolute;
  top: -.4rem;
  right: -.4rem;
}

.Polaris-Indicator--pulseIndicator:before {
  z-index: 1;
  animation: Polaris-Indicator--bounce 5s infinite;
}

.Polaris-Indicator--pulseIndicator:after {
  animation: Polaris-Indicator--pulse 5s infinite;
  top: -.4rem;
  right: -.4rem;
}

@keyframes Polaris-Indicator--bounce {
  from, 65%, 85% {
    transform: scale(1);
  }

  75% {
    transform: scale(.85);
  }

  82.5% {
    transform: scale(1.05);
  }
}

@keyframes Polaris-Indicator--pulse {
  from, 75% {
    opacity: 1;
    transform: scale(.85);
  }

  to {
    opacity: 0;
    transform: scale(2.5);
  }
}

.Polaris-BulkActions__Group {
  text-transform: initial;
  letter-spacing: initial;
  width: 100%;
  opacity: 0;
  border: none;
  flex-wrap: wrap;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  display: none;
}

@media (min-width: 40em) {
  .Polaris-BulkActions__Group {
    font-size: 1.4rem;
  }
}

.Polaris-BulkActions__Group.Polaris-BulkActions__Group--measuring {
  opacity: 0;
  transition: none;
  display: flex;
}

.Polaris-BulkActions__Group--entering, .Polaris-BulkActions__Group--exiting {
  opacity: 0;
  display: flex;
}

.Polaris-BulkActions__Group--entered {
  opacity: 1;
  display: flex;
}

.Polaris-BulkActions__Group--exited {
  opacity: 0;
  display: none;
}

@media (min-width: 28.625em) {
  .Polaris-BulkActions__Group--smallScreen {
    display: none;
  }
}

.Polaris-BulkActions__Group--largeScreen {
  display: none;
}

@media (min-width: 28.625em) {
  .Polaris-BulkActions__Group--largeScreen {
    display: flex;
  }

  .Polaris-BulkActions__Group--largeScreen.Polaris-BulkActions__Group--exiting {
    transition: none;
  }

  .Polaris-BulkActions__Group--largeScreen.Polaris-BulkActions__Group--exited {
    opacity: 0;
    display: none;
  }
}

.Polaris-BulkActions__ButtonGroupWrapper {
  width: 100%;
  max-width: 100%;
}

@media (max-width: 28.625em) {
  .Polaris-BulkActions__ButtonGroupWrapper > div > div:first-child {
    flex: auto;
  }
}

@media (min-width: 28.625em) {
  .Polaris-BulkActions__ButtonGroupWrapper {
    width: auto;
    justify-content: flex-start;
    margin-right: .8rem;
  }
}

.Polaris-BulkActions__Group--measuring .Polaris-BulkActions__ButtonGroupWrapper {
  width: auto;
  position: absolute;
}

.Polaris-BulkActions__BulkActionButton {
  white-space: nowrap;
}

.Polaris-BulkActions__CheckableContainer {
  flex: 1;
}

.Polaris-BulkActions--disabled {
  box-shadow: none;
  border-color: var(--p-border-disabled);
  background: var(--p-surface-disabled);
  color: var(--p-text-disabled);
  cursor: default;
  pointer-events: none;
  transition: none;
}

.Polaris-BulkActions--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-BulkActions__PaginatedSelectAll {
  padding: .4rem 0;
}

.Polaris-BulkActions__Slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
  transition: transform .2s;
  transform: translateY(0);
}

.Polaris-BulkActions__Slide--appear, .Polaris-BulkActions__Slide--enter, .Polaris-BulkActions__Slide--exit {
  transform: translateX(-4rem);
}

.Polaris-BulkActions__Slide--appearing, .Polaris-BulkActions__Slide--entering {
  transform: translateY(0);
}

.Polaris-TextContainer > :not(:first-child) {
  margin-top: 1.6rem;
}

.Polaris-TextContainer--spacingTight > :not(:first-child) {
  margin-top: .8rem;
}

.Polaris-TextContainer--spacingLoose > :not(:first-child) {
  margin-top: 2rem;
}

.Polaris-CalloutCard {
  align-items: center;
  display: flex;
}

.Polaris-CalloutCard__Image {
  width: 10rem;
  flex: none;
  display: none;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-CalloutCard__Image {
    margin-left: 2rem;
    display: block;
  }
}

@media (min-width: 30.625em) {
  .Polaris-CalloutCard__Image {
    margin-left: 2rem;
    display: block;
  }
}

.Polaris-CalloutCard__DismissImage {
  margin-right: 2rem;
}

.Polaris-CalloutCard__Content {
  flex: auto;
}

.Polaris-CalloutCard__Title {
  margin-bottom: 2rem;
}

.Polaris-CalloutCard__Buttons {
  margin-top: 2rem;
}

.Polaris-CalloutCard__Container {
  position: relative;
}

.Polaris-CalloutCard__Dismiss {
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
}

.Polaris-CalloutCard--hasDismiss {
  padding-right: calc(3.2rem + var(--p-icon-size));
}

.Polaris-Caption {
  margin: 0;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2rem;
}

@media (min-width: 40em) {
  .Polaris-Caption {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

.Polaris-RadioButton {
  margin: var(--p-choice-margin);
  position: relative;
}

.Polaris-RadioButton__Input {
  width: .1rem !important;
  height: .1rem !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.Polaris-RadioButton__Input.Polaris-RadioButton--keyFocused + .Polaris-RadioButton__Backdrop:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  border-radius: 50%;
  outline: .1rem solid #0000;
}

.Polaris-RadioButton__Input:checked + .Polaris-RadioButton__Backdrop {
  border-color: var(--p-interactive);
}

.Polaris-RadioButton__Input:checked + .Polaris-RadioButton__Backdrop:before {
  transition: opacity var(--p-duration-1-5-0) var(--p-ease), transform var(--p-duration-1-5-0) var(--p-ease);
  opacity: 1;
  transform: translate(-50%, -50%)scale(1);
}

.Polaris-RadioButton__Input:disabled + .Polaris-RadioButton__Backdrop {
  border-color: var(--p-border-disabled);
  cursor: default;
}

.Polaris-RadioButton__Input:disabled + .Polaris-RadioButton__Backdrop:before {
  background-color: var(--p-border-disabled);
}

.Polaris-RadioButton__Backdrop {
  width: 100%;
  height: 100%;
  border: var(--p-control-border-width) solid var(--p-border);
  background-color: var(--p-surface);
  transition: border-color 100ms var(--p-ease);
  border-radius: 50%;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.Polaris-RadioButton__Backdrop:before {
  content: "";
  opacity: 0;
  transform-origin: 50%;
  height: var(--p-icon-size);
  width: var(--p-icon-size);
  background-color: var(--p-interactive);
  transition: opacity 100ms var(--p-ease), transform 100ms var(--p-ease);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(.1);
}

@media (forced-colors: active) {
  .Polaris-RadioButton__Backdrop:before {
    border: .5rem solid #0000;
  }
}

.Polaris-RadioButton__Backdrop:after {
  content: "";
  z-index: 1;
  top: calc(var(--p-control-border-width) * -1 + -0.1rem);
  right: calc(var(--p-control-border-width) * -1 + -0.1rem);
  bottom: calc(var(--p-control-border-width) * -1 + -0.1rem);
  left: calc(var(--p-control-border-width) * -1 + -0.1rem);
  pointer-events: none;
  box-shadow: 0 0 0 calc(var(--p-control-border-width) * -1 + -0.1rem) var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  border-radius: 50%;
  display: block;
  position: absolute;
}

.Polaris-RadioButton__Backdrop.Polaris-RadioButton--hover, .Polaris-RadioButton__Backdrop:hover {
  cursor: pointer;
  border-color: var(--p-border-hovered);
}

.Polaris-ChoiceList {
  border: none;
  margin: 0;
  padding: 0;
}

.Polaris-ChoiceList--titleHidden > .Polaris-ChoiceList__Title {
  width: .1rem !important;
  height: .1rem !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.Polaris-ChoiceList__Choices {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Polaris-ChoiceList__ChoiceChildren {
  padding-left: calc(0.8rem + var(--p-choice-size, 1.6rem));
  margin-bottom: .8rem;
}

.Polaris-ChoiceList__ChoiceError {
  margin-top: .4rem;
  margin-bottom: .8rem;
}

.Polaris-ChoiceList__Title {
  text-transform: initial;
  letter-spacing: initial;
  margin: 0 0 .4rem;
  padding: 0;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  display: block;
}

@media (min-width: 40em) {
  .Polaris-ChoiceList__Title {
    font-size: 1.4rem;
  }
}

.Polaris-Collapsible {
  max-height: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: max-height;
  padding-top: 0;
  padding-bottom: 0;
  transition-property: max-height;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(0, 0, .42, 1);
  overflow: hidden;
}

.Polaris-Collapsible--isFullyClosed {
  display: none;
}

@media print {
  .Polaris-Collapsible--expandOnPrint {
    display: block;
    overflow: visible;
    max-height: none !important;
  }
}

.Polaris-ColorPicker {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
}

.Polaris-ColorPicker__MainColor {
  background: repeating-conic-gradient(var(--p-surface) 0% 25%, var(--p-surface-neutral-subdued) 0% 50%) 50% / 1.6rem
    1.6rem;
  height: 16rem;
  width: 16rem;
  border-radius: var(--p-border-radius-base);
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.Polaris-ColorPicker--fullWidth .Polaris-ColorPicker__MainColor {
  width: auto;
  flex-grow: 1;
}

.Polaris-ColorPicker__MainColor .Polaris-ColorPicker__Dragger {
  margin: 0;
  right: .9rem;
}

.Polaris-ColorPicker__MainColor .Polaris-ColorPicker__ColorLayer {
  border-radius: var(--p-border-radius-base);
}

.Polaris-ColorPicker__MainColor:after, .Polaris-ColorPicker__MainColor:before {
  content: "";
  z-index: 20;
  height: 100%;
  width: 100%;
  pointer-events: none;
  border-radius: var(--p-border-radius-base);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.Polaris-ColorPicker__MainColor:before {
  background: linear-gradient(to right, #fff, #0000);
}

.Polaris-ColorPicker__MainColor:after {
  background-image: linear-gradient(to top, #000, #0000);
}

.Polaris-ColorPicker__Dragger {
  z-index: 30;
  height: 1.8rem;
  width: 1.8rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
  border: var(--p-border-radius-base) solid var(--p-surface);
  pointer-events: none;
  background: none;
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
  bottom: .9rem;
  transform: none;
}

.Polaris-ColorPicker__HuePicker, .Polaris-ColorPicker__AlphaPicker {
  height: 16rem;
  width: 2.4rem;
  border-width: var(--p-border-radius-base);
  border-radius: 8rem;
  margin-left: .8rem;
  position: relative;
  overflow: hidden;
}

.Polaris-ColorPicker__HuePicker {
  background-image: linear-gradient(red 1.8rem, #ff0, #0f0, #0ff, #00f, #f0f, red 14.2rem);
}

.Polaris-ColorPicker__AlphaPicker {
  background: repeating-conic-gradient(var(--p-surface) 0% 25%, var(--p-surface-neutral-subdued) 0% 50%) 50% / 1.6rem
    1.6rem;
}

.Polaris-ColorPicker__ColorLayer {
  z-index: 10;
  height: 100%;
  width: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.Polaris-ColorPicker__Slidable {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.Polaris-DataTable {
  max-width: 100vw;
  position: relative;
}

.Polaris-DataTable--condensed .Polaris-DataTable__Navigation {
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 1.6rem .8rem;
  display: flex;
}

@media (min-width: 48em) {
  .Polaris-DataTable--condensed .Polaris-DataTable__Navigation {
    justify-content: flex-end;
  }
}

.Polaris-DataTable__Navigation {
  display: none;
}

.Polaris-DataTable__Pip {
  height: .6rem;
  width: .6rem;
  background: var(--p-text-subdued);
  border-radius: var(--p-border-radius-base);
}

.Polaris-DataTable__Pip:not(:last-of-type) {
  margin-right: .4rem;
}

.Polaris-DataTable__Pip--visible {
  background: var(--p-text);
}

.Polaris-DataTable__ScrollContainer {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

.Polaris-DataTable__Table {
  width: 100%;
  border-spacing: 0;
}

@media (min-width: 48em) {
  .Polaris-DataTable--hoverable:hover .Polaris-DataTable__Cell {
    background: var(--p-surface-hovered);
  }
}

.Polaris-DataTable__TableRow + .Polaris-DataTable__TableRow .Polaris-DataTable__Cell {
  border-top: 0.1rem solid var(--p-divider);
}

.Polaris-DataTable__Cell {
  white-space: nowrap;
  text-align: left;
  padding: 1.6rem;
  transition: background-color .2s ease-in-out;
}

.Polaris-DataTable__Cell--firstColumn {
  color: var(--p-text);
  text-align: left;
  white-space: normal;
  font-weight: 400;
}

.Polaris-DataTable__Cell--numeric {
  text-align: right;
}

.Polaris-DataTable__Cell--truncated {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 14.5rem;
  overflow-x: hidden;
}

.Polaris-DataTable__Cell--header {
  color: var(--p-text);
  border-bottom: 0.1rem solid var(--p-border);
  border-top: none;
  font-weight: 400;
}

.Polaris-DataTable__Cell--sortable {
  padding: 0;
}

.Polaris-DataTable__Cell--verticalAlignTop {
  vertical-align: top;
}

.Polaris-DataTable__Cell--verticalAlignBottom {
  vertical-align: bottom;
}

.Polaris-DataTable__Cell--verticalAlignMiddle {
  vertical-align: middle;
}

.Polaris-DataTable__Cell--verticalAlignBaseline {
  vertical-align: baseline;
}

.Polaris-DataTable__Icon {
  opacity: 0;
  align-self: flex-end;
  transition: opacity .2s, fill .2s;
  display: flex;
}

.Polaris-DataTable__Heading {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  color: var(--p-text);
  cursor: pointer;
  background: none;
  border: none;
  justify-content: flex-end;
  align-items: baseline;
  margin: .8rem;
  padding: .8rem;
  transition: color .2s;
  display: inline-flex;
  position: relative;
}

.Polaris-DataTable__Heading:focus {
  outline: none;
}

.Polaris-DataTable__Heading:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

.Polaris-DataTable__Heading svg {
  fill: var(--p-icon);
}

.Polaris-DataTable__Heading:hover {
  color: var(--p-interactive-hovered);
}

.Polaris-DataTable__Heading:hover svg {
  fill: var(--p-interactive-hovered);
}

.Polaris-DataTable__Heading:hover .Polaris-DataTable__Icon {
  opacity: 1;
}

.Polaris-DataTable__Heading:focus:not(:active):after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-DataTable__Heading--left {
  flex-direction: row-reverse;
  justify-content: flex-start;
}

.Polaris-DataTable__Cell--sorted .Polaris-DataTable__Icon {
  opacity: 1;
}

.Polaris-DataTable__Cell--total {
  background: var(--p-surface-subdued);
  border-bottom: 0.1rem solid var(--p-border-subdued);
  font-weight: 600;
}

.Polaris-DataTable--cellTotalFooter {
  border-top: 0.1rem solid var(--p-divider);
  border-bottom-left-radius: var(--p-border-radius-base);
  border-bottom-right-radius: var(--p-border-radius-base);
  border-bottom: none;
}

.Polaris-DataTable__Footer {
  background: var(--p-surface-subdued);
  color: var(--p-text-subdued);
  text-align: center;
  border-top: 0.1rem solid var(--p-divider);
  border-bottom-left-radius: var(--p-border-radius-base);
  border-bottom-right-radius: var(--p-border-radius-base);
  padding: 1.6rem;
}

.Polaris-DatePicker {
  position: relative;
}

.Polaris-DatePicker__MonthLayout {
  flex-wrap: wrap;
  margin-top: -1.6rem;
  margin-left: -1.6rem;
  display: flex;
}

.Polaris-DatePicker__MonthContainer {
  max-width: calc(100% - 1.6rem);
  min-width: 23rem;
  flex: 23rem;
  margin-top: 1.6rem;
  margin-left: 1.6rem;
}

.Polaris-DatePicker__Month {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  border: none;
}

.Polaris-DatePicker__Month--current {
  font-weight: 700;
}

.Polaris-DatePicker__DayCell {
  width: 14.2857%;
  border-radius: var(--p-border-radius-base);
  background: none;
  margin: 0;
  padding: 0;
}

.Polaris-DatePicker__DayCell--inRange {
  border-radius: 0;
}

.Polaris-DatePicker__Day {
  height: 100%;
  width: 100%;
  border-radius: var(--p-border-radius-base);
  text-align: center;
  color: var(--p-text);
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: .8rem;
  font-size: 1.2rem;
  display: block;
  position: relative;
}

.Polaris-DatePicker__Day:hover {
  background: var(--p-interactive-hovered);
  color: var(--p-text-on-interactive);
  outline: .1rem solid #0000;
}

.Polaris-DatePicker__Day:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

.Polaris-DatePicker__Day:focus:not(:active):after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-DatePicker__Day--today {
  font-weight: 700;
}

.Polaris-DatePicker__Day--inRange {
  background: var(--p-surface-selected);
  border-radius: 0;
}

@media (-ms-high-contrast: active) {
  .Polaris-DatePicker__Day--inRange {
    -ms-high-contrast-adjust: none;
    background-color: Highlight;
    color: HighlightText;
  }

  .Polaris-DatePicker__Day--inRange:hover {
    background-color: HighlightText;
    color: Highlight;
    outline: 0.2rem solid Highlight;
  }
}

.Polaris-DatePicker__Day--selected {
  background: var(--p-interactive);
  color: var(--p-text-on-interactive);
}

@media (-ms-high-contrast: active) {
  .Polaris-DatePicker__Day--selected {
    -ms-high-contrast-adjust: none;
    background-color: Highlight;
    color: HighlightText;
  }

  .Polaris-DatePicker__Day--selected:hover {
    background-color: HighlightText;
    color: Highlight;
    outline: 0.2rem solid Highlight;
  }
}

.Polaris-DatePicker__Day--disabled, .Polaris-DatePicker__Day--disabled:hover {
  color: var(--p-text-disabled);
  background-color: #0000;
}

@media (-ms-high-contrast) {
  .Polaris-DatePicker__Day--disabled {
    -ms-high-contrast-adjust: none;
    color: grayText;
  }

  .Polaris-DatePicker__Day--disabled:hover {
    color: grayText;
    outline: none;
  }
}

.Polaris-DatePicker__Day--disabled:focus:after {
  content: none;
}

.Polaris-DatePicker__EmptyDayCell {
  width: 14.2857%;
  margin: 0;
  padding: 0;
}

.Polaris-DatePicker__Weekday {
  color: var(--p-text-subdued);
  text-align: center;
  background: none;
  padding: .8rem;
  font-size: 1.2rem;
  font-weight: 400;
}

.Polaris-DatePicker__Weekday--current {
  color: var(--p-text);
  font-weight: 700;
}

.Polaris-DatePicker__Header {
  width: 100%;
  justify-content: space-between;
  display: flex;
  position: absolute;
  top: 1.6rem;
}

.Polaris-DatePicker__Title {
  text-align: center;
  flex: auto;
  margin-top: .2rem;
  padding-bottom: .4rem;
}

.Polaris-DatePicker__Day--firstInRange {
  border-radius: var(--p-border-radius-base);
}

.Polaris-DatePicker__Day--firstInRange.Polaris-DatePicker__Day--hasRange, .Polaris-DatePicker__Day--firstInRange.Polaris-DatePicker__Day--hoverRight, .Polaris-DatePicker__Day--firstInRange.Polaris-DatePicker__Day--hasRange:after, .Polaris-DatePicker__Day--firstInRange.Polaris-DatePicker__Day--hoverRight:after {
  border-radius: 3rem 0 0 3rem;
}

.Polaris-DatePicker__Day--lastInRange, .Polaris-DatePicker__Day--lastInRange:after {
  border-radius: 0 3rem 3rem 0;
}

.Polaris-DatePicker__Week {
  margin-bottom: .2rem;
}

.Polaris-DatePicker__Week > .Polaris-DatePicker__Day--inRange:first-child:not(.Polaris-DatePicker__Day--firstInRange):not(.Polaris-DatePicker__Day--lastInRange) {
  border-radius: var(--p-border-radius-base) 0 0 var(--p-border-radius-base);
}

.Polaris-DatePicker__Week > .Polaris-DatePicker__Day--inRange:last-child:not(.Polaris-DatePicker__Day--firstInRange):not(.Polaris-DatePicker__Day--lastInRange) {
  border-radius: 0 var(--p-border-radius-base) var(--p-border-radius-base) 0;
}

.Polaris-DatePicker__Day--inRange:after, .Polaris-DatePicker__Day--inRange:not(:hover) + .Polaris-DatePicker__Day:after {
  border-radius: 0 3rem 3rem 0;
}

.Polaris-DescriptionList {
  word-break: break-word;
  margin: 0;
  padding: 0;
}

@media (max-width: 48.0625em) and (min-width: 36.875em), (min-width: 51.875em) {
  [data-has-navigation] .Polaris-DescriptionList {
    flex-wrap: wrap;
    align-items: flex-start;
    display: flex;
  }
}

@media (min-width: 36.875em) {
  .Polaris-DescriptionList {
    flex-wrap: wrap;
    align-items: flex-start;
    display: flex;
  }
}

.Polaris-DescriptionList__Term {
  padding: 1.6rem 0 .8rem;
  font-weight: 600;
}

.Polaris-DescriptionList--spacingTight .Polaris-DescriptionList__Term {
  padding: .8rem 0 .4rem;
}

@media (max-width: 48.0625em) and (min-width: 36.875em), (min-width: 51.875em) {
  [data-has-navigation] .Polaris-DescriptionList__Term {
    flex: 0 25%;
    padding: 1.6rem 1.6rem 1.6rem 0;
  }

  .Polaris-DescriptionList--spacingTight [data-has-navigation] .Polaris-DescriptionList__Term {
    padding: .8rem .8rem .8rem 0;
  }

  .Polaris-DescriptionList__Description + [data-has-navigation] .Polaris-DescriptionList__Term + .Polaris-DescriptionList__Description {
    border-top: 0.1rem solid var(--p-divider);
  }
}

@media (min-width: 36.875em) {
  .Polaris-DescriptionList__Term {
    flex: 0 25%;
    padding: 1.6rem 1.6rem 1.6rem 0;
  }

  .Polaris-DescriptionList--spacingTight .Polaris-DescriptionList__Term {
    padding: .8rem .8rem .8rem 0;
  }

  .Polaris-DescriptionList__Description + .Polaris-DescriptionList__Term + .Polaris-DescriptionList__Description {
    border-top: 0.1rem solid var(--p-divider);
  }
}

.Polaris-DescriptionList__Description {
  margin-left: 0;
  padding: 0 0 1.6rem;
}

.Polaris-DescriptionList--spacingTight .Polaris-DescriptionList__Description {
  padding: 0 0 .8rem;
}

.Polaris-DescriptionList__Description + .Polaris-DescriptionList__Term {
  border-top: 0.1rem solid var(--p-divider);
}

@media (max-width: 48.0625em) and (min-width: 36.875em), (min-width: 51.875em) {
  [data-has-navigation] .Polaris-DescriptionList__Description {
    flex: 51%;
    padding: 1.6rem 0;
  }

  .Polaris-DescriptionList--spacingTight [data-has-navigation] .Polaris-DescriptionList__Description {
    padding: .8rem 0;
  }

  [data-has-navigation] .Polaris-DescriptionList__Description + .Polaris-DescriptionList__Term + .Polaris-DescriptionList__Description {
    border-top: 0.1rem solid var(--p-divider);
  }
}

@media (min-width: 36.875em) {
  .Polaris-DescriptionList__Description {
    flex: 51%;
    padding: 1.6rem 0;
  }

  .Polaris-DescriptionList--spacingTight .Polaris-DescriptionList__Description {
    padding: .8rem 0;
  }

  .Polaris-DescriptionList__Description + .Polaris-DescriptionList__Term + .Polaris-DescriptionList__Description {
    border-top: 0.1rem solid var(--p-divider);
  }
}

.Polaris-DisplayText {
  margin: 0;
}

.Polaris-DisplayText--sizeSmall {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
}

@media (min-width: 40em) {
  .Polaris-DisplayText--sizeSmall {
    font-size: 2rem;
    line-height: 2.8rem;
  }
}

.Polaris-DisplayText--sizeMedium {
  font-size: 2.1rem;
  font-weight: 400;
  line-height: 2.8rem;
}

@media (min-width: 40em) {
  .Polaris-DisplayText--sizeMedium {
    font-size: 2.6rem;
    line-height: 3.2rem;
  }
}

.Polaris-DisplayText--sizeLarge {
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 2.8rem;
}

@media (min-width: 40em) {
  .Polaris-DisplayText--sizeLarge {
    font-size: 2.8rem;
    line-height: 3.2rem;
  }
}

.Polaris-DisplayText--sizeExtraLarge {
  font-size: 2.7rem;
  font-weight: 600;
  line-height: 3.6rem;
}

@media (min-width: 40em) {
  .Polaris-DisplayText--sizeExtraLarge {
    font-size: 4.2rem;
    line-height: 4.4rem;
  }
}

.Polaris-DropZone-FileUpload {
  text-align: center;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  display: flex;
}

.Polaris-DropZone-FileUpload--large {
  padding: 3.2rem;
}

.Polaris-DropZone-FileUpload--small {
  padding: 1.25rem;
}

.Polaris-DropZone-FileUpload img {
  vertical-align: bottom;
}

.Polaris-DropZone-FileUpload__Action {
  border-radius: var(--p-border-radius-base);
  color: var(--p-interactive-hovered);
  background: var(--p-surface-selected-pressed);
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  border: none;
  flex: none;
  margin: 0;
  padding: .4rem .6rem;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  display: inline-flex;
  position: relative;
}

.Polaris-DropZone-FileUpload__Action:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

.Polaris-DropZone-FileUpload__Action:hover {
  color: var(--p-interactive-pressed);
}

.Polaris-DropZone-FileUpload__Action.Polaris-DropZone-FileUpload--disabled {
  box-shadow: none;
  border-color: var(--p-border-disabled);
  background: var(--p-surface-disabled);
  color: var(--p-text-disabled);
  cursor: not-allowed;
  box-shadow: none;
  transition: none;
}

.Polaris-DropZone-FileUpload__Action.Polaris-DropZone-FileUpload--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-DropZone-FileUpload__ActionTitle {
  color: var(--p-interactive);
  text-decoration: none;
}

.Polaris-DropZone-FileUpload__ActionTitle:not(.Polaris-DropZone-FileUpload__ActionTitle--disabled) {
  cursor: pointer;
}

.Polaris-DropZone-FileUpload__ActionTitle:not(.Polaris-DropZone-FileUpload__ActionTitle--disabled):hover, .Polaris-DropZone-FileUpload__ActionTitle:not(.Polaris-DropZone-FileUpload__ActionTitle--disabled):active {
  color: var(--p-interactive-pressed);
  text-decoration: underline;
}

.Polaris-DropZone-FileUpload__ActionTitle--focused {
  text-decoration: underline;
}

.Polaris-DropZone-FileUpload__ActionTitle--disabled {
  color: var(--p-interactive-disabled);
}

.Polaris-DropZone {
  background-color: var(--p-surface);
  border-radius: var(--p-border-radius-base);
  justify-content: center;
  display: flex;
  position: relative;
}

.Polaris-DropZone:after {
  content: "";
  z-index: 29;
  border-radius: var(--p-border-radius-base);
  pointer-events: none;
  border: .1rem dashed #0000;
  position: absolute;
  inset: 0;
}

.Polaris-DropZone:not(.Polaris-DropZone--focused):after {
  opacity: 1;
  border: .1rem dashed #0000;
  inset: 0;
  transform: scale(1);
}

.Polaris-DropZone:hover {
  outline: .1rem solid #0000;
}

.Polaris-DropZone--hasOutline {
  padding: .1rem;
}

.Polaris-DropZone--hasOutline:after {
  border-color: var(--p-border-neutral-subdued);
}

.Polaris-DropZone--hasOutline:not(.Polaris-DropZone--isDisabled):hover {
  cursor: pointer;
  background-color: var(--p-surface-subdued);
}

.Polaris-DropZone--hasOutline:not(.Polaris-DropZone--isDisabled):hover:after {
  border-color: var(--p-interactive-hovered);
}

.Polaris-DropZone--hasOutline:not(.Polaris-DropZone--focused):after {
  opacity: 1;
  border-radius: calc(var(--p-border-radius-base) + 0.2rem);
  border: .1rem dashed #0000;
  border-color: var(--p-border-neutral-subdued);
  inset: 0;
  transform: scale(1);
}

.Polaris-DropZone--isDragging:not(.Polaris-DropZone--isDisabled) {
  background-color: var(--p-surface-hovered);
}

.Polaris-DropZone--isDisabled {
  cursor: not-allowed;
}

.Polaris-DropZone--isDisabled:after {
  border-color: var(--p-border-disabled);
}

.Polaris-DropZone--sizeLarge {
  min-height: 12rem;
}

.Polaris-DropZone--sizeMedium {
  min-height: 10rem;
  align-items: center;
}

.Polaris-DropZone--sizeSmall {
  min-height: 5rem;
  align-items: center;
  padding: 0;
}

.Polaris-DropZone--measuring {
  visibility: hidden;
  min-height: 0;
}

.Polaris-DropZone__Container {
  flex: 1;
  position: relative;
}

.Polaris-DropZone__Container:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.2rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.2rem;
}

.Polaris-DropZone__Overlay {
  border-radius: calc(var(--p-border-radius-base) + 0.2rem);
  z-index: 30;
  border: 0.1rem dashed var(--p-interactive);
  text-align: center;
  color: var(--p-interactive);
  background-color: var(--p-surface-selected);
  pointer-events: none;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  display: flex;
  position: absolute;
  inset: 0;
}

.Polaris-DropZone--hasError .Polaris-DropZone__Overlay {
  border-color: var(--p-border-critical);
  color: var(--p-text-critical);
  background-color: var(--p-surface-critical-subdued);
}

.Polaris-DropZone--sizeSmall .Polaris-DropZone__Overlay {
  padding: 0;
}

.Polaris-DropZone--focused:not(.Polaris-DropZone--isDisabled) .Polaris-DropZone__Container:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-EmptyState {
  width: 100%;
  max-width: 99.8rem;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 2rem 0 6rem;
  display: flex;
}

.Polaris-EmptyState__Section {
  width: 100%;
  flex-direction: column-reverse;
  flex: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-EmptyState__Section {
    left: 2rem;
  }
}

@media (min-width: 46.5em) {
  .Polaris-EmptyState__Section {
    left: 2rem;
  }
}

.Polaris-EmptyState__Details {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-EmptyState__Details {
    max-width: 40rem;
  }
}

@media (min-width: 30.625em) {
  .Polaris-EmptyState__Details {
    max-width: 40rem;
  }
}

.Polaris-EmptyState__Image {
  width: initial;
  margin: 0;
}

.Polaris-EmptyState__ImageContainer, .Polaris-EmptyState__DetailsContainer {
  flex: auto;
  margin: 0;
  padding: 0;
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-EmptyState__ImageContainer, [data-has-navigation] .Polaris-EmptyState__DetailsContainer {
    flex-basis: 50%;
  }
}

@media (min-width: 46.5em) {
  .Polaris-EmptyState__ImageContainer, .Polaris-EmptyState__DetailsContainer {
    flex-basis: 50%;
  }
}

@media (max-width: 30.625em), (min-width: 48.0625em) and (max-width: 47.125em) {
  [data-has-navigation] .Polaris-EmptyState__ImageContainer, [data-has-navigation] .Polaris-EmptyState__DetailsContainer {
    overflow-x: hidden;
  }
}

@media (max-width: 30.625em) {
  .Polaris-EmptyState__ImageContainer, .Polaris-EmptyState__DetailsContainer {
    overflow-x: hidden;
  }
}

.Polaris-EmptyState--withinContentContainer {
  margin: 0 auto;
  padding-top: 2rem;
  padding-bottom: 6rem;
}

.Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Section {
  position: unset;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}

.Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Details {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Details {
    max-width: 40rem;
  }
}

@media (min-width: 30.625em) {
  .Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Details {
    max-width: 40rem;
  }
}

.Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Image {
  width: initial;
  margin: 0;
}

.Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Content {
  text-transform: initial;
  letter-spacing: initial;
  padding-bottom: .8rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
}

@media (min-width: 40em) {
  .Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Content {
    font-size: 1.4rem;
  }
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-EmptyState--imageContained .Polaris-EmptyState__Image {
    position: initial;
    width: 100%;
  }
}

@media (min-width: 46.5em) {
  .Polaris-EmptyState--imageContained .Polaris-EmptyState__Image {
    position: initial;
    width: 100%;
  }
}

.Polaris-EmptyState--fullWidth .Polaris-EmptyState__Details {
  max-width: 100%;
}

.Polaris-EmptyState__Content {
  text-transform: initial;
  letter-spacing: initial;
  color: var(--p-text-subdued);
  padding-bottom: .8rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
}

@media (min-width: 40em) {
  .Polaris-EmptyState__Content {
    font-size: 1.4rem;
  }
}

.Polaris-EmptyState__Actions {
  margin-top: 1.6rem;
}

.Polaris-EmptyState__FooterContent {
  color: var(--p-text-subdued);
  margin-top: 1.6rem;
}

.Polaris-Truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.Polaris-ExceptionList {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Polaris-ExceptionList__Item {
  color: var(--p-text-subdued);
  padding-left: 2.4rem;
  position: relative;
}

.Polaris-ExceptionList__Item + .Polaris-ExceptionList__Item {
  margin-top: .4rem;
}

.Polaris-ExceptionList__Icon {
  min-width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  margin-right: .4rem;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.Polaris-ExceptionList__Icon svg {
  fill: var(--p-icon-subdued);
}

.Polaris-ExceptionList--statusWarning .Polaris-ExceptionList__Icon svg {
  fill: var(--p-icon-warning);
}

.Polaris-ExceptionList--statusCritical .Polaris-ExceptionList__Icon svg {
  fill: var(--p-icon-critical);
}

.Polaris-ExceptionList__Bullet {
  width: .6rem;
  height: .6rem;
  background-color: var(--p-icon-subdued);
  border-radius: 100%;
}

.Polaris-ExceptionList--statusWarning .Polaris-ExceptionList__Bullet {
  background-color: var(--p-icon-warning);
}

.Polaris-ExceptionList--statusCritical .Polaris-ExceptionList__Bullet {
  background-color: var(--p-icon-critical);
}

.Polaris-ExceptionList__Title + .Polaris-ExceptionList__Description:before {
  content: "–";
  margin: 0 .4rem;
}

.Polaris-ExceptionList--statusWarning .Polaris-ExceptionList__Title, .Polaris-ExceptionList--statusCritical .Polaris-ExceptionList__Title {
  font-weight: 500;
}

.Polaris-ExceptionList--statusWarning .Polaris-ExceptionList__Title {
  color: var(--p-text-warning);
}

.Polaris-ExceptionList--statusCritical .Polaris-ExceptionList__Title {
  color: var(--p-text-critical);
}

.Polaris-Tag {
  max-width: 100%;
  min-height: 2.8rem;
  background-color: var(--p-surface-neutral);
  border-radius: var(--p-border-radius-base);
  color: var(--p-text);
  outline: .1rem solid #0000;
  align-items: center;
  padding: 0 .8rem;
  display: inline-flex;
}

.Polaris-Tag.Polaris-Tag--disabled {
  background: var(--p-surface-neutral-disabled);
  color: var(--p-text-disabled);
  transition: none;
}

.Polaris-Tag.Polaris-Tag--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Tag.Polaris-Tag--clickable {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  cursor: pointer;
  background: none;
  background-color: var(--p-surface-neutral);
  border: none;
  margin: 0;
  padding: 0 .8rem;
  position: relative;
}

.Polaris-Tag.Polaris-Tag--clickable:focus {
  outline: none;
}

.Polaris-Tag.Polaris-Tag--clickable:hover {
  background: var(--p-surface-neutral-hovered);
}

.Polaris-Tag.Polaris-Tag--clickable:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

.Polaris-Tag.Polaris-Tag--clickable:focus:not(:active):after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Tag.Polaris-Tag--clickable:active {
  background: var(--p-surface-neutral-pressed);
}

.Polaris-Tag.Polaris-Tag--clickable:disabled {
  background: var(--p-surface-neutral-disabled);
  cursor: default;
  pointer-events: none;
  color: var(--p-text-disabled);
}

.Polaris-Tag.Polaris-Tag--removable {
  padding-right: 0;
}

.Polaris-Tag.Polaris-Tag--linkable {
  padding: 0;
}

.Polaris-Tag__TagText {
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  overflow: hidden;
}

.Polaris-Tag__Button {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  height: 2.8rem;
  width: 2.8rem;
  background: none;
  border-radius: 0 .3rem .3rem 0;
  border: none;
  flex-shrink: 0;
  margin: 0 0 0 .4rem;
  padding: 0;
  display: block;
  position: relative;
}

.Polaris-Tag__Button svg {
  fill: var(--p-icon);
}

.Polaris-Tag__Button:focus {
  outline: none;
}

.Polaris-Tag__Button:hover {
  background: var(--p-surface-neutral-hovered);
  outline: .1rem solid #0000;
}

.Polaris-Tag__Button:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

.Polaris-Tag__Button:focus:not(:active):after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Tag__Button:active {
  background: var(--p-surface-neutral-pressed);
}

.Polaris-Tag__Button:disabled {
  cursor: default;
  pointer-events: none;
}

.Polaris-Tag__Button:disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Tag__Button.Polaris-Tag--segmented {
  margin-left: -.4rem;
}

.Polaris-Tag__Button.Polaris-Tag--segmented:after {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Polaris-Tag__Link {
  color: var(--p-text);
  border-radius: var(--p-border-radius-base);
  outline: none;
  padding: .4rem .8rem;
  text-decoration: none;
  display: inline-grid;
  position: relative;
}

.Polaris-Tag__Link .Polaris-Tag__LinkText {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Polaris-Tag__Link:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

.Polaris-Tag__Link:focus:not(:active) {
  text-decoration: underline;
}

.Polaris-Tag__Link:focus:not(:active):after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Tag__Link:hover {
  background: var(--p-surface-neutral-hovered);
  text-decoration: underline;
}

.Polaris-Tag__Link.Polaris-Tag--segmented {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Polaris-Tag__Link.Polaris-Tag--segmented:after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: .4rem;
}

.Polaris-Sheet {
  width: 100%;
  height: 100%;
  background-color: var(--p-surface);
  box-shadow: var(--p-modal-shadow);
  position: fixed;
  bottom: 0;
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-Sheet {
    border-left: 0.1rem solid var(--p-border-subdued);
  }
}

@media (min-width: 48.0625em) {
  .Polaris-Sheet {
    width: 38rem;
    right: 0;
  }
}

.Polaris-Sheet:focus {
  outline: none;
}

.Polaris-Sheet__Container {
  z-index: 519;
  position: fixed;
  inset: 0;
}

@media (min-width: 48.0625em) {
  .Polaris-Sheet__Container {
    width: 38rem;
    left: auto;
  }
}

.Polaris-Sheet__Bottom {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
  transform-origin: bottom;
  transition: transform .3s;
}

.Polaris-Sheet--enterBottom {
  transform: translateY(100%);
}

.Polaris-Sheet--enterBottomActive, .Polaris-Sheet--exitBottom {
  transform: translateY(0%);
}

.Polaris-Sheet--exitBottomActive {
  transform: translateY(100%);
}

.Polaris-Sheet__Right {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
  transform-origin: 100%;
  transition: transform .3s;
}

.Polaris-Sheet--enterRight {
  transform: translateX(100%);
}

.Polaris-Sheet--enterRightActive, .Polaris-Sheet--exitRight {
  transform: translateX(0%);
}

.Polaris-Sheet--exitRightActive {
  transform: translateX(100%);
}

.Polaris-Filters-ConnectedFilterControl__Item {
  z-index: 10;
  position: relative;
}

.Polaris-Filters-ConnectedFilterControl__Item--focused {
  z-index: 20;
}

.Polaris-Filters-ConnectedFilterControl__ProxyButtonContainer {
  width: 100%;
  height: 0;
  visibility: hidden;
  display: flex;
  position: absolute;
  top: -100rem;
  left: -100rem;
  overflow: hidden;
}

.Polaris-Filters-ConnectedFilterControl__ProxyButtonContainer > * {
  flex-shrink: 0;
}

.Polaris-Filters-ConnectedFilterControl {
  flex-grow: 1;
  display: flex;
}

.Polaris-Filters-ConnectedFilterControl .Polaris-Filters-ConnectedFilterControl__CenterContainer {
  min-width: 10rem;
  flex: auto;
}

.Polaris-Filters-ConnectedFilterControl.Polaris-Filters-ConnectedFilterControl--right .Polaris-Filters-ConnectedFilterControl__CenterContainer * {
  border-top-right-radius: var(--p-border-radius-base);
  border-bottom-right-radius: var(--p-border-radius-base);
}

.Polaris-Filters-ConnectedFilterControl__CenterContainer + .Polaris-Filters-ConnectedFilterControl__RightContainer, .Polaris-Filters-ConnectedFilterControl__CenterContainer + .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  margin-left: .8rem;
}

.Polaris-Filters-ConnectedFilterControl__RightContainer {
  display: flex;
}

.Polaris-Filters-ConnectedFilterControl__RightContainer .Polaris-Filters-ConnectedFilterControl__Item > div > button {
  margin-right: var(--p-button-group-item-spacing);
  border-radius: 0;
}

.Polaris-Filters-ConnectedFilterControl__RightContainer .Polaris-Filters-ConnectedFilterControl__Item {
  flex-shrink: 0;
}

.Polaris-Filters-ConnectedFilterControl__RightContainer .Polaris-Filters-ConnectedFilterControl__Item:first-of-type > div > button, .Polaris-Filters-ConnectedFilterControl__RightContainer.Polaris-Filters-ConnectedFilterControl--queryFieldHidden .Polaris-Filters-ConnectedFilterControl__Item:first-of-type > div > button {
  border-top-left-radius: var(--p-border-radius-base);
  border-bottom-left-radius: var(--p-border-radius-base);
}

.Polaris-Filters-ConnectedFilterControl__RightContainerWithoutMoreFilters .Polaris-Filters-ConnectedFilterControl__Item:last-child > div > button {
  border-top-right-radius: var(--p-border-radius-base);
  border-bottom-right-radius: var(--p-border-radius-base);
}

.Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer .Polaris-Filters-ConnectedFilterControl__Item > div > button {
  white-space: nowrap;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer.Polaris-Filters-ConnectedFilterControl--onlyButtonVisible .Polaris-Filters-ConnectedFilterControl__Item > div > button {
  border-radius: var(--p-border-radius-base);
}

.Polaris-Filters-ConnectedFilterControl__Wrapper {
  display: flex;
}

.Polaris-Filters-ConnectedFilterControl__AuxiliaryContainer {
  flex-grow: 0;
}

.Polaris-Filters {
  position: relative;
}

.Polaris-Filters__FiltersContainer {
  height: 100%;
  width: 100%;
  flex-direction: column;
  display: flex;
  position: relative;
}

.Polaris-Filters__FiltersContainerHeader {
  width: 100%;
  border-bottom: 0.1rem solid var(--p-divider);
  height: 5.6rem;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 2rem;
  display: flex;
  top: 0;
}

.Polaris-Filters__FiltersDesktopContainerContent {
  width: 100%;
  height: calc(100% - 12.6rem);
  padding: .8rem;
}

.Polaris-Filters__FiltersMobileContainerContent {
  width: 100%;
  height: calc(100% - 5.6rem);
  padding: .8rem;
}

.Polaris-Filters__FiltersContainerFooter {
  width: 100%;
  border-top: 0.1rem solid var(--p-divider);
  height: 7rem;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 1.4rem 2rem;
  display: flex;
  position: absolute;
  bottom: 0;
}

.Polaris-Filters__FiltersMobileContainerFooter {
  width: 100%;
  height: 7rem;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 1.4rem 1.6rem;
  display: flex;
}

.Polaris-Filters__EmptyFooterState {
  border-top: 0.1rem solid var(--p-divider);
  width: 100%;
  justify-content: center;
  padding-top: 1.4rem;
  display: flex;
}

.Polaris-Filters__FilterTriggerContainer {
  position: relative;
}

.Polaris-Filters__FilterTrigger {
  width: 100%;
  color: var(--p-text);
  border-radius: var(--p-border-radius-base);
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 1.4rem 2rem;
  position: relative;
}

.Polaris-Filters__FilterTrigger:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

.Polaris-Filters__FilterTrigger:focus {
  box-shadow: none;
}

.Polaris-Filters__FilterTrigger:hover {
  cursor: pointer;
  background-color: var(--p-surface-hovered);
  outline: .1rem solid #0000;
}

.Polaris-Filters__FilterTrigger:focus:not(:active):after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Filters__FilterTriggerTitle {
  font-size: 1.5rem;
  font-weight: 600;
}

.Polaris-Filters__AppliedFilterBadgeContainer {
  padding-top: .4rem;
  display: flex;
}

.Polaris-Filters--open .Polaris-Filters__AppliedFilterBadgeContainer {
  display: none;
}

.Polaris-Filters__FilterTriggerLabelContainer {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Polaris-Filters--open:before, .Polaris-Filters--open:after {
  content: "";
  width: calc(100% - 3.2rem);
  height: .1rem;
  background-color: var(--p-surface-subdued);
  display: block;
  position: relative;
  left: 1.6rem;
}

.Polaris-Filters--open:before {
  top: 0;
}

.Polaris-Filters--open:after {
  bottom: 0;
}

.Polaris-Filters--open.Polaris-Filters--first:after {
  content: "";
  width: calc(100% - 3.2rem);
  height: .1rem;
  background-color: var(--p-surface-subdued);
  display: block;
  position: relative;
  bottom: 0;
  left: 1.6rem;
}

.Polaris-Filters--open.Polaris-Filters--first:before, .Polaris-Filters--open ~ .Polaris-Filters--open:before {
  display: none;
}

.Polaris-Filters--open.Polaris-Filters--last:before {
  content: "";
  width: calc(100% - 3.2rem);
  height: .1rem;
  background-color: var(--p-surface-subdued);
  display: block;
  position: relative;
  top: 0;
  left: 1.6rem;
}

.Polaris-Filters--open.Polaris-Filters--last:after, .Polaris-Filters--open + .Polaris-Filters--last:before {
  display: none;
}

.Polaris-Filters__FilterNodeContainer {
  padding: .8rem 2rem 2rem;
}

.Polaris-Filters__SearchIcon {
  fill: currentColor;
}

.Polaris-Filters__Backdrop {
  z-index: 518;
  opacity: 0;
  display: block;
  position: fixed;
  inset: 0;
}

.Polaris-Filters__HelpText {
  margin-top: 1rem;
}

.Polaris-Filters__TagsContainer {
  flex-wrap: wrap;
  padding-top: .8rem;
  display: flex;
}

.Polaris-Filters__TagsContainer > * {
  margin-bottom: .8rem;
  margin-right: .8rem;
}

.Polaris-FooterHelp {
  width: 100%;
  justify-content: center;
  margin: 2rem 0;
  display: flex;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-FooterHelp {
    width: auto;
    margin: 2rem;
  }
}

@media (min-width: 30.625em) {
  .Polaris-FooterHelp {
    width: auto;
    margin: 2rem;
  }
}

.Polaris-FooterHelp__Content {
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem 2rem 1.6rem;
  display: inline-flex;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-FooterHelp__Content {
    width: auto;
  }
}

@media (min-width: 30.625em) {
  .Polaris-FooterHelp__Content {
    width: auto;
  }
}

.Polaris-FooterHelp__Icon {
  margin-right: .8rem;
}

.Polaris-FooterHelp__Text {
  text-transform: initial;
  letter-spacing: initial;
  border: none;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
}

@media (min-width: 40em) {
  .Polaris-FooterHelp__Text {
    font-size: 1.4rem;
  }
}

.Polaris-FormLayout {
  margin-top: -1.6rem;
  margin-left: -2rem;
}

.Polaris-FormLayout__Title {
  margin-bottom: -.8rem;
  padding: 1.6rem 2rem 0;
}

.Polaris-FormLayout__Items {
  flex-wrap: wrap;
  display: flex;
}

.Polaris-FormLayout__HelpText {
  color: var(--p-text-subdued);
  padding: .8rem 2rem 0;
}

.Polaris-FormLayout__Item {
  max-width: calc(100% - 2rem);
  flex: 22rem;
  margin-top: 1.6rem;
  margin-left: 2rem;
}

.Polaris-FormLayout--grouped .Polaris-FormLayout__Item {
  min-width: 22rem;
}

.Polaris-FormLayout--condensed .Polaris-FormLayout__Item {
  min-width: 11rem;
  flex-basis: 11rem;
}

.Polaris-Frame-Toast {
  max-width: 50rem;
  border-radius: var(--p-border-radius-wide);
  background: var(--p-surface);
  color: var(--p-text);
  margin-bottom: 2rem;
  padding: .8rem 1.6rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  display: inline-flex;
}

@media (min-width: 40em) {
  .Polaris-Frame-Toast {
    font-size: 2rem;
    line-height: 2.8rem;
  }
}

@media (min-width: 40em) {
  .Polaris-Frame-Toast {
    padding: 1.6rem;
  }
}

@media (forced-colors: active) {
  .Polaris-Frame-Toast {
    border: .2rem solid #0000;
  }
}

.Polaris-Frame-Toast__Action {
  margin-left: 3.2rem;
  margin-right: 1.6rem;
}

.Polaris-Frame-Toast--error {
  background: var(--p-action-critical);
  color: var(--p-text-on-critical);
}

.Polaris-Frame-Toast--error .Polaris-Frame-Toast__CloseButton {
  fill: var(--p-icon-on-critical);
}

.Polaris-Frame-Toast__CloseButton {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  color: currentColor;
  cursor: pointer;
  background: none;
  border: none;
  flex-direction: column;
  justify-content: flex-start;
  align-self: center;
  margin-right: -1.6rem;
  padding: 0 1.6rem;
  display: flex;
}

.Polaris-Frame-Toast__CloseButton svg {
  fill: currentColor;
}

@media (min-width: 40em) {
  .Polaris-Frame-Toast__CloseButton {
    align-self: flex-start;
    margin: -.8rem -1.6rem -.8rem 0;
    padding: 1.2rem 1.6rem .8rem;
  }
}

.Polaris-Frame-Toast__CloseButton:focus {
  outline: none;
}

:root {
  --toast-translate-y-out: 15rem;
  --toast-translate-y-in: 0;
}

.Polaris-Frame-ToastManager {
  z-index: 520;
  text-align: center;
  left: 0;
  right: 0;
  bottom: var(--global-ribbon-height);
  flex-direction: column;
  align-items: center;
  display: flex;
  position: fixed;
}

.Polaris-Frame-ToastManager__ToastWrapper {
  opacity: 0;
  transform: translateY(var(--toast-translate-y-out));
  transition: transform .4s, opacity .4s;
  display: inline-flex;
  position: absolute;
}

.Polaris-Frame-ToastManager__ToastWrapper--enter, .Polaris-Frame-ToastManager__ToastWrapper--exit {
  transform: translateY(var(--toast-translate-y-out));
  opacity: 0;
}

.Polaris-Frame-ToastManager--toastWrapperEnterDone {
  transform: translateY(var(--toast-translate-y-in));
  opacity: 1;
}

.Polaris-Frame-Loading {
  height: .3rem;
  background-color: var(--p-surface);
  opacity: 1;
  overflow: hidden;
}

.Polaris-Frame-Loading__Level {
  width: 100%;
  height: 100%;
  transform-origin: 0;
  background-color: var(--p-icon-success);
  transition: transform .5s linear;
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-Frame-Loading__Level {
    background-color: highlight;
  }
}

.Polaris-Modal-Dialog__Container {
  z-index: 519;
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
  position: fixed;
  inset: 0;
}

@media (min-width: 48.0625em) {
  .Polaris-Modal-Dialog__Container {
    justify-content: center;
  }
}

.Polaris-Modal-Dialog:focus {
  outline: none;
}

.Polaris-Modal-Dialog__Modal {
  width: 100%;
  max-height: calc(100vh - 6rem);
  background: var(--p-surface);
  box-shadow: var(--p-modal-shadow);
  flex-direction: column;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (forced-colors: active) {
  .Polaris-Modal-Dialog__Modal {
    border: .1rem solid #0000;
  }
}

@media (max-width: 48.0525em) {
  .Polaris-Modal-Dialog__Modal {
    max-height: 100%;
    bottom: 0;
  }
}

@media (min-width: 48.0625em) {
  .Polaris-Modal-Dialog__Modal {
    max-width: 62rem;
    border-radius: var(--p-border-radius-wide);
    margin: 0 auto;
    position: relative;
  }
}

@media (min-width: 48.0625em) and (min-height: 660px) {
  .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--limitHeight {
    max-height: 60rem;
  }
}

@media (min-width: 48.0625em) {
  .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeSmall {
    max-width: calc(100% - 6.4rem);
  }
}

@media (min-width: 27.75em) {
  .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeSmall {
    max-width: 38rem;
  }
}

@media (min-width: 48.0625em) {
  .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeLarge {
    max-width: calc(100% - 6.4rem);
  }
}

@media (min-width: 65.25em) {
  .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeLarge {
    max-width: 98rem;
  }
}

.Polaris-Modal-Dialog--animateFadeUp {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform, opacity;
  opacity: 1;
  transition: transform .2s, opacity .2s;
  transform: translateY(0);
}

.Polaris-Modal-Dialog--animateFadeUp.Polaris-Modal-Dialog--entering, .Polaris-Modal-Dialog--animateFadeUp.Polaris-Modal-Dialog--exiting, .Polaris-Modal-Dialog--animateFadeUp.Polaris-Modal-Dialog--exited {
  opacity: 0;
  transform: translateY(20rem);
}

.Polaris-Modal-Dialog--animateFadeUp.Polaris-Modal-Dialog--entered {
  opacity: 1;
  transform: translateY(0);
}

.Polaris-Modal-Footer {
  width: 100%;
  min-height: 6.4rem;
  border-top: 0.1rem solid var(--p-divider);
  align-self: flex-end;
  align-items: center;
  padding: 1.6rem;
  display: flex;
}

.Polaris-Modal-Footer__FooterContent {
  width: 100%;
}

.Polaris-Modal-CloseButton {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  background: none;
  border-radius: .6rem;
  border: none;
  margin: 0 -.8rem 0 2rem;
  padding: .8rem;
  position: relative;
}

.Polaris-Modal-CloseButton:focus {
  outline: none;
}

.Polaris-Modal-CloseButton:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

.Polaris-Modal-CloseButton:hover {
  background: var(--p-surface-hovered);
}

.Polaris-Modal-CloseButton:hover svg {
  fill: var(--p-icon-hovered);
}

.Polaris-Modal-CloseButton:active {
  background: var(--p-surface-pressed);
}

.Polaris-Modal-CloseButton:focus:not(:active):after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Modal-Header {
  border-bottom: 0.1rem solid var(--p-divider);
  flex-shrink: 0;
  align-items: flex-start;
  padding: 1.6rem 2rem;
  display: flex;
}

.Polaris-Modal-Header--titleHidden {
  z-index: 1;
  position: absolute;
  right: .8rem;
}

.Polaris-Modal-Header--titleHidden .Polaris-Modal-Header__Title {
  display: none;
}

.Polaris-Modal-Header__Title {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  flex: 1;
  margin-top: .4rem;
}

.Polaris-Modal-Section {
  flex: none;
  padding: 2rem;
}

.Polaris-Modal-Section:not(:last-of-type) {
  border-bottom: 0.1rem solid var(--p-divider);
}

.Polaris-Modal-Section.Polaris-Modal-Section--subdued {
  background: var(--p-surface-subdued);
}

.Polaris-Modal-Section.Polaris-Modal-Section--flush {
  padding: 0;
}

.Polaris-Modal__BodyWrapper {
  -webkit-overflow-scrolling: touch;
  flex-grow: 1;
  display: flex;
  overflow-x: hidden;
}

.Polaris-Modal__Body {
  width: 100%;
}

.Polaris-Modal__IFrame {
  width: 62rem;
  max-width: 100vw;
  border: none;
  display: block;
}

@media (min-width: 48.0625em) {
  .Polaris-Modal__IFrame {
    max-width: 62rem;
  }
}

.Polaris-Modal__Spinner {
  text-align: center;
  margin: 1.6rem;
}

.Polaris-Frame-ContextualSaveBar {
  height: 5.6rem;
  background: var(--p-surface);
  box-shadow: 0 .2rem .4rem #0000001a;
  display: flex;
}

.Polaris-Frame-ContextualSaveBar .Polaris-Frame-ContextualSaveBar__LogoContainer {
  border-right: none;
}

.Polaris-Frame-ContextualSaveBar .Polaris-Frame-ContextualSaveBar__ContextControl {
  opacity: .3;
  pointer-events: none;
}

.Polaris-Frame-ContextualSaveBar .Polaris-Frame-ContextualSaveBar__Message {
  color: var(--p-text);
}

@media (forced-colors: active) {
  .Polaris-Frame-ContextualSaveBar {
    border: .1rem solid #0000;
  }
}

.Polaris-Frame-ContextualSaveBar__LogoContainer {
  display: none;
}

@media (min-width: 48.0625em) {
  .Polaris-Frame-ContextualSaveBar__LogoContainer {
    height: 100%;
    background-color: #0000;
    flex: 0 0 24rem;
    align-items: center;
    padding: 0 1.6rem;
    display: flex;
  }
}

.Polaris-Frame-ContextualSaveBar__Contents {
  min-width: .1rem;
  max-width: 99.8rem;
  height: 100%;
  flex: auto;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 1.6rem;
  display: flex;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Frame-ContextualSaveBar__Contents {
    padding: 0 2rem;
  }
}

@media (min-width: 30.625em) {
  .Polaris-Frame-ContextualSaveBar__Contents {
    padding: 0 2rem;
  }
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-Frame-ContextualSaveBar__Contents {
    padding: 0 3.2rem;
  }
}

@media (min-width: 46.5em) {
  .Polaris-Frame-ContextualSaveBar__Contents {
    padding: 0 3.2rem;
  }
}

.Polaris-Frame-ContextualSaveBar--fullWidth {
  max-width: none;
  padding: 0 1.6rem;
}

.Polaris-Frame-ContextualSaveBar__Message {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--p-text-subdued);
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 2.4rem;
  overflow: hidden;
}

@media (min-width: 40em) {
  .Polaris-Frame-ContextualSaveBar__Message {
    font-size: 1.6rem;
  }
}

.Polaris-Frame-ContextualSaveBar__ActionContainer {
  flex-shrink: 0;
}

.Polaris-Frame-ContextualSaveBar__Action {
  margin-left: .8rem;
}

.Polaris-Frame-ContextualSaveBar__ContextControl {
  display: none;
}

@media (min-width: 48.0625em) {
  .Polaris-Frame-ContextualSaveBar__ContextControl {
    width: 24rem;
    display: block;
  }
}

.Polaris-Frame-CSSAnimation--startFade {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: opacity;
  pointer-events: none;
  transition: opacity .3s cubic-bezier(0, 0, .42, 1);
}

.Polaris-Frame-CSSAnimation--endFade {
  opacity: 1;
  pointer-events: auto;
}

.Polaris-Frame {
  width: 100%;
  min-height: 100vh;
  background-color: var(--p-background);
  display: flex;
}

@media print {
  .Polaris-Frame {
    background-color: #0000;
  }
}

@media (min-width: 48.0625em) {
  .Polaris-Frame {
    width: calc(100% - var(--p-frame-offset));
    margin-left: var(--p-frame-offset);
  }
}

.Polaris-Frame__Navigation {
  z-index: 516;
  height: 100%;
  outline: none;
  flex: none;
  align-items: stretch;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(0%);
}

@media print {
  .Polaris-Frame__Navigation {
    display: none !important;
  }
}

@media (min-width: 48.0625em) {
  .Polaris-Frame__Navigation {
    z-index: 1;
    left: var(--p-frame-offset);
    display: flex;
  }

  .Polaris-Frame--hasTopBar .Polaris-Frame__Navigation {
    height: calc(100% - 5.6rem);
    top: 5.6rem;
  }
}

.Polaris-Frame__Navigation:focus {
  outline: none;
}

.Polaris-Frame__Navigation--enter, .Polaris-Frame__Navigation--enterActive, .Polaris-Frame__Navigation--exit, .Polaris-Frame__Navigation--exitActive {
  display: flex;
}

.Polaris-Frame__Navigation--enter {
  transform: translateX(-100%);
}

.Polaris-Frame__Navigation--enterActive {
  transition: transform .3s cubic-bezier(0, 0, .42, 1);
  transform: translateX(0%);
}

.Polaris-Frame__Navigation--exit {
  transform: translateX(0%);
}

.Polaris-Frame__Navigation--exitActive {
  transition: transform .3s cubic-bezier(0, 0, .42, 1);
  transform: translateX(-100%);
}

.Polaris-Frame__NavigationDismiss {
  width: 3.2rem;
  height: 3.2rem;
  opacity: 0;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: opacity;
  cursor: pointer;
  background: none;
  border-radius: 50%;
  border: none;
  margin: 1.6rem;
  padding: 0;
  transition: opacity .1s;
  position: absolute;
  top: 0;
  left: 100%;
}

@media print {
  .Polaris-Frame__NavigationDismiss {
    display: none !important;
  }
}

.Polaris-Frame__NavigationDismiss svg {
  fill: var(--p-surface);
}

.Polaris-Frame__NavigationDismiss:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

.Polaris-Frame__Navigation--visible .Polaris-Frame__NavigationDismiss {
  pointer-events: all;
  opacity: 1;
}

.Polaris-Frame__NavigationDismiss:focus {
  border-radius: .3rem;
  outline: none;
  position: absolute;
}

.Polaris-Frame__NavigationDismiss:focus:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

@media (min-width: 48.0625em) {
  .Polaris-Frame__NavigationDismiss {
    display: none;
  }
}

.Polaris-Frame__NavigationDismiss:hover, .Polaris-Frame__NavigationDismiss:active {
  background-color: var(--p-overlay);
}

.Polaris-Frame__TopBar {
  z-index: 512;
  width: 100%;
  height: 5.6rem;
  position: fixed;
  top: 0;
  left: 0;
}

@media print {
  .Polaris-Frame__TopBar {
    display: none !important;
  }
}

@media (min-width: 48.0625em) {
  .Polaris-Frame__TopBar {
    left: var(--p-frame-offset);
    width: calc(100% - var(--p-frame-offset));
  }
}

.Polaris-Frame__ContextualSaveBar {
  z-index: 513;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

@media (min-width: 48.0625em) {
  .Polaris-Frame__ContextualSaveBar {
    left: var(--p-frame-offset);
    width: calc(100% - var(--p-frame-offset));
  }
}

.Polaris-Frame__Main {
  min-width: 0;
  max-width: 100%;
  padding-right: 0;
  padding-right: calc(constant(safe-area-inset-right));
  padding-right: calc(env(safe-area-inset-right));
  padding-left: 0;
  padding-left: calc(constant(safe-area-inset-left));
  padding-left: calc(env(safe-area-inset-left));
  padding-bottom: 0;
  padding-bottom: calc(constant(safe-area-inset-bottom));
  padding-bottom: calc(env(safe-area-inset-bottom));
  flex: 1;
  align-items: stretch;
  display: flex;
}

@media (min-width: 48.0625em) {
  .Polaris-Frame--hasNav .Polaris-Frame__Main {
    padding-left: 24rem;
    padding-left: calc(24rem + constant(safe-area-inset-left));
    padding-left: calc(24rem + env(safe-area-inset-left));
  }
}

@media print and (min-width: 48.0625em) {
  .Polaris-Frame--hasNav .Polaris-Frame__Main {
    padding-left: 0;
  }
}

.Polaris-Frame--hasTopBar .Polaris-Frame__Main {
  padding-top: 5.6rem;
}

@media print {
  .Polaris-Frame--hasTopBar .Polaris-Frame__Main {
    padding-top: 0;
  }
}

.Polaris-Frame__Content {
  padding-bottom: var(--global-ribbon-height, 0);
  min-width: 0;
  max-width: 100%;
  flex: 1;
  position: relative;
}

.Polaris-Frame__GlobalRibbonContainer {
  z-index: 510;
  width: 100%;
  position: fixed;
  bottom: 0;
}

@media (min-width: 48.0625em) {
  .Polaris-Frame__GlobalRibbonContainer {
    left: var(--p-frame-offset);
  }

  .Polaris-Frame--hasNav .Polaris-Frame__GlobalRibbonContainer {
    left: calc(24rem + var(--p-frame-offset));
    left: calc(24rem + var(--p-frame-offset));
    left: calc(24rem + var(--p-frame-offset) + constant(safe-area-inset-left));
    left: calc(24rem + var(--p-frame-offset) + env(safe-area-inset-left));
    width: calc(100% - 24rem - var(--p-frame-offset));
  }
}

.Polaris-Frame__LoadingBar {
  z-index: 514;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@media print {
  .Polaris-Frame__LoadingBar {
    display: none !important;
  }
}

@media (min-width: 48.0625em) {
  .Polaris-Frame--hasNav .Polaris-Frame__LoadingBar {
    left: var(--p-frame-offset);
  }

  .Polaris-Frame--hasTopBar .Polaris-Frame__LoadingBar {
    z-index: var(--p-override-loading-z-index, 511);
  }
}

.Polaris-Frame__Skip {
  z-index: 517;
  top: 1rem;
  left: calc(0.8rem + var(--p-frame-offset));
  opacity: 0;
  pointer-events: none;
  position: fixed;
}

.Polaris-Frame__Skip.Polaris-Frame--focused {
  pointer-events: all;
  opacity: 1;
}

.Polaris-Frame__Skip.Polaris-Frame--focused > a:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Frame__Skip > a {
  min-height: 3.6rem;
  min-width: 3.6rem;
  background: var(--p-surface);
  box-shadow: var(--p-button-drop-shadow);
  border-radius: var(--p-border-radius-base);
  color: var(--p-text);
  border: 0.1rem solid var(--p-border-neutral-subdued);
  border-top-color: var(--p-border-subdued);
  border-bottom-color: var(--p-border-shadow-subdued);
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  font-size: 1.5rem;
  line-height: 1;
  font-weight: var(--p-button-font-weight);
  text-transform: initial;
  letter-spacing: initial;
  color: var(--p-text);
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: .7rem 1.6rem;
  line-height: 1.6rem;
  text-decoration: none;
  display: inline-flex;
  position: relative;
}

.Polaris-Frame__Skip > a svg {
  fill: var(--p-icon);
}

.Polaris-Frame__Skip > a:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.2rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.2rem;
}

.Polaris-Frame__Skip > a:hover {
  background: var(--p-action-secondary-hovered);
  outline: .1rem solid #0000;
}

.Polaris-Frame__Skip > a:focus {
  box-shadow: var(--p-button-drop-shadow);
  outline: none;
}

.Polaris-Frame__Skip > a:focus:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Frame__Skip > a:active {
  background: var(--p-action-secondary-pressed);
  box-shadow: var(--p-button-drop-shadow);
}

.Polaris-Frame__Skip > a:active:after {
  box-shadow: none;
  border: none;
}

.Polaris-Frame__Skip > a.Polaris-Frame--pressed {
  background: var(--p-action-secondary-depressed);
  box-shadow: var(--p-button-pressed-inner-shadow);
  color: var(--p-text-on-primary);
  border-color: var(--p-border-depressed);
}

.Polaris-Frame__Skip > a.Polaris-Frame--pressed svg {
  fill: currentColor;
}

@media (-ms-high-contrast: active) {
  .Polaris-Frame__Skip > a {
    border: 0.1rem solid windowText;
  }
}

@media (min-width: 40em) {
  .Polaris-Frame__Skip > a {
    font-size: 1.4rem;
  }
}

.Polaris-Frame__Skip > a:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.2rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.2rem;
}

.Polaris-Frame__Skip > a:focus {
  border-color: none;
  box-shadow: none;
}

.Polaris-IndexTable {
  border-radius: inherit;
  position: relative;
  overflow: hidden;
}

.Polaris-IndexTable__LoadingContainer--enter {
  opacity: 0;
  transform: translateY(-100%);
}

.Polaris-IndexTable--loadingContainerEnterActive {
  opacity: 1;
  transition: opacity .1s ease-out, transform .1s ease-out;
  transform: translateY(0);
}

.Polaris-IndexTable__LoadingContainer--exit {
  opacity: 1;
  transform: translateY(0);
}

.Polaris-IndexTable--loadingContainerExitActive {
  opacity: 0;
  transition: opacity .1s ease-in, transform .1s ease-in;
  transform: translateY(-100%);
}

.Polaris-IndexTable__LoadingPanel {
  z-index: 37;
  width: 100%;
  background: var(--p-surface);
  box-shadow: 0 0 0 .1rem #3f3f440d, 0 .1rem .3rem #3f3f4426;
  justify-content: center;
  align-items: center;
  padding: .8rem 1.6rem;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.Polaris-IndexTable__LoadingPanel .Polaris-IndexTable__LoadingPanelRow {
  width: 100%;
  background: var(--p-surface-highlight-subdued);
  border-radius: var(--p-border-radius-base);
  flex-wrap: nowrap;
  padding: .8rem .8rem .4rem;
  display: flex;
}

.Polaris-IndexTable__LoadingPanelText {
  color: var(--p-text);
  margin-left: 1.2rem;
}

.Polaris-IndexTable__Table {
  width: 100%;
  min-width: 100%;
  border-collapse: collapse;
}

.Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableCell--first, .Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell, .Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableHeading--first, .Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableHeading--second {
  visibility: visible;
  background-color: var(--p-surface);
}

.Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableCell--first, .Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableHeading--first {
  filter: drop-shadow(0.1rem 0 0 var(--p-divider));
}

@media (min-width: 28.625em) {
  .Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell, .Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableHeading--second {
    filter: drop-shadow(0.1rem 0 0 var(--p-divider));
  }
}

.Polaris-IndexTable__Table--scrolling.Polaris-IndexTable__Table--unselectable .Polaris-IndexTable__TableHeading--second, .Polaris-IndexTable__Table--scrolling.Polaris-IndexTable__Table--unselectable .Polaris-IndexTable__TableCell:first-child {
  visibility: visible;
  filter: drop-shadow(0.1rem 0 0 var(--p-divider));
}

.Polaris-IndexTable__TableRow {
  background-color: var(--p-surface);
  cursor: pointer;
  filter: drop-shadow(0 -0.1rem 0 var(--p-divider));
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--unclickable {
  cursor: auto;
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable--statusSuccess, .Polaris-IndexTable__TableRow.Polaris-IndexTable--statusSuccess .Polaris-IndexTable__TableCell--first, .Polaris-IndexTable__TableRow.Polaris-IndexTable--statusSuccess .Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell {
  background-color: var(--p-surface-primary-selected);
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable--statusSubdued, .Polaris-IndexTable__TableRow.Polaris-IndexTable--statusSubdued .Polaris-IndexTable__TableCell--first, .Polaris-IndexTable__TableRow.Polaris-IndexTable--statusSubdued .Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell {
  background-color: var(--p-surface-subdued);
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--hovered, .Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--hovered .Polaris-IndexTable__TableCell--first, .Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--hovered .Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell {
  background-color: var(--p-surface-hovered);
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--selected, .Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--selected .Polaris-IndexTable__TableHeading--first, .Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--selected .Polaris-IndexTable__TableHeading--second, .Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--selected .Polaris-IndexTable__TableCell--first, .Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--selected .Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell {
  background-color: var(--p-surface-selected);
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--hovered.Polaris-IndexTable__TableRow--selected, .Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--hovered.Polaris-IndexTable__TableRow--selected .Polaris-IndexTable__TableCell--first, .Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--hovered.Polaris-IndexTable__TableRow--selected .Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell {
  background-color: var(--p-surface-selected-hovered);
}

.Polaris-IndexTable__TableRow--subdued {
  color: var(--p-text-subdued);
}

.Polaris-IndexTable__TableHeading {
  text-align: left;
  white-space: nowrap;
  border: none;
  padding: 1.6rem;
  font-weight: 500;
}

.Polaris-IndexTable__TableHeading--first {
  padding-left: 1.4rem;
  padding-right: 1.8rem;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}

.Polaris-IndexTable__ColumnHeaderCheckboxWrapper {
  display: flex;
}

.Polaris-IndexTable__FirstStickyHeaderElement {
  margin-left: -.2rem;
  padding-right: .2rem;
}

.Polaris-IndexTable__TableHeading--second:not(.Polaris-IndexTable__TableHeading--unselectable) {
  padding-left: 0;
}

@media (min-width: 28.625em) {
  .Polaris-IndexTable__TableHeading--second:not(.Polaris-IndexTable__TableHeading--unselectable) {
    z-index: auto;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
  }
}

.Polaris-IndexTable__TableHeading--second.Polaris-IndexTable__TableHeading--unselectable {
  z-index: auto;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}

.Polaris-IndexTable__TableCell {
  z-index: 1;
  text-align: left;
  white-space: nowrap;
  padding: .8rem 1.6rem;
}

.Polaris-IndexTable__TableCell--flush {
  padding: 0;
}

.Polaris-IndexTable__TableCell--first {
  z-index: 31;
  padding: 0;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}

.Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell {
  left: var(--p-checkbox-offset);
  padding-left: 0;
}

@media (min-width: 28.625em) {
  .Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell {
    z-index: 31;
    padding: 0;
    position: -webkit-sticky;
    position: sticky;
  }
}

.Polaris-IndexTable__Table--unselectable .Polaris-IndexTable__TableCell:first-child {
  background-color: var(--p-surface);
  z-index: 31;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}

.Polaris-IndexTable__Table--unselectable .Polaris-IndexTable--statusSuccess .Polaris-IndexTable__TableCell:first-child {
  background-color: var(--p-surface-primary-selected);
}

.Polaris-IndexTable__Table--unselectable .Polaris-IndexTable--statusSubdued .Polaris-IndexTable__TableCell:first-child {
  background-color: var(--p-surface-subdued);
}

.Polaris-IndexTable__Table--unselectable .Polaris-IndexTable__TableRow--hovered .Polaris-IndexTable__TableCell:first-child {
  background-color: var(--p-surface-hovered);
}

@media (min-width: 28.625em) {
  .Polaris-IndexTable--tableStickyScrolling .Polaris-IndexTable__TableCell:last-child, .Polaris-IndexTable--tableStickyScrolling .Polaris-IndexTable__TableHeading--last {
    filter: drop-shadow(-0.1rem 0 0 var(--p-divider));
  }
}

@media (min-width: 28.625em) {
  .Polaris-IndexTable--tableStickyLast .Polaris-IndexTable__TableCell:last-child {
    background-color: var(--p-surface);
    z-index: 31;
    position: -webkit-sticky;
    position: sticky;
    right: 0;
  }
}

@media (min-width: 28.625em) {
  .Polaris-IndexTable--tableStickyLast .Polaris-IndexTable__TableHeading--last {
    background-color: var(--p-surface);
    z-index: auto;
    position: -webkit-sticky;
    position: sticky;
    right: 0;
  }
}

.Polaris-IndexTable--tableStickyLast .Polaris-IndexTable--statusSuccess .Polaris-IndexTable__TableCell:last-child {
  background-color: var(--p-surface-primary-selected);
}

.Polaris-IndexTable--tableStickyLast .Polaris-IndexTable--statusSubdued .Polaris-IndexTable__TableCell:last-child {
  background-color: var(--p-surface-subdued);
}

.Polaris-IndexTable--tableStickyLast .Polaris-IndexTable__TableRow--hovered .Polaris-IndexTable__TableCell:last-child {
  background-color: var(--p-surface-hovered);
}

.Polaris-IndexTable--tableStickyLast .Polaris-IndexTable__TableRow--selected .Polaris-IndexTable__TableCell:last-child {
  background-color: var(--p-surface-selected);
}

.Polaris-IndexTable__StickyTable {
  visibility: hidden;
  z-index: 37;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}

.Polaris-IndexTable__StickyTableHeader {
  width: 100%;
  display: flex;
  position: absolute;
}

.Polaris-IndexTable__StickyTableHeader:not(.Polaris-IndexTable__StickyTableHeader--isSticky) {
  top: -100rem;
  left: -100rem;
}

.Polaris-IndexTable__StickyTableColumnHeader {
  flex: none;
}

.Polaris-IndexTable__StickyTableColumnHeader--isScrolling {
  box-shadow: 0.1rem 0.1rem 0 0 var(--p-divider), 0.1rem 0 0.1rem 0.1rem rgba(63, 63, 68, 0.05),
    0.1rem 0 0.3rem 0 rgba(63, 63, 68, 0.15);
}

.Polaris-IndexTable__StickyTableHeadings {
  flex: auto;
  display: flex;
  overflow: hidden;
}

.Polaris-IndexTable__StickyTableHeading--second {
  padding-left: 0;
}

@media (min-width: 28.625em) {
  .Polaris-IndexTable__StickyTableHeading--second {
    display: none;
  }
}

.Polaris-IndexTable__StickyTableHeading--second.Polaris-IndexTable--unselectable {
  display: none;
}

.Polaris-IndexTable--stickyTableHeadingSecondScrolling {
  padding: 0 .06rem 0 1.6rem;
  display: none;
}

@media (min-width: 28.625em) {
  .Polaris-IndexTable--stickyTableHeadingSecondScrolling {
    display: block;
  }
}

.Polaris-IndexTable__StickyTableHeader--isSticky {
  visibility: visible;
  background-color: var(--p-surface);
  box-shadow: 0 0 0 .1rem #3f3f440d, 0 .1rem .3rem #3f3f4426;
}

.Polaris-IndexTable:hover .Polaris-IndexTable__ScrollLeft, .Polaris-IndexTable:hover .Polaris-IndexTable__ScrollRight, .Polaris-IndexTable .Polaris-IndexTable__ScrollRight--onboarding {
  display: block;
}

.Polaris-IndexTable__BulkActionsWrapper {
  visibility: visible;
  z-index: 36;
  background: var(--p-surface);
  padding: .8rem;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}

.Polaris-IndexTable__ScrollBarContainer {
  z-index: 35;
  border-top: 0.1rem solid var(--p-divider);
  background-color: var(--p-surface);
  border-bottom-right-radius: var(--p-border-radius-base);
  border-bottom-left-radius: var(--p-border-radius-base);
  padding: .2rem;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

.Polaris-IndexTable--scrollBarContainerCondensed {
  visibility: hidden;
  pointer-events: none;
}

.Polaris-IndexTable__ScrollBar {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: scroll;
}

.Polaris-IndexTable__ScrollBar::-webkit-scrollbar-track {
  background-color: #0000;
  border-radius: .4rem;
}

.Polaris-IndexTable__ScrollBar::-webkit-scrollbar {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  height: .8rem;
  width: .8rem;
  background-color: #0000;
}

.Polaris-IndexTable__ScrollBar::-webkit-scrollbar-thumb {
  background-color: var(--p-border-neutral-subdued);
  border-radius: .4rem;
  transition: background-color .1s ease-out;
}

.Polaris-IndexTable__ScrollBar:hover::-webkit-scrollbar-thumb {
  background-color: var(--p-border-depressed);
}

.Polaris-IndexTable--disableTextSelection {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Polaris-IndexTable--selectMode {
  margin-bottom: -5.2rem;
  transform: translateY(-5.2rem);
}

.Polaris-IndexTable__EmptySearchResultWrapper {
  padding: 1.6rem;
}

.Polaris-IndexTable--condensedRow {
  width: calc(100% + 3.4rem);
  border-top: 0.1rem solid var(--p-divider);
  filter: none;
  align-items: center;
  transition: transform .2s;
  display: flex;
  transform: translateX(-3.4rem);
}

[data-selectmode="true"] .Polaris-IndexTable--condensedRow {
  transform: none;
}

.Polaris-IndexTable__CondensedList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: hidden;
}

.Polaris-IndexTable__HeaderWrapper {
  width: 100%;
  min-height: 5.6rem;
  background-color: var(--p-surface);
  align-items: flex-start;
  padding: 1rem 1.6rem;
  display: flex;
  position: relative;
}

.Polaris-IndexTable__HeaderWrapper.Polaris-IndexTable--unselectable {
  min-height: auto;
  padding: 0;
}

.Polaris-IndexTable__StickyTable--condensed {
  visibility: visible;
}

.Polaris-IndexTable__StickyTableHeader--condensed {
  padding: 1.6rem 1.6rem .8rem;
}

.Polaris-IndexTable__ScrollBarContent {
  height: .1rem;
  width: var(--p-scroll-bar-content-width);
}

.Polaris-IndexTable-Checkbox__TableCellContentContainer {
  align-items: center;
  display: flex;
}

.Polaris-IndexTable-Checkbox__Wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
}

.Polaris-IndexTable-Checkbox--expanded {
  width: 3.2rem;
  height: 3.2rem;
  padding-left: 1.6rem;
}

.Polaris-IndexTable-Checkbox--condensed {
  min-width: 5rem;
  min-height: 4.4rem;
  margin-right: -1.6rem;
}

.Polaris-IndexTable-ScrollContainer {
  overscroll-behavior-x: contain;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: auto;
}

.Polaris-IndexTable-ScrollContainer::-webkit-scrollbar {
  display: none;
}

.Polaris-KeyboardKey {
  height: 2.4rem;
  background-color: var(--p-surface);
  box-shadow: 0 0 0 0.1rem var(--p-border-subdued), 0 0.2rem 0 0 var(--p-surface),
    0 0.2rem 0 0.1rem var(--p-border-subdued);
  border-radius: var(--p-border-radius-base);
  color: var(--p-text-subdued);
  text-align: center;
  min-width: 2.4rem;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 .2rem .2rem;
  padding: 0 .4rem;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 2.4rem;
  display: inline-block;
}

.Polaris-Layout {
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: -1.6rem;
  margin-left: -2rem;
  display: flex;
}

@media print {
  body .Polaris-Layout {
    font-size: 1.1rem;
    line-height: 1.6rem;
  }

  .Polaris-Layout a, .Polaris-Layout button {
    color: var(--p-text);
  }
}

.Polaris-Layout__Section {
  min-width: 51%;
  flex: 2 2 48rem;
}

@media print {
  .Polaris-Layout__Section {
    flex: 2 2 36rem;
  }
}

.Polaris-Layout__Section--secondary {
  min-width: 0;
  flex: 24rem;
}

.Polaris-Layout__Section--fullWidth {
  flex: 100%;
}

.Polaris-Layout__Section--oneHalf {
  min-width: 0;
  flex: 45rem;
}

.Polaris-Layout__Section--oneThird {
  min-width: 0;
  flex: 24rem;
}

.Polaris-Layout__AnnotatedSection {
  min-width: 0;
  flex: 100%;
}

.Polaris-Layout__Section, .Polaris-Layout__AnnotatedSection {
  max-width: calc(100% - 2rem);
  margin-top: 1.6rem;
  margin-left: 2rem;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Layout__Section + .Polaris-Layout__AnnotatedSection, [data-has-navigation] .Polaris-Layout__AnnotatedSection + .Polaris-Layout__AnnotatedSection {
    border-top: 0.1rem solid var(--p-divider);
    padding-top: 1.6rem;
  }
}

@media (min-width: 30.625em) {
  .Polaris-Layout__Section + .Polaris-Layout__AnnotatedSection, .Polaris-Layout__AnnotatedSection + .Polaris-Layout__AnnotatedSection {
    border-top: 0.1rem solid var(--p-divider);
    padding-top: 1.6rem;
  }
}

.Polaris-Layout__AnnotationWrapper {
  flex-wrap: wrap;
  margin-top: -1.6rem;
  margin-left: -2rem;
  display: flex;
}

.Polaris-Layout__AnnotationContent {
  flex: 2 2 48rem;
}

.Polaris-Layout__Annotation {
  flex: 24rem;
  padding: 1.6rem 2rem 0;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Layout__Annotation {
    padding: 1.6rem 0 0;
  }
}

@media (min-width: 30.625em) {
  .Polaris-Layout__Annotation {
    padding: 1.6rem 0 0;
  }
}

@media (max-width: 48.0625em) and (min-width: 50em), (min-width: 65em) {
  [data-has-navigation] .Polaris-Layout__Annotation {
    padding: 2rem 2rem 2rem 0;
  }
}

@media (min-width: 50em) {
  .Polaris-Layout__Annotation {
    padding: 2rem 2rem 2rem 0;
  }
}

.Polaris-Layout__Annotation, .Polaris-Layout__AnnotationContent {
  min-width: 0;
  max-width: calc(100% - 2rem);
  margin-top: 1.6rem;
  margin-left: 2rem;
}

.Polaris-Layout__AnnotationDescription {
  color: var(--p-text-subdued);
}

.Polaris-Link {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  text-align: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: var(--p-interactive);
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  display: inline;
}

.Polaris-Link:hover {
  color: var(--p-interactive-hovered);
  text-decoration: none;
}

.Polaris-Link:focus:not(:active) {
  outline: var(--p-focused) auto 0.1rem;
}

.Polaris-Link:active {
  color: var(--p-interactive-pressed);
  position: relative;
}

.Polaris-Link:active:before {
  content: "";
  z-index: -1;
  border-radius: .3rem;
  display: block;
  position: absolute;
  inset: -.2rem -.5rem;
}

@media print {
  .Polaris-Link {
    -webkit-text-decoration-color: var(--p-border-neutral-subdued);
    -webkit-text-decoration-color: var(--p-border-neutral-subdued);
    text-decoration-color: var(--p-border-neutral-subdued);
  }
}

.Polaris-Link__IconLockup {
  white-space: nowrap;
  display: inline;
}

.Polaris-Link__IconLockup svg {
  fill: currentColor;
}

.Polaris-Link__IconLockup:before {
  content: "\2060";
}

.Polaris-Link__IconLayout {
  display: inline-flex;
}

.Polaris-Link__IconLayout:before {
  content: "\2060";
}

.Polaris-Link--monochrome, .Polaris-Link--monochrome:hover, .Polaris-Link--monochrome:focus, .Polaris-Link--monochrome:active {
  color: inherit;
}

.Polaris-Link--removeUnderline {
  text-decoration: none;
}

.Polaris-List {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 2rem;
  list-style: disc;
}

.Polaris-List + .Polaris-List {
  margin-top: 1.6rem;
}

.Polaris-List--typeNumber {
  padding-left: 3.2rem;
  list-style: decimal;
}

.Polaris-List__Item {
  margin-bottom: .8rem;
}

.Polaris-List__Item:last-child {
  margin-bottom: 0;
}

.Polaris-List__Item .Polaris-List:first-child {
  margin-top: .8rem;
}

.Polaris-MediaCard {
  height: 100%;
  width: 100%;
  flex-flow: wrap;
  display: flex;
}

.Polaris-MediaCard.Polaris-MediaCard--portrait {
  flex-flow: column;
}

@media (max-width: 50.25em) {
  .Polaris-MediaCard {
    flex-flow: column;
  }
}

.Polaris-MediaCard__MediaContainer {
  border-top-left-radius: var(--p-border-radius-wide);
  border-top-right-radius: var(--p-border-radius-wide);
  overflow: hidden;
}

.Polaris-MediaCard__MediaContainer:not(.Polaris-MediaCard--portrait) {
  flex-basis: 40%;
}

.Polaris-MediaCard__MediaContainer:not(.Polaris-MediaCard--portrait).Polaris-MediaCard--sizeSmall {
  flex-basis: 33%;
}

@media (min-width: 50.25em) {
  .Polaris-MediaCard__MediaContainer:not(.Polaris-MediaCard--portrait) {
    border-top-right-radius: 0;
    border-top-left-radius: var(--p-border-radius-wide);
    border-bottom-left-radius: var(--p-border-radius-wide);
  }
}

.Polaris-MediaCard__InfoContainer {
  position: relative;
}

.Polaris-MediaCard__InfoContainer:not(.Polaris-MediaCard--portrait) {
  flex-basis: 60%;
}

.Polaris-MediaCard__InfoContainer:not(.Polaris-MediaCard--portrait).Polaris-MediaCard--sizeSmall {
  flex-basis: 67%;
}

.Polaris-MediaCard__Popover {
  z-index: 400;
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
}

.Polaris-MediaCard__Heading {
  margin-right: 3.2rem;
}

.Polaris-MediaCard__PrimaryAction {
  margin-right: .8rem;
}

.Polaris-MediaCard__SecondaryAction {
  margin-left: -spacing(tight);
}

.Polaris-MediaCard__ActionContainer {
  padding-top: .8rem;
}

.Polaris-MediaCard__ActionContainer.Polaris-MediaCard--portrait {
  padding-top: 3.2rem;
}

@media (max-width: 50.25em) {
  .Polaris-MediaCard__ActionContainer {
    padding-top: 3.2rem;
  }
}

@keyframes Polaris-Navigation__fade--in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.Polaris-Navigation {
  width: calc(100vw - 6.4rem);
  min-width: 24rem;
  max-width: 36rem;
  height: 100%;
  min-height: 100%;
  background-color: var(--p-background);
  -webkit-overflow-scrolling: touch;
  border-right: 0.1rem solid var(--p-divider);
  padding-bottom: 0;
  padding-bottom: calc(constant(safe-area-inset-bottom));
  padding-bottom: calc(env(safe-area-inset-bottom));
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.Polaris-Navigation:focus {
  outline: none;
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation {
    max-width: 24rem;
    max-width: 24rem;
    max-width: calc(24rem + constant(safe-area-inset-left));
    max-width: calc(24rem + env(safe-area-inset-left));
  }
}

.Polaris-Navigation__UserMenu {
  flex: none;
}

.Polaris-Navigation__ContextControl {
  min-height: 5.6rem;
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__ContextControl {
    display: none;
  }
}

.Polaris-Navigation__PrimaryNavigation {
  max-width: 100%;
  flex-direction: column;
  flex: auto;
  align-items: stretch;
  display: flex;
  overflow: auto;
}

.Polaris-Navigation__PrimaryNavigation:focus {
  outline: none;
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__PrimaryNavigation {
    padding-top: 1.2rem;
  }
}

.Polaris-Navigation__LogoContainer {
  display: none;
}

@media (max-width: 48.0625em) {
  .Polaris-Navigation__LogoContainer {
    height: 5.6rem;
    flex: 0 0 5.6rem;
    flex-basis: calc(5.6rem + constant(safe-area-inset-left));
    flex-basis: calc(5.6rem + env(safe-area-inset-left));
    padding: 0 .8rem 0 1.6rem;
    padding-left: calc(1.6rem + constant(safe-area-inset-left));
    padding-left: calc(1.6rem + env(safe-area-inset-left));
    align-items: center;
    display: flex;
  }
}

.Polaris-Navigation__Logo, .Polaris-Navigation__LogoLink {
  display: block;
}

.Polaris-Navigation__Item {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  max-width: 100%;
  border-radius: var(--p-border-radius-base);
  color: var(--p-text);
  text-align: left;
  background: none;
  border: none;
  flex-grow: 1;
  align-items: flex-start;
  margin: 0 .8rem;
  padding: 0 .4rem 0 1.2rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 3.6rem;
  text-decoration: none;
  display: flex;
  position: relative;
}

.Polaris-Navigation__Item:focus {
  outline: none;
}

.Polaris-Navigation__Item:hover {
  background: var(--p-background-hovered);
  color: var(--p-text);
  text-decoration: none;
}

.Polaris-Navigation__Item:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

.Polaris-Navigation__Item.Polaris-Navigation--keyFocused {
  background: var(--p-background-hovered);
  color: var(--p-text);
  text-decoration: none;
}

.Polaris-Navigation__Item.Polaris-Navigation--keyFocused:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Navigation__Item:active, .Polaris-Navigation__Item:active:hover {
  color: var(--p-text);
  background-color: var(--p-background-pressed);
}

.Polaris-Navigation__Item:active:after, .Polaris-Navigation__Item:active:hover:after {
  content: none;
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__Item {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 3.2rem;
  }
}

.Polaris-Navigation__Item::-moz-focus-inner {
  border: none;
}

.Polaris-Navigation__Item--selected {
  color: var(--p-text-primary);
  background-color: var(--p-background-selected);
  outline: .1rem solid #0000;
  font-weight: 600;
  position: relative;
}

.Polaris-Navigation__Item--selected:before {
  content: "";
  height: 100%;
  width: .3rem;
  background-color: var(--p-action-primary);
  border-top-right-radius: var(--p-border-radius-base);
  border-bottom-right-radius: var(--p-border-radius-base);
  position: absolute;
  top: 0;
  bottom: 0;
  left: -.8rem;
}

.Polaris-Navigation__Item--selected:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

.Polaris-Navigation__Item--selected:hover, .Polaris-Navigation__Item--selected.Polaris-Navigation--keyFocused {
  background-color: var(--p-background-hovered);
  color: var(--p-text-primary-hovered);
}

.Polaris-Navigation__Item--selected .Polaris-Navigation--keyFocused:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Navigation__Item--selected:active, .Polaris-Navigation__Item--selected:active:hover {
  color: var(--p-text-primary);
  background-color: var(--p-background-pressed);
}

.Polaris-Navigation__Item--selected:active:after, .Polaris-Navigation__Item--selected:active:hover:after {
  content: none;
}

.Polaris-Navigation__Item--disabled {
  color: var(--p-text-disabled);
  pointer-events: none;
  opacity: .6;
}

.Polaris-Navigation__Item--disabled .Polaris-Navigation__Icon {
  opacity: .6;
}

.Polaris-Navigation__Badge {
  margin-top: .8rem;
  margin-left: .8rem;
  display: inline-flex;
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__Badge {
    margin-top: .4rem;
  }
}

.Polaris-Navigation__Icon {
  --p-filter-icon: brightness(0) saturate(100%) invert(36%) sepia(13%) saturate(137%) hue-rotate(169deg) brightness(95%)
    contrast(87%);
  --p-filter-icon-action-primary: brightness(0) saturate(100%) invert(20%) sepia(59%) saturate(5557%) hue-rotate(162deg)
    brightness(95%) contrast(101%);
  --p-filter-icon-on-interactive: brightness(0) saturate(100%) invert(100%);
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  align-self: flex-start;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 1.6rem;
}

.Polaris-Navigation__Icon svg {
  fill: var(--p-icon);
}

.Polaris-Navigation__Icon img {
  filter: var(--p-filter-icon);
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__Icon {
    margin-top: .6rem;
    margin-bottom: .6rem;
    margin-right: 1.6rem;
  }
}

.Polaris-Navigation__Item:hover .Polaris-Navigation__Icon svg, .Polaris-Navigation__Item.Polaris-Navigation--keyFocused .Polaris-Navigation__Icon svg {
  fill: var(--p-icon);
}

.Polaris-Navigation__Item:hover .Polaris-Navigation__Icon img, .Polaris-Navigation__Item.Polaris-Navigation--keyFocused .Polaris-Navigation__Icon img {
  filter: var(--p-filter-icon);
}

.Polaris-Navigation__Item--selected .Polaris-Navigation__Icon svg, .Polaris-Navigation__Item--selected:hover .Polaris-Navigation__Icon svg, .Polaris-Navigation--subNavigationActive .Polaris-Navigation__Icon svg, .Polaris-Navigation--subNavigationActive:hover .Polaris-Navigation__Icon svg, .Polaris-Navigation__Item--selected.Polaris-Navigation--keyFocused .Polaris-Navigation__Icon svg {
  fill: var(--p-action-primary);
}

.Polaris-Navigation__Item--selected .Polaris-Navigation__Icon img, .Polaris-Navigation__Item--selected:hover .Polaris-Navigation__Icon img, .Polaris-Navigation--subNavigationActive .Polaris-Navigation__Icon img, .Polaris-Navigation--subNavigationActive:hover .Polaris-Navigation__Icon img, .Polaris-Navigation__Item--selected.Polaris-Navigation--keyFocused .Polaris-Navigation__Icon img {
  filter: var(--p-filter-icon-action-primary);
}

.Polaris-Navigation__Icon svg {
  display: block;
}

.Polaris-Navigation__ExternalIcon {
  height: 4rem;
  align-items: center;
  padding: .4rem .8rem .4rem 1.6rem;
  display: flex;
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__ExternalIcon {
    height: 3.2rem;
  }
}

.Polaris-Navigation__ListItem {
  flex-wrap: wrap;
  display: flex;
  position: relative;
}

.Polaris-Navigation__RollupSection .Polaris-Navigation__ListItem, .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__ListItem {
  opacity: 1;
}

.Polaris-Navigation__ListItem:nth-child(1) {
  animation-delay: 0s;
}

.Polaris-Navigation__ListItem:nth-child(2) {
  animation-delay: 50ms;
}

.Polaris-Navigation__ListItem:nth-child(3) {
  animation-delay: .1s;
}

.Polaris-Navigation__ListItem:nth-child(4) {
  animation-delay: .15s;
}

.Polaris-Navigation__ListItem:nth-child(5) {
  animation-delay: .2s;
}

.Polaris-Navigation__ListItem:nth-child(6) {
  animation-delay: .25s;
}

.Polaris-Navigation__ListItem:nth-child(7) {
  animation-delay: .3s;
}

.Polaris-Navigation__ListItem:nth-child(8) {
  animation-delay: .35s;
}

.Polaris-Navigation__ListItem:nth-child(9) {
  animation-delay: .4s;
}

.Polaris-Navigation__ListItem:nth-child(10) {
  animation-delay: .45s;
}

.Polaris-Navigation__ListItem:nth-child(11) {
  animation-delay: .5s;
}

.Polaris-Navigation__ListItem:nth-child(12) {
  animation-delay: .55s;
}

.Polaris-Navigation__ListItem:not(:first-child) .Polaris-Navigation__Item {
  border-top: 0.1rem solid var(--p-background);
}

.Polaris-Navigation__ListItem--hasAction .Polaris-Navigation__Item {
  max-width: calc(100% - 5.6rem);
}

.Polaris-Navigation__ItemWrapper {
  width: 100%;
  flex-wrap: nowrap;
  display: flex;
}

.Polaris-Navigation__Text {
  flex: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  line-height: 2rem;
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__Text {
    margin-top: .6rem;
    margin-bottom: .6rem;
  }
}

@media (max-width: 48.0625em) {
  .Polaris-Navigation__Text {
    font-size: 1.5rem;
    line-height: 1.9rem;
  }
}

.Polaris-Navigation__SecondaryAction {
  height: 4rem;
  border-radius: var(--p-border-radius-base);
  align-items: center;
  margin-right: .4rem;
  padding: .4rem 1.6rem;
  display: flex;
  position: relative;
}

.Polaris-Navigation__SecondaryAction svg {
  fill: var(--p-icon);
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__SecondaryAction {
    height: 3.2rem;
  }
}

.Polaris-Navigation__SecondaryAction:hover, .Polaris-Navigation__SecondaryAction:focus, .Polaris-Navigation__SecondaryAction:active {
  background: var(--p-background-hovered);
}

.Polaris-Navigation__SecondaryAction:hover svg, .Polaris-Navigation__SecondaryAction:focus svg, .Polaris-Navigation__SecondaryAction:active svg {
  fill: var(--p-icon-hovered);
}

.Polaris-Navigation__SecondaryAction:hover img, .Polaris-Navigation__SecondaryAction:focus img, .Polaris-Navigation__SecondaryAction:active img {
  filter: var(--p-filter-icon-action-primary);
}

@media (-ms-high-contrast: active) {
  .Polaris-Navigation__SecondaryAction:hover svg, .Polaris-Navigation__SecondaryAction:focus svg, .Polaris-Navigation__SecondaryAction:active svg {
    fill: var(--p-icon-on-interactive);
  }

  .Polaris-Navigation__SecondaryAction:hover img, .Polaris-Navigation__SecondaryAction:focus img, .Polaris-Navigation__SecondaryAction:active img {
    filter: var(--p-filter-icon-on-interactive);
  }
}

.Polaris-Navigation__SecondaryAction:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

.Polaris-Navigation__SecondaryAction:focus:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Navigation__SecondaryAction:active {
  background: var(--p-background-pressed);
}

.Polaris-Navigation__SecondaryAction:active:after {
  content: none;
}

.Polaris-Navigation__SecondaryAction:active svg {
  fill: var(--p-icon-pressed);
}

.Polaris-Navigation__SecondaryAction:focus, .Polaris-Navigation__SecondaryAction:active {
  outline: none;
}

.Polaris-Navigation__SecondaryNavigation {
  flex-basis: 100%;
  margin-left: 0;
  overflow-x: visible;
}

.Polaris-Navigation__SecondaryNavigation.Polaris-Navigation--isExpanded {
  margin-bottom: .8rem;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__List {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item {
  color: var(--p-text-subdued);
  padding-left: 4.8rem;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 4rem;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:hover {
  color: var(--p-text-subdued);
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item.Polaris-Navigation--keyFocused {
  color: var(--p-text);
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item.Polaris-Navigation--keyFocused:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:active:after, .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:active:hover:after {
  content: none;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:active {
  color: var(--p-text-primary);
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item {
    font-size: 1.4rem;
    line-height: 2.8rem;
  }
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Text {
  margin-top: .6rem;
  margin-bottom: .6rem;
  line-height: 2rem;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected {
  color: var(--p-text-primary);
  position: relative;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected:hover {
  color: var(--p-text-primary-hovered);
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected.Polaris-Navigation--keyFocused, .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected:active {
  color: var(--p-text-primary);
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected:active:after {
  content: none;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--disabled {
  color: var(--p-text-disabled);
  font-weight: 500;
}

.Polaris-Navigation__SecondaryNavigation--noIcon {
  margin-left: 1.6rem;
}

.Polaris-Navigation__Section {
  padding: 0 0 1.6rem;
  padding-left: calc(constant(safe-area-inset-left));
  padding-left: calc(env(safe-area-inset-left));
  flex: none;
  margin: 0;
  list-style: none;
}

.Polaris-Navigation__Section + .Polaris-Navigation__Section {
  padding-top: .4rem;
  padding-bottom: 1.6rem;
}

.Polaris-Navigation__Section--fill {
  flex: 1 0 auto;
}

.Polaris-Navigation__Section--withSeparator {
  border-top: 0.1rem solid var(--p-divider);
}

.Polaris-Navigation__SectionHeading {
  text-transform: uppercase;
  align-items: center;
  padding-left: 1.6rem;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.6rem;
  display: flex;
}

@media (min-width: 40em) {
  .Polaris-Navigation__SectionHeading {
    font-size: 1.2rem;
  }
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Text {
  color: var(--p-text-subdued);
  font-size: 1.3rem;
}

@media (min-width: 40em) {
  .Polaris-Navigation__SectionHeading .Polaris-Navigation__Text {
    font-size: 1.2rem;
  }
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  border-radius: var(--p-border-radius-base);
  background: none;
  border: none;
  align-items: center;
  margin: 0 .4rem 0 0;
  padding: .4rem 1.6rem;
  display: flex;
  position: relative;
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus {
  outline: none;
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action svg {
  fill: var(--p-icon);
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:hover, .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus {
  background: var(--p-background-hovered);
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:hover svg, .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus svg {
  fill: var(--p-icon-hovered);
}

@media (-ms-high-contrast: active) {
  .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:hover svg, .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus svg {
    fill: var(--p-icon-on-interactive);
  }

  .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:hover img, .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus img {
    filter: var(--p-filter-icon-on-interactive);
  }
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:hover img {
  filter: var(--p-filter-icon);
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus:hover svg {
  fill: var(--p-icon-hovered);
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active, .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active:hover {
  background: var(--p-background-pressed);
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active svg, .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active:hover svg {
  fill: var(--p-icon-pressed);
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active:after, .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active:hover:after {
  content: none;
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus, .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active {
  outline: none;
}

.Polaris-Navigation__RollupToggle {
  color: var(--p-text);
  color: var(--p-text-subdued);
  font-weight: 400;
}

.Polaris-Navigation__RollupToggle:hover {
  color: var(--p-text-primary);
}

.Polaris-Navigation__RollupToggle:hover svg {
  fill: var(--p-action-primary);
}

.Polaris-Navigation__RollupToggle:hover img {
  filter: var(--p-filter-icon-action-primary);
}

.Polaris-Navigation__RollupToggle:focus {
  outline: none;
}

.Polaris-Navigation__RollupToggle:focus:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Navigation__List {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Polaris-Navigation__Indicator {
  height: 1rem;
  width: 1rem;
  display: inline-block;
  position: relative;
}

.Polaris-Navigation__SecondaryNavigation--noIcon .Polaris-Navigation__Item {
  padding-left: 1.2rem;
}

.Polaris-OptionList-Checkbox {
  width: 100%;
  margin: var(--p-choice-margin);
  position: relative;
}

.Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--active .Polaris-OptionList-Checkbox__Backdrop:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-OptionList-Checkbox__Input {
  width: .1rem !important;
  height: .1rem !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.Polaris-OptionList-Checkbox__Input.Polaris-OptionList-Checkbox--keyFocused + .Polaris-OptionList-Checkbox__Backdrop:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-OptionList-Checkbox__Input:active:not(:disabled) + .Polaris-OptionList-Checkbox__Backdrop, .Polaris-OptionList-Checkbox__Input:checked + .Polaris-OptionList-Checkbox__Backdrop, .Polaris-OptionList-Checkbox__Input.Polaris-OptionList-Checkbox__Input--indeterminate + .Polaris-OptionList-Checkbox__Backdrop {
  border-color: var(--p-interactive);
}

.Polaris-OptionList-Checkbox__Input:active:not(:disabled) + .Polaris-OptionList-Checkbox__Backdrop:before, .Polaris-OptionList-Checkbox__Input:checked + .Polaris-OptionList-Checkbox__Backdrop:before, .Polaris-OptionList-Checkbox__Input.Polaris-OptionList-Checkbox__Input--indeterminate + .Polaris-OptionList-Checkbox__Backdrop:before {
  opacity: 1;
  transform: scale(1);
}

@media (-ms-high-contrast: active) {
  .Polaris-OptionList-Checkbox__Input:active:not(:disabled) + .Polaris-OptionList-Checkbox__Backdrop:before, .Polaris-OptionList-Checkbox__Input:checked + .Polaris-OptionList-Checkbox__Backdrop:before, .Polaris-OptionList-Checkbox__Input.Polaris-OptionList-Checkbox__Input--indeterminate + .Polaris-OptionList-Checkbox__Backdrop:before {
    border: 0.2rem solid windowText;
  }
}

.Polaris-OptionList-Checkbox__Input:active:not(:disabled) ~ .Polaris-OptionList-Checkbox__Icon, .Polaris-OptionList-Checkbox__Input:checked ~ .Polaris-OptionList-Checkbox__Icon, .Polaris-OptionList-Checkbox__Input.Polaris-OptionList-Checkbox__Input--indeterminate ~ .Polaris-OptionList-Checkbox__Icon {
  opacity: 1;
  transition: opacity var(--p-duration-1-5-0) var(--p-ease), transform var(--p-duration-1-5-0) var(--p-ease);
  transform: translate(-50%, -50%)scale(1);
}

.Polaris-OptionList-Checkbox__Input:disabled + .Polaris-OptionList-Checkbox__Backdrop {
  border-color: var(--p-border-disabled);
}

.Polaris-OptionList-Checkbox__Input:disabled + .Polaris-OptionList-Checkbox__Backdrop:before {
  background-color: var(--p-action-secondary-disabled);
}

.Polaris-OptionList-Checkbox__Input:disabled + .Polaris-OptionList-Checkbox__Backdrop:hover {
  cursor: default;
}

.Polaris-OptionList-Checkbox__Input:disabled:checked + .Polaris-OptionList-Checkbox__Backdrop, .Polaris-OptionList-Checkbox__Input:disabled:checked + .Polaris-OptionList-Checkbox__Backdrop:before {
  background: var(--p-border-disabled);
}

.Polaris-OptionList-Checkbox__Backdrop {
  border: var(--p-control-border-width) solid var(--p-border);
  background-color: var(--p-surface);
  border-radius: var(--p-border-radius-base);
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.Polaris-OptionList-Checkbox__Backdrop:before {
  content: "";
  top: calc(var(--p-control-border-width) * -1);
  right: calc(var(--p-control-border-width) * -1);
  bottom: calc(var(--p-control-border-width) * -1);
  left: calc(var(--p-control-border-width) * -1);
  border-radius: var(--p-border-radius-base);
  background-color: var(--p-interactive);
  opacity: 0;
  transition: opacity 100ms var(--p-ease), transform 100ms var(--p-ease);
  position: absolute;
  transform: scale(.25);
}

.Polaris-OptionList-Checkbox__Backdrop.Polaris-OptionList-Checkbox--hover, .Polaris-OptionList-Checkbox__Backdrop:hover {
  cursor: pointer;
  border-color: var(--p-border-hovered);
}

.Polaris-OptionList-Checkbox__Backdrop:after {
  content: "";
  z-index: 1;
  top: calc(var(--p-control-border-width) * -1 + -0.1rem);
  right: calc(var(--p-control-border-width) * -1 + -0.1rem);
  bottom: calc(var(--p-control-border-width) * -1 + -0.1rem);
  left: calc(var(--p-control-border-width) * -1 + -0.1rem);
  pointer-events: none;
  box-shadow: 0 0 0 calc(var(--p-control-border-width) * -1 + -0.1rem) var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
}

.Polaris-OptionList-Checkbox__Icon {
  transform-origin: 50%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 100ms var(--p-ease), transform 100ms var(--p-ease);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(.25);
}

.Polaris-OptionList-Checkbox__Icon svg {
  fill: var(--p-icon-on-interactive);
}

@media (-ms-high-contrast: active) {
  .Polaris-OptionList-Checkbox__Icon {
    fill: windowText;
  }
}

.Polaris-OptionList-Option {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  color: inherit;
  width: 100%;
  min-height: 3.6rem;
  text-align: left;
  border-radius: var(--p-border-radius-base);
  background: none;
  border: none;
  margin: .4rem 0 0;
  padding: 0;
  text-decoration: none;
}

.Polaris-OptionList-Option:focus {
  outline: none;
}

.Polaris-OptionList-Option:visited {
  color: inherit;
}

.Polaris-OptionList-Option__SingleSelectOption {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  text-align: left;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.Polaris-OptionList-Option__SingleSelectOption:focus {
  outline: none;
}

.Polaris-OptionList-Option__SingleSelectOption:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

.Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--focused:not(:active):after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--active {
  background: var(--p-surface-selected);
}

.Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--active:before, .Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--select:before {
  content: "";
  background-color: var(--p-interactive);
  height: 100%;
  width: .3rem;
  border-top-right-radius: var(--p-border-radius-base);
  border-bottom-right-radius: var(--p-border-radius-base);
  display: block;
  position: absolute;
  top: 0;
  left: -.8rem;
}

.Polaris-OptionList-Option__SingleSelectOption:not(.Polaris-OptionList-Option--disabled) {
  color: inherit;
}

.Polaris-OptionList-Option__SingleSelectOption .Polaris-OptionList-Option__Media {
  padding: 0 .8rem 0 0;
}

.Polaris-OptionList-Option__Label, .Polaris-OptionList-Option__SingleSelectOption {
  width: 100%;
  cursor: pointer;
  border-radius: var(--p-border-radius-base);
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  align-items: flex-start;
  padding: .8rem;
  display: flex;
}

.Polaris-OptionList-Option__Label:hover:not(.Polaris-OptionList-Option--disabled), .Polaris-OptionList-Option__SingleSelectOption:hover:not(.Polaris-OptionList-Option--disabled) {
  background: var(--p-surface-hovered);
  outline: .1rem solid #0000;
}

.Polaris-OptionList-Option__Label.Polaris-OptionList-Option--select, .Polaris-OptionList-Option__Label.Polaris-OptionList-Option--select:hover:not(.Polaris-OptionList-Option--disabled), .Polaris-OptionList-Option__Label:active:not(.Polaris-OptionList-Option--disabled), .Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--select, .Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--select:hover:not(.Polaris-OptionList-Option--disabled), .Polaris-OptionList-Option__SingleSelectOption:active:not(.Polaris-OptionList-Option--disabled) {
  background: var(--p-surface-selected);
  outline: .1rem solid #0000;
}

.Polaris-OptionList-Option__Label.Polaris-OptionList-Option--disabled, .Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--disabled {
  background: var(--p-surface-disabled);
  cursor: default;
  color: var(--p-text-disabled);
}

.Polaris-OptionList-Option__Checkbox {
  box-sizing: border-box;
  width: var(--p-choice-size);
  height: var(--p-choice-size);
  margin-right: .8rem;
  margin-left: calc(var(--p-choice-margin) * -1);
  flex-shrink: 0;
  display: flex;
}

.Polaris-OptionList-Option--disabled .Polaris-OptionList-Option__Media svg {
  fill: var(--p-icon-disabled);
}

.Polaris-OptionList-Option__Media {
  padding: 0 .8rem;
}

.Polaris-OptionList-Option__Media svg {
  fill: var(--p-icon);
}

.Polaris-OptionList-Option--verticalAlignTop {
  align-items: flex-start;
}

.Polaris-OptionList-Option--verticalAlignCenter {
  align-items: center;
}

.Polaris-OptionList-Option--verticalAlignBottom {
  align-items: flex-end;
}

.Polaris-OptionList {
  margin: 0;
  padding: .8rem;
  list-style: none;
}

.Polaris-OptionList__Options {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Polaris-OptionList__Title {
  text-transform: uppercase;
  color: var(--p-text-subdued);
  padding: .8rem;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.6rem;
}

@media (min-width: 40em) {
  .Polaris-OptionList__Title {
    font-size: 1.2rem;
  }
}

.Polaris-Tooltip-TooltipOverlay {
  opacity: 1;
  box-shadow: var(--p-popover-shadow);
  border-radius: var(--p-border-radius-base);
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: opacity, left, top;
  margin: .4rem 1.6rem 1.6rem;
  transition: opacity .2s cubic-bezier(.36, 0, 1, 1) .1s;
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-Tooltip-TooltipOverlay {
    border: 0.2rem solid windowText;
  }
}

.Polaris-Tooltip-TooltipOverlay--measuring {
  opacity: 0;
}

.Polaris-Tooltip-TooltipOverlay--positionedAbove {
  margin: 1.6rem 1.6rem .4rem;
}

.Polaris-Tooltip-TooltipOverlay__Content {
  background-color: var(--p-surface);
  color: var(--p-text);
  max-width: 20rem;
  word-break: break-word;
  border-radius: .3rem;
  padding: .4rem .8rem;
  position: relative;
}

[data-polaris-tooltip-activator] {
  outline: none;
  position: relative;
}

[data-polaris-tooltip-activator]:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

[data-polaris-tooltip-activator]:focus-visible:after, [data-polaris-tooltip-activator][data-focus-visible-added]:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Header-Title {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 2.8rem;
}

@media (min-width: 40em) {
  .Polaris-Header-Title {
    font-size: 2rem;
  }
}

.Polaris-Header-Title__SubTitle {
  color: var(--p-text-subdued);
  margin-top: .8rem;
}

.Polaris-Header-Title__SubTitle.Polaris-Header-Title__SubtitleCompact {
  margin-top: 0;
}

.Polaris-Header-Title--hasThumbnail {
  grid-gap: 1.6rem;
  grid-template-columns: auto 1fr;
  display: grid;
}

.Polaris-Header-Title--hasThumbnail .Polaris-Header-Title__TitleAndSubtitleWrapper {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.Polaris-Header-Title__TitleWithMetadataWrapper {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.Polaris-Header-Title__TitleWithMetadataWrapper .Polaris-Header-Title {
  margin-right: .8rem;
  display: inline;
}

.Polaris-Header-Title__TitleWithMetadataWrapper .Polaris-Header-Title > * {
  display: inline;
}

.Polaris-Header-Title__TitleWithMetadataWrapper .Polaris-Header-Title__TitleMetadata {
  vertical-align: bottom;
  margin-top: 0;
}

.Polaris-Page-Header {
  padding: 1.6rem 1.6rem 0;
  position: relative;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Page-Header {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 30.625em) {
  .Polaris-Page-Header {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-Page-Header {
    padding-top: 1.6rem;
  }
}

@media (min-width: 46.5em) {
  .Polaris-Page-Header {
    padding-top: 1.6rem;
  }
}

.Polaris-Page-Header--titleHidden {
  width: .1rem !important;
  height: .1rem !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.Polaris-Page-Header__TitleWrapper {
  flex: auto;
  grid-area: title;
  align-self: center;
  margin-top: .4rem;
}

@media (min-width: 29.25em) {
  .Polaris-Page-Header__TitleWrapper {
    margin-top: 0;
  }
}

.Polaris-Page-Header__Navigation {
  justify-content: space-between;
  align-items: center;
  margin-bottom: .4rem;
  display: flex;
}

.Polaris-Page-Header--hasActionMenu.Polaris-Page-Header--mobileView .Polaris-Page-Header__Navigation {
  padding-right: 4rem;
}

@media print {
  .Polaris-Page-Header__Navigation {
    display: none !important;
  }
}

.Polaris-Page-Header__BreadcrumbWrapper {
  max-width: 100%;
  flex: none;
  grid-area: breadcrumbs;
  margin-right: 1.6rem;
}

@media print {
  .Polaris-Page-Header__BreadcrumbWrapper {
    display: none !important;
  }
}

.Polaris-Page-Header__PaginationWrapper {
  flex: none;
  justify-content: flex-end;
  margin-left: .4rem;
  line-height: 1;
  display: flex;
}

.Polaris-Page-Header__PaginationWrapper button {
  border: 0.1rem solid var(--p-border-neutral-subdued) !important;
  box-shadow: none !important;
}

.Polaris-Page-Header__PaginationWrapper button:hover, .Polaris-Page-Header__PaginationWrapper button:active, .Polaris-Page-Header__PaginationWrapper button:focus {
  border: 0.1rem solid var(--p-border-neutral-subdued) !important;
}

@media print {
  .Polaris-Page-Header__PaginationWrapper {
    display: none !important;
  }
}

.Polaris-Page-Header__AdditionalNavigationWrapper {
  flex: 1 0 auto;
  justify-content: flex-end;
  display: flex;
}

@media print {
  .Polaris-Page-Header__AdditionalNavigationWrapper {
    display: none !important;
  }
}

.Polaris-Page-Header:not(.Polaris-Page-Header--mobileView) .Polaris-Page-Header__MainContent {
  align-items: center;
  display: flex;
}

.Polaris-Page-Header__TitleActionMenuWrapper {
  flex: auto;
}

.Polaris-Page-Header:not(.Polaris-Page-Header--mobileView) .Polaris-Page-Header__TitleActionMenuWrapper {
  padding-right: 2rem;
}

.Polaris-Page-Header--hasActionMenu.Polaris-Page-Header--mobileView:not(.Polaris-Page-Header--hasNavigation) .Polaris-Page-Header__TitleActionMenuWrapper {
  padding-right: 4rem;
}

.Polaris-Page-Header__PrimaryActionWrapper {
  flex: none;
  margin-top: 0;
  margin-left: .4rem;
}

@media (min-width: 48em) {
  .Polaris-Page-Header__PrimaryActionWrapper {
    margin-left: 1.6rem;
  }
}

@media print {
  .Polaris-Page-Header__PrimaryActionWrapper {
    display: none !important;
  }
}

.Polaris-Page-Header__ActionMenuWrapper {
  margin-top: 0;
}

.Polaris-Page-Header--mobileView .Polaris-Page-Header__ActionMenuWrapper {
  margin-top: 0;
  position: absolute;
  top: 2.9rem;
  right: 2rem;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Page-Header--mobileView .Polaris-Page-Header__ActionMenuWrapper {
    right: -.8rem;
  }
}

@media (min-width: 30.625em) {
  .Polaris-Page-Header--mobileView .Polaris-Page-Header__ActionMenuWrapper {
    right: -.8rem;
  }
}

.Polaris-Page-Header--mobileView.Polaris-Page-Header--hasNavigation .Polaris-Page-Header__ActionMenuWrapper {
  top: 1.8rem;
}

@media print {
  .Polaris-Page-Header__ActionMenuWrapper {
    display: none !important;
  }
}

.Polaris-Page-Header__Row {
  min-height: 3.6rem;
  justify-content: space-between;
  display: flex;
}

.Polaris-Page-Header__Row + .Polaris-Page-Header__Row {
  margin-top: .4rem;
}

.Polaris-Page-Header--mobileView .Polaris-Page-Header__Row + .Polaris-Page-Header__Row {
  margin-top: .8rem;
}

.Polaris-Page-Header__Row + .Polaris-Page-Header__Row .Polaris-Page-Header__RightAlign {
  margin-left: 0;
}

.Polaris-Page-Header__LeftAlign {
  align-content: flex-start;
  align-items: center;
  display: flex;
}

.Polaris-Page-Header__RightAlign {
  white-space: nowrap;
  flex: auto;
  grid-area: actions;
  place-content: flex-end;
  align-items: flex-start;
  margin-left: 1.6rem;
  display: flex;
}

@media (max-width: 29.25em) {
  .Polaris-Page-Header--noBreadcrumbs .Polaris-Page-Header__RightAlign {
    margin-left: 0;
  }
}

@media (min-width: 29.25em) {
  .Polaris-Page-Header__AdditionalMetaData {
    margin-left: 5.2rem;
  }
}

.Polaris-Page-Header--noBreadcrumbs .Polaris-Page-Header__AdditionalMetaData {
  margin-left: 0;
}

@media print {
  .Polaris-Page-Header__AdditionalMetaData {
    margin-left: 0 !important;
  }
}

.Polaris-Page-Header__Actions {
  width: 100%;
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

@media (max-width: 67.5em) {
  .Polaris-Page-Header--longTitle .Polaris-Page-Header__AdditionalMetaData {
    margin-left: 0;
  }

  .Polaris-Page-Header--longTitle .Polaris-Page-Header__Row {
    grid-template-columns: auto 1fr;
    grid-template-areas: "breadcrumbs actions"
                         "title title";
    gap: .8rem 1.6rem;
    display: grid;
  }

  .Polaris-Page-Header--longTitle .Polaris-Page-Header__Row + .Polaris-Page-Header__Row {
    gap: 0;
  }
}

@media (max-width: 53.75em) {
  .Polaris-Page-Header--mediumTitle:not(.Polaris-Page-Header--noBreadcrumbs) .Polaris-Page-Header__AdditionalMetaData {
    margin-left: 0;
  }

  .Polaris-Page-Header--mediumTitle:not(.Polaris-Page-Header--noBreadcrumbs) .Polaris-Page-Header__Row {
    grid-template-columns: auto 1fr;
    grid-template-areas: "breadcrumbs actions"
                         "title title";
    gap: .8rem 1.6rem;
    display: grid;
  }

  .Polaris-Page-Header--mediumTitle:not(.Polaris-Page-Header--noBreadcrumbs) .Polaris-Page-Header__Row + .Polaris-Page-Header__Row {
    gap: 0;
  }
}

.Polaris-Page-Header--mediumTitle.Polaris-Page-Header--noBreadcrumbs .Polaris-Page-Header__TitleWrapper {
  margin-top: 0;
}

.Polaris-Page-Header--mediumTitle.Polaris-Page-Header--noBreadcrumbs .Polaris-Page-Header__RightAlign {
  margin-bottom: .4rem;
}

.Polaris-Page-Header--mediumTitle.Polaris-Page-Header--noBreadcrumbs .Polaris-Page-Header__Row {
  flex-wrap: wrap-reverse;
}

@media (min-width: 48.0625em) {
  .Polaris-Page-Header--mediumTitle.Polaris-Page-Header--noBreadcrumbs .Polaris-Page-Header__Row {
    flex-wrap: nowrap;
  }
}

.Polaris-Page-Header--isSingleRow .Polaris-Page-Header__Row {
  gap: 0;
}

html, body {
  min-height: 100%;
  height: 100%;
}

.Polaris-Page {
  max-width: 99.8rem;
  margin: 0 auto;
  padding: 0;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Page {
    padding: 0 2rem;
  }
}

@media (min-width: 30.625em) {
  .Polaris-Page {
    padding: 0 2rem;
  }
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-Page {
    padding: 0 3.2rem;
  }
}

@media (min-width: 46.5em) {
  .Polaris-Page {
    padding: 0 3.2rem;
  }
}

.Polaris-Page:after {
  content: "";
  display: table;
}

.Polaris-Page--fullWidth {
  max-width: none;
}

.Polaris-Page--narrowWidth {
  max-width: 66.2rem;
}

.Polaris-Page__Content {
  margin: .8rem 0;
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-Page__Content {
    margin-top: .8rem;
  }
}

@media (min-width: 46.5em) {
  .Polaris-Page__Content {
    margin-top: .8rem;
  }
}

.Polaris-Page--divider {
  border-top: 0.1rem solid var(--p-border-subdued);
  padding-top: 1.6rem;
}

.Polaris-PageActions {
  border-top: 0.1rem solid var(--p-divider);
  margin: 0 auto;
  padding: 2rem;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-PageActions {
    padding: 2rem 0;
  }
}

@media (min-width: 30.625em) {
  .Polaris-PageActions {
    padding: 2rem 0;
  }
}

@keyframes Polaris-ProgressBar--fillup {
  0% {
    width: 0;
  }
}

.Polaris-ProgressBar {
  width: 100%;
  background-color: var(--p-progressbar-background);
  border-radius: var(--p-border-radius-base);
  overflow: hidden;
}

@media (forced-colors: active) {
  .Polaris-ProgressBar {
    border: .1rem solid #0000;
  }
}

.Polaris-ProgressBar--sizeSmall {
  height: .8rem;
}

.Polaris-ProgressBar--sizeMedium {
  height: 1.6rem;
}

.Polaris-ProgressBar--sizeLarge {
  height: 3.2rem;
}

.Polaris-ProgressBar--colorHighlight {
  --p-progressbar-background: var(--p-surface-neutral);
  --p-progressbar-indicator: var(--p-border-highlight);
}

.Polaris-ProgressBar--colorPrimary {
  --p-progressbar-background: var(--p-surface-neutral);
  --p-progressbar-indicator: var(--p-action-primary);
}

.Polaris-ProgressBar--colorSuccess {
  --p-progressbar-background: var(--p-surface-neutral);
  --p-progressbar-indicator: var(--p-border-success);
}

.Polaris-ProgressBar--colorCritical {
  --p-progressbar-background: var(--p-surface-neutral);
  --p-progressbar-indicator: var(--p-interactive-critical);
}

.Polaris-ProgressBar__Indicator {
  height: inherit;
  width: 0;
  background-color: var(--p-progressbar-indicator);
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-ProgressBar__Indicator {
    border: 1.6rem solid highlight;
  }
}

.Polaris-ProgressBar__Animated {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: width;
  transition: width .5s;
  animation: Polaris-ProgressBar--fillup .5s;
}

.Polaris-ProgressBar__Progress, .Polaris-ProgressBar__Label {
  width: .1rem !important;
  height: .1rem !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.Polaris-RangeSlider-DualThumb__Wrapper {
  width: 100%;
  align-items: center;
  display: flex;
  position: relative;
}

.Polaris-RangeSlider-DualThumb__TrackWrapper {
  width: 100%;
  min-height: 2.8rem;
  cursor: pointer;
  align-items: center;
  display: flex;
  position: relative;
}

.Polaris-RangeSlider-DualThumb__TrackWrapper.Polaris-RangeSlider-DualThumb--disabled {
  opacity: .8;
  cursor: not-allowed;
}

.Polaris-RangeSlider-DualThumb__Track {
  z-index: 1;
  width: 100%;
  height: .4rem;
  border-radius: var(--p-range-slider-thumb-size-base);
  --unselected-range: transparent;
  --selected-range: var(--p-interactive);
  --gradient-colors: var(--unselected-range) 0%, var(--unselected-range) var(--Polaris-RangeSlider-progress-lower),
    var(--selected-range) var(--Polaris-RangeSlider-progress-lower),
    var(--selected-range) var(--Polaris-RangeSlider-progress-upper),
    var(--unselected-range) var(--Polaris-RangeSlider-progress-upper), var(--unselected-range) 100%;
  background-image: linear-gradient(to right, var(--gradient-colors));
  position: absolute;
}

.Polaris-RangeSlider-DualThumb--error .Polaris-RangeSlider-DualThumb__Track {
  --selected-range: var(--p-action-critical);
  --gradient-colors: var(--unselected-range) 0%, var(--unselected-range) var(--Polaris-RangeSlider-progress-lower),
    var(--selected-range) var(--Polaris-RangeSlider-progress-lower),
    var(--selected-range) var(--Polaris-RangeSlider-progress-upper),
    var(--unselected-range) var(--Polaris-RangeSlider-progress-upper), var(--unselected-range) 100%;
  background-image: linear-gradient(to right, var(--gradient-colors));
}

.Polaris-RangeSlider-DualThumb--disabled .Polaris-RangeSlider-DualThumb__Track {
  background: var(--p-border-disabled) none;
}

.Polaris-RangeSlider-DualThumb--trackDashed {
  content: "";
  height: .4rem;
  width: 100%;
  background-image: linear-gradient(to right, var(--p-border), var(--p-border) 50%, transparent 50%, transparent 100%);
  border-radius: var(--p-border-radius-base);
  border-right: var(--p-border-radius-base) var(--p-border) solid;
  background-size: .4rem .4rem;
  position: absolute;
}

.Polaris-RangeSlider-DualThumb__Thumbs {
  z-index: 10;
  width: var(--p-range-slider-thumb-size-base);
  height: var(--p-range-slider-thumb-size-base);
  border: 0.1rem solid var(--p-interactive);
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
  -webkit-tap-highlight-color: transparent;
  cursor: -webkit-grab;
  transition: transform var(--p-duration-1-5-0) var(--p-ease);
  border-radius: 50%;
  padding: 0;
  position: absolute;
}

.Polaris-RangeSlider-DualThumb__Thumbs:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.2rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-wide) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.2rem;
}

.Polaris-RangeSlider-DualThumb__Thumbs.Polaris-RangeSlider-DualThumb--disabled {
  cursor: not-allowed;
  border-color: var(--p-border-disabled);
  background: var(--p-border-disabled);
}

.Polaris-RangeSlider-DualThumb__Thumbs:active {
  transform: scale(1.5);
}

.Polaris-RangeSlider-DualThumb__Thumbs:focus {
  outline: none;
}

.Polaris-RangeSlider-DualThumb__Thumbs:focus:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-RangeSlider-DualThumb--error .Polaris-RangeSlider-DualThumb__Thumbs {
  border-color: var(--p-action-critical);
  background: linear-gradient(var(--p-action-critical), var(--p-action-critical));
}

.Polaris-RangeSlider-DualThumb__Prefix {
  flex: none;
  margin-right: .8rem;
}

.Polaris-RangeSlider-DualThumb__Suffix {
  flex: none;
  margin-left: .8rem;
}

.Polaris-RangeSlider-DualThumb__Output {
  z-index: 20;
  bottom: var(--p-range-slider-thumb-size-active);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition-property: opacity, visibility, bottom;
  transition-duration: var(--p-duration-1-5-0);
  transition-timing-function: var(--p-ease);
  transform: translateX(calc(-50% + var(--p-range-slider-thumb-size-base) / 2));
  position: absolute;
}

.Polaris-RangeSlider-DualThumb__Thumbs:active + .Polaris-RangeSlider-DualThumb__Output {
  opacity: 1;
  visibility: visible;
  bottom: calc(
    var(--p-range-slider-thumb-size-active) + var(--p-range-slider-thumb-size-active) -
      var(--p-range-slider-thumb-size-base)
  );
}

.Polaris-RangeSlider-DualThumb__OutputBubble {
  min-width: 3.2rem;
  height: 3.2rem;
  background-color: var(--p-surface);
  box-shadow: var(--p-popover-shadow);
  transition-property: transform;
  transition-duration: var(--p-duration-1-5-0);
  transition-timing-function: var(--p-ease);
  border-radius: .3rem;
  padding: 0 .8rem;
  display: flex;
  position: relative;
}

.Polaris-RangeSlider-DualThumb__Thumbs:hover + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble, .Polaris-RangeSlider-DualThumb__Thumbs:active + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble, .Polaris-RangeSlider-DualThumb__Thumbs:focus + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble {
  transform: translateY(-1.6rem);
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-RangeSlider-DualThumb__Thumbs:hover + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble, [data-has-navigation] .Polaris-RangeSlider-DualThumb__Thumbs:active + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble, [data-has-navigation] .Polaris-RangeSlider-DualThumb__Thumbs:focus + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble {
    transform: translateY(-.8rem);
  }
}

@media (min-width: 46.5em) {
  .Polaris-RangeSlider-DualThumb__Thumbs:hover + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble, .Polaris-RangeSlider-DualThumb__Thumbs:active + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble, .Polaris-RangeSlider-DualThumb__Thumbs:focus + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble {
    transform: translateY(-.8rem);
  }
}

.Polaris-RangeSlider-DualThumb__OutputText {
  text-transform: uppercase;
  text-align: center;
  color: var(--p-text);
  flex: auto;
  margin: auto;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.6rem;
  display: block;
}

@media (min-width: 40em) {
  .Polaris-RangeSlider-DualThumb__OutputText {
    font-size: 1.2rem;
  }
}

.Polaris-RangeSlider-SingleThumb {
  align-items: center;
  display: flex;
}

.Polaris-RangeSlider-SingleThumb.Polaris-RangeSlider-SingleThumb--disabled {
  opacity: .8;
}

.Polaris-RangeSlider-SingleThumb__InputWrapper {
  height: var(--p-range-slider-thumb-size-base);
  flex: auto;
  align-items: center;
  display: flex;
  position: relative;
}

.Polaris-RangeSlider-SingleThumb__InputWrapper input {
  cursor: pointer;
  background-color: #0000;
  padding: 1.2rem 0;
}

.Polaris-RangeSlider-SingleThumb__InputWrapper:after {
  content: "";
  height: .4rem;
  width: 100%;
  background-image: linear-gradient(to right, var(--p-border), var(--p-border) 50%, transparent 50%, transparent 100%);
  border-radius: var(--p-border-radius-base);
  border-right: var(--p-border-radius-base) var(--p-border) solid;
  background-size: .4rem .4rem;
  position: absolute;
}

.Polaris-RangeSlider-SingleThumb--disabled input {
  cursor: not-allowed;
}

.Polaris-RangeSlider-SingleThumb__Prefix {
  flex: none;
  margin-right: .8rem;
}

.Polaris-RangeSlider-SingleThumb__Suffix {
  flex: none;
  margin-left: .8rem;
}

.Polaris-RangeSlider-SingleThumb__Input {
  --progress-lower: var(--p-interactive);
  --progress-upper: transparent;
  --gradient-colors: var(--progress-lower, transparent) 0%,
    var(--progress-lower, transparent) var(--Polaris-RangeSlider-progress, 0%),
    var(--progress-upper, transparent) var(--Polaris-RangeSlider-progress, 100%),
    var(--progress-upper, transparent) 100%;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  z-index: 10;
  background-color: #0000;
  flex: auto;
  margin: 0;
  padding: 0;
  position: relative;
}

.Polaris-RangeSlider-SingleThumb__Input::-ms-tooltip {
  display: none;
}

.Polaris-RangeSlider-SingleThumb__Input:focus {
  outline: none;
}

.Polaris-RangeSlider-SingleThumb__Input::-moz-focus-outer {
  border: none;
}

.Polaris-RangeSlider-SingleThumb__Input::-ms-track {
  cursor: pointer;
  width: 100%;
  height: .4rem;
  background-image: linear-gradient(to right, var(--gradient-colors));
  border-radius: .4rem;
  border: none;
  outline: .1rem solid #0000;
}

.Polaris-RangeSlider-SingleThumb__Input::-moz-range-track {
  cursor: pointer;
  width: 100%;
  height: .4rem;
  background-image: linear-gradient(to right, var(--gradient-colors));
  border-radius: .4rem;
  border: none;
}

.Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-runnable-track {
  cursor: pointer;
  width: 100%;
  height: .4rem;
  background-image: linear-gradient(to right, var(--gradient-colors));
  border-radius: .4rem;
  border: none;
}

.Polaris-RangeSlider-SingleThumb__Input::-ms-thumb {
  cursor: -webkit-grab;
  width: var(--p-range-slider-thumb-size-base);
  height: var(--p-range-slider-thumb-size-base);
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
  box-shadow: 0 0 0 0 var(--p-focused);
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -ms-transition: box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease);
  margin-top: calc(var(--p-range-slider-thumb-size-base) * -1 / 2 - -0.2rem);
  border-radius: 50%;
  border: .1rem solid #0000;
  transition-property: border-color, box-shadow, transform;
  transition-duration: .2s;
  transition-timing-function: ease;
}

.Polaris-RangeSlider-SingleThumb__Input::-ms-thumb:hover {
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
}

.Polaris-RangeSlider-SingleThumb__Input::-moz-range-thumb {
  cursor: -webkit-grab;
  width: var(--p-range-slider-thumb-size-base);
  height: var(--p-range-slider-thumb-size-base);
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
  box-shadow: 0 0 0 0 var(--p-focused);
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transition: box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease);
  margin-top: calc(var(--p-range-slider-thumb-size-base) * -1 / 2 - -0.2rem);
  border-radius: 50%;
  border: .1rem solid #0000;
  transition-property: border-color, box-shadow, transform;
  transition-duration: .2s;
  transition-timing-function: ease;
}

.Polaris-RangeSlider-SingleThumb__Input::-moz-range-thumb:hover {
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
}

.Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb {
  cursor: -webkit-grab;
  width: var(--p-range-slider-thumb-size-base);
  height: var(--p-range-slider-thumb-size-base);
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
  box-shadow: 0 0 0 0 var(--p-focused);
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -webkit-transition: box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease);
  margin-top: calc(var(--p-range-slider-thumb-size-base) * -1 / 2 - -0.2rem);
  border-radius: 50%;
  border: .1rem solid #0000;
  transition-property: border-color, box-shadow, transform;
  transition-duration: .2s;
  transition-timing-function: ease;
}

.Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb:hover {
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
}

.Polaris-RangeSlider-SingleThumb__Input::-ms-thumb {
  transform: translateY(var(--p-range-slider-thumb-size-base) / 5) scale(0.4);
  margin-top: 0;
}

.Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb {
  margin-top: -var(--p-range-slider-thumb-size-base)-0.4rem/2;
}

.Polaris-RangeSlider-SingleThumb__Input:active::-ms-thumb {
  transform: scale(1.5);
}

.Polaris-RangeSlider-SingleThumb__Input:active::-moz-range-thumb {
  transform: scale(1.5);
}

.Polaris-RangeSlider-SingleThumb__Input:active::-webkit-slider-thumb {
  transform: scale(1.5);
}

.Polaris-RangeSlider-SingleThumb__Input:focus {
  outline: .1rem solid #0000;
}

.Polaris-RangeSlider-SingleThumb__Input:focus::-ms-thumb {
  border-color: var(--p-surface);
  box-shadow: 0 0 0 0.2rem var(--p-focused);
}

.Polaris-RangeSlider-SingleThumb__Input:focus::-moz-range-thumb {
  border-color: var(--p-surface);
  box-shadow: 0 0 0 0.2rem var(--p-focused);
}

.Polaris-RangeSlider-SingleThumb__Input:focus::-webkit-slider-thumb {
  border-color: var(--p-surface);
  box-shadow: 0 0 0 0.2rem var(--p-focused);
}

.Polaris-RangeSlider-SingleThumb--error .Polaris-RangeSlider-SingleThumb__Input {
  --progress-lower: var(--p-action-critical);
}

.Polaris-RangeSlider-SingleThumb--error .Polaris-RangeSlider-SingleThumb__Input::-ms-thumb {
  border-color: var(--p-action-critical);
  background: var(--p-action-critical);
}

.Polaris-RangeSlider-SingleThumb--error .Polaris-RangeSlider-SingleThumb__Input::-moz-range-thumb {
  border-color: var(--p-action-critical);
  background: var(--p-action-critical);
}

.Polaris-RangeSlider-SingleThumb--error .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb {
  border-color: var(--p-action-critical);
  background: var(--p-action-critical);
}

.Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-ms-track {
  cursor: auto;
  background-image: none;
  background-color: var(--p-border-disabled);
  outline: .1rem solid #0000;
}

.Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-moz-range-track {
  cursor: auto;
  background-image: none;
  background-color: var(--p-border-disabled);
}

.Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-runnable-track {
  cursor: auto;
  background-image: none;
  background-color: var(--p-border-disabled);
}

.Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-ms-thumb {
  cursor: not-allowed;
  border-color: var(--p-border-disabled);
  background: var(--p-border-disabled);
}

.Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-moz-range-thumb {
  cursor: not-allowed;
  border-color: var(--p-border-disabled);
  background: var(--p-border-disabled);
}

.Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb {
  cursor: not-allowed;
  border-color: var(--p-border-disabled);
  background: var(--p-border-disabled);
}

.Polaris-RangeSlider-SingleThumb__Output {
  z-index: 20;
  bottom: var(--p-range-slider-thumb-size-base);
  left: var(--Polaris-RangeSlider-progress);
  transform: translateX(calc(-50% + var(--Polaris-RangeSlider-output-factor) * var(--p-range-slider-thumb-size-base)));
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition-property: opacity, visibility, bottom;
  transition-duration: .2s;
  transition-timing-function: ease;
  position: absolute;
}

.Polaris-RangeSlider-SingleThumb__Input:active + .Polaris-RangeSlider-SingleThumb__Output {
  opacity: 1;
  visibility: visible;
  bottom: calc(
    var(--p-range-slider-thumb-size-base) + var(--p-range-slider-thumb-size-active) -
      var(--p-range-slider-thumb-size-base)
  );
}

.Polaris-RangeSlider-SingleThumb__OutputBubble {
  box-shadow: var(--p-popover-shadow);
  min-width: 3.2rem;
  height: 3.2rem;
  background-color: var(--p-surface);
  border-radius: .3rem;
  outline: .1rem solid #0000;
  padding: 0 .8rem;
  transition-property: transform;
  transition-duration: .2s;
  transition-timing-function: ease;
  display: flex;
  position: relative;
}

.Polaris-RangeSlider-SingleThumb__Input:hover + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble, .Polaris-RangeSlider-SingleThumb__Input:active + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble, .Polaris-RangeSlider-SingleThumb__Input:focus + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble {
  transform: translateY(-1.6rem);
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-RangeSlider-SingleThumb__Input:hover + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble, [data-has-navigation] .Polaris-RangeSlider-SingleThumb__Input:active + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble, [data-has-navigation] .Polaris-RangeSlider-SingleThumb__Input:focus + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble {
    transform: translateY(-.8rem);
  }
}

@media (min-width: 46.5em) {
  .Polaris-RangeSlider-SingleThumb__Input:hover + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble, .Polaris-RangeSlider-SingleThumb__Input:active + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble, .Polaris-RangeSlider-SingleThumb__Input:focus + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble {
    transform: translateY(-.8rem);
  }
}

.Polaris-RangeSlider-SingleThumb__OutputText {
  text-transform: uppercase;
  text-align: center;
  color: var(--p-text);
  flex: auto;
  margin: auto;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.6rem;
  display: block;
}

@media (min-width: 40em) {
  .Polaris-RangeSlider-SingleThumb__OutputText {
    font-size: 1.2rem;
  }
}

.Polaris-ResourceItem__CheckboxWrapper {
  display: flex;
}

.Polaris-ResourceItem {
  cursor: pointer;
  outline: none;
  position: relative;
}

.Polaris-ResourceItem:not(.Polaris-ResourceItem--persistActions) .Polaris-ResourceItem__Actions {
  right: 1.6rem;
}

.Polaris-ResourceItem:hover {
  background-color: var(--p-surface-hovered);
}

.Polaris-ResourceItem:hover:not(.Polaris-ResourceItem--persistActions) .Polaris-ResourceItem__Actions {
  clip: auto;
  height: 100%;
  overflow: visible;
}

@media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em) {
  [data-has-navigation] .Polaris-ResourceItem:hover:not(.Polaris-ResourceItem--persistActions) .Polaris-ResourceItem__Actions {
    display: none;
  }
}

@media (max-width: 46.5em) {
  .Polaris-ResourceItem:hover:not(.Polaris-ResourceItem--persistActions) .Polaris-ResourceItem__Actions {
    display: none;
  }
}

.Polaris-ResourceItem:active {
  background-color: var(--p-surface-pressed);
}

.Polaris-ResourceItem__ItemWrapper {
  max-width: 100%;
  overflow: hidden;
}

.Polaris-ResourceItem--focusedInner, .Polaris-ResourceItem--focusedInner.Polaris-ResourceItem--focused, .Polaris-ResourceItem--focusedInner.Polaris-ResourceItem--focused.Polaris-ResourceItem--selected {
  box-shadow: none;
}

.Polaris-ResourceItem__Link, .Polaris-ResourceItem__Button {
  z-index: 1;
  height: 100%;
  width: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.Polaris-ResourceItem__Button {
  border: none;
  padding: 0;
}

.Polaris-ResourceItem__Container {
  z-index: 2;
  min-height: 4.4rem;
  align-items: flex-start;
  padding: 1.2rem 1.6rem;
  display: flex;
  position: relative;
}

@media (min-width: 28.625em) {
  .Polaris-ResourceItem__Container {
    padding: 1.2rem 2rem;
  }
}

.Polaris-ResourceItem--alignmentLeading {
  align-items: flex-start;
}

.Polaris-ResourceItem--alignmentTrailing {
  align-items: flex-end;
}

.Polaris-ResourceItem--alignmentCenter {
  align-items: center;
}

.Polaris-ResourceItem--alignmentFill {
  align-items: stretch;
}

.Polaris-ResourceItem--alignmentBaseline {
  align-items: baseline;
}

.Polaris-ResourceItem__Owned {
  display: flex;
}

.Polaris-ResourceItem__OwnedNoMedia {
  padding-top: .4rem;
}

.Polaris-ResourceItem__Handle {
  width: 4.8rem;
  min-height: 4.4rem;
  justify-content: center;
  align-items: center;
  margin: -1.2rem .4rem -1.2rem -1.2rem;
  display: flex;
}

@media (max-width: 28.615em) {
  .Polaris-ResourceItem__Handle {
    visibility: hidden;
  }

  .Polaris-ResourceItem--selectMode .Polaris-ResourceItem__Handle {
    visibility: visible;
  }
}

.Polaris-ResourceItem--selectable {
  width: calc(100% + 4rem);
  margin-right: -4rem;
  transition: transform .2s;
  transform: translateX(-4rem);
}

.Polaris-ResourceItem--selectable.Polaris-ResourceItem--selectMode {
  transform: translateX(0);
}

@media (min-width: 28.625em) {
  .Polaris-ResourceItem--selectable {
    width: 100%;
    margin-right: 0;
    transform: translateX(0);
  }
}

.Polaris-ResourceItem__Media {
  color: inherit;
  flex: none;
  margin-right: 2rem;
  text-decoration: none;
}

.Polaris-ResourceItem__Content {
  min-width: 0;
  max-width: 100%;
  flex: auto;
}

.Polaris-ResourceItem__Actions {
  pointer-events: initial;
  height: 100%;
  max-height: 5.6rem;
  clip: rect(0.1rem, 0.1rem, 0.1rem, 0.1rem);
  height: .1rem;
  display: flex;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.Polaris-ResourceItem--focused .Polaris-ResourceItem__Actions {
  clip: auto;
  height: 100%;
  overflow: visible;
}

@media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em) {
  [data-has-navigation] .Polaris-ResourceItem__Actions {
    display: none;
  }
}

@media (max-width: 46.5em) {
  .Polaris-ResourceItem__Actions {
    display: none;
  }
}

.Polaris-ResourceItem--persistActions .Polaris-ResourceItem__Actions {
  pointer-events: initial;
  height: 100%;
  flex: none;
  align-items: center;
  margin-top: 0;
  margin-left: 1.6rem;
  display: flex;
  position: relative;
}

@media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em) {
  [data-has-navigation] .Polaris-ResourceItem--persistActions .Polaris-ResourceItem__Actions {
    display: none;
  }
}

@media (max-width: 46.5em) {
  .Polaris-ResourceItem--persistActions .Polaris-ResourceItem__Actions {
    display: none;
  }
}

.Polaris-ResourceItem__Disclosure {
  width: 4.8rem;
  min-height: 4.4rem;
  pointer-events: initial;
  display: none;
  position: relative;
  top: -1.2rem;
  right: -1.6rem;
}

.Polaris-ResourceItem--selectMode .Polaris-ResourceItem__Disclosure {
  display: none;
}

@media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em) {
  [data-has-navigation] .Polaris-ResourceItem__Disclosure {
    flex: 0 0 4.8rem;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

@media (max-width: 46.5em) {
  .Polaris-ResourceItem__Disclosure {
    flex: 0 0 4.8rem;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.Polaris-ResourceItem--selected {
  background-color: var(--p-surface-selected);
}

.Polaris-ResourceItem--selected:hover {
  background-color: var(--p-surface-selected-hovered);
}

.Polaris-ResourceItem--selected:active {
  background-color: var(--p-surface-selected-pressed);
}

.Polaris-ResourceItem__ListItem {
  position: relative;
}

.Polaris-ResourceItem__ListItem:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 0rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: 0;
}

.Polaris-ResourceItem__ListItem + .Polaris-ResourceItem__ListItem {
  border-top: 0.1rem solid var(--p-divider);
}

.Polaris-ResourceItem__ListItem:after {
  border-radius: .2rem;
}

.Polaris-ResourceItem__ListItem:last-of-type, .Polaris-ResourceItem__ListItem:last-of-type .Polaris-ResourceItem__ItemWrapper, .Polaris-ResourceItem__ListItem:last-of-type.Polaris-ResourceItem--focused:after {
  border-bottom-left-radius: var(--p-border-radius-wide);
  border-bottom-right-radius: var(--p-border-radius-wide);
}

.Polaris-ResourceItem__ListItem.Polaris-ResourceItem--focused {
  z-index: 1;
}

.Polaris-ResourceItem__ListItem.Polaris-ResourceItem--focused:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

* + ul > .Polaris-ResourceItem__ListItem:first-of-type.Polaris-ResourceItem--focused:after {
  top: .1rem;
}

.Polaris-Select {
  position: relative;
}

.Polaris-Select select::-ms-expand {
  display: none;
}

.Polaris-Select--disabled .Polaris-Select__Content {
  color: var(--p-text-disabled);
}

.Polaris-Select--disabled .Polaris-Select__InlineLabel {
  color: inherit;
}

.Polaris-Select--disabled .Polaris-Select__Icon svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Select--disabled .Polaris-Select__Backdrop {
  border-color: var(--p-border-disabled);
}

.Polaris-Select--disabled .Polaris-Select__Backdrop:before {
  background-color: var(--p-action-secondary-disabled);
}

.Polaris-Select--disabled .Polaris-Select__Backdrop:hover {
  cursor: default;
}

.Polaris-Select__Content {
  text-transform: initial;
  letter-spacing: initial;
  z-index: 20;
  width: 100%;
  min-height: 3.6rem;
  border: none;
  align-items: center;
  padding: .5rem .8rem .5rem 1.2rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  display: flex;
  position: relative;
}

@media (min-width: 40em) {
  .Polaris-Select__Content {
    font-size: 1.4rem;
  }
}

.Polaris-Select__InlineLabel {
  color: var(--p-text-subdued);
  white-space: nowrap;
  margin-right: .4rem;
  overflow: hidden;
}

.Polaris-Select__SelectedOption {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  overflow: hidden;
}

.Polaris-Select__Prefix {
  padding-right: .8rem;
}

.Polaris-Select__Icon svg {
  fill: var(--p-icon);
}

.Polaris-Select__Input {
  text-transform: initial;
  letter-spacing: initial;
  text-rendering: auto;
  z-index: 30;
  width: 100%;
  height: 100%;
  opacity: .001;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  border: none;
  margin: 0;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 40em) {
  .Polaris-Select__Input {
    font-size: 1.4rem;
  }
}

.Polaris-Select__Backdrop {
  z-index: 10;
  border: 0.1rem solid var(--p-border-subdued);
  border-bottom-color: var(--p-border-shadow-subdued);
  border-radius: var(--p-border-radius-base);
  background-color: var(--p-surface);
  box-shadow: var(--p-button-drop-shadow);
  position: absolute;
  inset: 0;
}

.Polaris-Select__Backdrop:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.2rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.2rem;
}

.Polaris-Select--error .Polaris-Select__Backdrop {
  border-color: var(--p-border-critical);
  background-color: var(--p-surface-critical-subdued);
}

.Polaris-Select--error .Polaris-Select__Backdrop.Polaris-Select--hover, .Polaris-Select--error .Polaris-Select__Backdrop:hover {
  border-color: var(--p-border-critical);
}

.Polaris-Select--error .Polaris-Select__Input:focus ~ .Polaris-Select__Backdrop:after, .Polaris-Select__Input:focus ~ .Polaris-Select__Backdrop:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

@media (-ms-high-contrast: active) {
  .Polaris-Select__Content {
    color: windowText;
    -ms-high-contrast-adjust: none;
  }

  .Polaris-Select__InlineLabel {
    color: inherit;
  }

  .Polaris-Select__InlineLabel:after {
    content: ":";
  }

  .Polaris-Select__SelectedOption {
    color: inherit;
  }

  .Polaris-Select__Icon svg {
    fill: buttonText;
  }

  .Polaris-Select__Backdrop:after {
    display: none;
  }

  .Polaris-Select__Input:focus ~ .Polaris-Select__Content {
    color: highlightText;
  }

  .Polaris-Select__Input:focus ~ .Polaris-Select__Backdrop {
    background-color: highlight;
  }

  .Polaris-Select--disabled .Polaris-Select__Content {
    color: grayText;
  }

  .Polaris-Select--disabled .Polaris-Select__Icon {
    opacity: 1;
  }

  .Polaris-Select--disabled .Polaris-Select__Icon svg {
    fill: grayText;
  }
}

.Polaris-ResourceList__FiltersWrapper {
  padding: 1.2rem 1.2rem 1.6rem;
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList__FiltersWrapper {
    padding: 1.6rem;
  }
}

.Polaris-ResourceList__FiltersWrapper + .Polaris-ResourceList {
  border-top: 0.1rem solid var(--p-divider);
}

.Polaris-ResourceList__HeaderOuterWrapper {
  background-color: var(--p-surface);
  z-index: 2;
  border-top-left-radius: var(--p-border-radius-wide);
  border-top-right-radius: var(--p-border-radius-wide);
  position: relative;
  overflow: hidden;
}

.Polaris-ResourceList__HeaderOuterWrapper + .Polaris-ResourceList {
  border-top: 0.1rem solid var(--p-divider);
}

.Polaris-ResourceList__HeaderWrapper--disabled {
  pointer-events: none;
}

.Polaris-ResourceList__HeaderWrapper--overlay {
  z-index: 4;
  background-color: var(--p-overlay);
  position: absolute;
  inset: 0;
}

.Polaris-ResourceList__FiltersWrapper + .Polaris-ResourceList__HeaderOuterWrapper {
  margin-top: -1.6rem;
}

.Polaris-ResourceList__HeaderWrapper {
  width: 100%;
  min-height: 5.6rem;
  background-color: var(--p-surface);
  align-items: flex-start;
  padding: 1rem 1.2rem;
  display: flex;
  position: relative;
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList__HeaderWrapper {
    flex-direction: row;
    padding: 1rem 1.6rem;
  }
}

.Polaris-ResourceList__HeaderWrapper--isSticky {
  box-shadow: 0 0 0 .1rem #3f3f440d, 0 .1rem .3rem #3f3f4426;
}

.Polaris-ResourceList__HeaderContentWrapper {
  z-index: 1;
  min-height: 3.6rem;
  opacity: 1;
  transition: opacity .2s;
  display: flex;
  position: absolute;
  left: 1.2rem;
  right: 1.2rem;
}

.Polaris-ResourceList__HeaderWrapper--inSelectMode .Polaris-ResourceList__HeaderContentWrapper {
  opacity: 0;
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList__HeaderContentWrapper {
    align-self: flex-start;
    left: 1.6rem;
    right: 1.6rem;
  }
}

.Polaris-ResourceList__SortWrapper, .Polaris-ResourceList__AlternateToolWrapper {
  flex: 1;
  align-items: center;
  display: flex;
  position: relative;
}

.Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__SortWrapper, .Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__AlternateToolWrapper {
  padding-right: .8rem;
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList__SortWrapper, .Polaris-ResourceList__AlternateToolWrapper {
    flex: 0 auto;
    margin-left: 1.6rem;
    position: relative;
    left: auto;
  }

  .Polaris-ResourceList__HeaderWrapper--hasAlternateTool.Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__SortWrapper, .Polaris-ResourceList__HeaderWrapper--hasSort.Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__SortWrapper, .Polaris-ResourceList__HeaderWrapper--hasAlternateTool.Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__AlternateToolWrapper, .Polaris-ResourceList__HeaderWrapper--hasSort.Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__AlternateToolWrapper {
    padding-right: 0;
  }
}

.Polaris-ResourceList__SortWrapper {
  min-width: 0;
  max-width: 100%;
}

.Polaris-ResourceList__SortWrapper > * {
  max-width: 100%;
}

.Polaris-ResourceList__HeaderTitleWrapper {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  align-self: center;
  padding-left: .4rem;
}

.Polaris-ResourceList__HeaderWrapper--hasAlternateTool .Polaris-ResourceList__HeaderTitleWrapper, .Polaris-ResourceList__HeaderWrapper--hasSort .Polaris-ResourceList__HeaderTitleWrapper {
  display: none;
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__HeaderTitleWrapper, .Polaris-ResourceList__HeaderWrapper--hasAlternateTool.Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__HeaderTitleWrapper, .Polaris-ResourceList__HeaderWrapper--hasSort.Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__HeaderTitleWrapper {
    display: none;
  }

  .Polaris-ResourceList__HeaderWrapper--hasAlternateTool .Polaris-ResourceList__HeaderTitleWrapper, .Polaris-ResourceList__HeaderWrapper--hasSort .Polaris-ResourceList__HeaderTitleWrapper {
    display: block;
  }
}

.Polaris-ResourceList__BulkActionsWrapper {
  z-index: 2;
  width: 100%;
  position: relative;
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList__BulkActionsWrapper {
    flex: 0 auto;
    align-self: flex-start;
  }
}

.Polaris-ResourceList__CheckableButtonWrapper {
  display: none;
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList__CheckableButtonWrapper {
    flex: 1;
    display: block;
  }
}

.Polaris-ResourceList__SelectButtonWrapper {
  flex: none;
  position: relative;
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList__SelectButtonWrapper {
    display: none;
  }
}

.Polaris-ResourceList__EmptySearchResultWrapper {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
}

@media (min-height: 37.5em) {
  .Polaris-ResourceList__EmptySearchResultWrapper {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
  }
}

.Polaris-ResourceList__ResourceListWrapper {
  position: relative;
}

.Polaris-ResourceList {
  z-index: 1;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.Polaris-ResourceList__ItemWrapper {
  z-index: 1;
  max-width: 100%;
  position: relative;
  overflow: hidden;
}

.Polaris-ResourceList__ItemWrapper + .Polaris-ResourceList__ItemWrapper {
  border-top: 0.1rem solid var(--p-divider);
}

.Polaris-ResourceList__ItemWrapper--isLoading {
  min-height: 6.4rem;
}

.Polaris-ResourceList__SpinnerContainer {
  z-index: 4;
  justify-content: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.Polaris-ResourceList__LoadingOverlay {
  z-index: 3;
  background-color: var(--p-overlay);
  justify-content: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.Polaris-ResourceList__DisabledPointerEvents {
  pointer-events: none;
}

.Polaris-ResourceList--disableTextSelection {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Polaris-FilterControl-DateSelector__DateTextField {
  margin-top: .8rem;
}

.Polaris-FilterControl-DateSelector__DatePicker {
  margin-top: 1.6rem;
}

.Polaris-ResourceList-FilterControl__AppliedFilters {
  -webkit-overflow-scrolling: touch;
  flex-wrap: nowrap;
  margin: -1.2rem -1.2rem 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  overflow: auto hidden;
}

.Polaris-ResourceList-FilterControl__AppliedFilters:after {
  content: "";
  flex: 0 0 1.2rem;
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList-FilterControl__AppliedFilters {
    flex-wrap: wrap;
    margin-top: -1.6rem;
    margin-left: -.8rem;
    margin-right: 0;
  }

  .Polaris-ResourceList-FilterControl__AppliedFilters:after {
    content: none;
    flex: none;
  }
}

@media (max-width: 28.625em) {
  .Polaris-ResourceList-FilterControl__AppliedFilters {
    padding-bottom: .8rem;
  }
}

.Polaris-ResourceList-FilterControl__AppliedFilter {
  max-width: calc(100% - .8rem);
  flex: 1 0 auto;
  margin-top: .8rem;
  margin-left: 1.2rem;
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList-FilterControl__AppliedFilter {
    flex: 0 auto;
    margin-left: .8rem;
  }
}

.Polaris-SkeletonBodyText {
  height: .8rem;
  position: relative;
}

.Polaris-SkeletonBodyText:after {
  content: "";
  background-color: var(--p-surface-neutral);
  border-radius: .3rem;
  display: block;
  position: absolute;
  inset: 0;
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-SkeletonBodyText:after {
    background-color: grayText;
  }
}

.Polaris-SkeletonBodyText:last-child:not(:first-child) {
  width: 80%;
}

.Polaris-SkeletonBodyText + .Polaris-SkeletonBodyText {
  margin-top: 1.2rem;
}

.Polaris-SkeletonDisplayText__DisplayText {
  max-width: 12rem;
  position: relative;
}

.Polaris-SkeletonDisplayText__DisplayText:after {
  content: "";
  background-color: var(--p-surface-neutral);
  border-radius: .3rem;
  display: block;
  position: absolute;
  inset: 0;
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-SkeletonDisplayText__DisplayText:after {
    background-color: grayText;
  }
}

.Polaris-SkeletonDisplayText--sizeSmall {
  height: 2.4rem;
}

@media (min-width: 40em) {
  .Polaris-SkeletonDisplayText--sizeSmall {
    height: 2.8rem;
  }
}

.Polaris-SkeletonDisplayText--sizeMedium {
  height: 2.8rem;
}

@media (min-width: 40em) {
  .Polaris-SkeletonDisplayText--sizeMedium {
    height: 3.2rem;
  }
}

.Polaris-SkeletonDisplayText--sizeLarge {
  height: 2.8rem;
}

@media (min-width: 40em) {
  .Polaris-SkeletonDisplayText--sizeLarge {
    height: 3.2rem;
  }
}

.Polaris-SkeletonDisplayText--sizeExtraLarge {
  height: 3.6rem;
}

@media (min-width: 40em) {
  .Polaris-SkeletonDisplayText--sizeExtraLarge {
    height: 4.4rem;
  }
}

.Polaris-SkeletonPage__Page {
  max-width: 99.8rem;
  margin: 0 auto;
  padding: 0;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-SkeletonPage__Page {
    padding: 0 2rem;
  }
}

@media (min-width: 30.625em) {
  .Polaris-SkeletonPage__Page {
    padding: 0 2rem;
  }
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-SkeletonPage__Page {
    padding: 0 3.2rem;
  }
}

@media (min-width: 46.5em) {
  .Polaris-SkeletonPage__Page {
    padding: 0 3.2rem;
  }
}

.Polaris-SkeletonPage--fullWidth {
  max-width: none;
}

.Polaris-SkeletonPage--narrowWidth {
  max-width: 66.2rem;
}

.Polaris-SkeletonPage__Content {
  margin: .8rem 0;
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-SkeletonPage__Content {
    margin-top: .8rem;
  }
}

@media (min-width: 46.5em) {
  .Polaris-SkeletonPage__Content {
    margin-top: .8rem;
  }
}

.Polaris-SkeletonPage__Header {
  padding: 1.6rem 1.6rem .8rem;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-SkeletonPage__Header {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 30.625em) {
  .Polaris-SkeletonPage__Header {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-SkeletonPage__Header {
    padding-top: 1.6rem;
  }
}

@media (min-width: 46.5em) {
  .Polaris-SkeletonPage__Header {
    padding-top: 1.6rem;
  }
}

.Polaris-SkeletonPage__BreadcrumbAction {
  margin-top: -.4rem;
  margin-bottom: -.4rem;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
}

.Polaris-SkeletonPage__TitleAndPrimaryAction {
  display: flex;
}

@media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em) {
  [data-has-navigation] .Polaris-SkeletonPage__TitleAndPrimaryAction {
    display: block;
  }
}

@media (max-width: 46.5em) {
  .Polaris-SkeletonPage__TitleAndPrimaryAction {
    display: block;
  }
}

.Polaris-SkeletonPage__TitleWrapper {
  flex: 1;
}

.Polaris-SkeletonPage__Title {
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 2.8rem;
}

@media (min-width: 40em) {
  .Polaris-SkeletonPage__Title {
    font-size: 2rem;
  }
}

.Polaris-SkeletonPage__SkeletonTitle {
  max-width: 12rem;
  height: 2.8rem;
  position: relative;
}

.Polaris-SkeletonPage__SkeletonTitle:after {
  content: "";
  background-color: var(--p-surface-neutral);
  border-radius: .3rem;
  display: block;
  position: absolute;
  inset: 0;
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-SkeletonPage__SkeletonTitle:after {
    background-color: grayText;
  }
}

.Polaris-SkeletonPage__PrimaryAction {
  align-self: stretch;
}

.Polaris-SkeletonPage__PrimaryAction > * {
  height: 3.6rem;
  min-width: 10rem;
}

@media (max-width: 48.0625em), (min-width: 48.0625em) and (max-width: 65em) {
  [data-has-navigation] .Polaris-SkeletonPage__PrimaryAction {
    margin-top: 1.6rem;
    margin-bottom: -.8rem;
  }
}

@media (max-width: 50em) {
  .Polaris-SkeletonPage__PrimaryAction {
    margin-top: 1.6rem;
    margin-bottom: -.8rem;
  }
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-SkeletonPage__PrimaryAction {
    margin-top: .8rem;
    margin-bottom: -.8rem;
  }
}

@media (min-width: 30.625em) {
  .Polaris-SkeletonPage__PrimaryAction {
    margin-top: .8rem;
    margin-bottom: -.8rem;
  }
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-SkeletonPage__PrimaryAction {
    margin-top: 0;
  }
}

@media (min-width: 46.5em) {
  .Polaris-SkeletonPage__PrimaryAction {
    margin-top: 0;
  }
}

@media (max-width: 48.0625em) and (min-width: 50em), (min-width: 65em) {
  [data-has-navigation] .Polaris-SkeletonPage__PrimaryAction {
    margin-top: 0;
  }
}

@media (min-width: 50em) {
  .Polaris-SkeletonPage__PrimaryAction {
    margin-top: 0;
  }
}

.Polaris-SkeletonPage__Actions {
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  margin-top: .8rem;
  display: flex;
}

.Polaris-SkeletonPage__Action {
  min-height: 2.8rem;
  flex-direction: column;
  justify-content: center;
  margin-top: -.4rem;
  margin-bottom: -.4rem;
  padding-top: 1.6rem;
  padding-right: 2.4rem;
  display: flex;
}

.Polaris-SkeletonPage__Action:first-child {
  padding-right: 0;
}

@media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em) {
  [data-has-navigation] .Polaris-SkeletonPage__Action:not(:last-child) {
    display: none;
  }
}

@media (max-width: 46.5em) {
  .Polaris-SkeletonPage__Action:not(:last-child) {
    display: none;
  }
}

.Polaris-SkeletonThumbnail {
  position: relative;
}

.Polaris-SkeletonThumbnail:after {
  content: "";
  background-color: var(--p-surface-neutral);
  border-radius: .3rem;
  display: block;
  position: absolute;
  inset: 0;
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-SkeletonThumbnail:after {
    background-color: grayText;
  }
}

.Polaris-SkeletonThumbnail--sizeSmall {
  height: 4rem;
  width: 4rem;
}

.Polaris-SkeletonThumbnail--sizeMedium {
  height: 6rem;
  width: 6rem;
}

.Polaris-SkeletonThumbnail--sizeLarge {
  height: 8rem;
  width: 8rem;
}

.Polaris-Tabs {
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.Polaris-Tabs__Wrapper {
  border-bottom: 0.1rem solid var(--p-divider);
  padding: 0 .8rem;
}

.Polaris-Tabs--fitted {
  flex-wrap: nowrap;
}

.Polaris-Tabs--fitted .Polaris-Tabs__TabContainer {
  flex: 100%;
}

.Polaris-Tabs--fitted .Polaris-Tabs__Title {
  width: 100%;
  padding: .8rem 1.6rem;
}

.Polaris-Tabs--fillSpace .Polaris-Tabs__TabContainer {
  flex: auto;
}

.Polaris-Tabs__TabContainer {
  margin: 0;
  padding: 0;
  display: flex;
}

.Polaris-Tabs__Tab {
  color: inherit;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  text-transform: initial;
  letter-spacing: initial;
  color: var(--p-text-subdued);
  color: var(--p-text);
  width: 100%;
  min-width: 100%;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  justify-content: center;
  margin: .1rem 0 -.1rem;
  padding: .8rem .4rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  text-decoration: none;
  position: relative;
}

.Polaris-Tabs__Tab:visited {
  color: inherit;
}

.Polaris-Tabs__Tab:focus {
  outline: none;
}

@media (min-width: 40em) {
  .Polaris-Tabs__Tab {
    font-size: 1.4rem;
  }
}

.Polaris-Tabs__Tab:hover {
  text-decoration: none;
}

.Polaris-Tabs__Tab:hover .Polaris-Tabs__Title {
  color: var(--p-text);
  background-color: #0000;
  font-weight: 400;
}

.Polaris-Tabs__Tab:hover .Polaris-Tabs__Title:before {
  background-color: var(--p-border-hovered);
}

.Polaris-Tabs__Tab:active .Polaris-Tabs__Title {
  background-color: #0000;
}

.Polaris-Tabs__Tab:active .Polaris-Tabs__Title:before {
  background: var(--p-surface-primary-selected-pressed);
}

.Polaris-Tabs__Tab:focus .Polaris-Tabs__Title {
  color: var(--p-text);
  font-weight: 400;
}

.Polaris-Tabs__Tab:focus:not(:active) .Polaris-Tabs__Title:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Tabs__Tab--selected {
  color: var(--p-text);
  font-weight: 400;
}

.Polaris-Tabs__Tab--selected:focus .Polaris-Tabs__Title {
  outline: .3rem solid #0000;
}

.Polaris-Tabs__Tab--selected:focus .Polaris-Tabs__Title:before {
  background: var(--p-action-primary);
}

.Polaris-Tabs__Tab--selected .Polaris-Tabs__Title {
  color: var(--p-text);
  outline: .3rem solid #0000;
}

.Polaris-Tabs__Tab--selected .Polaris-Tabs__Title:before {
  background: var(--p-action-primary);
}

.Polaris-Tabs__Title {
  border-radius: var(--p-border-radius-base);
  min-width: 5rem;
  color: var(--p-text-subdued);
  padding: .8rem 1.6rem;
  display: block;
  position: relative;
}

.Polaris-Tabs__Title:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

.Polaris-Tabs__Title:before {
  content: "";
  height: .3rem;
  border-top-left-radius: var(--p-border-radius-base);
  border-top-right-radius: var(--p-border-radius-base);
  position: absolute;
  bottom: -.8rem;
  left: 0;
  right: 0;
}

.Polaris-Tabs--titleWithIcon {
  display: flex;
}

.Polaris-Tabs__Panel {
  display: block;
}

.Polaris-Tabs__Panel:focus {
  outline: none;
}

.Polaris-Tabs__Panel--hidden {
  display: none;
}

.Polaris-Tabs__List {
  margin: 0;
  padding: .8rem;
  list-style: none;
}

.Polaris-Tabs__Item {
  color: inherit;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  width: 100%;
  min-height: 1.6rem;
  text-align: left;
  cursor: pointer;
  border-radius: var(--p-border-radius-base);
  background: none;
  border: none;
  margin: 0;
  padding: .8rem 1.6rem;
  text-decoration: none;
  display: block;
  position: relative;
}

.Polaris-Tabs__Item:visited {
  color: inherit;
}

.Polaris-Tabs__Item:focus {
  outline: none;
}

.Polaris-Tabs__Item:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

.Polaris-Tabs__Item::-moz-focus-inner {
  border: none;
}

.Polaris-Tabs__Item:hover {
  background-color: var(--p-surface-hovered);
}

.Polaris-Tabs__Item:active {
  background-color: var(--p-surface-primary-selected-pressed);
}

.Polaris-Tabs__Item:focus:not(:active):after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Tabs__DisclosureTab {
  display: none;
}

.Polaris-Tabs__DisclosureTab--visible {
  display: flex;
}

.Polaris-Tabs__DisclosureActivator {
  height: 100%;
  cursor: pointer;
  background-color: #0000;
  border: none;
  outline: none;
  margin: .1rem .1rem -.1rem 0;
  position: relative;
}

.Polaris-Tabs__DisclosureActivator:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

.Polaris-Tabs__DisclosureActivator:hover svg, .Polaris-Tabs__DisclosureActivator:focus svg {
  fill: var(--p-icon);
}

.Polaris-Tabs__DisclosureActivator:focus .Polaris-Tabs__Title:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-Tabs__DisclosureActivator:hover .Polaris-Tabs__Title:before {
  background-color: var(--p-border-hovered);
}

.Polaris-Tabs__TabMeasurer {
  visibility: hidden;
  height: 0;
  display: flex;
}

.Polaris-Thumbnail {
  background: var(--p-surface);
  min-width: 4rem;
  max-width: 100%;
  border-radius: var(--p-border-radius-base);
  border: 0.1rem solid var(--p-divider);
  display: block;
  position: relative;
  overflow: hidden;
}

.Polaris-Thumbnail:after {
  content: "";
  padding-bottom: 100%;
  display: block;
}

.Polaris-Thumbnail--sizeSmall {
  width: 4rem;
}

.Polaris-Thumbnail--sizeMedium {
  width: 6rem;
}

.Polaris-Thumbnail--sizeLarge {
  width: 8rem;
}

.Polaris-Thumbnail > * {
  max-width: 100%;
  max-height: 100%;
  color: var(--p-icon-subdued);
  margin: auto;
  position: absolute;
  inset: 0;
}

.Polaris-Thumbnail > * svg {
  fill: currentColor;
}

.Polaris-TopBar-SearchDismissOverlay {
  z-index: 515;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.Polaris-TopBar-SearchDismissOverlay--visible {
  background-color: #0000;
  animation: none;
}

@keyframes Polaris-TopBar-SearchDismissOverlay__fade--in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.Polaris-TopBar-Search {
  visibility: hidden;
  z-index: 516;
  pointer-events: none;
  box-shadow: var(--p-modal-shadow);
  position: fixed;
  top: 5.6rem;
  left: 0;
  right: 0;
  overflow: hidden;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-TopBar-Search {
    max-width: 58rem;
    border-radius: var(--p-border-radius-wide);
    margin: .4rem 2rem 0;
    position: absolute;
    top: 100%;
  }
}

@media (min-width: 30.625em) {
  .Polaris-TopBar-Search {
    max-width: 58rem;
    border-radius: var(--p-border-radius-wide);
    margin: .4rem 2rem 0;
    position: absolute;
    top: 100%;
  }
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-TopBar-Search {
    margin: .4rem 3.2rem 0;
  }
}

@media (min-width: 46.5em) {
  .Polaris-TopBar-Search {
    margin: .4rem 3.2rem 0;
  }
}

.Polaris-TopBar-Search__SearchContent {
  background-color: var(--p-surface);
}

.Polaris-TopBar-Search--visible {
  visibility: initial;
  pointer-events: all;
}

.Polaris-TopBar-Search__Results {
  max-height: calc(100vh - 5.6rem);
  flex-direction: column;
  margin: 0;
  display: flex;
  position: relative;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-TopBar-Search__Results {
    max-height: 60vh;
  }
}

@media (min-width: 30.625em) {
  .Polaris-TopBar-Search__Results {
    max-height: 60vh;
  }
}

.Polaris-TopBar-SearchField {
  z-index: 519;
  width: 100%;
  max-width: 58rem;
  border: .1rem solid #0000;
  flex: auto;
  align-items: center;
  display: flex;
  position: relative;
}

.Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Input, .Polaris-TopBar-SearchField__Input:focus {
  color: var(--p-text);
  border: none;
}

.Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Input::-ms-input-placeholder, .Polaris-TopBar-SearchField__Input:focus::-ms-input-placeholder {
  color: var(--p-text-subdued);
}

.Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Input::placeholder, .Polaris-TopBar-SearchField__Input:focus::placeholder {
  color: var(--p-text-subdued);
}

.Polaris-TopBar-SearchField__Input:focus ~ .Polaris-TopBar-SearchField__Backdrop {
  background-color: var(--p-surface-search-field);
}

.Polaris-TopBar-SearchField__Input:focus ~ .Polaris-TopBar-SearchField__Backdrop:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-TopBar-SearchField__Input:focus ~ .Polaris-TopBar-SearchField__BackdropShowFocusBorder {
  border: 0.1rem solid var(--top-bar-border);
}

.Polaris-TopBar-SearchField__Input:focus ~ .Polaris-TopBar-SearchField__Icon svg {
  fill: var(--p-icon);
}

.Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Backdrop {
  background-color: var(--p-surface-search-field);
}

.Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__BackdropShowFocusBorder {
  border: 0.1rem solid var(--top-bar-border);
}

.Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Icon svg {
  fill: var(--p-icon);
}

.Polaris-TopBar-SearchField__Input {
  text-transform: initial;
  letter-spacing: initial;
  z-index: 2;
  height: 3.6rem;
  width: 100%;
  color: var(--p-text);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: fill, color;
  -webkit-appearance: textfield;
  -ms-appearance: textfield;
  appearance: textfield;
  background-color: #0000;
  border: none;
  outline: none;
  padding: 0 0 0 3.6rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  transition: fill .2s, color .2s;
}

@media (min-width: 40em) {
  .Polaris-TopBar-SearchField__Input {
    font-size: 1.4rem;
  }
}

.Polaris-TopBar-SearchField__Input::-ms-input-placeholder {
  color: var(--p-text);
  transition: color .167s 33ms;
}

.Polaris-TopBar-SearchField__Input::placeholder {
  color: var(--p-text);
  transition: color .167s 33ms;
}

.Polaris-TopBar-SearchField__Input::-webkit-search-decoration, .Polaris-TopBar-SearchField__Input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

.Polaris-TopBar-SearchField__Icon {
  z-index: 3;
  height: 2rem;
  pointer-events: none;
  display: flex;
  position: absolute;
  top: 50%;
  left: .8rem;
  transform: translateY(-50%);
}

.Polaris-TopBar-SearchField__Icon svg {
  fill: var(--p-icon);
  transition: fill .167s 33ms;
}

.Polaris-TopBar-SearchField__Clear {
  z-index: 3;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background: none;
  border: none;
  padding: .8rem;
  position: relative;
}

.Polaris-TopBar-SearchField__Clear svg {
  fill: var(--p-icon);
}

.Polaris-TopBar-SearchField__Clear:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-wide) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

.Polaris-TopBar-SearchField__Clear:focus, .Polaris-TopBar-SearchField__Clear:hover {
  outline: none;
}

.Polaris-TopBar-SearchField__Clear:focus svg, .Polaris-TopBar-SearchField__Clear:hover svg {
  fill: var(--p-icon-hovered);
}

.Polaris-TopBar-SearchField__Clear:focus:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-TopBar-SearchField__Clear:active svg {
  fill: var(--p-icon-pressed);
}

.Polaris-TopBar-SearchField__Clear:active:after {
  border: none;
}

.Polaris-TopBar-SearchField__Backdrop {
  z-index: 1;
  background-color: var(--p-surface-search-field, var(--top-bar-background-lighter));
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: background-color;
  border-radius: var(--p-border-radius-base);
  transition: background-color .2s;
  animation: Polaris-TopBar-SearchField--toLightBackground .01ms;
  position: absolute;
  inset: 0;
}

.Polaris-TopBar-SearchField__Backdrop:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

@keyframes Polaris-TopBar-SearchField--toLightBackground {
  to {
    background-color: var(--p-surface-neutral, var(--top-bar-background-lighter));
  }
}

.Polaris-MessageIndicator__MessageIndicatorWrapper {
  position: relative;
}

.Polaris-MessageIndicator {
  z-index: 1;
  width: 1.2rem;
  height: 1.2rem;
  background-color: var(--p-icon-highlight);
  border: solid 0.2rem var(--p-background);
  border-radius: 100%;
  position: absolute;
  top: -.3rem;
  right: -.3rem;
}

.Polaris-Menu-Message__Section {
  max-width: 32.5rem;
  border-top: 0.1rem solid var(--p-divider);
  margin-top: .8rem;
  padding-top: .8rem;
}

.Polaris-TopBar-Menu__ActivatorWrapper {
  height: 5.6rem;
  align-items: center;
  display: flex;
}

.Polaris-TopBar-Menu__Activator {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  color: var(--p-text);
  min-width: 3.6rem;
  cursor: pointer;
  background: none;
  border-radius: .3rem;
  border: none;
  justify-content: center;
  align-items: center;
  margin: 0 .8rem 0 0;
  padding: .6rem .8rem;
  transition: background-color .1s;
  display: flex;
  position: relative;
}

.Polaris-TopBar-Menu__Activator:focus {
  outline: none;
}

.Polaris-TopBar-Menu__Activator:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1rem var(--p-focused);
  transition: box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.1rem);
  display: block;
  position: absolute;
  inset: -.1rem;
}

.Polaris-TopBar-Menu__Activator:focus {
  background-color: var(--top-bar-background-lighter);
  outline: none;
}

.Polaris-TopBar-Menu__Activator:focus:after {
  box-shadow: 0 0 0 0.2rem var(--p-focused);
  outline: .1rem solid #0000;
}

.Polaris-TopBar-Menu__Activator:hover {
  background-color: var(--top-bar-background-lighter, var(--p-surface-hovered));
}

.Polaris-TopBar-Menu__Activator:active, .Polaris-TopBar-Menu__Activator[aria-expanded="true"] {
  background-color: var(--top-bar-background-darker, var(--p-surface-pressed));
  outline: none;
  transition: none;
}

.Polaris-TopBar-Menu__Activator:active:after, .Polaris-TopBar-Menu__Activator[aria-expanded="true"]:after {
  border: none;
}

@media (max-width: 48.0525em) {
  .Polaris-TopBar-Menu__Activator {
    margin: 0;
  }

  .Polaris-TopBar-Menu__Activator:focus, .Polaris-TopBar-Menu__Activator:hover, .Polaris-TopBar-Menu__Activator:active, .Polaris-TopBar-Menu__Activator[aria-expanded="true"] {
    opacity: .85;
    background-color: #0000;
  }
}

.Polaris-TopBar-Menu__Section {
  border-top: 0.1rem solid var(--p-divider);
  margin-top: .8rem;
  padding-top: .8rem;
}

.Polaris-TopBar-UserMenu__Details {
  max-width: 16rem;
  margin-left: .8rem;
}

@media (max-width: 48.0525em) {
  .Polaris-TopBar-UserMenu__Details {
    display: none;
  }
}

.Polaris-TopBar-UserMenu__Name {
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: initial;
  letter-spacing: initial;
  color: var(--p-text);
  text-align: left;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.6rem;
  overflow: hidden;
}

@media (min-width: 40em) {
  .Polaris-TopBar-UserMenu__Name {
    font-size: 1.4rem;
  }
}

.Polaris-TopBar-UserMenu__Detail {
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: .7;
  color: var(--p-text);
  text-align: left;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2rem;
  overflow: hidden;
}

@media (min-width: 40em) {
  .Polaris-TopBar-UserMenu__Detail {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

.Polaris-TopBar-UserMenu__Name, .Polaris-TopBar-UserMenu__Detail {
  transition: color .167s 33ms;
}

.Polaris-TopBar {
  height: 5.6rem;
  box-shadow: var(--p-top-bar-shadow);
  background-color: var(--p-surface);
  transition: background-color .2s ease-in-out;
  display: flex;
  position: relative;
}

.Polaris-TopBar:after {
  content: "";
  width: 100%;
  border-bottom: .1rem solid #0000;
  position: absolute;
  bottom: 0;
}

.Polaris-TopBar__LogoDisplayControl {
  display: none;
}

@media (min-width: 48.0625em) {
  .Polaris-TopBar__LogoDisplayControl {
    display: flex;
  }
}

.Polaris-TopBar__LogoDisplayContainer {
  display: flex;
}

.Polaris-TopBar__LogoContainer {
  height: 100%;
  flex: 0 0 24rem;
  flex-basis: calc(24rem + constant(safe-area-inset-left));
  flex-basis: calc(24rem + env(safe-area-inset-left));
  padding: 0 .8rem 0 1.6rem;
  padding-left: calc(1.6rem + constant(safe-area-inset-left));
  padding-left: calc(1.6rem + env(safe-area-inset-left));
  align-items: center;
}

.Polaris-TopBar__Logo, .Polaris-TopBar__LogoLink {
  display: block;
}

.Polaris-TopBar__ContextControl {
  display: none;
}

@media (min-width: 48.0625em) {
  .Polaris-TopBar__ContextControl {
    width: 24rem;
    display: block;
  }
}

@media (min-width: 87.5em) {
  .Polaris-TopBar__ContextControl {
    width: 24rem;
  }
}

.Polaris-TopBar__NavigationIcon {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  fill: var(--p-icon);
  background: none;
  border-radius: .3rem;
  border: none;
  align-self: center;
  margin: 0 .8rem 0 1rem;
  padding: .8rem;
  transition: fill .167s 33ms;
  position: relative;
}

.Polaris-TopBar__NavigationIcon:focus {
  outline: none;
}

.Polaris-TopBar__NavigationIcon.Polaris-TopBar--focused:active {
  background-color: var(--p-surface-pressed);
}

.Polaris-TopBar__NavigationIcon:hover {
  background-color: var(--p-surface-hovered);
}

.Polaris-TopBar__NavigationIcon:after {
  content: "";
  width: calc(100% + 2rem);
  height: calc(100% + 2rem);
  position: absolute;
  top: -.8rem;
  left: -.8rem;
}

@media (min-width: 48.0625em) {
  .Polaris-TopBar__NavigationIcon {
    display: none;
  }
}

.Polaris-TopBar__Contents {
  z-index: 10;
  height: 100%;
  flex: auto;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

@media (min-width: 42.5em) {
  .Polaris-TopBar__Contents {
    position: relative;
  }
}

.Polaris-TopBar__SearchField {
  max-width: 99.8rem;
  width: 100%;
  max-width: none;
  margin: 0 0 0 calc(50% - 49.9rem);
  padding: 0;
  position: relative;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-TopBar__SearchField {
    padding: 0 2rem;
  }
}

@media (min-width: 30.625em) {
  .Polaris-TopBar__SearchField {
    padding: 0 2rem;
  }
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-TopBar__SearchField {
    padding: 0 3.2rem;
  }
}

@media (min-width: 46.5em) {
  .Polaris-TopBar__SearchField {
    padding: 0 3.2rem;
  }
}

@media (max-width: 1238px) {
  .Polaris-TopBar__SearchField {
    margin-left: 0;
    margin-right: .4rem;
  }
}

.Polaris-TopBar__SecondaryMenu svg {
  fill: var(--p-icon);
}

.Polaris-VideoThumbnail__Thumbnail {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 56.25%;
  position: relative;
}

.Polaris-VideoThumbnail__Thumbnail.Polaris-VideoThumbnail__WithPlayer {
  z-index: 1;
  width: 100%;
  height: 100%;
  padding-bottom: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.Polaris-VideoThumbnail__PlayButton {
  width: 100%;
  height: 100%;
  opacity: .8;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  transition: opacity .2s ease-in;
  position: absolute;
  top: 0;
  left: 0;
}

.Polaris-VideoThumbnail__PlayButton:hover, .Polaris-VideoThumbnail__PlayButton:focus {
  opacity: 1;
}

.Polaris-VideoThumbnail__PlayButton:focus {
  box-shadow: inset 0.2rem 0 0 var(--p-focused);
  background-image: linear-gradient(#dfe3e84d, #dfe3e84d);
  outline: none;
}

.Polaris-VideoThumbnail__PlayIcon {
  width: 6rem;
  height: 6rem;
  margin-top: -3rem;
  margin-left: -3rem;
  position: absolute;
  top: 50%;
  left: 50%;
}

.Polaris-VideoThumbnail__Timestamp {
  border-radius: var(--p-border-radius-base);
  color: var(--p-text);
  background-color: var(--p-surface);
  opacity: .8;
  text-align: center;
  margin-bottom: .8rem;
  margin-left: .8rem;
  padding: 0 .4rem;
  position: absolute;
  bottom: 0;
}

.Polaris-VideoThumbnail--withProgress {
  margin-bottom: 1.2rem;
}

.Polaris-VideoThumbnail__Progress {
  width: 100%;
  background-color: var(--p-surface);
  height: .6rem;
  position: absolute;
  bottom: 0;
  overflow: hidden;
}

.Polaris-VideoThumbnail__Indicator {
  height: inherit;
  width: 100%;
  transform-origin: 0;
  background-color: var(--p-border-highlight);
  transition: transform .5s;
  transform: scaleX(0);
}

.Polaris-VideoThumbnail__ProgressBar, .Polaris-VideoThumbnail__Label {
  width: .1rem !important;
  height: .1rem !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

/*# sourceMappingURL=index.15d0629e.css.map */
